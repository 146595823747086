import React from "react";
import { Link, useLocation } from "react-router-dom";
import moment from "moment/moment";
import { removeHtmlTags } from "../../components/htmlToString/HtmlToString";

const BlogCard = ({ item }) => {
  const { pathname } = useLocation();
  return (
    <>
      <div className="col-md-6 col-lg-4 mb-6">
        <div className="card border-0">
          <Link
            to={pathname + "/" + item._id}
            className="link-hover-dark-primary"
          >
            <img
              style={{ height: "300px", width: "100%", objectFit: "cover" }}
              src={item?.blog_images[0]}
              alt="product 1"
              className="card-img-top image"
            />
          </Link>
          <div className="card-body px-0">
            <h5 className=" lh-13 card-title mb-2 letter-spacing-25">
              <Link
                to={pathname + "/" + item._id}
                className="link-hover-dark-primary"
              >
                {item?.title}
              </Link>
            </h5>
            <p className="lh-13 letter-spacing-25 card-text-hide">
              {/* <Link
                to={pathname + "/" + item._id}
                className="link-hover-dark-primary text-capitalize"
              > */}
              {item?.description &&
                removeHtmlTags(item?.description).substring(0,50)}
              ...
              {/* </Link> */}
            </p>
            <ul className="list-inline">
              <li className="list-inline-item mr-0">
                <span className="text-gray">
                  {moment(item?.createdAt).format("MMMM Do YYYY")} by
                </span>
              </li>
              <li className="list-inline-item">&nbsp;{item?.user_name}</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogCard;
