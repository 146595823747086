import { CloseIcon } from "../../assets/icons/Icons";
import ButtonCom from "../../common/Button";
import * as React from "react";
import { Box, CircularProgress, Grid, IconButton } from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  customCircularProgress: {
    width: '25px !important',
    height: '25px !important',
    color: '#000 !important',
  },
}));

const styles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  border: "0",
  outline: "0",
  bgcolor: "#fff",
  borderRadius: "10px",
  p: 2,
};

const DeleteDialog = ({
  dialogTitle,
  dialogText,
  handleCloseDelete,
  open,
  handleClose,
  onChange,
  handleDelete,
  loading
}) => {
  const classes = useStyles();
  return (
    <div>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={styles}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h5">{dialogTitle}</Typography>

            <IconButton>
              <CloseIcon onClick={handleClose} />
            </IconButton>
          </div>
          <div style={{display: 'flex', justifyContent: 'flex-end', gap: '1rem', marginTop: '3rem'}}>
            {/* <Grid
              container
            > */}
              <Grid item xs={9} className="text-end">
                <ButtonCom
                  style={{ backgroundColor: "rgb(201 200 200)", color: "#000", fontWeight: '700' }}
                  text= {loading ? <CircularProgress className={classes.customCircularProgress}/> : 'Yes'}
                  onClick={() => handleDelete()}
                  type="submit"
                />
              </Grid>
              <Grid item xs={3} className="text-end">
                <ButtonCom

                  text="No"
                  onClick={handleClose}
                />
              </Grid>
            {/* </Grid> */}
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default DeleteDialog;
