import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Pagination,
  Typography,
  Drawer,
  Stack,
  Divider,
  Button,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import PageHeader from "../../components/pageHeader/PageHeader";
import EnhancedTable from "../../components/table/EnhancedTable ";
import { CloseIcon, Visibility } from "../../assets/icons/Icons";
import "../../components/table/table.css";
import { useForm } from "../../common/useForms";
import { makeStyles } from "@material-ui/core/styles";
import Input from "../../common/Input";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { getPermissions } from "../../common/permissions";
import { permissions } from "../../common/constants";
import { getWalletDetails } from "../../redux/action/WalletAction";
import Loader from "../loader/Loader";

const useStyles = makeStyles((theme) => ({
  filterMain: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "#fff",
  },
  mainFilterBox: {
    padding: "10px 20px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "200px",
    },

    [theme.breakpoints.up("md")]: {
      width: "300px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "240px !important",
    },
    [theme.breakpoints.up("xs")]: {
      width: "100%",
    },
  },

  stackFilter: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  typographyFilter: {
    fontWeight: "bold !important",
    color: "#000 !important",
    fontSize: "30px !important",
  },
  buttonFilter: {
    flexDirection: "row !important",
    marginTop: "8px",
    gap: "8px",
  },
}));

const columns = [
  {
    id: "user_name",
    numeric: true,
    disablePadding: false,
    label: "Username",
  },
  {
    id: "transaction_amount",
    numeric: true,
    disablePadding: true,
    label: "Transaction Amount",
  },
  {
    id: "closing_balance",
    numeric: true,
    disablePadding: true,
    label: "Closing Balance",
  },
  {
    id: "transaction_type",
    numeric: false,
    disablePadding: true,
    label: "Transaction Type",
  },
  {
    id: "view",
    numeric: true,
    disablePadding: false,
    label: "View",
  },
].filter((el) => el !== null);

const initialValues = {
  banner_images: "",
  banner_type_id: "",
  chapter_id: "",
};

const RecordPerPage = [
  { id: 5, title: "5" },
  { id: 10, title: "10" },
];
const initialFilterValues = {
  first_name: "",
};

const WalletList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState(false);
  const [filterData, setFilterData] = useState(true);
  const [filterDatas, setFilterDatas] = useState(initialFilterValues);

  const [openDelete, setOpenDelete] = useState(false);
  const [popupName, setPopupName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { getWalletLoading, wallet } = useSelector(
    (state) => state?.WalletReducer
  );

  useEffect(() => {
    dispatch(getWalletDetails(page, rowsPerPage));
  }, [page, rowsPerPage]);

  const handleChangeRow = (event) => {
    const rowPerPage = event.target.value;
    setRowsPerPage(event.target.value);
    setPage(1);
    dispatch(getWalletDetails(page, rowPerPage));
  };

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const tableTitle = "SubscriptionList";

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleClickOpen = () => {
    setPopupName("Create Banner");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    resetForm();
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if (popupName === "Edit Banner") {
      if ("banner_type_id" in fieldValues) {
        if (
          fieldValues.banner_type_id === "" ||
          fieldValues.banner_type_id === undefined ||
          fieldValues.banner_type_id === null ||
          fieldValues.banner_type_id?.trim() === ""
        ) {
          temp.banner_type_id = "Please enter banner type";
        } else {
          temp.banner_type_id = "";
        }
      }
    } else {
      if ("banner_images" in fieldValues) {
        if (
          fieldValues.banner_images === "" ||
          fieldValues.banner_images === undefined ||
          fieldValues.banner_images === null
        ) {
          temp.banner_images = "This field is required.";
        } else {
          temp.banner_images = "";
        }
      }

      if ("banner_type_id" in fieldValues) {
        if (
          fieldValues.banner_type_id === "" ||
          fieldValues.banner_type_id === undefined ||
          fieldValues.banner_type_id === null
        ) {
          temp.banner_type_id = "Please select banner type";
        } else {
          temp.banner_type_id = "";
        }
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values,
    setFieldValues,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  } = useForm(initialValues, true, validate);

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const rows =
    wallet?.walletData && Array.isArray(wallet?.walletData)
      ? wallet?.walletData?.map((data) => {
          return {
            _id: data._id,
            user_name: data?.user_name,
            transaction_amount: data.transaction_amount,
            closing_balance: data?.closing_balance,
            transaction_type: data?.transaction_type,
            view: (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <IconButton
                  onClick={() =>
                    navigate("/admin-wallet/view", {
                      state: {
                        transaction_amount: data.transaction_amount,
                        closing_balance: data.closing_balance,
                        transaction_type: data.transaction_type,
                        user_name: data.user_name,
                        user_mobile_number: data.user_mobile_number,
                      },
                    })
                  }
                  sx={{ color: "black" }}
                >
                  <Visibility />
                </IconButton>
              </div>
            ),
          };
        })
      : [];

  const openFilterDrawer = () => {
    setFilter(true);
  };

  const closeFilterDrawer = () => {
    setFilter(false);
    setFilterData(!filterData);
  };

  const closeFilterDrawer1 = () => {
    setFilter(false);
  };

  const resetFormFilter = () => {
    setFilterDatas(initialFilterValues);
    setPage(1);
    dispatch(getWalletDetails(1, "", rowsPerPage));
    closeFilterDrawer();
  };

  const handleSubmitFilter = (e) => {
    e.preventDefault();
    const body = {
      first_name: filterDatas.first_name,
    };
    setFilterDatas(body);

    const first_name = filterDatas.first_name ? filterDatas.first_name : "";

    setPage(1);
    dispatch(getWalletDetails(page, first_name, rowsPerPage));
    closeFilterDrawer1();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFilterDatas((prevFilterData) => ({
      ...prevFilterData,
      [name]: value,
    }));
  };
  return (
    <Box>
      <PageHeader
        onFilterClick={openFilterDrawer}
        title="Wallet Transactions"
        onAddClick={handleClickOpen}
        isShowAdd={!!getPermissions(permissions.wallet, "is_add") && false}
      />

      {getWalletLoading ? (
        <Loader />
      ) : (
        <EnhancedTable
          key={rows._id}
          columns={columns}
          rows={rows}
          order={order}
          orderBy={orderBy}
          selected={selected}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          title={tableTitle}
          tableList={wallet?.walletData}
        />
      )}
      <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
        <form onSubmit={handleSubmitFilter} className={classes.filterMain}>
          <Box className={classes.mainFilterBox}>
            <Stack className={classes.stackFilter}>
              <Typography className={classes.typographyFilter}>
                Filter
              </Typography>

              <CloseIcon
                className="close-button"
                onClick={closeFilterDrawer1}
              />
            </Stack>
            <Box
              sx={{
                marginTop: "5px",
              }}
            >
              <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />
            </Box>

            <div>
              <Box>
                <Input
                  text="Add New"
                  variant="outlined"
                  color="primary"
                  label="First Name"
                  name="first_name"
                  value={filterDatas.first_name}
                  onChange={handleChange}
                />
              </Box>

              <Stack className={classes.buttonFilter}>
                <Button
                  color="secondary"
                  size="large"
                  variant="contained"
                  className="cancel-filter"
                  onClick={resetFormFilter}
                >
                  Reset
                </Button>
                <Button
                  color="primary"
                  size="large"
                  variant="contained"
                  className="order-filter-button"
                  type="submit"
                  style={{
                    cursor: "pointer",
                  }}
                >
                  Filter
                </Button>
              </Stack>
            </div>
          </Box>
        </form>
      </Drawer>
      {/* <DeleteDialog
        open={openDelete}
        loading={deleteLoading}
        dialogTitle="Are you sure to want to delete this record?"
        handleClose={handleCloseDelete}
        handleDelete={() => handleSingleDelete(DeleteId)}
      /> */}

      <Box
        sx={{
          display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
          justifyContent: "space-between",
          alignItems: "center",
          marginLeft: { xs: "10px", sm: "20px", md: "20px", lg: "20px" },
          marginRight: { xs: "10px", sm: "15px", md: "15px", lg: "15px" },
          mt: 2,
          mb: 2,
        }}
      >
        <Box
          sx={{
            fontSize: "14px",
            marginBottom: { xs: "10px", sm: "0", md: "0", lg: "0" },
            gap: 2,
            width: { sm: "100%", md: "50%" },
          }}
          display="flex"
          alignItems="center"
        >
          <Box>
            Showing {wallet?.currentPage} of {wallet?.totalPages} Results
          </Box>
          <Box display="flex" sx={{ gap: 1 }} alignItems="center">
            <Typography>Records per page</Typography>

            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                id="limites"
                name="limites"
                value={rowsPerPage}
                onChange={handleChangeRow}
                displayEmpty
                sx={{ maxWidth: "65px", maxHeight: "2.2rem" }}
                inputProps={{ "aria-label": "Without label" }}
              >
                {RecordPerPage?.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name || item.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Pagination
          count={wallet?.totalPages}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
          sx={{
            "& .MuiButtonBase-root ": {
              color: "#000 !important",
            },
            "& .Mui-selected ": {
              background: "#4b4b4e70 !important",
            },
            "& .Mui-disabled ": {
              background: "#6c696966 !important",
              color: "#000000 !important",
              opacity: 1,
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default WalletList;
