import { enqueueSnackbar } from "notistack";
import axios from "../ApiConfig";
import { ApiURL, snackBar } from "../common";

const axiosApiCall = () =>
  axios.create({
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  });

export const GetAllTransactions =
  (page, rowsPerPage, email = "", mobile_number = "", success_status = "") =>
  async (dispatch) => {
    try {
      dispatch({
        type: "TRANSACTION_GET_LOADING",
        payload: true,
      });

      const res = await axios.get(
        `${ApiURL}transaction/all-transaction?limit=${rowsPerPage}&page=${page}&email=${email}&mobile_number=${mobile_number}&success_status=${success_status}`
      );

      if (res.data.status === true) {
        const responseData =
          res.data.data || res.data.data.length > 0 ? res.data.data : [];
        dispatch({
          type: "TRANSACTION_GET_SUCCESS",
          payload: responseData,
        });
      } else {
        dispatch({
          type: "TRANSACTION_GET_SUCCESS",
          payload: [],
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: "TRANSACTION_GET_LOADING",
        payload: false,
      });
    }
  };
