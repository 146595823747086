import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Paper, Select, Typography } from "@mui/material";
import Input from "../../../common/Input";
import { useFormik } from "formik";
import MuiPhoneNumber from "material-ui-phone-number";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import * as Yup from "yup";
import SelectBox from "../../../common/SelectBox";
import { useDispatch, useSelector } from "react-redux";
import { GetAllCountry } from "../../../redux/action/CountryAction";
import dayjs from "dayjs";
import { getChapterAll } from "../../../redux/action/ChapterAction";
import axios from "axios";
import { ApiURL, snackBar } from "../../../redux/common";

const timer = 60;

var emailValidation =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const validationSchemaForm = Yup.object().shape({
  first_name: Yup.string()
    .required("Please enter first name")
    .matches(/^\s*\S.*/, "Please enter first name"),
  last_name: Yup.string()
    .required("Please enter last name")
    .matches(/^\s*\S.*/, "Please enter last name"),
  refferel_type: Yup.string().required("Please select refferel type"),
  refferel_code: Yup.string()
    .required("Please enter refferel code")
    .max(8, "Please enter valid refferel code")
    .min(8, "Please enter valid refferel code"),
  email: Yup.string()
    .required("Please enter email")
    .matches(emailValidation, "Please enter valid email"),
  mobile_number: Yup.string().required("Please enter mobile number"),
  // description: Yup.string().required("Please enter Description"),
  gender: Yup.string().required("Please select gender"),
  birth_date: Yup.object().required("Please select birth date"),
  otp: Yup.string()
    .required("Please verify otp")
    .max(6, "Otp must be exactly 6 numbers")
    .min(6, "Otp must be exactly 6 numbers"),
});

const genderOptions = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
  { label: "Other", value: "other" },
];

const Step1 = ({
  handleBack,
  handleNext,
  activeStep,
  value,
  isRefferalVerified,
  setisRefferalVerified,
  isOtpVerified,
  setIsOtpVerified,
}) => {
  const initialDate = new Date();

  const dispatch = useDispatch();
  const { getAllChapter } = useSelector((state) => state.ChapterReducer);

  const allChapterData =
    getAllChapter &&
    getAllChapter?.chapterData?.map((el) => {
      return { value: el?._id, label: el.chapter_name };
    });

  useEffect(() => {
    dispatch(getChapterAll());
  }, []);

  useEffect(() => {
    dispatch(GetAllCountry());
  }, []);

  const formik = useFormik({
    initialValues: {
      first_name: value.first_name || "",
      last_name: value.last_name || "",
      email: value.email || "",
      mobile_number: value.mobile_number || "",
      refferel_type: value.refferel_type || "",
      refferel_code: value.refferel_code || "",
      gender: value.gender || "",
      birth_date: value.birth_date || "",
      otp: value.otp || "",
    },
    validationSchema: validationSchemaForm,
    onSubmit: (values) => {
      // You can perform form submission here
      handleNext(values);
    },
  });

  const [otpGetButton, setOtpGetButton] = useState(false);
  const [otpGetButtonSeconds, setOtpGetButtonSeconds] = useState();
  const [otpApiCalledOneTime, setotpApiCalledOneTime] = useState(false);

  const handleOtpClick = async () => {
    setotpApiCalledOneTime(true);
    setOtpGetButtonSeconds(timer);
    setOtpGetButton(true);

    const payload = {};
    payload.mobile_number = formik.values.mobile_number
      .split(" ")[1]
      .replace("-", "");
    try {
      const res = await axios.post(`${ApiURL}user/send-otp`, payload);
      if (res.data.status === true) {
        snackBar(res?.data?.message, res?.data?.status);
      } else {
        snackBar(res?.data?.message, res?.data?.status);
      }
    } catch (err) {
      snackBar("Please enter valid mobile number", false);
    }

    const interval = setInterval(() => {
      setOtpGetButtonSeconds((prev) =>
        prev == 1 ? clearInterval(interval) : prev - 1
      );
    }, 1000);
    setTimeout(() => {
      setOtpGetButton(false);
    }, timer * 1000);
  };

  const verifyRefferalApi = async (payload) => {
    try {
      const res = await axios.post(
        `${ApiURL}chapter/verify-reffreal-code`,
        payload
      );
      if (res.data.status === true) {
        snackBar(res?.data?.message, res?.data?.status);
        setisRefferalVerified(true);
        return res;
      }
    } catch (err) {
      snackBar(err?.response?.data?.message, err?.response?.data?.status);
      console.error(err);
    } finally {
      return;
    }
  };

  const verifyRefferalCode = async () => {
    const payload = {
      chapterId_refferalType: formik.values.refferel_type,
      refferal_code: formik.values.refferel_code,
    };
    verifyRefferalApi(payload);
  };

  const handleOtpVerifyClick = async () => {
    const deviceId = localStorage?.getItem("uuid")?.replace(/"/gi, "");
    const payload = {};
    payload.mobile_number = formik.values.mobile_number
      .split(" ")[1]
      .replace("-", "");
    payload.otp = formik.values.otp;
    payload.device_id = deviceId;

    try {
      const res = await axios.post(`${ApiURL}user/verify-member-otp`, payload);
      if (res.data.status === true) {
        setIsOtpVerified(true);
        setOtpGetButton(false);
        snackBar(res?.data?.message, res?.data?.status);
      } else {
        snackBar(res?.data?.message, res?.data?.status);
      }
    } catch {
      snackBar("Something went wrong", false);
    }
  };

  return (
    <div>
      <Box
        component={Paper}
        marginX={30}
        padding={5}
        // sx={{ backgroundColor: "green" }}
      >
        <Typography
          textAlign="center"
          fontWeight={900}
          fontSize={28}
          marginBottom={2}
          color="black"
        >
          User Details
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          {/* <Grid container spacing={2} md={12}> */}
          {/* <Grid item md={6}>
              <Input
                id="company_name"
                name="company_name"
                type="text"
                maxLength={100}
                label="Company Name"
                value={formik.values.company_name}
                onChange={formik.handleChange}
                error={formik.touched.company_name && Boolean(formik.errors.company_name)}
                helperText={formik.touched.company_name && <div className="err-msg err-msg-font">{formik.errors.company_name}</div>}
              />
            </Grid>
            <Grid item md={6}>
              <Input
                id="name"
                name="name"
                type="date"
                label="Established Year"
              />
            </Grid> */}
          <Grid container spacing={2}>
            <Grid item md={6} xs={12} sm={12}>
              <Input
                id="first_name"
                name="first_name"
                type="text"
                maxLength={50}
                label="First Name"
                onChange={formik.handleChange}
                value={formik.values.first_name}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.first_name && Boolean(formik.errors.first_name)
                }
                helperText={
                  formik.touched.first_name && formik.errors.first_name ? (
                    <div className="err-msg err-msg-font">
                      {formik.errors.first_name}
                    </div>
                  ) : null
                }
              />
            </Grid>

            <Grid item md={6} xs={12} sm={12}>
              <Input
                id="last_name"
                name="last_name"
                type="text"
                label="Last Name"
                maxLength={50}
                onChange={formik.handleChange}
                value={formik.values.last_name}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.last_name && Boolean(formik.errors.last_name)
                }
                helperText={
                  formik.touched.last_name && formik.errors.last_name ? (
                    <div className="err-msg err-msg-font">
                      {formik.errors.last_name}
                    </div>
                  ) : null
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12} sm={12}>
              <Input
                id="email"
                name="email"
                type="email"
                label="Email"
                maxLength={150}
                onChange={formik.handleChange}
                value={formik.values.email}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={
                  formik.touched.email && formik.errors.email ? (
                    <div className="err-msg err-msg-font">
                      {formik.errors.email}
                    </div>
                  ) : null
                }
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item md={6} xs={12} sm={12}>
              <SelectBox
                mt={1}
                id="refferel_type"
                name="refferel_type"
                label="Refferal Type"
                disabled={isRefferalVerified}
                ClassName="range-select-filter"
                value={formik.values.refferel_type}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                options={allChapterData || []}
                error={
                  formik.touched.refferel_type &&
                  Boolean(formik.errors.refferel_type)
                }
                touched={formik.touched.refferel_type}
              />
              {formik.touched.refferel_type && formik.errors.refferel_type && (
                <div className="err-msg-font">
                  {formik.errors.refferel_type}
                </div>
              )}
            </Grid>
            <Grid item md={4} xs={12} sm={12}>
              <Input
                id="refferel_code"
                name="refferel_code"
                type="text"
                label="Refferal Code"
                disable={isRefferalVerified}
                maxLength={8}
                onChange={formik.handleChange}
                value={formik.values.refferel_code}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.refferel_code &&
                  Boolean(formik.errors.refferel_code)
                }
                helperText={
                  formik.touched.refferel_code &&
                  formik.errors.refferel_code ? (
                    <div className="err-msg err-msg-font">
                      {formik.errors.refferel_code}
                    </div>
                  ) : null
                }
              />
            </Grid>
            <Grid item md={2} xs={12} sm={12}>
              <Button
                sx={{ marginTop: 1, width: "fit-content" }}
                type="button"
                onClick={verifyRefferalCode}
                variant="contained"
                disabled={
                  !formik.values.refferel_code ||
                  formik.errors.refferel_code ||
                  isRefferalVerified
                }
              >
                Verify
              </Button>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item md={6} xs={12} sm={12}>
              <SelectBox
                type="text"
                id="gender"
                name="gender"
                label="Gender"
                ClassName="range-select-filter"
                value={formik.values.gender}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                options={genderOptions}
                error={formik.touched.gender && Boolean(formik.errors.gender)}
                touched={formik.touched.gender}
              />
              {formik.touched.gender && formik.errors.gender && (
                <div className="err-msg-font">{formik.errors.gender}</div>
              )}
            </Grid>
            <Grid item md={6} xs={12} sm={12}>
              <div style={{ marginTop: "7px" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker", "DatePicker"]}>
                    <DatePicker
                      id="birth_date"
                      sx={{ width: "100%", padding: "0px 0px !important" }}
                      name="birth_date"
                      fullWidth
                      label="Birth Date"
                      value={formik.values.birth_date || null}
                      format="DD/MM/YYYY"
                      onChange={(newValue) =>
                        formik.setFieldValue("birth_date", newValue)
                      }
                      slotProps={{
                        textField: {
                          variant: "outlined",
                          error: Boolean(
                            formik.touched.birth_date &&
                              formik.errors.birth_date
                          ), //error handling
                          helperText: formik.touched.birth_date &&
                            formik.errors.birth_date && (
                              <div style={{marginLeft:"-12px"}} className="err-msg-font">
                                {formik.errors.birth_date}
                              </div>
                            ),
                        },
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            {/* <Grid item md={6} xs={12} sm={12}>
              <div style={{ marginTop: "3px" }}>
                <Input
                  id="password"
                  name="password"
                  type="password"
                  label="Password"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.password &&
                    Boolean(formik.errors.password)
                  }
                  helperText={
                    formik.touched.password &&
                      formik.errors.password ? (
                      <div className="err-msg err-msg-font">
                        {formik.errors.password}
                      </div>
                    ) : null
                  }
                />
              </div>
            </Grid>

            <Grid item md={6} xs={12} sm={12}>
              <div style={{ marginTop: "3px" }}>
                <Input
                  id="confirm_password"
                  name="confirm_password"
                  type="password"
                  label="Confirm Password"
                  onChange={formik.handleChange}
                  value={formik.values.confirm_password}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.confirm_password &&
                    Boolean(formik.errors.confirm_password)
                  }
                  helperText={
                    formik.touched.confirm_password &&
                      formik.errors.confirm_password ? (
                      <div className="err-msg err-msg-font">
                        {formik.errors.confirm_password}
                      </div>
                    ) : null
                  }
                />
              </div>
            </Grid> */}
          </Grid>
          {/* <Grid container spacing={2}>
            <Grid item md={12} xs={12} sm={12}>
              <Input
                id="address"
                name="address"
                type="text"
                label="Address"
                onChange={formik.handleChange}
                value={formik.values.address}
                onBlur={formik.handleBlur}
                maxLength={500}
                error={
                  formik.touched.address &&
                  Boolean(formik.errors.address)
                }
                helperText={
                  formik.touched.address && formik.errors.address ? (
                    <div className="err-msg err-msg-font">
                      {formik.errors.address}
                    </div>
                  ) : null
                }
                maxRows={2}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12} sm={12}>
              <div style={{ marginTop: "1rem" }}>
                <Autocomplete
                  id="country"
                  name="country"
                  value={formik.values.country || null}
                  // classes={customStyles}
                  onChange={(e, value) => {
                    formik.setFieldValue("country", value);
                    dispatch(GetAllStates(value?._id));
                    dispatch(cleareAllStates());
                    formik.setFieldValue("state", "");
                    formik.setFieldValue("city", "");
                    formik.setFieldValue("pincode", "");
                  }}
                  options={allCountryData?.countryData || []}
                  autoHighlight
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.country &&
                    Boolean(formik.errors.country)
                  }
                  helperText={
                    formik.touched.country && formik.errors.country ? (
                      <div className="err-msg err-msg-font">
                        {formik.errors.country}
                      </div>
                    ) : null
                  }
                  getOptionLabel={(option) => option.country_name}
                  renderInput={(params) => (
                    <TextField
                      sx={{ color: "#000" }}
                      {...params}
                      label="Country"
                      inputProps={{
                        ...params.inputProps,
                        style: { color: "#000" },
                      }}
                    />
                  )}
                />
                {formik.errors.country && (
                  <Typography
                    variant="caption"
                    color="error"
                    sx={{ fontSize: "12px" }}
                  >
                    {formik.errors.country}
                  </Typography>
                )}
              </div>
            </Grid>

            <Grid item md={6} xs={12} sm={12}>
              <div style={{ marginTop: "1rem" }}>
                <Autocomplete
                  id="state"
                  name="state"
                  value={formik.values.state || null}
                  onChange={(e, value) => {
                    formik.setFieldValue("state", value);
                    dispatch(GetAllCities(value?._id));
                    dispatch(cleareAllCities());
                    formik.setFieldValue("city", "");
                    formik.setFieldValue("pincode", "");
                  }}
                  options={allStateData || []}
                  autoHighlight
                  getOptionLabel={(option) => option.state_name}
                  renderInput={(params) => (
                    <TextField
                      sx={{ color: "#000" }}
                      {...params}
                      label="State"
                      inputProps={{
                        ...params.inputProps,
                        style: { color: "#000" },
                      }}
                    />
                  )}
                />
                {formik.touched.state && formik.errors.state && (
                  <Typography
                    variant="caption"
                    color="error"
                    sx={{ fontSize: "12px" }}
                  >
                    {formik.errors.state}
                  </Typography>
                )}
              </div>
            </Grid>
          </Grid> */}

          {/* <Grid container spacing={2}>
            <Grid item md={6} xs={12} sm={12}>
              <div style={{ marginTop: "1rem" }}>
                <Autocomplete
                  id="city"
                  name="city"
                  value={formik.values.city || null}
                  onChange={(e, value) => {
                    formik.setFieldValue("city", value);
                    dispatch(GetAllPostalCodes(value?._id));
                    dispatch(clearePostalCodes());
                    formik.setFieldValue("pincode", "");
                  }}
                  options={allCitiesData || []}
                  autoHighlight
                  getOptionLabel={(option) => option.city_name}
                  renderInput={(params) => (
                    <TextField
                      sx={{ color: "#000" }}
                      {...params}
                      label="City"
                      inputProps={{
                        ...params.inputProps,
                        style: { color: "#000" },
                      }}
                    />
                  )}
                />
                {formik.touched.city && formik.errors.city && (
                  <Typography
                    variant="caption"
                    color="error"
                    sx={{ fontSize: "12px" }}
                  >
                    {formik.errors.city}
                  </Typography>
                )}
              </div>
            </Grid>

            <Grid item md={6} xs={12} sm={12}>
              <div style={{ marginTop: "1rem" }}>
                <Autocomplete
                  id="pincode"
                  name="Postal code"
                  value={formik.values.pincode || null}
                  onChange={(e, value) =>
                    formik.setFieldValue("pincode", value)
                  }
                  options={allPostalData || []}
                  autoHighlight
                  getOptionLabel={(option) => option.postal_code}
                  renderInput={(params) => (
                    <TextField
                      sx={{ color: "#000" }}
                      {...params}
                      label="Postal code"
                      inputProps={{
                        ...params.inputProps,
                        style: { color: "#000" },
                      }}
                    />
                  )}
                />
                {formik.touched.pincode && formik.errors.pincode && (
                  <Typography
                    variant="caption"
                    color="error"
                    sx={{ fontSize: "12px" }}
                  >
                    {formik.errors.pincode}
                  </Typography>
                )}
              </div>
            </Grid>
          </Grid> */}

          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item md={4} xs={12} sm={12}>
              <div style={{ marginTop: "9px" }}>
                <MuiPhoneNumber
                  fullWidth
                  defaultCountry={"in"}
                  size="small"
                  variant="outlined"
                  id="mobile_number"
                  name="mobile_number"
                  label="Mobile Number"
                  type="text"
                  onBlur={formik.handleBlur}
                  onChange={(value) => {
                    formik.setFieldValue("mobile_number", value);
                  }}
                  value={formik.values.mobile_number}
                  error={formik.errors.mobile_number}
                  helperText={
                    formik.touched.mobile_number &&
                    formik.errors.mobile_number ? (
                      <div className="err-msg-mobile err-msg-font">
                        {formik.errors.mobile_number}
                      </div>
                    ) : null
                  }
                  InputLabelProps={{ sx: { color: "black" } }}
                  disabled={isOtpVerified}
                />
              </div>
            </Grid>
            <Grid item md={2} xs={12} sm={12}>
              <Button
                sx={{ marginTop: 1, width: "fit-content" }}
                type="button"
                onClick={handleOtpClick}
                variant="contained"
                disabled={
                  otpGetButton ||
                  !formik.values.mobile_number ||
                  formik.errors.mobile_number ||
                  isOtpVerified
                }
              >
                {otpGetButtonSeconds > 1 ? otpGetButtonSeconds : "Get OTP"}
              </Button>
            </Grid>
            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
              item
              md={4}
              xs={12}
              sm={12}
            >
              <Input
                name={`otp`}
                maxLength={6}
                type="number"
                value={formik.values.otp}
                label="OTP"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.otp && Boolean(formik.errors.otp)}
                helperText={
                  formik.touched.otp && (
                    <div className="err-msg err-msg-font">
                      {formik.errors.otp}
                    </div>
                  )
                }
                disable={!otpApiCalledOneTime || isOtpVerified}
              />
            </Grid>
            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
              item
              md={2}
              xs={12}
              sm={12}
            >
              <Button
                sx={{ marginTop: 1 }}
                type="button"
                onClick={handleOtpVerifyClick}
                variant="contained"
                disabled={
                  !otpApiCalledOneTime ||
                  !formik.values.otp ||
                  isOtpVerified ||
                  formik.errors.otp
                }
              >
                Verify
              </Button>
            </Grid>
          </Grid>

          <Grid
            container
            sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}
          >
            <Grid item>
              <Button
                sx={{ margin: 1 }}
                type="button"
                variant="contained"
                disabled={activeStep === 0}
                // onClick={handleBack}
              >
                Back
              </Button>
            </Grid>
            <Grid item>
              <Button
                sx={{ margin: 1 }}
                type="submit"
                // onClick={handleSubmit}
                variant="contained"
                disabled={!isOtpVerified || !isRefferalVerified}
              >
                Next
              </Button>
            </Grid>
          </Grid>
          {/* </Grid> */}
        </form>
      </Box>
    </div>
  );
};

export default Step1;
