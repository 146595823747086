import { Typography } from "@mui/material";
import React, { useCallback, useState } from "react";
import { PieChart, Pie, Legend, Tooltip } from "recharts";
import NoDataChart from "../nodatachart/NoDataChart";

// const data = [
//   { name: "India", value: 400 },
//   { name: "USA", value: 300 },
//   { name: "UK", value: 300 },
//   { name: "Dubai", value: 200 }
// ];

const BaeCharts = ({ data, title }) => {
  return (
    <div>

      <PieChart width={1000} height={400}>
        <Pie
          dataKey="value"
          isAnimationActive={false}
          data={data}
          cx={200}
          cy={"50%"}
          outerRadius={80}
          fill="#8884d8"
          label
        />
        <Tooltip />
      </PieChart>
    </div>
  );
};

export default BaeCharts;
