import React, { useEffect, useState } from "react";
import { Avatar, Box, Card, Grid, Divider } from "@mui/material";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import ButtonCom from "../../common/Button";
import { EditIcon } from "../../assets/icons/Icons";
import { makeStyles } from "@material-ui/core/styles";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  BarChart,
  Bar,
} from "recharts";
import BarCharts from "./BarCharts";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import BarChart2 from "./BarChart2";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { useFormik } from "formik";
import axios from "../../redux/ApiConfig";
import { ApiURL } from "../../redux/common";
import PendingRequest from "../../pages/PendingRequest";
import UserInfoListing from "../UserInfoListing/UserInfoListing";
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import CalendarInput from "../dateRangePicker/DateRangePicker";
import { api } from "../../web/config/api";
import NoDataChart from "../nodatachart/NoDataChart";
import Loader from "../../web/components/loader/Loader";
import useUserRolePermissions from "../../common/UserPermissions";

const StyledIcon = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: "center",
  marginBottom: theme.spacing(3),
}));

const useStyles = makeStyles((theme) => ({
  image: {
    marginTop: "100px",
    marginLeft: "30px",
  },
  mainCompDetails: {
    display: "contents",
    alignItems: "center",
    justifyContent: "center",
  },
  [theme.breakpoints.down("xs")]: {
    mainCompDetails: {
      display: "flex",
      flexGrow: 1,
      flexDirection: "column",
      alignItems: "center",
    },
  },
  title: {
    display: "flex",
    fontSize: "20px",
    fontWeight: "bold",
    paddingLeft: "20px",
    color: "white",
  },
}));

const Dashboard = () => {
  const auth_data = JSON.parse(localStorage.getItem("user_data"));
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(true);

  const userRoleData = useUserRolePermissions("Event");

  const classes = useStyles();

  const getDashboardData = async (
    startDate = new Date(),
    endDate = new Date()
  ) => {
    try {
      setLoading(true);
      const payload = {
        start_date: startDate.toISOString(),
        end_date: endDate.toISOString(),
      };
      const res = await api.post(`${ApiURL}dashboard/get-data`, payload);
      setDashboardData(res?.data);
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDashboardData();
  }, []);

  const lineChartData =
    dashboardData?.users_by_role.map((role) => ({
      name: role.user_role,
      role_type: role.role_count,
    })) || [];

  const barChartData =
    dashboardData?.user_by_membership_purchase.map((membership) => ({
      name: membership.membership,
      value: membership.purchased_user_count,
    })) || [];

  const partnerRequestsChartData =
    dashboardData?.partner_requests.map((request) => ({
      name: request.request_status,
      partner_requests: request.request_count,
    })) || [];

  const registeredUsersChartData =
    dashboardData?.registerd_users.map((user) => ({
      name: user.user_status,
      count: user.user_count,
    })) || [];

  const userByActiveStatus =
    dashboardData?.user_by_active_status.map((user) => ({
      name: user?.user_isActive ? "Active" : "In Active",
      value: user.user_count,
    })) || [];

  return (
    <>
      {/* Header Section */}
      <Box
        sx={{
          marginTop: "30px",
          marginLeft: { xs: "20px", md: "25px", lg: "25px" },
          marginRight: { xs: "15px", md: "25px", lg: "25px" },
          marginBottom: "30px",
        }}
      >
        <Card
          sx={{
            height: "100%",
            paddingTop: "24px",
            paddingBottom: "24px",
            textAlign: "center",
            color: "black",
            boxShadow: "none",
            background:
              "linear-gradient(317deg, rgba(254,118,120,1) 9%, rgba(21,62,128,1) 100%)",
          }}
        >
          <Typography variant="p" className={classes.title}>
            Dashboard
          </Typography>
        </Card>
      </Box>

      {/* User Info Listing */}
      <UserInfoListing />

      {/* Date Range Picker */}
      <Grid container spacing={2}>
        <Grid
          sx={{
            marginLeft: { xs: "20px", md: "25px", lg: "25px" },
            marginRight: { xs: "15px", md: "25px", lg: "25px" },
          }}
          item
          md={12}
          xs={12}
          sm={12}
        >
          <div style={{ marginTop: "7px" }}>
            <CalendarInput
              disabled={loading}
              getDashboardData={getDashboardData}
            />
          </div>
        </Grid>
      </Grid>

      {loading ? (
        <Loader />
      ) : (
        <>
          {/* Chart Section 1 */}
          <Box
            sx={{
              marginTop: "50px",
              marginLeft: { xs: "20px", md: "25px", lg: "25px" },
              marginRight: { xs: "15px", md: "25px", lg: "25px" },
            }}
          >
            <Card sx={{ minHeight: "340px" }}>
              <Grid container spacing={2} sx={{ marginTop: "15px" }}>
                {/* Chart 1 - Users by Role */}
                <Grid item md={6} xs={12} sx={{ marginTop: "-50px" }}>
                  <Typography
                    variant="h5"
                    sx={{ marginTop: "47px", paddingLeft: "25px" }}
                  >
                    Users by Role:
                  </Typography>
                  {lineChartData.length > 0 ? (
                    <BarChart
                      width={500}
                      height={300}
                      data={lineChartData}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="role_type" fill="#82ca9d" />
                    </BarChart>
                  ) : (
                    <NoDataChart />
                  )}
                </Grid>

                {/* Chart 2 - Users by Membership Purchase */}
                <Grid item md={6} xs={12} sx={{ marginTop: "-50px" }}>
                  <Typography
                    variant="h5"
                    sx={{ marginTop: "47px", paddingLeft: "25px" }}
                  >
                    Users by Membership Purchase:
                  </Typography>
                  {barChartData.length > 0 ? (
                    <>
                      <BarCharts
                        data={barChartData}
                        title="Users by Membership Purchase:"
                      />
                      <Typography
                        sx={{
                          marginTop: "-54px",
                          justifyContent: "center",
                          display: "flex",
                          color: "rgb(136, 132, 216)",
                          marginLeft: "-81px",
                        }}
                      >
                        <HorizontalRuleIcon />
                        Users by Membership Purchase
                      </Typography>
                    </>
                  ) : (
                    <NoDataChart />
                  )}
                </Grid>
              </Grid>
            </Card>
          </Box>

          {/* Chart Section 2 */}
          <Box
            sx={{
              marginTop: "50px",
              marginLeft: { xs: "20px", md: "25px", lg: "25px" },
              marginRight: { xs: "15px", md: "25px", lg: "25px" },
            }}
          >
            <Card sx={{ minHeight: "360px" }}>
              <Grid container spacing={2} sx={{ marginTop: "15px" }}>
                {/* Chart 3 - Partner Requests */}
                <Grid item md={6} xs={12}>
                  <Typography
                    variant="h5"
                    sx={{
                      marginTop: "15px",
                      marginBottom: "30px",
                      paddingLeft: "25px",
                    }}
                  >
                    Registered Partner Requests:
                  </Typography>
                  {partnerRequestsChartData.length > 0 ? (
                    <BarChart
                      width={500}
                      height={300}
                      data={partnerRequestsChartData}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="partner_requests" fill="#82ca9d" />
                    </BarChart>
                  ) : (
                    <NoDataChart />
                  )}
                </Grid>

                {/* Chart 4 - Registered Users */}
                <Grid item md={6} xs={12}>
                  <Typography
                    variant="h5"
                    sx={{
                      marginTop: "15px",
                      marginBottom: "20px",
                      paddingLeft: "25px",
                    }}
                  >
                    Registered Memberships Status:
                  </Typography>
                  {registeredUsersChartData.length > 0 ? (
                    <>
                      <BarChart2 data={registeredUsersChartData} />
                      <Typography
                        sx={{
                          justifyContent: "center",
                          display: "flex",
                          color: "rgb(136, 132, 216)",
                          marginLeft: "61px",
                        }}
                      >
                        <HorizontalRuleIcon />
                        Memberships
                      </Typography>
                    </>
                  ) : (
                    <NoDataChart />
                  )}
                </Grid>
              </Grid>
            </Card>
          </Box>

          {/* Chart Section 3 */}
          <Box
            sx={{
              marginTop: "50px",
              marginLeft: { xs: "20px", md: "25px", lg: "25px" },
              marginRight: { xs: "15px", md: "25px", lg: "25px" },
            }}
          >
            <Card sx={{ minHeight: "340px" }}>
              <Grid container spacing={2} sx={{ marginTop: "15px" }}>
                {/* Chart 1 - Users by Role */}
                <Grid item md={6} xs={12} sx={{ marginTop: "-50px" }}>
                  <Typography
                    variant="h5"
                    sx={{ marginTop: "15px", paddingLeft: "25px" }}
                  >
                    Users by Active Status:
                  </Typography>

                  {userByActiveStatus?.length > 0 ? (
                    <>
                      <BarCharts
                        data={userByActiveStatus}
                        title="Users by Active Status:"
                      />{" "}
                      <Typography
                        sx={{
                          marginTop: "-54px",
                          justifyContent: "center",
                          display: "flex",
                          color: "rgb(136, 132, 216)",
                          marginLeft: "-81px",
                        }}
                      >
                        <HorizontalRuleIcon />
                        Users by Active Status
                      </Typography>
                    </>
                  ) : (
                    <NoDataChart />
                  )}
                </Grid>
              </Grid>
            </Card>
          </Box>
        </>
      )}
    </>
  );
};

export default Dashboard;
