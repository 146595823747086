import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { FindSearch, GetBusinessCategoryAll, GetChapterAll } from '../../redux/findserachAction';
import { Autocomplete, TextField } from '@mui/material';
import SearchList from './SearchList';
import { snackBar } from '../../utils/common';
import LogoHome from "../../../assets/images/logo-home.png";
import Loader from '../../components/loader/Loader';



const FindChapterSerach = () => {

    const [selectedValue, setSelectedValue] = useState({ category_name: '' });
    const [selectedValues, setSelectedValues] = useState({ chapter_name: "" });
    const [allBusinessCategory, setAllBusinessCategory] = useState([])
    const [allChapter, setAllChapter] = useState([])
    const [showSearchList, setShowSearchList] = useState(false);
    const [upadtedList, setUpadtedList] = useState([])

    const defaultProps = {
        options: allBusinessCategory,
        getOptionLabel: (option) => option.category_name,
    };

    const defaultPropsChapter = {
        options: allChapter,
        getOptionLabel: (option) => option.chapter_name,
    };



    const getAllBusinessCategory = async () => {
        const res = await GetBusinessCategoryAll();
        if (res) {
            setAllBusinessCategory(res.data?.categoryData);
        }
    };

    const getAllChapter = async () => {
        const res = await GetChapterAll();
        if (res) {
            setAllChapter(res?.data?.chapterData);
        }
    };

    useEffect(() => {
        getAllBusinessCategory();
        getAllChapter();
    }, []);

    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            setLoading(true)
            const res = await FindSearch(selectedValue._id, selectedValues._id);

            if (res?.status === true) {
                setLoading(false)
                setShowSearchList(true);
                setUpadtedList(res?.data?.userData);
                snackBar(res?.message, res?.status);
            } else {
                setLoading(false)
                snackBar(res?.message, res?.status);
                setShowSearchList(false);

            }

        } catch (error) {
            snackBar('An error occurred while processing your request', false);
        }
    };






    return (
        <div>
            <div className='content-wrap'>
                <section className="home-main-intro">
                    <div className="home-main-intro-container">
                        <div className="container">
                            <div className="heading mb-9">
                                <h1 className="mb-7">
                                    <span className="d-block slideInLeft animated" data-animate="slideInLeft">Discover</span>
                                    <span className="font-weight-light d-block fadeInRight animated" data-animate="fadeInRight">your amazing city</span>
                                </h1>
                                <p className="h5 font-weight-normal text-secondary mb-0 fadeInDown animated" data-animate="fadeInDown">
                                    Find great places to stay, eat, shop, or visit from local experts.
                                </p>
                            </div>
                            <div className="form-search-style-02 pb-9 fadeInDown animated" data-animate="fadeInDown">
                                <form onSubmit={handleSubmit}>
                                    <div className="row align-items-end no-gutters">
                                        <div className="col-xl-6 mb-4 mb-xl-0 py-3 px-4 bg-white border-right position-relative rounded-left form-search-item">
                                            <label htmlFor="key-word" className="font-size-md font-weight-semibold text-dark mb-0 lh-1">Business Category</label>
                                            <Autocomplete
                                                {...defaultProps}
                                                id="auto-complete"
                                                autoComplete
                                                includeInputInList
                                                renderInput={(params) => (
                                                    <TextField {...params} variant="standard" placeholder='Ex: category' />
                                                )}
                                                onChange={(event, newValue) => {
                                                    setSelectedValue(newValue);
                                                }}
                                                value={selectedValue || null}
                                                className="form-control form-control-mini border-0 px-0 bg-transparent"
                                            />
                                        </div>


                                        <div className="col-xl-4 mb-4 mb-xl-0 py-3 px-4 bg-white position-relative rounded-right form-search-item">
                                            <label htmlFor="key-word" className="font-size-md font-weight-semibold text-dark mb-0 lh-1">Chapter</label>
                                            <Autocomplete
                                                {...defaultPropsChapter}
                                                id="auto-complete"
                                                autoComplete
                                                includeInputInList
                                                renderInput={(params) => (
                                                    <TextField {...params} variant="standard" placeholder='Ex: chapter' />
                                                )}
                                                onChange={(event, newValue) => {
                                                    setSelectedValues(newValue);
                                                }}
                                                value={selectedValues || null}
                                                className="form-control form-control-mini border-0 px-0 bg-transparent"
                                            />
                                        </div>
                                        <div className="col-xl-2 button" style={{ top: "-20px" }}>
                                            <button
                                                type="submit"
                                                className="btn btn-primary btn-lg btn-icon-left btn-block"><i className="fal fa-search" />Search
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            {loading ?
                <>
                    <Loader/>
                </>
                :
                <SearchList show={showSearchList} upadtedList={upadtedList} />
            }
        </div>
    )
}

export default FindChapterSerach
