import { Box, Card, Grid, Typography } from "@mui/material";
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import moment from "moment";

const EventAdminDetails = () => {
  const navigate = useNavigate("");

  const state = useLocation();
  const eventDetails = state?.state?.eventData;
  console.log("eventDetails", eventDetails);

  const formattedDate = moment(eventDetails?.start_time).format("DD/MM/YYYY");
  const formattedEndDate = moment(eventDetails?.end_time).format("DD/MM/YYYY");

  return (
    <div>
      <div>
        <Box
          sx={{
            px: 3,
            paddingBottom: "3px",
            // backgroundColor: "rgb(241, 245, 249)",
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <ArrowCircleLeftOutlinedIcon
              fontSize="large"
              onClick={() => navigate("/admin-events")}
              sx={{ cursor: "pointer", marginTop: "5px", marginBottom: "5px" }}
            />
          </Box>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{
                paddingBottom: "25px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Card
                sx={{
                  width: "90%",
                  marginTop: "0px",
                  height: "100%",
                  backgroundColor: "#d1d1d17a",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "700",
                    fontSize: "20px",
                    color: "black",
                    marginTop: "-11px",
                    padding: "22px 15px",
                  }}
                >
                  Event Details
                </Typography>
                <Box
                  sx={{
                    flexGrow: 1,
                    marginTop: "10px",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography
                        sx={{
                          fontWeight: "100",
                          fontSize: "17px",
                          marginTop: "-11px",
                          padding: " 15px",
                          display: "flex",
                          color: "#605a5a",
                        }}
                      >
                        Event Name
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "600",
                          fontSize: "18px",
                          marginTop: "-31px",
                          padding: " 15px",
                          display: "flex",
                          color: "black",
                        }}
                      >
                        {eventDetails?.event_name || "-"}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        sx={{
                          fontWeight: "100",
                          fontSize: "17px",
                          marginTop: "-11px",
                          padding: " 15px",
                          display: "flex",
                          color: "#605a5a",
                        }}
                      >
                        Event Type
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "600",
                          fontSize: "18px",
                          marginTop: "-31px",
                          padding: " 15px",
                          display: "flex",
                          color: "black",
                        }}
                      >
                        {eventDetails?.event_type || "-"}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography
                        sx={{
                          fontWeight: "100",
                          fontSize: "17px",
                          marginTop: "-11px",
                          padding: " 15px",
                          display: "flex",
                          color: "#605a5a",
                        }}
                      >
                        Start Time
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "600",
                          fontSize: "18px",
                          marginTop: "-31px",
                          padding: " 15px",
                          display: "flex",
                          color: "black",
                        }}
                      >
                        {formattedDate || "-"}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        sx={{
                          fontWeight: "100",
                          fontSize: "17px",
                          marginTop: "-11px",
                          padding: " 15px",
                          display: "flex",
                          color: "#605a5a",
                        }}
                      >
                        End Time
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "600",
                          fontSize: "18px",
                          marginTop: "-31px",
                          padding: " 15px",
                          display: "flex",
                          color: "black",
                        }}
                      >
                        {formattedEndDate || "-"}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography
                        sx={{
                          fontWeight: "100",
                          fontSize: "17px",
                          marginTop: "-11px",
                          padding: " 15px",
                          display: "flex",
                          color: "#605a5a",
                        }}
                      >
                        Price
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "600",
                          fontSize: "18px",
                          marginTop: "-31px",
                          padding: " 15px",
                          display: "flex",
                          color: "black",
                        }}
                      >
                        {eventDetails?.price || "-"}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        sx={{
                          fontWeight: "100",
                          fontSize: "17px",
                          marginTop: "-11px",
                          padding: " 15px",
                          display: "flex",
                          color: "#605a5a",
                        }}
                      >
                        Venue Link
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "600",
                          fontSize: "18px",
                          marginTop: "-31px",
                          padding: " 15px",
                          display: "flex",
                          color: "black",
                        }}
                      >
                        {eventDetails?.venue_link}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          fontWeight: "100",
                          fontSize: "17px",
                          marginTop: "-11px",
                          padding: " 15px",
                          display: "flex",
                          color: "#605a5a",
                        }}
                      >
                        Venue
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "600",
                          fontSize: "18px",
                          marginTop: "-31px",
                          padding: " 15px",
                          display: "flex",
                          color: "black",
                        }}
                      >
                        {eventDetails?.venue || "-"}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          fontWeight: "600",
                          fontSize: "18px",
                          marginTop: "-31px",
                          padding: " 15px",
                          display: "flex",
                          color: "black",
                        }}
                      >
                        <Link to={`/event/${eventDetails?._id}`}>Book Now</Link>
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
};

export default EventAdminDetails;
