import React from "react";
import {
  Dialog,
  Card,
  CardContent,
  TextField,
  Button,
  Typography,
  Grid,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { Formik, Form, Field, ErrorMessage, useFormik } from "formik";
import { Close as CloseIcon } from "@mui/icons-material";
import MuiPhoneNumber from "material-ui-phone-number";
import { snackBar } from "../../../redux/common";
import * as Yup from "yup";
import GuestDetailsForm from "./GuestDetailsForm";

const GuestDetailsModal = ({
  open,
  handleClose,
  guest,
  checkoutButton,
  guestDetails,
  setGuestDetails,
}) => {
  const validationSchmaObj = {};
  const validations = {
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    phone: Yup.string().required("Phone is required"),
  };
  Array.from({ length: guest }).forEach((_, index) => {
    validationSchmaObj[`name${index}`] = validations["name"];
    validationSchmaObj[`email${index}`] = validations["email"];
    validationSchmaObj[`phone${index}`] = validations["phone"];
  });
  const validationSchema = Yup.object().shape(validationSchmaObj);

  const initialValues = {
    name: "",
    email: "",
    phone: "",
  };

  const handleFormSubmit = (values, { resetForm }) => {
    if (isFormDisabled()) {
      snackBar("Fill all guest details.", false);
      return;
    } else {
      resetForm();
    }
  };

  const isFormDisabled = () => {
    return guestDetails.some(
      (detail) => !detail.name || !detail.email || !detail.phone
    );
  };

  const handleFieldChange = (index, fieldName, fieldValue) => {
    const updatedGuestDetails = guestDetails.map((detail, i) =>
      i === index ? { ...detail, [fieldName]: fieldValue } : detail
    );
    setGuestDetails(updatedGuestDetails);
  };

  const handleAlertClose = () => {
    setGuestDetails([]);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, { resetForm }) => {},
  });

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" sx={{ color: "#000 !important" }}>
          Add Guest Details
        </Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <form onSubmit={formik.handleSubmit}>
          {/* {guestDetails.map((detail, index) => (
            <Card key={index} style={{ marginBottom: "20px" }}>
              <CardContent>
                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                >
                  Guest {index + 1}:
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      error={
                        formik.touched[`name${index}`] &&
                        formik.errors[`name${index}`]
                      }
                      label="Name"
                      name={`name${index}`}
                      variant="outlined"
                      fullWidth
                      required
                      value={detail.name}
                      onChange={(e) => {
                        formik.handleChange(e);
                        handleFieldChange(index, "name", e.target.value);
                      }}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched[`name${index}`] &&
                    formik.errors[`name${index}`] &&
                    !formik.values[`name${index}`] ? (
                      <div className="err-msg-font">
                        {formik.errors[`name${index}`]}
                      </div>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MuiPhoneNumber
                      error={
                        formik.touched[`phone${index}`] &&
                        formik.errors[`phone${index}`]
                      }
                      label="Phone"
                      name={`phone${index}`}
                      defaultCountry={"in"}
                      variant="outlined"
                      fullWidth
                      required
                      value={detail.phone}
                      onChange={(value) => {
                        formik.setFieldValue(`phone${index}`, value);
                        handleFieldChange(index, "phone", value);
                      }}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched[`phone${index}`] &&
                    formik.errors[`phone${index}`] ? (
                      <div className="err-msg-font">
                        {formik.errors[`phone${index}`]}
                      </div>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      error={
                        formik.touched[`email${index}`] &&
                        formik.errors[`email${index}`]
                      }
                      label="Email"
                      variant="outlined"
                      name={`email${index}`}
                      fullWidth
                      required
                      value={detail.email}
                      onChange={(e) => {
                        formik.handleChange(e);
                        handleFieldChange(index, "email", e.target.value);
                      }}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched[`email${index}`] &&
                    formik.errors[`email${index}`] ? (
                      <div className="err-msg-font">
                        {formik.errors[`email${index}`]}
                      </div>
                    ) : null}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          ))} */}
          {guestDetails.map((detail, index) => (
            <GuestDetailsForm
              index={index}
              value={{
                name: formik.values[`name${index}`],
                phone: formik.values[`phone${index}`],
                email: formik.values[`email${index}`],
              }}
              names={{
                name: `name${index}`,
                phone: `phone${index}`,
                email: `email${index}`,
              }}
              onchange={{
                nameChange: (e) => {
                  formik.handleChange(e);
                  handleFieldChange(index, "name", e.target.value);
                },
                phoneChange: (value) => {
                  formik.setFieldValue(`phone${index}`, value);
                  handleFieldChange(index, "phone", value);
                },
                emailChange: (e) => {
                  formik.handleChange(e);
                  handleFieldChange(index, "email", e.target.value);
                },
              }}
              valuesforvalidation={{
                name: formik.values[`name${index}`],
              }}
              onBlur={formik.handleBlur}
              touched={{
                name: formik.touched[`name${index}`],
                phone: formik.touched[`phone${index}`],
                email: formik.touched[`email${index}`],
              }}
              errors={{
                name: formik.errors[`name${index}`],
                phone: formik.errors[`phone${index}`],
                email: formik.errors[`email${index}`],
              }}
            />
          ))}
          <Grid
            container
            justifyContent="flex-end"
            sx={{ marginTop: "20px", marginBottom: "10px" }}
          >
            <Button
              type="button"
              variant="contained"
              color="warning"
              className="mr-2"
              onClick={handleClose}
            >
              Cancel
            </Button>
            {formik.isValid ? (
              checkoutButton
            ) : (
              <Button variant="contained" type="button" disabled>
                Check Out
              </Button>
            )}
          </Grid>
        </form>
      </DialogContent>

      {guestDetails.length === guest && (
        <Dialog open={guestDetails.length === guest}>
          <DialogContent dividers>
            <Typography variant="h6">All details submitted:</Typography>
            {guestDetails.map((detail, index) => (
              <Card key={index} style={{ marginBottom: "20px" }}>
                <CardContent>
                  <Typography>{`Name: ${detail.name}`}</Typography>
                  <Typography>{`Email: ${detail.email}`}</Typography>
                  <Typography>{`Phone: ${detail.phone}`}</Typography>
                </CardContent>
              </Card>
            ))}
            <Button onClick={handleAlertClose} color="primary">
              Close
            </Button>
          </DialogContent>
        </Dialog>
      )}
    </Dialog>
  );
};

export default GuestDetailsModal;
