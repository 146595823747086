import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { removeHtmlTags } from "../../htmlToString/HtmlToString";

const fixDescription = (data) => {
  if (data?.length < 50) {
    return data;
  }
  return data?.slice(0, 50) + "...";
};

const CommonSlide = ({ item, variants }) => {
  return (
    <motion.div variants={variants} className="store card border-0 rounded-0">
      <div className="position-relative store-image">
        {/* <Link to={`/event/${item._id}`} tabIndex={0}> */}
        {item?.event_images && item.event_images.length > 0 && (
          <img
            style={{ height: "300px", width: "100%", objectFit: "cover" }}
            src={item.event_images[0]}
            alt="store 1"
            className="card-img-top rounded-0"
          />
        )}
        {/* </Link> */}
        <div className="image-content position-absolute d-flex align-items-center">
          <div className="content-left">
            <div className="badge badge-primary">{item.bedge}</div>
          </div>
        </div>
      </div>
      <div className="card-body px-0 pb-0 pt-3">
        <Link
          to={`/event/${item._id}`}
          className="card-title h5 text-dark d-inline-block mb-2"
          tabIndex={0}
        >
          <span className="letter-spacing-25">
            {item?.event_name &&
              (item?.event_name.length > 40
                ? item?.event_name?.substring(0, 40) + "..."
                : item?.event_name)}
          </span>
        </Link>
        <ul className="list-inline store-meta mb-4 font-size-sm d-flex align-items-center flex-wrap">
          <li className="list-inline-item"></li>
          <li className="list-inline-item">
            <span className="mr-1">From</span>
            <span className="text-danger font-weight-semibold">
              ₹{item.price}
            </span>
          </li>
          <li className="list-inline-item separate" />
          <li className="list-inline-item">
            <a className="link-hover-secondary-primary" tabIndex={0}>
              <span>{item.chapter_name}</span>
            </a>
          </li>
        </ul>
        <div className="media">
          <div className="media-body lh-14 font-size-sm card-text-hide card-text-hide">
            {item.event_description && removeHtmlTags(item.event_description)}
          </div>
        </div>
      </div>
      <ul className="list-inline card-footer rounded-0 border-top pt-3 mt-5 bg-transparent px-0 store-meta d-flex align-items-center">
        <li className="list-inline-item">
          <span className="d-inline-block mr-1">
            <i className="fal fa-map-marker-alt"></i>
          </span>
          <a
            className="text-secondary text-decoration-none link-hover-secondary-blue"
            tabIndex={0}
          >
            {item.city_name}, {item.country_name},{" "}
          </a>
        </li>
        <li className="list-inline-item separate"></li>
        <li className="list-inline-item">
          <Link
            to={`/event/${item._id}`}
            // className="card-title h5 text-dark d-inline-block mb-2"
            tabIndex={0}
          >
            <span className="text-green">Book Now!</span>
          </Link>
        </li>
      </ul>
    </motion.div>
  );
};

export default CommonSlide;
