import React from "react";
import { Box, Grid, Typography, Button, useTheme } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { themeSettings } from "../../theme";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useLocation } from "react-router-dom";

const PageHeader = ({
  title,
  btnText,
  onAddClick,
  onBtnClick,
  onFilterClick,
  isShowAdd,
  onExportClick,
  wallet,
  myRefferaltnText,
  onMyRefferalClick,
  href = true,
}) => {
  const theme = useTheme();
  const typography = themeSettings(theme.palette.mode);
  const userDataString = localStorage.getItem("user_data");
  const userData = JSON.parse(userDataString);
  const userRole =
    userData?.admin?.user_role?.role || userData?.user?.user_role?.role;

  const location = useLocation();

  const shouldShowExportButton =
    userRole === "Community Super Admin" &&
    location?.pathname !== "/admin-clientreview" &&
    location?.pathname !== "/admin-banner" &&
    location?.pathname !== "/admin-services" &&
    location?.pathname !== "/admin/business-category" &&
    location?.pathname !== "/admin-transactions" &&
    location?.pathname !== "/admin-wallet" &&
    location?.pathname !== "/admin-notification" &&
    location?.pathname !== "/admin-privacy-policy" &&
    location?.pathname !== "/admin-faqs-category" &&
    location?.pathname !== "/admin-about-us" &&
    location?.pathname !== "/admin-terms-and-conditions" &&
    location?.pathname !== "/admin-faqs" &&
    location?.pathname !== "/admin-pages" &&
    location?.pathname !== "/admin-page-banners" &&
    location?.pathname !== "/admin-referral" &&
    location?.pathname !== "/admin-my-referrals" 

  const shouldShowFilterButton =
    location?.pathname !== "/admin-clientreview" &&
    location?.pathname !== "/admin-banner" &&
    location?.pathname !== "/admin-wallet" &&
    location?.pathname !== "/admin-privacy-policy" &&
    location?.pathname !== "/admin-faqs-category" &&
    location?.pathname !== "/admin-about-us" &&
    location?.pathname !== "/admin-terms-and-conditions" &&
    location?.pathname !== "/admin-pages" &&
    location?.pathname !== "/admin-page-banners"

  const shouldShowAddButton =
    userRole === "Community Super Admin" &&
    location?.pathname === "/admin-withdraw";

  return (
    <Box sx={{ px: 3, backgroundColor: "rgb(241, 245, 249)" }}>
      <Grid
        container
        style={{
          paddingTop: "40px",
          paddingBottom: "40px",
          alignItems: "center",
        }}
      >
        <Grid item sm={4} xs={12}>
          <Typography
            fontSize={typography.typography.h4.fontSize}
            sx={{ fontWeight: "bold" }}
          >
            {title}
          </Typography>
          {userRole !== "Community Super Admin" &&
            location?.pathname === "/admin-withdraw" && (
              <Typography
                fontSize={typography.typography.h4.fontSize}
                sx={{
                  fontWeight: "bold",
                  marginTop: "0px",
                  marginLeft: "150px",
                }}
              >
                Total Amount : {wallet}
              </Typography>
            )}
        </Grid>
        <Grid item sm={8} xs={12}>
          <Box
            sx={{
              display: "flex",
              marginTop: { xs: "20px", sm: "0", md: "0", lg: "0" },
              justifyContent: "flex-end",
            }}
          >
            <Box display="flex" sx={{ marginTop: "20px" }}>
              {shouldShowExportButton && (
                <a href={href} download target="_blank" rel="noreferrer">
                  <Button
                    startIcon={<ArrowDownwardIcon />}
                    sx={{
                      fontSize: "14px",
                      fontWeight: "500",
                      borderRadius: "50px",
                      border: "0",
                      color: "#fff",
                      ":hover": { bgcolor: "#3974d3", border: "0" },
                      backgroundColor: "#153e80",
                      marginLeft: {
                        xs: "0px",
                        sm: "15px",
                        md: "15px",
                        lg: "15px",
                      },
                      textTransform: "none",
                    }}
                    variant="outlined"
                    onClick={onExportClick}
                  >
                    Export
                  </Button>
                </a>
              )}

{myRefferaltnText && (
                <Button
                  sx={{
                    fontSize: "14px",
                    fontWeight: "500",
                    borderRadius: "50px",
                    border: "0",
                    color: "#fff",
                    ":hover": { bgcolor: "#3974d3", border: "0" },
                    backgroundColor: "#153e80",
                    marginLeft: {
                      xs: "0px",
                      sm: "15px",
                      md: "15px",
                      lg: "15px",
                    },
                    textTransform: "none",
                  }}
                  variant="outlined"
                  onClick={onMyRefferalClick}
                >
                  {myRefferaltnText}
                </Button>
              )}

              {btnText && !shouldShowAddButton && (
                <Button
                  sx={{
                    fontSize: "14px",
                    fontWeight: "500",
                    borderRadius: "50px",
                    border: "0",
                    color: "#fff",
                    ":hover": { bgcolor: "#3974d3", border: "0" },
                    backgroundColor: "#153e80",
                    marginLeft: {
                      xs: "0px",
                      sm: "15px",
                      md: "15px",
                      lg: "15px",
                    },
                    textTransform: "none",
                  }}
                  variant="outlined"
                  onClick={onBtnClick}
                >
                  {btnText}
                </Button>
              )}
              
              {isShowAdd && (
                <Button
                  startIcon={<AddIcon />}
                  sx={{
                    fontSize: "14px",
                    fontWeight: "500",
                    borderRadius: "50px",
                    border: "0",
                    color: "#fff",
                    ":hover": { bgcolor: "#3974d3", border: "0" },
                    backgroundColor: "#153e80",
                    marginLeft: {
                      xs: "0px",
                      sm: "15px",
                      md: "15px",
                      lg: "15px",
                    },
                    textTransform: "none",
                  }}
                  variant="outlined"
                  onClick={onAddClick}
                >
                  ADD
                </Button>
              )}
              {shouldShowFilterButton && (
                <Button
                  startIcon={<FilterAltIcon />}
                  sx={{
                    fontSize: "14px",
                    fontWeight: "500",
                    borderRadius: "50px",
                    border: "0",
                    color: "#fff",
                    ":hover": { bgcolor: "#3974d3", border: "0" },
                    backgroundColor: "#153e80",
                    marginLeft: {
                      xs: "0px",
                      sm: "15px",
                      md: "15px",
                      lg: "15px",
                    },
                    textTransform: "none",
                  }}
                  variant="outlined"
                  onClick={onFilterClick}
                >
                  Filter
                </Button>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PageHeader;
