
const initialState = {
    loading: false,
    role: null,
    userData: {},
}

function AuthReducer(state = initialState, action) {
    switch (action.type) {
        case "LOADING_AUTH":
            return {
                ...state,
                loading: action.payload
            }
        case "AUTH_SUCCESS":
            return {
                ...state,
                userData: action.payload,
                role: action.payload.role_type
            }
        default:
            return state;
    }
}

export default AuthReducer;