import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Pagination,
  Typography,
  Drawer,
  Stack,
  Divider,
  Button,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import PageHeader from "../pageHeader/PageHeader";
import EnhancedTable from "../table/EnhancedTable ";
import { DeleteIcon, EditIcon, CloseIcon } from "../../assets/icons/Icons";
import "../../components/table/table.css";
import { useForm } from "../../common/useForms";
import FormDialog from "../dialog/dialog";
import { makeStyles } from "@material-ui/core/styles";
import Input from "../../common/Input";
import { useDispatch, useSelector } from "react-redux";
import DeleteDialog from "../dialog/deletedialog";
import { useNavigate } from "react-router-dom";
import useUserRolePermissions from "../../common/UserPermissions";
import { deleteService } from "../../redux/action/ServiceAction";
import {
  createBusinessCategory,
  deleteBusinessCategory,
  getBusinessCategory,
  updateBusinessCategory,
} from "../../redux/action/BusinessCategoryAction";
import Loader from "../loader/Loader";
import { getPermissions } from "../../common/permissions";
import { permissions } from "../../common/constants";

const useStyles = makeStyles((theme) => ({
  filterMain: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "#fff",
  },
  mainFilterBox: {
    padding: "10px 20px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "200px",
    },

    [theme.breakpoints.up("md")]: {
      width: "300px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "240px !important",
    },
    [theme.breakpoints.up("xs")]: {
      width: "100%",
    },
  },

  stackFilter: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  typographyFilter: {
    fontWeight: "bold !important",
    color: "#000 !important",
    fontSize: "30px !important",
  },
  buttonFilter: {
    flexDirection: "row !important",
    marginTop: "8px",
    gap: "8px",
  },
}));
const initialValues = {
  category_name: "",
  is_active: "",
};
const initialFilterValues = {
  category_name: "",
};

const RecordPerPage = [
  { id: 5, title: "5" },
  { id: 10, title: "10" },
  // { id: 15, title: "15" },
  // { id: 20, title: "20" },
  // { id: 30, title: "30" },
];

const BusinessCategoryList = () => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState(false);
  const [filterData, setFilterData] = useState(true);
  const [filterDatas, setFilterDatas] = useState(initialFilterValues);
  const [selectPostal, setselectPostal] = useState(null);
  const [DeleteId, SetDeleteId] = useState(null);
  const [dialogTitle, setDialogTitle] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [downLoadData, setDownLoadData] = useState("");

  const { getLoading, b_category, createLoading, updateLoading } = useSelector(
    (state) => state?.BusinessCategoryReducer
  );

  const userRoleData = useUserRolePermissions("Membership");

  const isEditAllowed = getPermissions(permissions.business_category,"is_edit");
  const isDeleteAllowed = userRoleData && userRoleData.is_delete === 1;
  const isAddAllowed = userRoleData && userRoleData.is_add === 1;

  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();

  const fetchServiceDetail = async () => {
    await dispatch(getBusinessCategory(rowsPerPage, page, ""));
  };
  useEffect(() => {
    fetchServiceDetail();
  }, [rowsPerPage, page]);

  const columns = [
    {
      id: "category_name",
      numeric: false,
      disablePadding: true,
      label: "Category Name",
    },
    {
      id: "is_active",
      label: "Is Active",
    },
    !!getPermissions(permissions.business_category, "is_delete") ||
    !!getPermissions(permissions.business_category, "is_edit")
      ? { id: "action", numeric: true, disablePadding: false, label: "Action" }
      : null,
  ].filter((el) => el !== null);

  const tableTitle = "ServicesList";

  const handleChangeRow = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      let body = {};
      if (dialogTitle === "Add Business Category") {
        body = {
          is_active: values.is_active,
          category_name: values.category_name,
        };
      }

      if (dialogTitle === "Update Business Category") {
        body = {
          is_active: values.is_active,
          category_name: values.category_name,
        };
        dispatch(updateBusinessCategory(values._id, body, handleClose));
        dispatch(getBusinessCategory(rowsPerPage, page, ""));
      } else {
        await dispatch(createBusinessCategory(body, handleClose));
        await dispatch(getBusinessCategory(rowsPerPage, page, ""));
      }
    }
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleBtnClick = () => {
    // navigate("/services/create-services", {
    //     state: { formName: "Create Services", serviceData: {} },
    // });
    setOpen(true);
    setDialogTitle("Add Business Category");
    setValues(initialValues);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      // const newSelected = rows.map((n) => n.id);
      // setSelected(newSelected);
      return;
    }
    setSelected([]);
  };
  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if (dialogTitle === "Add Business Category") {
      if ("category_name" in fieldValues) {
        if (
          fieldValues.category_name === "" ||
          fieldValues.category_name === undefined ||
          fieldValues.category_name === null ||
          fieldValues.category_name?.trim() === ""
        ) {
          temp.category_name = "Please enter business category";
        } else {
          temp.category_name = "";
        }
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleEditClick = (category) => {
    setOpen(true);
    setDialogTitle("Update Business Category");
    setValues({
      ...values,
      _id: category._id,
      category_name: category.category_name,
      is_active: category.is_active,
    });
    setErrors({});
  };

  const handleClickOpenDelete = (category) => {
    setOpenDelete(true);
    SetDeleteId(category._id);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleSingleDelete = async () => {
    handleCloseDelete();
    await dispatch(deleteBusinessCategory(DeleteId));
    await dispatch(getBusinessCategory(rowsPerPage, page, ""));
  };

  const fields = [
    {
      id: "category_name",
      label: "Category name",
      type: "text",
      value: values.category_name,
      name: "category_name",
      errors: errors.category_name,
    },
    {
      id: "is_active",
      label: "Status",
      type: "select",
      options: [
        { value: "true", label: "ACTIVE" },
        { value: "false", label: "INACTIVE" },
      ],
      value: values.is_active,
      name: "is_active",
      errors: errors.is_active,
    },
  ];
  //filter
  const resetFormFilter = () => {
    setFilterDatas(initialFilterValues);
    setPage(1);
    dispatch(getBusinessCategory(rowsPerPage, page, ""));
    closeFilterDrawer();
  };

  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
    setFilterData(!filterData);
  };
  const closeFilterDrawer1 = () => {
    setFilter(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilterDatas({
      ...filterDatas,
      [name]: value,
    });
  };

  const handleSubmitFilter = (e) => {
    e.preventDefault();
    const category_name = filterDatas.category_name;
    setPage(1);
    dispatch(getBusinessCategory(rowsPerPage, page, category_name));
    closeFilterDrawer1();
  };

  const rows =
    b_category?.categoryData && Array.isArray(b_category?.categoryData)
      ? b_category?.categoryData.map((category) => {
          return {
            _id: category?._id,
            category_name: category?.category_name,
            is_active: category?.is_active ? "Active" : "inActive",
            action: (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {!!getPermissions(permissions.business_category, "is_edit") && (
                  <IconButton
                    onClick={() => handleEditClick(category)}
                    sx={{ color: "black" }}
                  >
                    <EditIcon />
                  </IconButton>
                )}

                {!!getPermissions(
                  permissions.business_category,
                  "is_delete"
                ) && (
                  <IconButton
                    onClick={() => handleClickOpenDelete(category)}
                    sx={{ color: "black" }}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </div>
            ),
            // selected: selected,
          };
        })
      : [];

  return (
    <div>
      <Box>
        <PageHeader
          title="Business Category"
          isShowAdd={false}
          onBtnClick={handleBtnClick}
          btnText={
            !!getPermissions(permissions.business_category, "is_add")
              ? "Create Business Category"
              : ""
          }
          onFilterClick={openFilterDrawer}
          href={downLoadData}
        />
        {/* {
        loading ? (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
            </Box>
        ) : ( */}
        {getLoading ? (
          <Loader />
        ) : (
          // <Box sx={{ display: "flex", justifyContent: "center" }}>
          //     <CircularProgress />
          // </Box>
          <EnhancedTable
            key={5}
            columns={columns}
            rows={rows}
            order={order}
            orderBy={orderBy}
            selected={selected}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            title={tableTitle}
            tableList={b_category && b_category?.categoryData}
          />
        )}
        {/* )} */}
        <FormDialog
          open={open}
          dialogTitle={dialogTitle}
          fields={
            dialogTitle === "Add Business Category"
              ? fields.filter((el) =>
                  ["category_name", "is_active"].includes(el.name)
                )
              : fields.filter((el) =>
                  ["category_name", "is_active"].includes(el.name)
                )
          }
          loading={
            dialogTitle === "Update Business Category"
              ? updateLoading
              : createLoading
          }
          submitText="Submit"
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          onChange={handleInputChange}
        />
        <DeleteDialog
          open={openDelete}
          // loading={deleteLoading}
          dialogTitle="Are you sure to want to delete this record?"
          handleClose={handleCloseDelete}
          handleDelete={() => handleSingleDelete(DeleteId)}
        />

        <Box
          sx={{
            display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
            justifyContent: "space-between",
            alignItems: "center",
            marginLeft: { xs: "10px", sm: "20px", md: "20px", lg: "20px" },
            marginRight: { xs: "10px", sm: "15px", md: "15px", lg: "15px" },
            mt: 2,
            mb: 2,
          }}
        >
          <Box
            sx={{
              fontSize: "14px",
              marginBottom: { xs: "10px", sm: "0", md: "0", lg: "0" },
              gap: 2,
              width: { sm: "100%", md: "50%" },
            }}
            display="flex"
            alignItems="center"
          >
            <Box>
              {/* {" "}
                Showing {membershipData?.currentPage} of{" "}
                {membershipData?.totalPages} Pages */}
            </Box>
            <Box display="flex" sx={{ gap: 1 }} alignItems="center">
              <Typography>Records per page</Typography>

              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <Select
                  id="limites"
                  name="limites"
                  value={rowsPerPage}
                  onChange={handleChangeRow}
                  displayEmpty
                  sx={{ maxWidth: "65px", maxHeight: "2.2rem" }}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {RecordPerPage?.map((item) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name || item.title}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Pagination
            count={b_category && b_category?.totalPages}
            page={page}
            onChange={handleChangePage}
            variant="outlined"
            shape="rounded"
            sx={{
              "& .MuiButtonBase-root ": {
                color: "#000 !important",
              },
              "& .Mui-selected ": {
                background: "#4b4b4e70 !important",
              },
              "& .Mui-disabled ": {
                background: "#6c696966 !important",
                color: "#000000 !important",
                opacity: 1,
              },
            }}
          />
        </Box>

        <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
          <form onSubmit={handleSubmitFilter} className={classes.filterMain}>
            <Box className={classes.mainFilterBox}>
              <Stack className={classes.stackFilter}>
                <Typography className={classes.typographyFilter}>
                  Filter
                </Typography>

                <CloseIcon
                  className="close-button"
                  onClick={closeFilterDrawer1}
                />
              </Stack>
              <Box
                sx={{
                  marginTop: "5px",
                }}
              >
                <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />
              </Box>

              <div>
                <Box>
                  <Input
                    variant="outlined"
                    color="primary"
                    label="Business Category"
                    name="category_name"
                    value={filterDatas.category_name}
                    onChange={handleChange}
                    maxLength={200}
                  />
                </Box>
                <Stack className={classes.buttonFilter}>
                  <Button
                    color="secondary"
                    size="large"
                    variant="contained"
                    className="cancel-filter"
                    onClick={resetFormFilter}
                  >
                    Reset
                  </Button>
                  <Button
                    color="primary"
                    size="large"
                    variant="contained"
                    className="order-filter-button"
                    type="submit"
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    Filter
                  </Button>
                </Stack>
              </div>
            </Box>
          </form>
        </Drawer>
      </Box>
    </div>
  );
};

export default BusinessCategoryList;
