import React from "react";
import logo from "../../assets/image/logo-home.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // For smooth scrolling effect
    });
  };

  const currentYear = new Date().getFullYear();

  return (
    <footer
      className="main-footer main-footer-style-01 bg-pattern-01"
      style={{ paddingBottom: "20px", paddingTop: "20px" }}
    >
      <div className="footer-second">
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-lg-6 mb-6 mb-lg-0">
              <div className="mb-8">
                <img style={{ width: "200px" }} src={logo} alt="Thedir" />
              </div>
              <div className="mb-7">
                <div className="font-size-md font-weight-semibold text-dark mb-4">
                  Global Garner Sales Services Limited
                </div>
                <p className="mb-0">
                  5th floor, Grand Emporio, Motera Stadium Road,
                  <br />
                  Ahmedabad, Gujarat
                  <br />
                  380005
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg mb-6 mb-lg-0">
              <div className="font-size-md font-weight-semibold text-dark mb-4">
                Company
              </div>
              <ul className="list-group list-group-flush list-group-borderless">
                <li className="list-group-item px-0 lh-1625 bg-transparent py-1">
                  <Link to="/" className="link-hover-secondary-primary">
                    Home
                  </Link>
                </li>
                <li className="list-group-item px-0 lh-1625 bg-transparent py-1">
                  <Link to="/blog" className="link-hover-secondary-primary">
                    Blog
                  </Link>
                </li>
                <li className="list-group-item px-0 lh-1625 bg-transparent py-1">
                  <Link to="/about-us" className="link-hover-secondary-primary">
                    About Us
                  </Link>
                </li>
                <li className="list-group-item px-0 lh-1625 bg-transparent py-1">
                  <Link
                    to="/contact-us"
                    className="link-hover-secondary-primary"
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-md-6 col-lg mb-6 mb-lg-0">
              <div className="font-size-md font-weight-semibold text-dark mb-4">
                Quick Links
              </div>
              <ul className="list-group list-group-flush list-group-borderless">
                <li className="list-group-item px-0 lh-1625 bg-transparent py-1">
                  <Link to="/faq" className="link-hover-secondary-primary">
                    FAQS
                  </Link>
                </li>
                <li className="list-group-item px-0 lh-1625 bg-transparent py-1">
                  <Link
                    to="/privacy-policy"
                    className="link-hover-secondary-primary"
                  >
                    Privacy
                  </Link>
                </li>
                <li className="list-group-item px-0 lh-1625 bg-transparent py-1">
                  <Link
                    to="/terms-and-condition"
                    className="link-hover-secondary-primary"
                  >
                    Terms & Conditions
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-last mt-2 mt-md-2">
        <div className="container">
          <div className="footer-last-container position-relative">
            <div className="row align-items-center">
              <div className="col-lg-4 mb-lg-0">
                <div className="social-icon text-dark">
                  <ul className="list-inline">
                    <li className="list-inline-item mr-5">
                      <a target="_blank" title="Twitter" href="#">
                        <i className="fab fa-twitter"></i>
                        <span>Twitter</span>
                      </a>
                    </li>
                    <li className="list-inline-item mr-5">
                      <a target="_blank" title="Facebook" href="#">
                        <i className="fab fa-facebook-f"></i>
                        <span>Facebook</span>
                      </a>
                    </li>
                    <li className="list-inline-item mr-5">
                      <a target="_blank" title="Google plus" href="#">
                        <svg className="icon icon-google-plus-symbol">
                          <use xlinkHref="#icon-google-plus-symbol" />
                        </svg>
                        <span>Google plus</span>
                      </a>
                    </li>
                    <li className="list-inline-item mr-5">
                      <a target="_blank" title="Instagram" href="#">
                        <svg className="icon icon-instagram">
                          <use xlinkHref="#icon-instagram" />
                        </svg>
                        <span>Instagram</span>
                      </a>
                    </li>
                    <li className="list-inline-item mr-5">
                      <a target="_blank" title="Rss" href="#">
                        <i className="fas fa-rss" />
                        <span>Rss</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-5 mb-lg-0">
                <div>
                  © {currentYear}{" "}
                  <Link
                    to="/"
                    className="link-hover-dark-primary font-weight-semibold"
                  >
                    GGC{" "}
                  </Link>
                  All Rights Resevered.
                </div>
              </div>
              <div className="back-top text-left text-lg-right">
                <a
                  className="gtf-back-to-top link-hover-secondary-primary in"
                  onClick={handleScrollToTop}
                >
                  <i
                    className="fal fa-arrow-up"
                    style={{ cursor: "pointer" }}
                  />
                  <span style={{ cursor: "pointer" }}>Back To Top</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
