import React from "react";
// import client_1 from "../../assets/image/listing/client-1.png";

const ReviewCard = ({ item }) => {
  return (
    <div
      className="box"
      style={{
        width: "100%",
        display: "inline-block",
      }}
    >
      <div className="card testimonial h-100 border-0 bg-transparent mt-7">
        <a class="author-image" tabindex="0">
          <img
            src={item?.client_image}
            alt="Testimonial"
            style={{ position: "absolute", left: "20px", top: "-40px" }}
            class="rounded-circle"
          />
        </a>
        <div className="card-body bg-white">
          <div className="testimonial-icon text-right">
            <svg className="icon icon-quote">
              <use xlinkHref="#icon-quote" />
            </svg>
          </div>
          <ul className="list-inline mb-4 d-flex align-items-end flex-wrap">
            <li className="list-inline-item">
              <a
                className="font-size-lg text-dark font-weight-semibold d-inline-block"
                tabIndex={0}
              >
                {item?.client_name}
              </a>
            </li>
            <li className="list-inline-item">
              <span className="h5 font-weight-light mb-0 d-inline-block ml-1 text-gray">
                /
              </span>
            </li>
            <li>
              <span className="text-gray">{item?.client_profile}</span>
            </li>
          </ul>
          <div className="card-text text-gray pr-4" style={{ height: "105px",overflow:"scroll" }}>
            {item?.review}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewCard;
