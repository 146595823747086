import { Autocomplete, Box, Button, Grid, Paper, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import UserHeader from "../header/Header";
import { useFormik } from "formik";
import * as Yup from "yup";
import MuiPhoneNumber from "material-ui-phone-number";
import { useDispatch, useSelector } from "react-redux";
import './beMemeberForm.css'
import Input from "../../common/Input";
import { GetAllCountry } from "../../redux/action/CountryAction";
import { GetAllStates, cleareAllStates } from "../../redux/action/StateAction";
import { GetAllCities, cleareAllCities } from "../../redux/action/CityAction";
import { GetAllPostalCodes, clearePostalCodes } from "../../redux/action/PostalCodeAction";
import { CreatePartnerReq } from "../../redux/action/PartnerRequestAction";
import BeMemberFormComponents from "./BeMemberFormComponents";

// const phoneRegExp =
//   /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
var emailValidation =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const SignupSchema = Yup.object().shape({
  name: Yup.string().required("Please enter name"),
  email: Yup.string()
    .required("Please enter email")
    .matches(emailValidation, "Please enter valid email"),
  mobile_number: Yup.string().required("Please enter mobile number"),
  // .matches(phoneRegExp, "Phone number is not valid"),
  // .min(9, "Too short")
  // .max(15, "Too long"),
  city: Yup.object().required("Please select city"),
  state: Yup.object().required("Please select state"),
  country: Yup.object().required("Please select country"),
  postal: Yup.object().required("Please select postal code"),
  // message: Yup.string().required("Please enter message"),
});

const BeMemberForm = () => {
  const dispatch = useDispatch();

  const {
    allStateData,
  } = useSelector((state) => state.StateReducer);
  const {
    allCountryData
  } = useSelector((state) => state.CountryReducer);
  const {
    allCitiesData
  } = useSelector((state) => state.CityReducer);
  const {
    allPostalData
  } = useSelector((state) => state.PostalCodeReducer);
  useEffect(() => {
    dispatch(GetAllCountry())
    // dispatch(GetAllPostalCodes())
  }, [])

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      mobile_number: "",
      city: "",
      state: "",
      country: "",
      postal: "",
      message: "",
    },
    validationSchema: SignupSchema,
    onSubmit: (values, { resetForm }) => {
      const number = values.mobile_number.split(" ");
      const body = {
        name: values.name,
        email: values.email,
        phone_code: number[0]?.replace("+", ''),
        mobile_number: number[1]?.replace("-", ''),
        message: values.message || "-",
        city_id: values.city._id, 
        country_id: values.country._id, 
        state_id: values.state._id, 
        postalcode_id: values.postal._id
      }
      dispatch(CreatePartnerReq(body));
      resetForm();
      // alert(JSON.stringify(values, null, 2));
    },
  });

  // const number = formik.values.mobile_number.split(" ");

  return (
    <div>
      <Box
        sx={{
          margin: 5,
        }}
      >
        <Grid container spacing={{ xs: 2, md: 5, sm: 2, xl: 5, lg: 5 }}>
          <Grid item md={5}>
            <Box>
              <Typography
                fontWeight={900}
                fontSize={28}
                marginBottom={2}
                color="black"
              >
                Request Demo
              </Typography>
              <Typography
                fontWeight={900}
                fontSize={16}
                marginBottom={2}
                color="gray"
              >
                Tell us a few details and we’ll conduct a review of your threat
                profile and identify the threats that can be addressed with
                CyberSiARA. One of our executives will demonstrate our
                platform’s approach to remediating automated and human-driven
                fraud and abuse.
              </Typography>
            </Box>
          </Grid>

          <Grid item md={7} xs={12} sm={12}>
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
                <BeMemberFormComponents />
              {/* <Grid item md={12}>
                <Box component={Paper} padding={{ xs: 2, sm: 3 }} elevation={5}>
                  <Typography
                    textAlign="center"
                    fontWeight={900}
                    fontSize={28}
                    marginBottom={2}
                    color="black"
                  >
                    Registration
                  </Typography>
                  <form onSubmit={formik.handleSubmit}>
                    <Input
                      id="name"
                      name="name"
                      type="text"
                      label="Name"
                      onChange={formik.handleChange}
                      value={formik.values.name}
                      onBlur={formik.handleBlur}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText = {
                        formik.touched.name && formik.errors.name ? (
                          <div className="err-msg err-msg-font">{formik.errors.name}</div>
                        ) : null
                      }
                      maxLength={50}
                    />
                    <Input
                      id="email"
                      name="email"
                      label="Email Address"
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      InputLabelProps={{ sx: { color: 'black' } }}
                      helperText={
                        formik.touched.email && formik.errors.email ? (
                          <div className="err-msg err-msg-font">{formik.errors.email}</div>
                        ) : null
                      }
                      maxLength={150}
                    />

                    <MuiPhoneNumber
                      fullWidth
                      defaultCountry={"in"}
                      size="small"
                      variant="outlined"
                      id="mobile_number"
                      name="mobile_number"
                      label="Mobile Number"
                      type="text"
                      onBlur={formik.handleBlur}
                      onChange={(value) => {
                        formik.setFieldValue("mobile_number", value);
                      }}
                      value={formik.values.mobile_number}
                      error={formik.errors.mobile_number}
                      helperText={
                        formik.touched.mobile_number &&
                          formik.errors.mobile_number ? (
                          <div className="err-msg-mobile err-msg-font">{formik.errors.mobile_number}</div>
                        ) : null
                      }
                      InputLabelProps={{ sx: { color: 'black' } }}
                      // sx={{
                      //   "& .MuiOutlinedInput-root:hover": {
                      //     "& > fieldset": {
                      //       borderColor: "black",
                      //     },
                      //   },
                      //   "&.Mui-focused .MuiOutlinedInput-root": {
                      //     borderColor: "#4f46e5!important",
                      //   },
                      //   "& .MuiOutlinedInput-root": {
                      //     "&.Mui-focused fieldset": {
                      //       borderColor: "#4f46e5!important",
                      //     },
                      //     "& fieldset": {
                      //       borderColor: "black!important",
                      //     },
                      //     color: "black!important",
                      //     mb: 1,
                      //   },
                      //   mt: 1,
                      //   borderRadius: "12px",
                      // }}
                    />

                    <div style={{marginTop: '1rem'}}>
                      <Autocomplete
                        id='country'
                        name='country'
                        value={formik.values.country || null}
                        // classes={customStyles}
                        onChange={(e, value) => {
                          formik.setFieldValue('country', value)
                          dispatch(GetAllStates(value?._id))
                          dispatch(cleareAllStates())
                          formik.setFieldValue('state', '')
                        }}
                        options={allCountryData?.countryData || []}
                        autoHighlight
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.country && Boolean(formik.errors.country)
                        }
                        helperText={
                          formik.touched.country && formik.errors.country ? (
                            <div className="err-msg-font">{formik.errors.country}</div>
                          ) : null
                        }

                        getOptionLabel={(option) => option.country_name}
                        renderInput={(params) => (
                          <TextField
                            sx={{ color: '#000' }}
                            {...params}
                            label='Country'
                            inputProps={{
                              ...params.inputProps,
                              style: { color: '#000' },
                            }}
                          />
                        )}
                      />
                      {(formik.touched.country && formik.errors.country) && (
                        <Typography variant="caption" color="error" sx={{fontSize: '12px'}}>
                          {formik.errors.country}
                        </Typography>
                      )}
                      </div>

                    <div style={{marginTop: '1rem'}}>
                      <Autocomplete
                        id='state'
                        name='state'
                        value={formik.values.state || null}
                        onChange={(e, value) => {
                          formik.setFieldValue('state', value)
                          dispatch(GetAllCities(value?._id))
                          dispatch(cleareAllCities())
                          formik.setFieldValue('city', '')
                        }}
                        options={allStateData || []}
                        autoHighlight
                        getOptionLabel={(option) => option.state_name}
                        renderInput={(params) => (
                          <TextField
                            sx={{ color: '#000' }}
                            {...params}
                            label='State'
                            inputProps={{
                              ...params.inputProps,
                              style: { color: '#000' },
                            }}
                          />
                        )}
                      />
                      {(formik.touched.state && formik.errors.state) && (
                        <Typography variant="caption" color="error" sx={{fontSize: '12px'}}>
                          {formik.errors.state}
                        </Typography>
                      )}
                      </div>

                    <div style={{marginTop: '1rem'}}>
                      <Autocomplete
                        id='city'
                        name='city'
                        value={formik.values.city || null}
                        onChange={(e, value) => {
                          formik.setFieldValue('city', value)
                          dispatch(GetAllPostalCodes(value?._id))
                          dispatch(clearePostalCodes())
                          formik.setFieldValue('postal', '')
                        }}
                        options={allCitiesData || []}
                        autoHighlight
                        getOptionLabel={(option) => option.city_name}
                        renderInput={(params) => (
                          <TextField
                            sx={{ color: '#000' }}
                            {...params}
                            label='City'
                            inputProps={{
                              ...params.inputProps,
                              style: { color: '#000' },
                            }}
                          />
                        )}
                      />
                      {(formik.touched.city && formik.errors.city) && (
                        <Typography variant="caption" color="error" sx={{fontSize: '12px'}}>
                          {formik.errors.city}
                        </Typography>
                      )}
                      </div>

                    <div style={{marginTop: '1rem'}}>
                      <Autocomplete
                        id='postal'
                        name='Postal code'
                        value={formik.values.postal || null}
                        onChange={(e, value) => formik.setFieldValue('postal', value)}
                        options={allPostalData || []}
                        autoHighlight
                        getOptionLabel={(option) => option.postal_code}
                        renderInput={(params) => (
                          <TextField
                            sx={{ color: '#000' }}
                            {...params}
                            label='Postal code'
                            inputProps={{
                              ...params.inputProps,
                              style: { color: '#000' },
                            }}
                          />
                        )}
                      />
                      {(formik.touched.postal && formik.errors.postal) && (
                        <Typography variant="caption" color="error" sx={{fontSize: '12px'}}>
                          {formik.errors.postal}
                        </Typography>
                      )}
                      </div>

                      <Input
                        fullWidth
                        maxRows={4}
                        name="message"
                        value={formik.values.message}
                        label="Message"
                        variant="outlined"
                        onChange={formik.handleChange}
                        // onBlur={formik.handleBlur}
                        // error={
                        //   formik.touched.message && Boolean(formik.errors.message)
                        // }
                        // helperText={
                        //   formik.touched.message && formik.errors.message ? (
                        //     <div>{formik.errors.message}</div>
                        //   ) : null
                        // }
                        maxLength={500}
                      />
                      <Button
                        sx={{ marginTop: 4 }}
                        type="submit"
                        variant="contained"
                      >
                        Submit
                      </Button>
                  </form>
                </Box>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default BeMemberForm;
