import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Pagination,
  Typography,
  Drawer,
  Stack,
  Divider,
  Button,
  CircularProgress,
} from "@mui/material";
import PageHeader from "../../components/pageHeader/PageHeader";
import EnhancedTable from "../../components/table/EnhancedTable ";
import { DeleteIcon, EditIcon, CloseIcon } from "../../assets/icons/Icons";
import "../../components/table/table.css";
import { Form, useForm } from "../../common/useForms";
import FormDialog from "../../components/dialog/dialog";
import { makeStyles } from "@material-ui/core/styles";
import Input from "../../common/Input";
import { useDispatch, useSelector } from "react-redux";
import {
  CreateSubscription,
  DeleteSubscription,
  GetSubscription,
  UpdateSubscription,
} from "../../redux/action/SubscriptionAction";
import { useSnackbar } from "notistack";
import DeleteDialog from "../dialog/deletedialog";
import SelectBox from "../../common/SelectBox";

const useStyles = makeStyles((theme) => ({
  filterMain: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "#fff",
  },
  mainFilterBox: {
    padding: "10px 20px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "200px",
    },

    [theme.breakpoints.up("md")]: {
      width: "300px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "240px !important",
    },
    [theme.breakpoints.up("xs")]: {
      width: "100%",
    },
  },

  stackFilter: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  typographyFilter: {
    fontWeight: "bold !important",
    color: "#000 !important",
    fontSize: "30px !important",
  },
  buttonFilter: {
    flexDirection: "row !important",
    marginTop: "8px",
    gap: "8px",
  },
}));

const columns = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  { id: "type", numeric: true, disablePadding: false, label: "Type" },
  {
    id: "min_turn_over",
    numeric: true,
    disablePadding: false,
    label: "Min turn over",
  },
  {
    id: "details",
    numeric: true,
    disablePadding: false,
    label: "Details",
  },
  { id: "action", numeric: true, disablePadding: false, label: "Action" },
];

const initialValues = {
  name: "",
  type: "",
  min_turn_over: "",
  details: "",
};
const initialFilterValues = {
  name: "",
  type: "",
};

const ManageSubscriptionList = () => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filter, setFilter] = useState(false);
  const [filterData, setFilterData] = useState(true);
  const [filterDatas, setFilterDatas] = useState(initialFilterValues);
  const [selectedSubScription, setSelectedSubScription] = useState(null);
  const [DeleteId, SetDeleteId] = useState(null);
  const [dialogTitle, setDialogTitle] = useState("");

  const filterMenuItem = [
    { value: "Basic", label: "Basic" },
    { value: "Advanced", label: "Advanced" },
    { value: "Enterprise", label: "Enterprise" },
  ];
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const {
    subscriptionData,
    loading,
    updateLoading,
    createLoading,
    deleteLoading,
  } = useSelector((state) => state.SubscriptionReducer);

  const tableTitle = "SubscriptionList";

  useEffect(() => {
    if (!open) {
      const search = filterDatas.name
        ? filterDatas.name
        : filterDatas.type || "";
      dispatch(GetSubscription(page, search));
    }
  }, [page, open]);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleClickOpen = () => {
    setOpen(true);
    setDialogTitle("Add New Subscription");
    setValues(initialValues);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("name" in fieldValues)
      temp.name = fieldValues.name ? "" : "This field is required.";
    if ("type" in fieldValues)
      temp.type = fieldValues.type ? "" : "This field is required.";
    if ("min_turn_over" in fieldValues)
      temp.min_turn_over = fieldValues.min_turn_over
        ? ""
        : "This field is required.";
    if ("details" in fieldValues)
      temp.details = fieldValues.details ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleEditClick = (subscription) => {
    setSelectedSubScription(subscription);
    setDialogTitle("Update Subscription");
    setValues({
      ...values,
      name: subscription.name || "",
      type: subscription.type || "",
      min_turn_over: subscription.min_turn_over || "",
      details: subscription.details || "",
    });
    setErrors({});
    setOpen(true);
  };

  const handleClickOpenDelete = (subscription) => {
    setOpenDelete(true);
    SetDeleteId(subscription._id);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleSingleDelete = async () => {
    await dispatch(DeleteSubscription(DeleteId, handleCloseDelete)).then(
      (res) => {
        if (res && res.data.status === true) {
          dispatch(GetSubscription(page));
        }
      }
    );
  };

  const fields = [
    {
      id: "name",
      label: "Name",
      value: values.name,
      name: "name",
      maxLength: 50,
    },
    {
      id: "type",
      label: "Type",
      type: "select",
      options: [
        { value: "Basic", label: "Basic" },
        { value: "Advanced", label: "Advanced" },
        { value: "Enterprise", label: "Enterprise" },
      ],
      value: values.type,
      name: "type",
      maxLength: 100,
    },
    {
      id: "min_turn_over",
      label: "Min turn over",
      value: values.min_turn_over,
      name: "min_turn_over",
      maxLength: 10,
    },
    {
      id: "details",
      label: "Details",
      value: values.details,
      name: "details",
    },
  ];

  const isAnyFieldNotEmpty = Object.values(values).some(
    (value) => value.trim() !== ""
  );

  //filter

  const resetFormFilter = () => {
    setFilterDatas(initialFilterValues);
  };

  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
    setFilterData(!filterData);
  };
  const closeFilterDrawer1 = () => {
    setFilter(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFilterDatas({
      ...filterDatas,
      [name]: value,
    });
  };

  const handleSubmitFilter = (e) => {
    e.preventDefault();
    const body = {
      name: filterDatas.name,
      type: filterDatas.type,
    };
    const search = filterDatas.name ? filterDatas.name : filterDatas.type || "";
    setPage(1);
    dispatch(GetSubscription(page, search));
    closeFilterDrawer1();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const body = {
        name: values.name,
        type: values.type,
        min_turn_over: values.min_turn_over,
        details: values.details,
      };

      if (dialogTitle === "Update Subscription") {
        dispatch(
          UpdateSubscription(selectedSubScription._id, body, handleClose)
        );
      } else {
        dispatch(CreateSubscription(body, handleClose));
      }
    }
  };

  const rows =
    subscriptionData && Array.isArray(subscriptionData?.membershipData)
      ? subscriptionData?.membershipData.map((subscription) => {
          const selected = subscription === selectedSubScription;
          return {
            _id: subscription._id,
            name: subscription.name || "-",
            details: subscription.details || "-",
            package_type: subscription.package_type || "-",
            type: subscription.type || "-",
            min_turn_over: subscription.min_turn_over || "-",
            action: (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <IconButton
                  onClick={() => handleEditClick(subscription)}
                  sx={{ color: "black" }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  onClick={() => handleClickOpenDelete(subscription)}
                  sx={{ color: "black" }}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            ),
            selected: selected,
          };
        })
      : [];

  const isAnyFieldFilled = Object.values(filterDatas).some((value) =>
    Boolean(value)
  );

  return (
    <Box>
      <PageHeader
        title="Subscription List"
        onAddClick={handleClickOpen}
        onFilterClick={openFilterDrawer}
      />
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      ) : subscriptionData.length === 0 ? (
        <Typography variant="body1" align="center">
          No data found
        </Typography>
      ) : (
        <EnhancedTable
          key={rows._id}
          columns={columns}
          rows={rows}
          order={order}
          orderBy={orderBy}
          selected={selected}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          title={tableTitle}
          tableList={subscriptionData}
        />
      )}

      <FormDialog
        open={open}
        dialogTitle={dialogTitle}
        fields={fields}
        loading={
          dialogTitle === "Update Subscription" ? updateLoading : createLoading
        }
        submitText="Submit"
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        onChange={handleInputChange}
        isAnyFieldNotEmpty={isAnyFieldNotEmpty}
      />
      <DeleteDialog
        open={openDelete}
        loading={deleteLoading}
        dialogTitle="Are you sure to want to delete this record?"
        handleClose={handleCloseDelete}
        handleDelete={() => handleSingleDelete(DeleteId)}
      />

      <Box
        sx={{
          display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
          justifyContent: "space-between",
          alignItems: "center",
          marginLeft: { xs: "10px", sm: "20px", md: "20px", lg: "20px" },
          marginRight: { xs: "10px", sm: "15px", md: "15px", lg: "15px" },
          mt: 2,
          mb: 2,
        }}
      >
        <Box
          sx={{
            fontSize: "14px",
            marginBottom: { xs: "10px", sm: "0", md: "0", lg: "0" },
            gap: 2,
            width: { sm: "100%", md: "50%" },
          }}
          display="flex"
          alignItems="center"
        >
          <Box>
            {" "}
            Showing {subscriptionData?.currentPage} of{" "}
            {subscriptionData?.totalPages} Pages
          </Box>
        </Box>
        <Pagination
          count={subscriptionData?.totalPages}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
          sx={{
            "& .MuiButtonBase-root ": {
              color: "#000 !important",
            },
            "& .Mui-selected ": {
              background: "#4b4b4e70 !important",
            },
            "& .Mui-disabled ": {
              background: "#6c696966 !important",
              color: "#000000 !important",
              opacity: 1,
            },
          }}
        />
      </Box>

      <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
        <form onSubmit={handleSubmitFilter} className={classes.filterMain}>
          <Box className={classes.mainFilterBox}>
            <Stack className={classes.stackFilter}>
              <Typography className={classes.typographyFilter}>
                Filter
              </Typography>

              <CloseIcon
                className="close-button"
                onClick={closeFilterDrawer1}
              />
            </Stack>
            <Box
              sx={{
                marginTop: "5px",
              }}
            >
              <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />
            </Box>

            <div>
              <Box>
                <Input
                  disabled={isAnyFieldFilled && !filterDatas.name}
                  text="Add New"
                  variant="outlined"
                  color="primary"
                  label="Name"
                  name="name"
                  value={filterDatas.name}
                  onChange={handleChange}
                  maxLength={10}
                />
              </Box>
              <SelectBox
                disabled={isAnyFieldFilled && !filterDatas.type}
                options={filterMenuItem}
                label={"Type"}
                value={filterDatas.type}
                name="type"
                onChange={handleChange}
              ></SelectBox>
              <Stack className={classes.buttonFilter}>
                <Button
                  color="secondary"
                  size="large"
                  variant="contained"
                  className="cancel-filter"
                  onClick={resetFormFilter}
                >
                  Reset
                </Button>
                <Button
                  color="primary"
                  size="large"
                  variant="contained"
                  className="order-filter-button"
                  type="submit"
                  style={{
                    cursor: "pointer",
                  }}
                >
                  Filter
                </Button>
              </Stack>
            </div>
          </Box>
        </form>
      </Drawer>
    </Box>
  );
};

export default ManageSubscriptionList;
