import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { FormControl, Typography } from '@mui/material';

const MyAutocomplete = ({
  field,
  label,
  value,
  autoCompleteData,
  onChange,
  labelAccess,
  customStyles,
}) => {
  return (
    <FormControl fullWidth sx={{ color: '#000 !important', marginTop: '1rem' }}>
      <Autocomplete
        id={field.id}
        value={value || null}
        classes={customStyles}
        onChange={onChange}
        options={autoCompleteData || []}
        autoHighlight
        sx={{
          // "& .MuiAutocomplete-popupIndicator": {
          //   color: "red !important",
          // },
          // "& .MuiInputLabel-root": {
          //   color: "#000 !important",
          // },
          "&.css-1jr8uhn-MuiAutocomplete-root:hover .MuiAutocomplete-clearIndicator": {
            color: "#000 !important",
          },
          '&.css-16y2wkt-MuiFormLabel-root-MuiInputLabel-root': {
            color: '#000 !important',
          },
        }}
        getOptionLabel={(option) => option[labelAccess]}
        renderInput={(params) => (
          <TextField
            sx={{ input: { color: "#000" } }}
            {...params}
            label={label}
            inputProps={{
              ...params.inputProps,
              style: { color: '#000' },
            }}
            variant="outlined"
            error={Boolean(field.errors)}
          />
        )}
      />
      {field.errors && (
        <Typography variant="caption" color="error" sx={{ fontSize: '12px' }}>
          <div className="err-msg-font">{field.errors}</div>
        </Typography>
      )}
    </FormControl>
  );
};

export default MyAutocomplete;
