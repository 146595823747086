import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Typography,
  Drawer,
  Stack,
  Divider,
  Button,
} from "@mui/material";
import PageHeader from "../../components/pageHeader/PageHeader";
import EnhancedTable from "../../components/table/EnhancedTable ";
import { DeleteIcon, EditIcon, CloseIcon } from "../../assets/icons/Icons";
import "../../components/table/table.css";
import { Form, useForm } from "../../common/useForms";
import FormDialog from "../../components/dialog/dialog";
import { makeStyles } from "@material-ui/core/styles";
import Input from "../../common/Input";
import { useDispatch, useSelector } from "react-redux";
import {
  CreateUserManage,
  DeleteUserManage,
  GetAllUser,
  UpdateUserManage,
} from "../../redux/action/UserManageAction";
import { useSnackbar } from "notistack";
import DeleteDialog from "../dialog/deletedialog";
import ComingSoon from "../soon/ComingSoon";
import {
  CreateRole,
  GetUserRoleList,
} from "../../redux/action/RoleandPermissionAction";
import { Navigate, useNavigate } from "react-router-dom";
import axios from "../../redux/ApiConfig";
import { ApiURL } from "../../redux/common";
import { getPermissions } from "../../common/permissions";
import { permissions } from "../../common/constants";
import useUserRolePermissions from "../../common/UserPermissions";
import Loader from "../loader/Loader";

const useStyles = makeStyles((theme) => ({
  filterMain: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "#fff",
  },
  mainFilterBox: {
    padding: "10px 20px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "200px",
    },

    [theme.breakpoints.up("md")]: {
      width: "300px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "240px !important",
    },
    [theme.breakpoints.up("xs")]: {
      width: "100%",
    },
  },

  stackFilter: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  typographyFilter: {
    fontWeight: "bold !important",
    color: "#000 !important",
    fontSize: "30px !important",
  },
  buttonFilter: {
    flexDirection: "row !important",
    marginTop: "8px",
    gap: "8px",
  },
}));

const columns = [
  {
    id: "role",
    numeric: false,
    disablePadding: true,
    label: "Role",
  },
  {
    id: "is_active",
    numeric: false,
    disablePadding: true,
    label: "Is Active",
  },
  { id: "action", numeric: true, disablePadding: false, label: "Action" },
];

const initialValues = {
  email: "",
  role: "",
  mobile_number: "",
  password: "",
  confirm_password: "",
};
const initialFilterValues = {
  name: "",
};

const ManagePermissionList = () => {
  const dispatch = useDispatch();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filter, setFilter] = useState(false);
  const [filterData, setFilterData] = useState(true);
  const [filterDatas, setFilterDatas] = useState(initialFilterValues);
  const [selectedUser, setSelectedUser] = useState(null);
  const [DeleteId, SetDeleteId] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [popupName, setPopupName] = useState("");
  const [tempPass, setTempPass] = useState("");
  const userRoleData = useUserRolePermissions("User");
  const {
    loading,
    updateLoading,
    deleteLoading,
    createLoading,
    roleListLoading,
    roleList,
  } = useSelector((state) => state.RoleandPermissionReducer);
  const userallData = [];

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const tableTitle = "SubscriptionList";


  const [downLoadData, setDownLoadData] = useState("");



  const DownLoadPdf = async () => {
    try {
        const res = await axios.get(`${ApiURL}role/download-data`);
        setDownLoadData(res?.data?.data)

    } catch (error) {
        console.error('Error fetching role data:', error);
        return {};
    }
};

useEffect(() => {
    DownLoadPdf()
}, [])



  // useEffect(() => {
  //   if(!open){
  //     dispatch(GetAllUser(page));
  //   }
  // }, [page, open]);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleClickOpen = () => {
    setPopupName("Add New Role");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    resetForm();
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if (popupName === "Add New Role") {
      if ("role" in fieldValues) {
        if (
          fieldValues.role === "" ||
          fieldValues.role === undefined ||
          fieldValues.role === null ||
          fieldValues.role?.trim() === ""
        ) {
          temp.role = "Please enter role name";
        } else {
          temp.role = "";
        }
      }
    } else {
      if ("country" in fieldValues) {
        if (
          fieldValues.country === "" ||
          fieldValues.country === undefined ||
          fieldValues.country === null
        ) {
          temp.country = "Please select country";
        } else {
          temp.country = "";
        }
      }

      if ("role" in fieldValues) {
        if (
          fieldValues.role === "" ||
          fieldValues.role === undefined ||
          fieldValues.role === null ||
          fieldValues.role?.trim() === ""
        ) {
          temp.role = "Please enter role name";
        } else {
          temp.role = "";
        }
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleEditClick = (user) => {
    setSelectedUser(user);
    setPopupName("Update Permission");
    navigate("/admin-update-role-permission", {
      state: user,
    });
    setValues({
      ...values,
      email: user.email || "",
      mobile_number: user.mobile_number || "",
      role: user.role || "",
    });
    setErrors({});
    setOpen(true);
  };

  const handleClickOpenDelete = (subscription) => {
    setOpenDelete(true);
    SetDeleteId(subscription._id);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleSingleDelete = async () => {
    try {
      const res = await axios.delete(`${ApiURL}role/delete/${DeleteId}`);
      if (res && res.data) {
        handleCloseDelete();
        dispatch(GetAllUser(page, "", "", "", "", "", "", "", "", "", "", ""));
        dispatch(GetUserRoleList());
        enqueueSnackbar(res.data.message || res.message, {
          variant: res.data.status ? "success" : "error",
          autoHideDuration: 2000,
          isOpen: true,
        });
      } else {
        enqueueSnackbar(res.message || "API response is missing data", {
          variant: "error",
          autoHideDuration: 2000,
          isOpen: true,
        });
      }
    } catch (error) {
      console.error("API call error:", error.response.data.message);
      enqueueSnackbar(error?.response?.data?.message || "API call failed", {
        variant: "error",
        autoHideDuration: 2000,
        isOpen: true,
      });
    }
  };

  const fields = [
    {
      id: "role",
      label: "Role",
      type: "text",
      value: values.role,
      name: "role",
      errors: errors.role,
      maxLength: 35,
    },

    // {
    //   id: "mobile_number",
    //   label: "Phone Number",
    //   value: values.mobile_number,
    //   name: "mobile_number",
    //   errors: errors.mobile_number,
    //   maxLength: 10,
    // },
    // {
    //   id: "password",
    //   label: "Password",
    //   value: values.password,
    //   name: "password",
    //   errors: errors.password,
    // },
    // {
    //   id: "confirm_password",
    //   label: "Confirm Password",
    //   value: values.confirm_password,
    //   name: "confirm_password",
    //   errors: errors.confirm_password,
    // },
  ];

  const isAnyFieldNotEmpty = Object.values(values).some(
    (value) => value.trim() !== ""
  );

  //filter

  const resetFormFilter = () => {
    setFilterDatas(initialFilterValues);
    setPage(1);
    dispatch(GetUserRoleList(""));
    closeFilterDrawer();
  };

  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
    setFilterData(!filterData);
  };
  const closeFilterDrawer1 = () => {
    setFilter(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFilterDatas({
      ...filterDatas,
      [name]: value,
    });
  };

  const handleSubmitFilter = (e) => {
    e.preventDefault();
    const body = {
      name: filterDatas.name,
    };
    dispatch(GetUserRoleList(filterDatas.name));
    setFilterDatas(body);
    setPage(1);
    closeFilterDrawer1();
  };

  const rows =
    roleList && Array.isArray(roleList)
      ? roleList.map((role) => {
          const selected = role === selectedUser;
          return {
            _id: role._id,
            role: role.role,
            is_active: role.is_active ? "Active" : "inActive",
            action: (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {!!getPermissions(permissions.role, "is_edit") && (
                  <IconButton
                    onClick={() => handleEditClick(role)}
                    sx={{ color: "black" }}
                  >
                    <EditIcon />
                  </IconButton>
                )}
                {!!getPermissions(permissions.role, "is_delete") && (
                  <IconButton
                    onClick={() => handleClickOpenDelete(role)}
                    sx={{ color: "black" }}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </div>
            ),
            selected: selected,
          };
        })
      : [];

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      let body = {};
      if (popupName === "Update Permission") {
        body = {
          email: values.email,
          role: values.role,
          mobile_number: values.mobile_number,
        };
      } else {
        body = {
          role: values.role,
        };
      }

      if (popupName === "Update Permission") {
        dispatch(UpdateUserManage(selectedUser._id, body, handleClose));
      } else {
        dispatch(CreateRole(body, handleClose));
      }
    }
  };

  useEffect(() => {
    if (!open) {
      dispatch(GetUserRoleList());
    }
  }, [page, open, dispatch]);

  useEffect(() => {
    dispatch(GetUserRoleList());
  }, []);

  return (
    <Box>
      <PageHeader
        title="User Role & Permission"
        onAddClick={() => navigate("/admin-create-role-permission")}
        onFilterClick={openFilterDrawer}
        isShowAdd={!!getPermissions(permissions.role, "is_add")}
        href={downLoadData}
      />

      {(loading || roleListLoading) ? (
        <Loader/>
        // <Box sx={{ display: "flex", justifyContent: "center" }}>
        //   <CircularProgress />
        // </Box>
      ) : (
        <EnhancedTable
          key={rows._id}
          columns={columns}
          rows={rows}
          order={order}
          orderBy={orderBy}
          selected={selected}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          title={tableTitle}
          tableList={roleList}
          rowsPerPage={roleList.length}
        />
      )}
      <FormDialog
        open={open}
        dialogTitle={popupName}
        fields={
          popupName === "Update Permission"
            ? fields.filter(
                (el) => !["password", "confirm_password"].includes(el.name)
              )
            : fields
        }
        loading={
          popupName === "Update Permission" ? updateLoading : createLoading
        }
        submitText="Submit"
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        onChange={handleInputChange}
        isAnyFieldNotEmpty={isAnyFieldNotEmpty}
      />

      <DeleteDialog
        open={openDelete}
        loading={deleteLoading}
        dialogTitle="Are you sure to want to delete this record?"
        handleClose={handleCloseDelete}
        handleDelete={() => handleSingleDelete(DeleteId)}
      />
      <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
        <form onSubmit={handleSubmitFilter} className={classes.filterMain}>
          <Box className={classes.mainFilterBox}>
            <Stack className={classes.stackFilter}>
              <Typography className={classes.typographyFilter}>
                Filter
              </Typography>

              <CloseIcon
                className="close-button"
                onClick={closeFilterDrawer1}
              />
            </Stack>
            <Box
              sx={{
                marginTop: "5px",
              }}
            >
              <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />
            </Box>

            <div>
              <Box>
                <Input
                  text="Add New"
                  variant="outlined"
                  color="primary"
                  label="Role Name"
                  name="name"
                  value={filterDatas.name}
                  onChange={handleChange}
                  maxLength={100}
                />
              </Box>
              <Stack className={classes.buttonFilter}>
                <Button
                  color="secondary"
                  size="large"
                  variant="contained"
                  className="cancel-filter"
                  onClick={resetFormFilter}
                >
                  Reset
                </Button>
                <Button
                  color="primary"
                  size="large"
                  variant="contained"
                  className="order-filter-button"
                  type="submit"
                  style={{
                    cursor: "pointer",
                  }}
                >
                  Filter
                </Button>
              </Stack>
            </div>
          </Box>
        </form>
      </Drawer>
    </Box>
  );
};

export default ManagePermissionList;
