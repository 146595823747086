import axios from "axios"
import { ApiURL, snackBar } from "../common"



export const getBusinessCategory = (rowsPerPage, page, category_name) => async (dispatch) => {
    try {
        dispatch({
            type: "LOADING_GET_BUSINESS_CATEGORY",
            payload: true
        })
        await axios.get(`${ApiURL}business-category/get-all-category?limit=${rowsPerPage}&page=${page}&search=${category_name}`).then((res) => {
            if (res.data.status && res.data.data.categoryData.length > 0) {
                dispatch({
                    type: "GET_BUSINESS_CATEGORY",
                    payload: res.data.data,
                })
                dispatch({
                    type: "LOADING_GET_BUSINESS_CATEGORY",
                    payload: false
                })
            } else {
                dispatch({
                    type: "LOADING_GET_BUSINESS_CATEGORY",
                    payload: false
                })
                dispatch({
                    type: "FAIL_GET_BUSINESS_CATEGORY",
                    payload: res.data.data,
                })
                snackBar("Data Not Found", false)
            }
        })

    } catch (error) {
        dispatch({
            type: "FAIL_GET_BUSINESS_CATEGORY",
            payload: error,
        })
    }
}

export const createBusinessCategory = (data, handleClose) => async (dispatch) => {
    try {
        dispatch({
            type: "LOADING_CREATE_BUSINESS_CATEGORY",
            payload: true
        })
        await axios.post(`${ApiURL}business-category/create-category`, data).then((res) => {
            if (res?.data?.status) {
                dispatch({
                    type: "CREATE_BUSINESS_CATEGORY",
                    payload: res?.data?.data
                })
                dispatch({
                    type: "LOADING_CREATE_BUSINESS_CATEGORY",
                    payload: false
                })
                snackBar(res?.data?.message, res?.data?.status)
                handleClose()
                return res;
            } else {
                dispatch({
                    type: "LOADING_CREATE_BUSINESS_CATEGORY",
                    payload: false
                })
                snackBar(res?.data?.message, res?.data?.status)
            }
        })

    } catch (error) {
        dispatch({
            type: "FAIL_CREATE_BUSINESS_CATEGORY",
            payload: error
        })
        snackBar("Something went wrong", false)
    }
}


export const updateBusinessCategory = (id, data, handleClose) => async (dispatch) => {
    try {
        dispatch({
            type: "LOADING_UPDATE_BUSINESS_CATEGORY",
            payload: true
        })

        await axios.put(`${ApiURL}business-category/update/${id}`, data).then((res) => {
            if (res.data.status && res.data.data) {
                dispatch({
                    type: "LOADING_UPDATE_BUSINESS_CATEGORY",
                    payload: false
                })
                snackBar(res.data.message, res.data.status)
                handleClose()
                return res
            } else {
                dispatch({
                    type: "LOADING_UPDATE_BUSINESS_CATEGORY",
                    payload: false
                })
                snackBar(res.data.message, res.data.status)
            }
        })

    } catch (error) {
        dispatch({
            type: "FAIL_UPDATE_BUSINESS_CATEGORY",
            payload: error
        })
        dispatch({
            type: "LOADING_UPDATE_BUSINESS_CATEGORY",
            payload: false
        })
        snackBar(error)

    }
}


export const deleteBusinessCategory = (id) => async (dispatch) => {
    try {
        dispatch({
            type: "LOADING_DELETE_BUSINESS_CATEGORY",
            payload: true,
        })
        await axios.delete(`${ApiURL}business-category/delete/${id}`).then((res) => {
            if (res.data.status) {
                dispatch({
                    type: "LOADING_DELETE_BUSINESS_CATEGORY",
                    payload: false,
                })
                snackBar(res.data.message, res.data.status)
                return res;
            } else {
                dispatch({
                    type: "LOADING_DELETE_BUSINESS_CATEGORY",
                    payload: false,
                })
                snackBar(res.data.message, res.data.status)
            }
        })

    } catch (error) {
        dispatch({
            type: "FAIL_DELETE_BUSINESS_CATEGORY",
            payload: error,
        })
        dispatch({
            type: "LOADING_DELETE_BUSINESS_CATEGORY",
            payload: false,
        })
    }
}