import { useState, useMemo, useEffect } from "react";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
  getDetails,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import "@reach/combobox/styles.css";

export default function Places({ setSelected, selected, isLoaded, formikProps }) {
  if (!isLoaded) return <div className="places-container-loading"><p>Loading...</p></div>;
  return (
    <>
      <div className={(formikProps.touched.selected &&
                            formikProps.touched.selected &&
                            Boolean(formikProps.errors.selected)) ? "places-container-error" : "places-container"}>
        <PlacesAutocomplete formikProps={formikProps} addressvalue={selected} setSelected={setSelected} />
      </div>
    </>
  );
}

const PlacesAutocomplete = ({ addressvalue,setSelected, formikProps }) => {
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();

  const handleSelect = async (address) => {
    if (address.trim() === '') {
      // Address is empty, you may want to handle this case accordingly
      return;
    }
    setValue(address, false);
    clearSuggestions();

    const results = await getGeocode({ address });
   
    const { lat, lng } = await getLatLng(results[0]);
    setSelected({ lat, lng, results });
  };
  const getPlaceholderClassName = () => {
    return (formikProps.touched.selected && formikProps.errors.selected) ? 'combobox-input-error' : 'combobox-input';
  };
  useEffect(() => {
    if(addressvalue){
      setValue(addressvalue,false);
    }
  }, [])
  
  return (
    <>
    <Combobox onSelect={handleSelect}>
      <ComboboxInput
        value={value}
        onChange={(e) => {
          setValue(e.target.value)
          if(!e?.target?.value){
            setSelected(undefined);
          }
        }}
        disabled={!ready}
        className={getPlaceholderClassName()}
        placeholder="Search an address"
      />
      <ComboboxPopover>
        <ComboboxList>
          {status === "OK" &&
            data.map(({ place_id, description }) => (
              <ComboboxOption key={place_id} value={description} />
            ))}
        </ComboboxList>
      </ComboboxPopover>
    </Combobox>
    </>
  );
};
