import React, { useRef, useState, useEffect } from "react";
import CommonSlide from "./CommonSlide";
import Slider from "react-slick";
import { GetAllEvent } from "../../../redux/eventAction";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import SliderImage from "../../slider/SliderImage";

const HomeOurDirectory = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const sliderRef = useRef(null);

  const [allGetEvent, setAllGetEvent] = useState([]);

  const getEvent = async () => {
    const res = await GetAllEvent();
    if (res) {
      setAllGetEvent(res.data?.eventData);
    }
  };

  useEffect(() => {
    getEvent();
  }, []);

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0,
  });

  const cardVariants = {
    initial: { opacity: 0, y: 100 },
    animate: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.8, ease: "easeOut" },
    },
  };

  const containerVariants = {
    initial: { opacity: 0 },
    animate: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.5,
        ease: "easeInOut",
      },
    },
  };

  const settings = {
    dots: true,
    infinite: false,
    arrows: false,
    // className: "directory-slide-show",
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
    afterChange: (index) => {
      setCurrentIndex(index);
    },
  };

  const goToNextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const goToPrevSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  return (
    <>
      {allGetEvent?.length > 0 ? (
        <section id="section-03" className="pb-8 our-directory">
          <div className="container">
            <div className="d-flex align-items-center mb-7 flex-wrap flex-sm-nowrap">
              <h2 className="mb-3 mb-sm-0">
                <span className="font-weight-semibold">Events</span>
              </h2>
              <Link
                to="/events"
                className="link-hover-dark-primary ml-0 ml-sm-auto w-100 w-sm-auto"
              >
                <span className="font-size-md d-inline-block mr-1">
                  View All
                </span>
                <i className="fal fa-chevron-right" />
              </Link>
            </div>

            <motion.div
              ref={ref}
              variants={containerVariants}
              initial="initial"
              animate={inView ? "animate" : "initial"}
              className="container"
            >
              <Slider ref={sliderRef} {...settings}>
                {allGetEvent &&
                  allGetEvent.map((item) => {
                    return <CommonSlide variants={cardVariants} item={item} />;
                  })}
              </Slider>
            </motion.div>
            {/* <div className="d-flex align-items-center pb-1">
              <div className="ml-auto d-flex slick-custom-nav pl-5">
                <div
                  className={`arrow ${currentIndex == 0 ? "disabled" : ""}`}
                  id="previous"
                >
                  <i onClick={goToPrevSlide} className="fa fa-chevron-left" />
                </div>
                <div
                  className={`arrow ${currentIndex == allGetEvent?.length - 3 ? "disabled" : ""
                    }`}
                  id="next"
                >
                  <i onClick={goToNextSlide} className="fa fa-chevron-right" />
                </div>
              </div>
            </div> */}
          </div>
        </section>
      ) : (
        <SliderImage />
      )}
    </>
  );
};

const directoryMenu = [
  {
    menu_name: "all",
  },
  {
    menu_name: "featured",
  },
  {
    menu_name: "best rate",
  },
  {
    menu_name: "most view",
  },
  {
    menu_name: "popular",
  },
  {
    menu_name: "ad directory",
  },
];

export default HomeOurDirectory;
