import React from "react";
import {
  TextField,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Box,
  Autocomplete,
} from "@mui/material";
import { CloseIcon } from "../../assets/icons/Icons";
import { makeStyles } from "@material-ui/core/styles";
import MyAutocomplete from "../../common/myAutoComplete";

const useStyles = makeStyles((theme) => ({
  customCircularProgress: {
    width: '25px !important',
    height: '25px !important',
    color: '#fff !important',
  },
}));

const styles = {
  root: {
    borderBottom: '1px solid black',
  },
  icon: {
    color: 'black',
    fill: 'black',
  },
  };

const customStyles = {
  option: {
    color: 'red', // Set the text color to red
  },
};

const FormDialog = ({
  dialogTitle,
  dialogText,
  fields,
  submitText,
  open,
  handleClose,
  handleSubmit,
  onChange,
  loading,
  fullWidth = false
}) => {
  const classes = styles;
  const useClasses = useStyles();
  return (
    <Dialog open={open}
      fullWidth={fullWidth}
      onClose={false}
      disableBackdropClick={true} // Prevent closing when clicking outside
      disableEscapeKeyDown={true}>
      <div className="mui-dialog-box-popup" style={{ backgroundColor: '#fff', color: '#000' }}>
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h5" sx={{ color: '#000 !important' }}>{dialogTitle}</Typography>

          <IconButton >
            <CloseIcon onClick={handleClose} />
          </IconButton>
        </DialogTitle>

        <DialogContent sx={{ minWidth: '400px' }}>
          <DialogContentText>{dialogText}</DialogContentText>
          {fields.map((field, index) => (
            <div key={index}>
              
              {field.type === "select" ? (
                <>
                  <FormControl fullWidth sx={{ color: '#000', marginTop: "10px" }}>
                    <InputLabel labelId='test-select-label' sx={{ fontSize: '0.9rem' }}>{field.label}</InputLabel>
                    <Select
                      inputProps={{
                        classes: {
                          root: classes.border,
                          icon: classes.icon,
                        },
                      }}
                      labelId='test-select-label'
                      value={field.value}
                      sx={{ input: { color: "#000" }, svg: { color: "#000" }, color: '#000', backgroundColor: '#fff' }}
                      name={field.name}
                      variant="outlined"
                      onChange={(e) => onChange(e, field.name)}
                    // error={Boolean(field.errors)}
                    >
                      {field?.options?.map((option, optionIndex) => (
                        <MenuItem key={optionIndex} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {field.errors && (
                      <Typography variant="caption" color="error" sx={{ fontSize: '12px' }}>
                        <div className="err-msg-font">{field.errors}</div>
                      </Typography>
                    )}
                  </FormControl>
                </>
              )
                :
                field.type === "autocomplete" ? (
                  <>
                    {field.id === 'country' ?
                      <FormControl fullWidth sx={{ color: '#000 !important', marginTop: '1rem' }}>
                        <Autocomplete
                          inputProps={{
                            classes: {
                              root: classes.border,
                              icon: classes.icon,
                            },
                          }}
                          options={field?.autoCompleteData || []}
                          id={field?.label}
                          onChange={(event, value) => onChange(event = { target: { name: field?.name, value: value } }, field?.name)}
                          value={field?.value || null}
                          sx={{
                            // "& .MuiAutocomplete-popupIndicator": {
                            //   color: "#000 !important",
                            // },
                            // "& .MuiInputLabel-root": {
                            //   color: "#000 !important",
                            // },
                            "&.css-1jr8uhn-MuiAutocomplete-root:hover .MuiAutocomplete-clearIndicator": {
                              color: "#000 !important",
                            },
                          }}
                          autoHighlight
                          getOptionLabel={(option) => option?.country_name}
                          renderOption={(props, option) => (
                            <Box component="li"
                              // sx={{ '& > img': { mr: 2, flexShrink: 0 }, color: '#000', backgroundColor: '#fff' }}
                              {...props}>

                              {/* <img
                                loading="lazy"
                                width="20"
                                src={`https://flagcdn.com/w20/${option?.iso2?.toLowerCase()}.png`}
                                srcSet={`https://flagcdn.com/w40/${option?.code?.toLowerCase()}.png 2x`}
                                alt=""
                              /> */}
                              {option?.country_name}
                              {/* ({option?.iso2}) +{option?.phone_code} */}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              sx={{ color: '#000' }}
                              {...params}
                              label={field.label}
                              inputProps={{
                                ...params.inputProps,
                                // autoComplete: 'new-password',
                                style: { color: '#000' },
                              }}
                              variant="outlined"
                              error={Boolean(field.errors)}
                            />
                          )}
                        />
                        {field.errors && (
                          <Typography variant="caption" color="error" sx={{ fontSize: '12px' }}>
                            <div className="err-msg-font">{field.errors}</div>
                          </Typography>
                        )}
                      </FormControl>
                      :
                      <MyAutocomplete
                        field={field}
                        label={field.label}
                        labelAccess={field.labelAccess}
                        value={field?.value}
                        autoCompleteData={field?.autoCompleteData}
                        error={field.errors}
                        onChange={(event, value) =>
                          onChange(event = { target: { name: field?.name, value: value } }, field?.name)
                        }
                      />
                    }
                  </>
                ) : (
                  <TextField
                    sx={{ input: { color: "#000" } }}
                    autoFocus={index === 0}
                    multiline={field.type != "file"}
                    rows={field.type != "file" && (field.rows || 1)}
                    margin="dense"
                    id={field.id}
                    label={field.label}
                    type={field.type || "text"}
                    fullWidth
                    variant={field.type == "file" ? "outlined" : "outlined"}
                    value={field.value}
                    name={field.name}
                    onChange={(e) => onChange(e, field.name)}
                    error={Boolean(field.errors)}
                    helperText={<div style={{ marginLeft: '-12px', fontSize: '12px' }}>{field.errors || field.helperText}</div>}
                    inputProps={{ multiple:field.multiple ,maxLength: field.maxLength || undefined }}
                  />
                )}
                
            </div>
          ))}
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", display: "flex" }}>
          <Button
            onClick={handleSubmit}
            // disabled={!isAnyFieldNotEmpty}
            sx={{
              fontSize: "14px",
              fontWeight: "600",
              border: "0",
              // color: isAnyFieldNotEmpty ? "#fff" : "#fff !important",
              ":hover": { bgcolor: "#1976d2", border: "0" },
              backgroundColor: "#153e80",
              marginLeft: {
                xs: "0px",
                sm: "15px",
                md: "15px",
                lg: "15px",
              },
              color: '#fff',
              textTransform: "none",
              minWidth: '100px'
              // opacity: isAnyFieldNotEmpty ? 1 : 0.5,
              // pointerEvents: isAnyFieldNotEmpty ? "auto" : "none",
            }}
          >
            {loading ? <CircularProgress className={useClasses.customCircularProgress} /> : submitText}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default FormDialog;
