import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Pagination,
  Typography,
  Drawer,
  Stack,
  Divider,
  Button,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import PageHeader from "../../components/pageHeader/PageHeader";
import EnhancedTable from "../../components/table/EnhancedTable ";
import { DeleteIcon, EditIcon, CloseIcon } from "../../assets/icons/Icons";
import "../../components/table/table.css";
import { Form, useForm } from "../../common/useForms";
import FormDialog from "../../components/dialog/dialog";
import { makeStyles } from "@material-ui/core/styles";
import Input from "../../common/Input";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import useUserRolePermissions from "../../common/UserPermissions";
import {
  CreateFAQ,
  DeleteFAQ,
  GetAllFAQ,
  UpdateFAQ,
} from "../../redux/action/FaqCategoryAction";
import Loader from "../../components/loader/Loader";
import DeleteDialog from "../../components/dialog/deletedialog";

const useStyles = makeStyles((theme) => ({
  filterMain: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "#fff",
  },
  mainFilterBox: {
    padding: "10px 20px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "200px",
    },

    [theme.breakpoints.up("md")]: {
      width: "300px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "240px !important",
    },
    [theme.breakpoints.up("xs")]: {
      width: "100%",
    },
  },

  stackFilter: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  typographyFilter: {
    fontWeight: "bold !important",
    color: "#000 !important",
    fontSize: "30px !important",
  },
  buttonFilter: {
    flexDirection: "row !important",
    marginTop: "8px",
    gap: "8px",
  },
}));

const columns = [
  {
    id: "category_name",
    numeric: false,
    disablePadding: true,
    label: "Category Name",
  },

  //   !!getPermissions(permissions.ClientReview, "is_edit") ||
  //   !!getPermissions(permissions.ClientReview, "is_delete")
  { id: "action", numeric: true, disablePadding: false, label: "Action" },
  // : null,
].filter((el) => el !== null);

const initialValues = {
  category_name: "",
};

const RecordPerPage = [
  { id: 5, title: "5" },
  { id: 10, title: "10" },
];

const AdminFAQsCategory = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState(false);
  const [filterData, setFilterData] = useState(true);
  const [selectedClient, setSelectedBanner] = useState(null);

  const [DeleteId, SetDeleteId] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [popupName, setPopupName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [editImageValue, setEditImageValue] = useState(null);
  const role = useUserRolePermissions();
  const faqData = useSelector((state) => state?.FAQCategoryReducer);
  const { loading, faqcategory, createLoading, updateLoading, deleteLoading } =
    useSelector((state) => state?.FAQCategoryReducer);

  useEffect(() => {
    dispatch(GetAllFAQ(page, rowsPerPage));
  }, [page, dispatch]);

  const handleChangeRow = (event) => {
    const rowPerPage = event.target.value;
    setRowsPerPage(event.target.value);

    dispatch(GetAllFAQ(page, rowPerPage));
  };

  // const { banner } =
  //     useSelector((state) => state.BannerReducer);

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const tableTitle = "FAQ's CategoryList";

  useEffect(() => {
    if (!open) {
      dispatch(GetAllFAQ(page, rowsPerPage));
    }
  }, [page, open, dispatch]);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleClickOpen = () => {
    setPopupName("Create FAQ's category");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    resetForm();
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values,
    setFieldValues,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  } = useForm(initialValues, true, validate);

  const handleEditClick = async (data) => {
    setSelectedBanner(data);
    setErrors({});
    setPopupName("Edit FAQ's category");

    setValues({
      ...values,
      category_name: data?.category_name || "",
    });
    setErrors({});
    setOpen(true);
  };

  const handleClickOpenDelete = (data) => {
    setOpenDelete(true);
    SetDeleteId(data._id);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleSingleDelete = async () => {
    await dispatch(DeleteFAQ(DeleteId, handleCloseDelete)).then((res) => {
      if (res && res.data.status === true) {
        dispatch(GetAllFAQ(page, rowsPerPage));
      }
    });
  };

  const fields = [
    {
      id: "category_name",
      label: "Category Name",
      type: "text",
      value: values.category_name,
      name: "category_name",
      errors: errors.category_name,
    },
  ];

  const rows =
    faqcategory?.categoryList && Array.isArray(faqcategory?.categoryList)
      ? faqcategory?.categoryList?.map((data) => {
          const selected = data === selectedClient;
          return {
            _id: data._id,
            category_name: data.category_name,

            // status: data.is_active ? "approved" : 'pending',
            action: (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {/* {!!getPermissions(permissions.ClientReview, "is_edit") && ( */}
                <IconButton
                  onClick={() => handleEditClick(data)}
                  sx={{ color: "black" }}
                >
                  <EditIcon />
                </IconButton>
                {/* )} */}
                {/* {!!getPermissions(permissions.ClientReview, "is_delete") && ( */}
                <IconButton
                  onClick={() => handleClickOpenDelete(data)}
                  sx={{ color: "black" }}
                >
                  <DeleteIcon />
                </IconButton>
                {/* )} */}
              </div>
            ),
            selected: selected,
          };
        })
      : [];

  const handleSubmit = async (e) => {
    e.preventDefault();

    const body = {
      category_name: values.category_name,
    };

    if (validate()) {
      if (popupName === "Edit FAQ's category") {
        dispatch(UpdateFAQ(selectedClient._id, body, handleClose));
        handleClose();
      } else {
        dispatch(CreateFAQ(body, handleClose));
        handleClose();
      }
    }
  };

  return (
    <Box>
      <PageHeader
        title="FAQ's Category"
        onAddClick={handleClickOpen}
        isShowAdd={true}
      />

      {loading ? (
        <Loader />
      ) : (
        // <Box sx={{ display: "flex", justifyContent: "center" }}>
        //   <CircularProgress />
        // </Box>
        <EnhancedTable
          key={rows._id}
          columns={columns}
          rows={rows}
          order={order}
          orderBy={orderBy}
          selected={selected}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          title={tableTitle}
          tableList={faqcategory?.categoryList}
        />
      )}
      <FormDialog
        open={open}
        dialogTitle={popupName}
        fields={
          popupName === "Edit FAQ's category"
            ? fields.filter((el, index) => ["category_name"].includes(el.name))
            : fields.filter((el) => ["category_name"].includes(el.name))
        }
        loading={
          popupName === "Edit FAQ's category" ? updateLoading : createLoading
        }
        submitText="Submit"
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        onChange={handleInputChange}

        // isAnyFieldNotEmpty={isAnyFieldNotEmpty}
      />

      <DeleteDialog
        open={openDelete}
        loading={deleteLoading}
        dialogTitle="Are you sure to want to delete this record?"
        handleClose={handleCloseDelete}
        handleDelete={() => handleSingleDelete(DeleteId)}
      />

      <Box
        sx={{
          display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
          justifyContent: "space-between",
          alignItems: "center",
          marginLeft: { xs: "10px", sm: "20px", md: "20px", lg: "20px" },
          marginRight: { xs: "10px", sm: "15px", md: "15px", lg: "15px" },
          mt: 2,
          mb: 2,
        }}
      >
        <Box
          sx={{
            fontSize: "14px",
            marginBottom: { xs: "10px", sm: "0", md: "0", lg: "0" },
            gap: 2,
            width: { sm: "100%", md: "50%" },
          }}
          display="flex"
          alignItems="center"
        >
          <Box>
            {" "}
            Showing {faqcategory?.currentPage} of {faqcategory?.totalPages}{" "}
            Results
          </Box>
          <Box display="flex" sx={{ gap: 1 }} alignItems="center">
            <Typography>Records per page</Typography>

            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                id="limites"
                name="limites"
                value={rowsPerPage}
                onChange={handleChangeRow}
                displayEmpty
                sx={{ maxWidth: "65px", maxHeight: "2.2rem" }}
                inputProps={{ "aria-label": "Without label" }}
              >
                {RecordPerPage?.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name || item.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Pagination
          count={faqcategory?.totalPages}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
          sx={{
            "& .MuiButtonBase-root ": {
              color: "#000 !important",
            },
            "& .Mui-selected ": {
              background: "#4b4b4e70 !important",
            },
            "& .Mui-disabled ": {
              background: "#6c696966 !important",
              color: "#000000 !important",
              opacity: 1,
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default AdminFAQsCategory;
