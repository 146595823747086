import React, { useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Step1 from "./step1";
import { useFormik } from "formik";
import UserHeader from "../header/Header";
import * as Yup from "yup";
import Step2 from "./step2";
import Step3 from "./step3";
import axios from "../../../redux/ApiConfig";
import { ApiURL, snackBar } from "../../../redux/common";
import dayjs from "dayjs";
// import Step3 from "./step4";
// import UserInformation from "./UserInformation";
// import AddressDetails from "./AddressDetails";
// import UserFinalData from "./UserFinalData";
var emailValidation =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const validationSchemaForm = Yup.object().shape({
  first_name: Yup.string()
    .required("Please enter first name")
    .matches(/^\s*\S.*/, "Please enter first name"),
  last_name: Yup.string()
    .required("Please enter last name")
    .matches(/^\s*\S.*/, "Please enter last name"),
  email: Yup.string()
    .required("Please enter email")
    .matches(emailValidation, "Please enter valid email"),
  mobile_number: Yup.string().required("Please enter mobile number"),
  // description: Yup.string().required("Please enter Description"),
  gender: Yup.string().required("Please select gender"),
  birth_date: Yup.object().required("Please select birth date"),
  address: Yup.string()
    .required("Please enter address")
    .matches(/^\s*\S.*/, "Please enter address"),
  country: Yup.object().required("Please select country"),
  state: Yup.object().required("Please select state"),
  city: Yup.object().required("Please select city"),
  pincode: Yup.object().required("Please select pincode"),
});

const CheckEligibility = () => {
  const stepsData = ["USER DETAILS", "BUSINESS DETAILS", "CONTACT INFORMATION"];
  // const stepsData = ["USER DETAILS", "BUSINESS DETAILS", "DOCUMENT SUBMITION"];
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [value, setValue] = useState({});
  const [isRefferalVerified, setisRefferalVerified] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = async (values) => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setSkipped(newSkipped);

    const payload = { ...value, ...values };
    setValue(payload);
    if (activeStep == 2) {
      // submit api call will be here
      let body = {};
      body = {
        first_name: payload.first_name,
        last_name: payload.last_name,
        phone_code: payload.mobile_number.split(" ")[0]?.replace("+",""),
        mobile_number: payload.mobile_number.split(" ")[1]?.replace("-",""),
        birth_date: dayjs(payload.birth_date)?.format("YYYY-MM-DD"),
        gender: payload.gender,
        email: payload.email,
        address: payload.address,
        chapterId_refferalType: payload.refferel_type,
        refferal_code: payload.refferel_code,
        city_id: payload.city._id,
        state_id: payload.state._id,
        postalcode_id: payload.pincode._id,
        country_id: payload.country._id,
        password: payload.password,
        confirm_password: payload.confirm_password,
        company_name: payload.company_name,
        pan_number: payload.pan_number,
        gst_number: payload.gst_number,
      };

      try {
        const res = await axios.post(
          `${ApiURL}user/register-member`,
          {
            ...body,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if(res?.data?.status){
          setValue({});
          setActiveStep(0);
          setisRefferalVerified(false);
          setIsOtpVerified(false);
        }
        snackBar(res?.data?.message, res?.data?.status);
      } catch (error) {
        snackBar(error?.response?.data?.message, error?.response?.data?.status);
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = (values) => {
    const payload = { ...value, ...values };
    setValue(payload);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // const handleSkip = () => {
  //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
  //     setSkipped((prevSkipped) => {
  //         const newSkipped = new Set(prevSkipped.values());
  //         newSkipped.add(activeStep);
  //         return newSkipped;
  //     });
  // };

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      birth_date: "",
      address: "",
      city_id: "",
      state_id: "",
      postalcode_id: "",
      phone_code: "",
      mobile_number: "",
      email: "",
      country_id: "",
      gender: "",
    },
    validationSchema: validationSchemaForm,
    onSubmit: (values) => {
      // You can perform form submission here
      handleNext();
    },
  });

  return (
    <>
      {/* <UserHeader /> */}
      <div
        className="container"
        style={{
          padding: "40px",
          backgroundColor: "#FFF",
          minHeight: "90vh",
        }}
      >
        <Box
          sx={{
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          }}
        >
          <Stepper
            sx={{ padding: "20px" }}
            alternativeLabel
            activeStep={activeStep}
          >
            {stepsData.map((label, index) => {
              const stepProps = {};
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel
                    sx={{ color: "blue !important", fontWeight: "bold" }}
                  >
                    {label}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>

          {activeStep === 0 && (
            <Step1
              handleBack={handleBack}
              handleNext={handleNext}
              activeStep={activeStep}
              value={value}
              isRefferalVerified={isRefferalVerified}
              setisRefferalVerified={setisRefferalVerified}
              isOtpVerified={isOtpVerified}
              setIsOtpVerified={setIsOtpVerified}
            />
          )}
          {activeStep === 1 && (
            <div>
              <Step2
                handleBack={handleBack}
                handleNext={handleNext}
                activeStep={activeStep}
                value={value}
              />
            </div>
          )}
          {activeStep === 2 && (
            <Step3
              handleBack={handleBack}
              handleNext={handleNext}
              activeStep={activeStep}
              value={value}
            />
          )}
        </Box>
      </div>
    </>
  );
};

export default CheckEligibility;
