import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import Input from "../../common/Input";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import SelectBox from "../../common/SelectBox";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  addPayment,
  getPaymentRequestId,
} from "../../redux/action/PartnerRequestAction";

const rangeOptions = [];

const PaymentType = [
  { id: 1, name: "Cash" },
  { id: 2, name: "Cheque" },
  { id: 3, name: "UPI" },
];

const PaymentStatus = [
  { id: 1, name: "Done" },
  { id: 2, name: "Pending" },
];

const PartnerReqDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [payment, setPayment] = useState("");
  const [paymentStatus, setpaymentStatus] = useState("");
  const [paymentData, setPaymentData] = useState("");

  const handleChangeStatus = (event) => {
    setpaymentStatus(event.target.value);
  };

  const handleChange = (event) => {
    setPayment(event.target.value);
  };

  const {
    _id,
    name,
    email,
    mobile_number,
    postalcode,
    country_name,
    state_name,
    city_name,
    status,
  } = location?.state?.partnerData;

  const PartnerReqSchema = Yup.object().shape({
    amount: Yup.string().required("Please the enter amount"),
    cheque_number: Yup.string()
      .test(
        "is-cheque",
        "Cheque number must be exactly 6 digits",
        function (value) {
          if (payment === "Cheque") {
            return /^[0-9]{6}$/.test(value);
          }
          return true;
        }
      )
      .test("is-required", "Please enter the cheque number", function (value) {
        if (payment === "Cheque") {
          return value && value.trim().length > 0;
        }
        return true;
      }),
    cheque_date: Yup.string().test(
      "is-required",
      "Please enter the cheque date",
      function (value) {
        if (payment === "Cheque") {
          return value && value.trim().length > 0;
        }
        return true;
      }
    ),

    account_holder_bank: Yup.string().test(
      "is-required",
      "Please enter account the holder bank",
      function (value) {
        if (payment === "Cheque") {
          return value && value.trim().length > 0;
        }
        return true;
      }
    ),
    account_holder_name: Yup.string().test(
      "is-required",
      "Please enter account the holder name",
      function (value) {
        if (payment === "Cheque") {
          return value && value.trim().length > 0;
        }
        return true;
      }
    ),

    bank_branch: Yup.string().test(
      "is-required",
      "Please enter the bank branch",
      function (value) {
        if (payment === "Cheque") {
          return value && value.trim().length > 0;
        }
        return true;
      }
    ),

    upi: Yup.string()
      .matches(/^\w+@\w+$/, "Invalid UPI ID format")
      .test("is-required", "Please enter the UPI ID", function (value) {
        if (payment === "UPI") {
          return value && value.trim().length > 0;
        }
        return true;
      }),
  });

  const formik = useFormik({
    initialValues: {
      name: name,
      email: email,
      phone_no: mobile_number,
      postalcode: postalcode,
      country_name: country_name,
      state_name: state_name,
      city_name: city_name,
      status: status,
      amount: "",
      payment_by: "",
      upi: "",
      cheque_date: "",
      cheque_number: "",
      account_holder_bank: "",
      account_holder_name: "",
      bank_branch: "",
    },
    validationSchema: PartnerReqSchema,
    onSubmit: async (values) => {
      let body = {
        payment_by: payment,
        status: paymentStatus,
        amount: values.amount,
        request_id: _id,
        currency: "INR",
      };

      if (payment === "Cheque") {
        body = {
          ...body,
          cheque_date: values.cheque_date,
          cheque_number: values.cheque_number,
          account_holder_bank: values.account_holder_bank,
          account_holder_name: values.account_holder_name,
          bank_branch: values.account_holder_name,
        };
      } else if (payment === "UPI") {
        body = {
          ...body,
          upi: values.upi,
        };
      }

      try {
        const res = await addPayment(body);
        if (res && res.status === true) {
          navigate("/admin-partner-request");
        }
      } catch (error) {
        console.error("Error submitting the form:", error);
      }
    },
  });

  const paymentRequestIdGet = async (id) => {
    try {
      const response = await getPaymentRequestId(id);
      setPaymentData(response.data);
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    paymentRequestIdGet(_id);
  }, [_id]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          margin: "3rem",
          height: "100vh",
        }}
      >
        <ArrowCircleLeftOutlinedIcon
          fontSize="large"
          sx={{cursor: 'pointer'}}
          onClick={() => navigate("/admin-partner-request")}
        />
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid item md={8} xs={12} sm={12}>
            <Grid item md={12}>
              <Box component={Paper} padding={{ xs: 2, sm: 3 }} elevation={5}>
                <Typography
                  textAlign="center"
                  fontWeight={900}
                  fontSize={21}
                  marginBottom={2}
                  color="black"
                >
                  Partner Request Details
                </Typography>
                <form onSubmit={formik.handleSubmit}>
                  <Typography variant="h6" sx={{ marginTop: "0.5rem" }}>
                    Name:
                  </Typography>
                  <Input
                    id="name"
                    name="name"
                    disable={true}
                    type="text"
                    label="Name"
                    value={formik.values.name}
                  />
                  <Typography variant="h6" sx={{ marginTop: "0.5rem" }}>
                    Email:
                  </Typography>
                  <Input
                    id="email"
                    name="email"
                    disable={true}
                    type="text"
                    label="Email"
                    value={formik.values.email}
                  />

                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12} sm={12}>
                      <Typography variant="h6" sx={{ marginTop: "0.5rem" }}>
                        Phone Number:
                      </Typography>
                      <Input
                        id="phone_no"
                        name="phone_no"
                        type="text"
                        label="Phone Number"
                        disable={true}
                        value={formik.values.phone_no}
                      />
                    </Grid>

                    <Grid item md={6} xs={12} sm={12}>
                      <Typography variant="h6" sx={{ marginTop: "0.5rem" }}>
                        PostalCode:
                      </Typography>
                      <Input
                        id="postalcode"
                        name="postalcode"
                        type="text"
                        label="PostalCode"
                        disable={true}
                        value={formik.values.postalcode}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12} sm={12}>
                      <Typography variant="h6" sx={{ marginTop: "0.5rem" }}>
                        Country Name:
                      </Typography>
                      <Input
                        id="country_name"
                        name="country_name"
                        type="text"
                        label="Country Name"
                        disable={true}
                        value={formik.values.country_name}
                      />
                    </Grid>

                    <Grid item md={6} xs={12} sm={12}>
                      <Typography variant="h6" sx={{ marginTop: "0.5rem" }}>
                        State Name:
                      </Typography>
                      <Input
                        id="state_name"
                        name="state_name"
                        disable={true}
                        type="text"
                        label="State Name"
                        value={formik.values.state_name}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12} sm={12}>
                      <Typography variant="h6" sx={{ marginTop: "0.5rem" }}>
                        City Name:
                      </Typography>
                      <Input
                        id="city_name"
                        name="city_name"
                        type="text"
                        disable={true}
                        label="City Name"
                        value={formik.values.city_name}
                      />
                    </Grid>

                    <Grid item md={6} xs={12} sm={12}>
                      <Typography variant="h6" sx={{ marginTop: "0.5rem" }}>
                        Status:
                      </Typography>
                      <Input
                        id="status"
                        name="status"
                        type="text"
                        label="Status"
                        disable={true}
                        value={
                          formik.values.status &&
                          formik.values.status[0] &&
                          formik.values.status[0].toUpperCase() +
                            formik.values.status.slice(1)
                        }
                      />
                    </Grid>
                  </Grid>

                  <Typography variant="h5" sx={{ marginTop: "0.5rem" }}>
                    Payment Details:
                  </Typography>

                  <Grid container spacing={2}>
                    <Grid
                      item
                      md={12}
                      xs={12}
                      sm={12}
                      sx={{ marginTop: "-8px" }}
                    >
                      <div style={{ marginTop: "20px" }}>
                        <Typography variant="h6" sx={{ marginTop: "0.5rem" }}>
                          Payment Type:
                        </Typography>

                        <Select
                          id="payment_by"
                          name="payment_by"
                          value={paymentData ? paymentData.payment_by : payment}
                          disabled={paymentData ? true : false}
                          onChange={handleChange}
                          displayEmpty
                          sx={{ width: "100%" }}
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          {PaymentType?.map((item) => {
                            return (
                              <MenuItem key={item.id} value={item.name}>
                                {item.name || item.title}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid
                      item
                      md={12}
                      xs={12}
                      sm={12}
                      sx={{ marginTop: "-8px" }}
                    >
                      <div style={{ marginTop: "20px" }}>
                        <Typography variant="h6" sx={{ marginTop: "0.5rem" }}>
                          Payment Status:
                        </Typography>

                        <Select
                          id="status"
                          name="status"
                          value={
                            paymentData ? paymentData.status : paymentStatus
                          }
                          onChange={handleChangeStatus}
                          displayEmpty
                          sx={{ width: "100%" }}
                          disabled={paymentData ? true : false}
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          {PaymentStatus?.map((item) => {
                            return (
                              <MenuItem key={item.id} value={item.name}>
                                {item.name || item.title}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </div>
                    </Grid>
                  </Grid>

                  {payment === "Cash" ||
                  payment === "UPI" ||
                  payment === "Cheque" ||
                  paymentData ? (
                    <Grid container spacing={2}>
                      <Grid item md={12} xs={12} sm={12}>
                        <Typography variant="h6" sx={{ marginTop: "0.5rem" }}>
                          Amount:
                        </Typography>
                        <Input
                          id="amount"
                          name="amount"
                          type="number"
                          label="Amount"
                          onChange={formik.handleChange}
                          value={
                            paymentData
                              ? paymentData.amount
                              : formik.values.amount
                          }
                          maxLength={10}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.amount &&
                            Boolean(formik.errors.amount)
                          }
                          helperText={
                            formik.touched.amount && formik.errors.amount ? (
                              <div className="err-msg err-msg-font">
                                {formik.errors.amount}
                              </div>
                            ) : null
                          }
                          disabled={paymentData ? true : false}
                          inputProps={{ readOnly: true, type: "number" }}
                        />
                      </Grid>
                    </Grid>
                  ) : null}

                  {payment === "UPI" || paymentData?.payment_by === "UPI" ? (
                    <Grid container spacing={2}>
                      <Grid item md={12} xs={12} sm={12}>
                        <Typography variant="h6" sx={{ marginTop: "0.5rem" }}>
                          UPI:
                        </Typography>
                        <Input
                          id="upi"
                          name="upi"
                          type="text"
                          label="UPI"
                          onChange={formik.handleChange}
                          value={
                            paymentData ? paymentData?.upi : formik.values.upi
                          }
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.upi && Boolean(formik.errors.upi)
                          }
                          helperText={
                            formik.touched.upi && formik.errors.upi ? (
                              <div className="err-msg err-msg-font">
                                {formik.errors.upi}
                              </div>
                            ) : null
                          }
                          disabled={paymentData ? true : false}
                        />
                      </Grid>
                    </Grid>
                  ) : null}

                  {payment === "Cheque" ||
                  paymentData?.payment_by === "Cheque" ? (
                    <>
                      <Grid container spacing={2}>
                        <Grid item md={6} xs={6} sm={6}>
                          <Typography variant="h6" sx={{ marginTop: "0.5rem" }}>
                            Cheque Number:
                          </Typography>
                          <Input
                            id="cheque_number"
                            name="cheque_number"
                            type="number"
                            label="Cheque Number"
                            onChange={formik.handleChange}
                            value={
                              paymentData
                                ? paymentData?.cheque_number
                                : formik.values.cheque_number
                            }
                            onBlur={formik.handleBlur}
                            disabled={paymentData ? true : false}
                            error={
                              formik.touched.cheque_number &&
                              Boolean(formik.errors.cheque_number)
                            }
                            helperText={
                              formik.touched.cheque_number &&
                              formik.errors.cheque_number ? (
                                <div className="err-msg err-msg-font">
                                  {formik.errors.cheque_number}
                                </div>
                              ) : null
                            }
                          />
                        </Grid>
                        <Grid item md={6} xs={6} sm={6}>
                          <Typography variant="h6" sx={{ marginTop: "0.5rem" }}>
                            Cheque Date:
                          </Typography>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer
                              components={["DatePicker", "DatePicker"]}
                            >
                              <DatePicker
                                id="cheque_date"
                                sx={{ width: "100%", borderColor: "black" }}
                                name="cheque_date"
                                fullWidth
                                label="Cheque Date"
                                value={formik.values.cheque_date}
                                inputFormat="dd/MM/yyyy"
                                onChange={(newValue) =>
                                  formik.setFieldValue("cheque_date", newValue)
                                }
                                disabled={paymentData ? true : false}
                                error={
                                  formik.touched.cheque_date &&
                                  Boolean(formik.errors.cheque_date)
                                }
                                helperText={
                                  formik.touched.cheque_date &&
                                  formik.errors.cheque_date ? (
                                    <div>{formik.errors.cheque_date}</div>
                                  ) : null
                                }
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </Grid>
                      </Grid>
                    </>
                  ) : null}

                  {payment === "Cheque" ||
                  paymentData?.payment_by === "Cheque" ? (
                    <Grid container spacing={2}>
                      <Grid item md={6} xs={6} sm={6}>
                        <Typography variant="h6" sx={{ marginTop: "0.5rem" }}>
                          Account Holder Name:
                        </Typography>
                        <Input
                          id="account_holder_name"
                          name="account_holder_name"
                          type="text"
                          label="Account Holder Name"
                          onChange={formik.handleChange}
                          value={
                            paymentData
                              ? paymentData?.account_holder_name
                              : formik.values.account_holder_name
                          }
                          disabled={paymentData ? true : false}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.account_holder_name &&
                            Boolean(formik.errors.account_holder_name)
                          }
                          helperText={
                            formik.touched.account_holder_name &&
                            formik.errors.account_holder_name ? (
                              <div className="err-msg err-msg-font">
                                {formik.errors.account_holder_name}
                              </div>
                            ) : null
                          }
                        />
                      </Grid>
                      <Grid item md={6} xs={6} sm={6}>
                        <Typography variant="h6" sx={{ marginTop: "0.5rem" }}>
                          Account Holder Bank:
                        </Typography>
                        <Input
                          id="account_holder_bank"
                          name="account_holder_bank"
                          type="text"
                          label="Account Holder bank"
                          onChange={formik.handleChange}
                          value={
                            paymentData
                              ? paymentData?.account_holder_bank
                              : formik.values.account_holder_bank
                          }
                          disabled={paymentData ? true : false}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.account_holder_bank &&
                            Boolean(formik.errors.account_holder_bank)
                          }
                          helperText={
                            formik.touched.account_holder_bank &&
                            formik.errors.account_holder_bank ? (
                              <div className="err-msg err-msg-font">
                                {formik.errors.account_holder_bank}
                              </div>
                            ) : null
                          }
                        />
                      </Grid>
                    </Grid>
                  ) : null}

                  {payment === "Cheque" ||
                  paymentData?.payment_by === "Cheque" ? (
                    <Grid container spacing={2}>
                      <Grid item md={12} xs={12} sm={12}>
                        <Typography variant="h6" sx={{ marginTop: "0.5rem" }}>
                          Bank Branch:
                        </Typography>
                        <Input
                          id="bank_branch"
                          name="bank_branch"
                          type="text"
                          label="Bank Branch"
                          onChange={formik.handleChange}
                          value={
                            paymentData
                              ? paymentData?.bank_branch
                              : formik.values.bank_branch
                          }
                          disabled={paymentData ? true : false}
                          maxLength={10}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.bank_branch &&
                            Boolean(formik.errors.bank_branch)
                          }
                          helperText={
                            formik.touched.bank_branch &&
                            formik.errors.bank_branch ? (
                              <div className="err-msg err-msg-font">
                                {formik.errors.bank_branch}
                              </div>
                            ) : null
                          }
                        />
                      </Grid>
                    </Grid>
                  ) : null}

                  {!paymentData ? (
                    <Button
                      sx={{ marginTop: 4 }}
                      type="submit"
                      variant="contained"
                    >
                      Submit
                    </Button>
                  ) : null}
                </form>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default PartnerReqDetails;
