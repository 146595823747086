import React, { useEffect, useState } from "react";
import Faq from "react-faq-component";
import { useDispatch, useSelector } from "react-redux";
import { getAllFAQs } from "../../../redux/action/FaqsAction";
import Loader from "../loader/Loader";

const styles = {
  bgColor: "white",
  titleTextColor: "black",
  rowTitleColor: "black",
  rowContentColor: "grey",
  arrowColor: "red",
};
const config = {
  animate: true,
  arrowIcon: "V",
  openOnload: 0,
  expandIcon: "+",
  collapseIcon: "-",
};

const Faqs = () => {
  const dispatch = useDispatch();
  const { getFaqLoading, FaqData } = useSelector((state) => state.FaqsReducer)

  const fetchFAQsData = async () => {
    await dispatch(getAllFAQs(""))
  }

  useEffect(() => {
    fetchFAQsData()
  }, [])


  return (
    <div>
      {getFaqLoading ?
        <Loader />
        :
        FaqData.map((item, i) => {
          return (
            <Faq
              key={i}
              data={{
                title: "",
                rows: [{
                  title: (<b>{item.category_name}</b>),
                  content: (
                    <ol start={1}>
                      {item.faqs.map((faq, j) => (
                        <li key={j} style={{ marginTop: "0.5em" }}>
                          <b>{faq.question}</b>
                          <ul>
                            <li style={{ marginLeft: "-27px" }}>{faq.answer}</li>
                          </ul>
                        </li>
                      ))}
                    </ol>
                  ),
                }]
              }} styles={styles} config={config} />
          )
        })
      }
    </div>
  );
};

export default Faqs;
