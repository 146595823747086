import React, { useEffect, useState } from "react";
import hotel5 from "../../assets/image/shop/hotel-5.jpg";
import hotel4 from "../../assets/image/shop/hotel-4.jpg";
import hotel2 from "../../assets/image/shop/hotel-2.jpg";
import Slider from "react-slick";
import { Link, useParams } from "react-router-dom";
import { api } from "../../config/api";
import NotFound from "../notFound/NotFound";
import Loader from "../../components/loader/Loader";
import moment from "moment/moment";
import CloseIcon from "@mui/icons-material/Close";
import styled from "styled-components";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import Login from "../auth/Login";
import { DisplayHTMLString } from "../../components/htmlToString/HtmlToString";
import PaymentGateway from "../../components/PaymentGateway";
import GuestDetailsModal from "../../components/guestDetailsModal/GuestDetailsModal";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { base_Url, snackBar } from "../../utils/common";
import RegistrationLoader from "../../components/loader/RegisterLoader";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: "2px",
  },
  "& .MuiDialogActions-root": {
    padding: "2px",
  },
}));

const EventDetails = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [error, setError] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [isGuestModalOpen, setIsGuestModalOpen] = useState(false);
  const [loginModalOpen, setLoginModalOpen] = useState(false);

  const handleGuestModalOpen = () => {
    setIsGuestModalOpen(true);
  };
  const handleGuestModalClose = () => {
    setIsGuestModalOpen(false);
  };

  const handleModalOpen = () => {
    setLoginModalOpen(true);
  };

  const handleModalClose = () => {
    setLoginModalOpen(false);
  };

  const [formData, setFormData] = useState({
    persons: "" || "1",
    guest: "" || "0",
    type: "" || "1",
    date: "" || "",
  });

  const {
    event_name,
    event_description,
    chapter_name,
    start_time,
    price,
    event_images,
    qr_code,
    venue_link,
    venue,
  } = data;

  const basePricePerPerson = price;

  const calculateTotalPrice = (data) => {
    const totalPersons = parseInt(data.persons, 10) + parseInt(data.guest, 10);
    return basePricePerPerson * totalPersons;
  };

  const [totalPrice, setTotalPrice] = useState(calculateTotalPrice(formData));
  const [guestDetails, setGuestDetails] = useState([]);
  const [eventRegistrationLoading, setEventRegistrationLoading] =
    useState(false);

  useEffect(() => {
    setTotalPrice(calculateTotalPrice(formData));
  }, [formData]);

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    if (value <= 100) {
      setFormData({ ...formData, [id]: value });
    }
  };

  const handleGuestBlur = (event) => {
    console.log(event, "eventvalue");
    const { id, value } = event.target;
    if (value <= 100) {
      if (id == "guest") {
        setGuestDetails(
          Array.from({ length: value }).fill({
            name: "",
            email: "",
            phone: "",
          })
        );
      }
    }
  };
  const token = localStorage.getItem("access_token");

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!token) {
      handleModalOpen();
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const { id } = useParams();

  const getEventById = () => {
    api
      .get(`event/event-by-id/${id}`)
      .then((res) => {
        if (res.status) {
          const data = res.data;
          if (Array.isArray(data) && data.length) {
            setData(res.data[0]);
          } else {
            setError(true);
          }
        } else {
          setError(true);
        }
      })
      .catch(() => setError(true))
      .finally(() => setLoading(false));
  };

  const handlePaymentSuccess = async (response) => {
    try {
      setEventRegistrationLoading(true);
      const payload = {
        event_id: id,
        razorpay_payment_id: response?.razorpay_payment_id,
        guest_users: guestDetails,
      };
      const res = await api.post(
        `${base_Url}event/event-registration`,
        payload
      );
      if (res?.status) {
        snackBar(res?.message, res?.status);
        setGuestDetails([]);
        setIsGuestModalOpen(false);
      } else {
        snackBar(res?.message, res?.status);
      }
    } catch {
      snackBar("Please try again!", false);
    } finally {
      setEventRegistrationLoading(false);
    }
  };

  useEffect(() => {
    getEventById();
  }, []);

  var settings = {
    dots: false,
    infinite: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    className: "hero-banner-arrow",
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          arrows: false,
          centerMode: false,
          centerPadding: "0%",
        },
      },
    ],
    centerMode: true,
    centerPadding: "20%",
  };

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <NotFound />;
  }
  console.log("rendered data");
  return (
    <div id="wrapper-content" className="wrapper-content pb-0 pt-0">
      {eventRegistrationLoading && <RegistrationLoader />}
      <Slider {...settings}>
        {event_images.map((link, index) => {
          // Check media type based on the link (assuming link includes file extensions)
          const fileType = link.substring(link.lastIndexOf(".") + 1);
          const isImage = fileType.match(/(jpg|jpeg|png|gif)/i);

          // Render image or video based on media type
          return (
            <div key={index}>
              <div
                className="box center"
                style={{ width: "100%", display: "inline-block" }}
              >
                {isImage ? (
                  <img
                    style={{ height: "568.188px", width: "100%" }}
                    src={link}
                    alt={`Image ${index}`}
                  />
                ) : (
                  <video
                    style={{ height: "568.188px", width: "100%" }}
                    autoPlay
                    loop
                    muted
                    playsInline
                  >
                    <source src={link} type={`video/${fileType}`} />
                    Your browser does not support the video tag.
                  </video>
                )}
              </div>
            </div>
          );
        })}
      </Slider>
      <div className="page-title bg-gray-06 pt-8 pb-9">
        <div className="container">
          <ul className="breadcrumb breadcrumb-style-03 mb-6">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/events">Events </Link>
            </li>
            <li className="breadcrumb-item">{event_name}</li>
          </ul>
          <div className="explore-details-top d-flex flex-wrap flex-lg-nowrap">
            <div className="store">
              <div className="d-flex flex-wrap mb-3">
                <h2 className="text-dark mr-3 mb-0">{event_name}</h2>
                <span className="check font-weight-semibold text-green my-auto">
                  <svg className="icon icon-check-circle">
                    <use xlinkHref="#icon-check-circle" />
                  </svg>
                  Verified
                </span>
              </div>
              <ul className="list-inline store-meta d-flex flex-wrap align-items-center">
                <li className="list-inline-item">
                  <span className="text-link">{chapter_name}</span>
                </li>
                <li className="list-inline-item separate" />
                <li className="list-inline-item">
                  <span className="mr-1">
                    <i className="fal fa-clock" />
                  </span>
                  <span>{moment(start_time).format("MMMM Do YYYY")}</span>
                </li>
              </ul>
              <li className="list-inline-item">
                <span className="mr-1">
                  <LocationOnIcon />
                </span>
                <span>{venue}</span>
              </li>
            </div>
            <div className="ml-0 ml-lg-auto mt-4 mt-lg-0 d-flex flex-wrap flex-sm-nowrap">
              <ul className="list-inline mb-0">
                <li className="list-inline-item">
                  <a
                    className="btn btn-white font-size-md mb-3 mb-sm-0 py-1 px-3 rounded-sm"
                    onClick={handleClickOpen}
                  >
                    <span className="d-inline-block mr-1">
                      <QrCodeScannerIcon />
                    </span>
                    QR Code
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="page-container">
          <div className="row">
            <div className="page-content col-xl-8 mb-8 mb-xl-0">
              <div className="explore-details-container">
                <div className="mb-9">
                  <h3 className="font-size-lg text-uppercase font-weight-semibold border-bottom pb-1 pt-2 mb-6">
                    description
                  </h3>
                  <div className="mb-7">
                    <p className="mb-6">
                      {DisplayHTMLString(event_description)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="sidebar col-xl-4">
              <div className="card p-4 widget border-0 rounded-0 reservation bg-gray-06 mb-6">
                <div className="card-title d-flex mb-0 font-size-md font-weight-semibold text-dark text-uppercase border-bottom pb-2 lh-1">
                  <span className="text-secondary mr-3">
                    <i className="fal fa-calendar" />
                  </span>
                  <span> Make An online Reservation</span>
                </div>
                <div className="card-body px-0">
                  <div className="text-danger font-weight-semibold mb-2">
                    <span>
                      Total Amount:₹
                      {isNaN(totalPrice) ? basePricePerPerson : totalPrice}
                    </span>
                  </div>
                  <div className="form-reservation">
                    <form onSubmit={handleSubmit}>
                      <div className="d-flex">
                        <div className="col-md-5">
                          <div className="form-group person">
                            <label htmlFor="persons">Persons:</label>
                            <div className="bg-white">
                              <input
                                disabled={true}
                                type="number"
                                id="persons"
                                className="form-control form-control-sm bg-transparent col-12"
                                name="persons"
                                min="1"
                                value={formData.persons}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-7">
                          <div
                            className="form-group person"
                            style={{ marginLeft: "20px" }}
                          >
                            <label htmlFor="guest">
                              Guest<span className="text-gray"> (Max 100)</span>:
                            </label>
                            <div className="bg-white">
                              <input
                                name="guest"
                                min="0"
                                max="100"
                                id="guest"
                                className="form-control form-control-sm bg-transparent col-12"
                                type="number"
                                value={formData.guest}
                                onChange={handleInputChange}
                                onBlur={handleGuestBlur}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="form-group mb-4">
                        <div className="form-group">
                          <label htmlFor="date">Date:</label>
                          <div className="bg-white px-2">
                            <span className="text">
                              {moment(start_time).format("MMMM Do YYYY")}
                            </span>
                          </div>
                        </div>
                      </div>

                      {token ? (
                        formData.guest > 0 ? (
                          <button
                            className="btn btn-primary btn-block"
                            type="button"
                            onClick={handleGuestModalOpen}
                            disabled={formData.guest > 100}
                          >
                            Book Now
                          </button>
                        ) : (
                          <PaymentGateway
                            amount={
                              isNaN(totalPrice)
                                ? basePricePerPerson
                                : totalPrice
                            }
                            afterPayment={handlePaymentSuccess}
                          >
                            <button
                              className="btn btn-primary btn-block"
                              type="button"
                            >
                              Book Now
                            </button>
                          </PaymentGateway>
                        )
                      ) : (
                        <button
                          className="btn btn-primary btn-block"
                          type="button"
                          onClick={handleModalOpen}
                        >
                          Book Now
                        </button>
                      )}
                    </form>
                  </div>
                </div>
              </div>
              <div className="widget map mb-6  mb-6 rounded-0">
                <div className="button-direction">
                  <Link to={venue_link} className="btn btn-sm btn-icon-left">
                    <i className="fas fa-location-arrow" />
                    Get Direction
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          QR Code
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Stack direction="row" spacing={2}>
            <img
              style={{ maxWidth: "400px", width: "100%" }}
              src={qr_code}
              alt=""
            />
          </Stack>
        </DialogContent>
      </BootstrapDialog>
      <Login open={loginModalOpen} handleClose={handleModalClose} />
      <GuestDetailsModal
        guest={formData.guest}
        open={isGuestModalOpen}
        handleClose={handleGuestModalClose}
        guestDetails={guestDetails}
        setGuestDetails={setGuestDetails}
        checkoutButton={
          <PaymentGateway
            amount={isNaN(totalPrice) ? basePricePerPerson : totalPrice}
            afterPayment={handlePaymentSuccess}
          >
            <Button variant="contained" type="button">
              Check Out
            </Button>
          </PaymentGateway>
        }
      />
    </div>
  );
};

export default EventDetails;
