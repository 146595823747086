import axios from "axios";
import React, { createContext, useContext, useState } from "react";
import { base_Url } from "../utils/common";

const LocationContext = createContext();

export const useLocation = () => {
  return useContext(LocationContext);
};

const removeRoles = ["Community Super Admin", "Community Admin"];

export const LocationProvider = ({ children }) => {
  const [country, setCountry] = useState({});
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [postal, setPostal] = useState([]);
  const [role, setRole] = useState([]);

  const getAllCountry = async () => {
    const res = await axios.get(`${base_Url}country/all-country`);
    if (res.data.status === true) {
      setCountry(res.data.data);
      setState([]);
      setCity([]);
      setPostal([]);
    }
  };

  const getState = async (id) => {
    const res = await axios.get(`${base_Url}state/state-by-countryid/${id}`);
    if (res.data.status === true) {
      setState(res.data.data);
      setCity([]);
      setPostal([]);
    }
  };

  const getCity = async (id) => {
    const res = await axios.get(`${base_Url}city/city-by-stateid/${id}`);
    if (res.data.status === true) {
      setCity(res.data.data);
      setPostal([]);
    }
  };

  const getPostalCode = async (id) => {
    const res = await axios.get(`${base_Url}postalcode/code-by-cityid/${id}`);
    if (res.data.status === true) {
      setPostal(res.data.data);
    }
  };

  const getRole = async () => {
    const res = await axios.get(`${base_Url}role/all-roles`);
    if (res.data.status === true) {
      setRole(res?.data?.data?.filter((item) => !removeRoles.includes(item.role)));
    }
  };

  return (
    <LocationContext.Provider
      value={{
        country,
        getAllCountry,
        getState,
        state,
        getCity,
        getRole,
        role,
        city,
        postal,
        getPostalCode,
        setState,
        setCity,
        setPostal,
        getRole,
        role,
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};
