import { api } from "../config/api";


export const GetAllBlog = async (limit = 10, page = 1) => {
  try {
    const response = await api.get(`blog/all-blog?limit=${limit}&page=${page}`);
    const responseData = response;
    return responseData;
  } catch (error) {
    throw error;
  }
};
