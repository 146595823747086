import axios from "../ApiConfig";
import { ApiURL, snackBar } from "../common";

export const loginUser = (data, navigate) => async (dispatch) => {
  try {
    dispatch({
      type: "LOADING_AUTH",
      payload: true,
    });
    const res = await axios.post(`${ApiURL}user/login`, data);
    if (res.data.status === true) {
      dispatch({
        type: "AUTH_SUCCESS",
        payload: res.data.data,
      });
      localStorage.setItem("access_token", res.data.data.token);
      localStorage.setItem("user_data", JSON.stringify(res.data.data));
      localStorage.setItem("uuid", JSON.stringify(data.device_id));
      navigate("/admin/dashboard");
      snackBar(res?.data?.message, res?.data?.status);
      return res;
    }
    snackBar(res?.data?.message, res?.data?.status);
  } catch (err) {
    snackBar(err?.response?.data?.message, err?.response?.data?.status);
  } finally {
    dispatch({
      type: "LOADING_AUTH",
      payload: false,
    });
  }
};

export const logoutUser = (navigate) => async (dispatch) => {
  const uuid = JSON.parse(localStorage.getItem("uuid"));
  const data = { device_id: uuid };
  try {
    dispatch({
      type: "LOADING_AUTH",
      payload: true,
    });
    const res = await axios.post(`${ApiURL}admin/logout`, data);
    const keysToRemove = ["access_token", "user_data", "uuid"];
    keysToRemove.forEach((key) => {
      localStorage.removeItem(key);
    });
    navigate("/");
    snackBar(res?.data?.message, res?.data?.status);
  } catch (err) {
    snackBar(err?.response?.data?.message, err?.response?.data?.status);
    console.error(err, "login--err");
  } finally {
    const keysToRemove = ["access_token", "user_data", "uuid"];
    keysToRemove.forEach((key) => {
      localStorage.removeItem(key);
    });
    navigate("/admin/login");
    dispatch({
      type: "LOADING_AUTH",
      payload: false,
    });
  }
};
