import React, { useEffect } from "react";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "../../components/Input";

const schemaValidation = Yup.object().shape({
  company_name: Yup.string().required("Please enter company name"),
  pan_number: Yup.string()
    .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "Invalid pan Number")
    .required("Please enter pan number"),
  gst_number: Yup.string()
    .required("Please enter gst number")
    .min(15, "GST number must be exactly 15 characters")
    .max(15, "GST number must be exactly 15 characters"),
});

const Step2 = ({ handleBack, handleNext, activeStep, value }) => {
  // const dispatch = useDispatch();

  // const { getAllChapter } = useSelector((state) => state.ChapterReducer);

  const allChapterData = [];
  // getAllChapter &&
  // getAllChapter?.chapterData?.map((el) => {
  //   return { value: el?._id, label: el.chapter_name };
  // });

  // useEffect(() => {
  //   dispatch(getChapterAll());
  // }, []);

  const formik = useFormik({
    initialValues: {
      company_name: value.company_name || "",
      pan_number: value.pan_number || "",
      gst_number: value.gst_number || "",
    },
    validationSchema: schemaValidation,
    onSubmit: (values, { resetForm }) => {
      const body = {
        company_name: values.company_name,
        pan_number: values.pan_number,
        gst_number: values.gst_number,
      };

      const resetInitForm = () => {
        resetForm();
      };
      //   dispatch(CreateUserManage(formData));
      //   resetForm();
      //   resetInitForm();
      handleNext(body);
    },
  });

  return (
    <div>
      <Box
        component={Paper}
        sx={{
          padding: {
            xs: "30px",
            sm: "30px",
            md: "40px",
            lg: "50px",
            xl: "60px",
          },
        }}
        // sx={{ backgroundColor: "green" }}
      >
        <Typography
          textAlign="center"
          fontWeight={500}
          fontSize={28}
          marginBottom={2}
          color="black"
          font-family="Nunito,Inter var,Roboto,Helvetica Neue,Arial,sans-serif"
        >
          Business Details
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12} sm={12}>
              <Input
                id="company_name"
                name="company_name"
                type="text"
                label="Company Name"
                maxLength={80}
                onChange={formik.handleChange}
                value={formik.values.company_name}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.company_name &&
                  Boolean(formik.errors.company_name)
                }
                helperText={
                  formik.touched.company_name && formik.errors.company_name ? (
                    <div className="err-msg err-msg-font">
                      {formik.errors.company_name}
                    </div>
                  ) : null
                }
              />
            </Grid>
          </Grid>
          {/* <Grid container spacing={2}>
            <Grid item md={6} xs={12} sm={12}>
              <SelectBox
                id="refferel_type"
                name="refferel_type"
                label="Refferal Type"
                ClassName="range-select-filter"
                value={formik.values.refferel_type}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                options={allChapterData || []}
                error={
                  formik.touched.refferel_type &&
                  Boolean(formik.errors.refferel_type)
                }
                touched={formik.touched.refferel_type}
              />
              {formik.touched.refferel_type && formik.errors.refferel_type && (
                <div className="err-msg-font">
                  {formik.errors.refferel_type}
                </div>
              )}
            </Grid>
            <Grid item md={6} xs={12} sm={12} sx={{marginTop:"5.5px"}}>
              <Input
                id="refferel_code"
                name="refferel_code"
                type="text"
                label="Refferel Code"
                maxLength={80}
                onChange={formik.handleChange}
                value={formik.values.refferel_code}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.refferel_code &&
                  Boolean(formik.errors.refferel_code)
                }
                helperText={
                  formik.touched.refferel_code &&
                    formik.errors.refferel_code ? (
                    <div className="err-msg err-msg-font">
                      {formik.errors.refferel_code}
                    </div>
                  ) : null
                }
              />
            </Grid>
          </Grid> */}
          <Grid container spacing={2}>
            <Grid item md={6} xs={12} sm={12}>
              <Input
                id="pan_number"
                name="pan_number"
                type="text"
                maxLength={10}
                label="Pan Number"
                onChange={(e) =>
                  formik.setFieldValue(
                    "pan_number",
                    e.target.value.toUpperCase()
                  )
                }
                value={formik.values.pan_number}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.pan_number && Boolean(formik.errors.pan_number)
                }
                helperText={
                  formik.touched.pan_number && formik.errors.pan_number ? (
                    <div className="err-msg err-msg-font">
                      {formik.errors.pan_number}
                    </div>
                  ) : null
                }
              />
            </Grid>

            <Grid item md={6} xs={12} sm={12}>
              <Input
                id="gst_number"
                name="gst_number"
                type="text"
                label="GST Number"
                maxLength={15}
                onChange={(e) =>
                  formik.setFieldValue(
                    "gst_number",
                    e.target.value.toUpperCase()
                  )
                }
                value={formik.values.gst_number}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.gst_number && Boolean(formik.errors.gst_number)
                }
                helperText={
                  formik.touched.gst_number && formik.errors.gst_number ? (
                    <div className="err-msg err-msg-font">
                      {formik.errors.gst_number}
                    </div>
                  ) : null
                }
              />
            </Grid>
          </Grid>
          <Grid
            container
            sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}
          >
            <Grid item>
              <Button
                sx={{ margin: 1 }}
                type="button"
                variant="contained"
                // disabled={activeStep === 0}
                onClick={() => handleBack(formik.values)}
              >
                Back
              </Button>
            </Grid>
            <Grid item>
              <Button
                sx={{ margin: 1 }}
                type="submit"
                // onClick={handleSubmit}
                variant="contained"
              >
                {!activeStep <= 1 ? "Next" : "Submit"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </div>
  );
};

export default Step2;
