const initialState = {
  loading: false,
  faqcategory: [],
  createLoading: false,
  updateLoading: false,
  deleteLoading: false,
};

function FAQCategoryReducer(state = initialState, action) {
  switch (action.type) {
    case "FAQ_GET_LOADING":
      return {
        ...state,
        loading: action.payload,
      };

    case "FAQ_GET_SUCCESS":
      return {
        ...state,
        faqcategory: action.payload,
        loading: false,
      };

    case "CREATE_LOADING_FAQ":
      return {
        ...state,
        createLoading: action.payload,
      };

    case "CREATE_FAQ_SUCCESS":
      return {
        ...state,
      };

    case "UPDATE_LOADING_FAQ":
      return {
        ...state,
        updateLoading: action.payload,
      };
    case "UPDATE_FAQ_SUCCESS":
      return {
        ...state,
      };

    case "DELETE_FAQ_LOADING":
      return {
        ...state,
        deleteLoading: action.payload,
      };

    case "DELETE_FAQ_SUCCESS":
      return {
        ...state,
      };

    default:
      return state;
  }
}

export default FAQCategoryReducer;
