import how1 from "../assets/image/Be_Partner/Image_1.png";
import how2 from "../assets/image/Be_Partner/Image_2.png";
import how3 from "../assets/image/Be_Partner/Image_3.png";
import how4 from "../assets/image/Be_Partner/Image_4.png";
import how5 from "../assets/image/Be_Partner/Image_5.png";
import how6 from "../assets/image/Be_Partner/Image_6.png";
import how7 from "../assets/image/Be_Partner/Image_7.png";

import how8 from "../assets/image/Be_Partner/Image_8.png";
import how9 from "../assets/image/Be_Partner/Image_9.png";
import how10 from "../assets/image/Be_Partner/Image_10.png";
import how11 from "../assets/image/Be_Partner/Image_11.png";
import how12 from "../assets/image/Be_Partner/Image_12.png";
import how13 from "../assets/image/Be_Partner/Image_13.png";
import how14 from "../assets/image/Be_Partner/Image_14.png";
import how15 from "../assets/image/Be_Partner/Image_15.png";

import beamember1 from "../assets/image/Be_Member/Image1.png"
import beamember2 from "../assets/image/Be_Member/Image2.png"
import beamember3 from "../assets/image/Be_Member/Image3.png"
import beamember4 from "../assets/image/Be_Member/Image4.png"
import beamember5 from "../assets/image/Be_Member/Image5.png"
import beamember6 from "../assets/image/Be_Member/Image6.png"
import beamember7 from "../assets/image/Be_Member/Image7.png"
import beamember8 from "../assets/image/Be_Member/Image8.png"
import beamember9 from "../assets/image/Be_Member/Image9.png"
import beamember10 from "../assets/image/Be_Member/Image10.png"
import beamember11 from "../assets/image/Be_Member/Image11.png"
import beamember12 from "../assets/image/Be_Member/Image12.png"
import beamember13 from "../assets/image/Be_Member/Image13.png"
import beamember14 from "../assets/image/Be_Member/Image14.png"
import beamember15 from "../assets/image/Be_Member/Image15.png"
import beamember16 from "../assets/image/Be_Member/Image16.png"
import beamember17 from "../assets/image/Be_Member/Image17.png"
import beamember18 from "../assets/image/Be_Member/Image18.png"
import beamember19 from "../assets/image/Be_Member/Image19.png"

// be a parner
export const beMember1 = [
  {
    cardImage: how1,
    title: "Global Network,Local Impact",
    description: `By becoming a partner, you gain access to a vast global
    network while maintaining a strong local presence. Our
    community thrives on diversity, connecting businesses
    worldwide to create a dynamic ecosystem of opportunities.`,
  },
  {
    cardImage: how2,
    title: "Proven Success",
    description: `As an integral part of the Global Garner family, you align
    with a proven track record of success. Our parent
    company's astounding valuation in a short span of 7 years
    is a testament to our commitment to excellence and
    innovation.`,
  },
  {
    cardImage: how3,
    title: "Exclusive Resources",
    description: `Benefit from exclusive resources, tools, and insights
    tailored to empower your business. From cutting-edge
    technology solutions to expert guidance, we provide the
    resources you need to stay ahead in today's competitive
    market.`,
  },
  {
    cardImage: how4,
    title: "Collaborative Ecosystem",
    description: `Join a community where collaboration is key. Engage with
    like-minded entrepreneurs, share ideas, and foster
    strategic partnerships that propel your business towards
    unprecedented success.`,
  },
  {
    cardImage: how5,
    title: "Tailored Support Service",
    description: `Our dedicated support team is committed to ensuring your
    success. Receive personalised assistance, training
    programs, and ongoing support to navigate challenges and
    seize opportunities.`,
  },
  {
    cardImage: how6,
    title: "Marketing Amplification",
    description: `Leverage our global marketing reach to amplify your brand.
    Benefit from targeted marketing campaigns, exposure
    through our extensive network, and the opportunity to
    showcase your products or services to a wider audience.`,
  },
  {
    cardImage: how7,
    title: " Innovative Events and Networking",
    description: `Participate in exclusive events, seminars, and networking
    opportunities that enrich your knowledge and expand your
    business horizons. Connect with industry leaders,
    potential clients, and collaborators to fuel your growth
    journey.`,
  },
];
export const beMember2 = [
  {
    cardImage: how8,
    title: "Market Expansion",
    description:
      "Tap into the global network to explore new markets and expand your business reach. Collaborate with other members who have successfully navigated similar challenges, gaining valuable insights and strategies.",
  },
  {
    cardImage: how9,
    title: "Technology Adoption",
    description:
      "Stay ahead in the fast-paced business landscape by leveraging the community's technological resources. Access cutting-edge solutions, share best practices, and collaborate with tech-savvy entrepreneurs to enhance your business capabilities.",
  },
  {
    cardImage: how10,
    title: "Strategic Partnerships",
    description:
      "Build strategic partnerships with fellow members to overcome challenges collaboratively. The community provides a platform for synergies, allowing you to pool resources, share expertise, and jointly tackle industry-specific hurdles.",
  },
  {
    cardImage: how11,
    title: "Talent Acquisition and Development",
    description:
      "Access a diverse talent pool within the community for recruitment and skill development. Leverage the collective knowledge to address workforce challenges and stay competitive in attracting top talent.",
  },
  {
    cardImage: how12,
    title: "Knowledge Exchange and Innovation",
    description:
      "Global Garner Community fosters a culture of continuous learning and innovation. Partners can leverage the community's vast knowledge base to stay updated on industry trends, emerging technologies, and innovative business practices. This knowledge exchange becomes a catalyst for innovation within your own business, enabling you to stay ahead of the curve and pioneer groundbreaking solutions.",
  },
  {
    cardImage: how13,
    title: "Funding and Investment Opportunities",
    description:
      "As a partner, gain access to a network of potential investors and financial experts within the community. Global Garner Community serves as a platform where partners can showcase their projects, attract investment, and explore funding opportunities. Whether you are a startup seeking seed funding or an established business looking for expansion capital, the community provides a gateway to a diverse range of financial resources.",
  },
  {
    cardImage: how14,
    title: "Advocacy and Policy Influence",
    description:
      "Partners at higher levels, such as Regional and Zonal Chapter Presidents, have the unique opportunity to influence industry policies and advocate for the collective interests of the community. Global Garner Community collaborates with regulatory bodies and policymakers, allowing partners to actively shape the business environment. This influence is particularly valuable in addressing regulatory challenges and fostering an environment conducive to business growth.",
  },
  {
    cardImage: how15,
    title: "Crisis Management and Resilience",
    description:
      "Businesses often face unforeseen challenges and crises. Global Garner Community provides a supportive network for partners to share experiences, strategies, and resources for effective crisis management. Leverage the collective resilience of the community to navigate economic downturns, supply chain disruptions, or other unforeseen challenges, turning adversity into an opportunity for growth and adaptation.",
  },
];
// be a parner

// be a member
export const beMemberForm = [
  {
    cardImage: beamember1,
    title: "Your One Stop Business Solution",
    description: `At our community business, we understand the importance of
      providing comprehensive support for entrepreneurs and small
      business owners like you. We offer a range of services to
      help you thrive in today's competitive market. By becoming a
      member of our community, you'll gain access to all essential
      resources in one convenient place, giving you a significant
      advantage in achieving your entrepreneurial goals.`,
  },
  {
    cardImage: beamember2,
    title: "Chart your Path to Success",
    description: `A well-crafted business plan serves as a roadmap to success.
    Our experienced advisors will guide you through the process
    of developing a solid business plan tailored to your unique
    vision. With a clear strategy in place, you'll be better
    prepared to navigate challenges, seize opportunities, and
    attract potential investors or partners.`,
  },
  {
    cardImage: beamember3,
    title: "Stay Compliant & Protected",
    description: `Navigating the maze of permits, licences, and legal
    requirements can be overwhelming. Our team of experts will
    assist you in understanding the necessary legalities and
    ensuring compliance with local regulations. By taking care
    of the bureaucratic aspects, you can focus on growing your
    business while maintaining peace of mind knowing you're
    operating within the law.`,
  },
  {
    cardImage: beamember4,
    title: "Assemble your Dream Team",
    description: `Building a talented and dedicated team is crucial for the
    success of any business. We offer staffing solutions
    tailored to your specific needs, helping you find the right
    people to support your operations. Whether you require
    full-time employees, part-time staff, or even freelance
    professionals, we can connect you with qualified individuals
    who share your vision and passion.`,
  },
  {
    cardImage: beamember5,
    title: "Fuel Your Growth",
    description: `Securing adequate financing is often a challenge for small
    businesses. Our team will assist you in exploring various
    funding options, such as loans, grants, or investment
    opportunities. By leveraging our network of financial
    partners and experts, you'll gain access to the resources
    you need to fuel your business growth and reach your full
    potential.`,
  },
  {
    cardImage: beamember6,
    title: "Stand out from the Crowd",
    description: `Effective marketing and branding strategies are essential
    for attracting customers and building a strong reputation.
    Our marketing specialists will work with you to develop a
    compelling brand identity, create engaging marketing
    campaigns, and utilise various channels to increase your
    visibility. By differentiating yourself from competitors,
    you'll gain a competitive edge in the market.`,
  },
  {
    cardImage: beamember7,
    title: "Sharpen Your Skills",
    description: `Continuous learning and professional development are vital
    for staying ahead in today's dynamic business landscape. Our
    community provides comprehensive business training programs
    and workshops designed to enhance your entrepreneurial
    skills, from leadership and communication to financial
    management and digital marketing. Expand your knowledge and
    stay ahead of the curve with our educational offerings`,
  },
  {
    cardImage: beamember8,
    title: "Enrichment Program",
    description: `Outdoor Games For Self & Staf ,Books/Health Subscription,
    Airport Lounges & Premium Club ,Social Events.`,
  },
  {
    cardImage: beamember9,
    title:
      "Discover the Power of Owning Your Own OTT Channel on YouTube: Unleash Your Business Potential",
    description: `Take the leap into the world of OTT on YouTube – where
    innovation meets business success. Start your channel now
    and let your brand shine on the global stage!.`,
  },
  {
    cardImage: beamember10,
    title: "Global Reach, Local Engagement",
    description: `Reach a worldwide audience while maintaining a local touch.
    Your YouTube OTT Channel allows you to connect with viewers
    globally, expanding your business horizon without losing the
    personal touch that sets you apart.`,
  },
  {
    cardImage: beamember11,
    title: "Control Monetization of Your Content",
    description: `Turn your passion into profit. Monetize your videos with
    various revenue streams, including ad revenue, channel
    memberships, and merchandise sales, transforming your
    channel into a lucrative business venture.`,
  },
  {
    cardImage: beamember12,
    title: "Seamless Brand Integration",
    description: `Effortlessly integrate your brand into your content.
    Showcase your products or services in a natural and engaging
    way, creating a seamless brand experience that resonates
    with your audience.`,
  },
  {
    cardImage: beamember13,
    title: "Data-Driven Insights",
    description: `Gain valuable insights into your audience. YouTube's
    analytics tools provide comprehensive data on viewer
    behavior, helping you tailor your content, make informed
    business decisions, and refine your marketing strategy.`,
  },
  {
    cardImage: beamember14,
    title: "Interactive Community Building",
    description: `Foster a thriving community around your brand. Engage with
    your audience through comments, live chats, and community
    posts, building a dedicated fan base that actively
    participates in your brand's journey.`,
  },
  {
    cardImage: beamember15,
    title: "Cross-Promotion Opportunities",
    description: `Leverage the power of collaboration. Cross-promote with
    other YouTubers, influencers, or brands to tap into new
    audiences and boost your channel's visibility, creating
    strategic partnerships that benefit your business.`,
  },
  {
    cardImage: beamember16,
    title: "Access to YouTube's Premium Subscribers",
    description: `Tap into YouTube's Premium subscriber base. With an OTT
    channel, you gain access to viewers who are part of
    YouTube's subscription service, expanding your potential
    revenue streams and reaching a premium audience.`,
  },
  {
    cardImage: beamember17,
    title: "Dynamic Content Flexibility",
    description: `Diversify your content offerings. From tutorials and product
    showcases to behind-the-scenes glimpses, your YouTube OTT
    channel allows you the flexibility to experiment with
    various content types, keeping your audience engaged and
    excited.`,
  },
  {
    cardImage: beamember18,
    title: "Mobile Accessibility",
    description: `Meet your audience where they are. With YouTube's mobile
    accessibility, your content is readily available to viewers
    on the go, ensuring that your brand remains accessible and
    relevant in today's fast-paced digital landscape.`,
  },
  {
    cardImage: beamember19,
    title: "Future-Proof Your Business",
    description: `Position your business for the future. Embrace the rapidly
    growing world of OTT and streaming services, future-proofing
    your brand by staying at the forefront of digital content
    consumption trends.`,
  },
];
