import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import Home1 from "../../assets/image/banner/banner_1.png";
import Home2 from "../../assets/image/banner/banner_2.png";
import axios from "axios";
import { ApiURL } from "../../../redux/common";
const data = [
  {
    bgImage: Home1,
  },
  {
    bgImage: Home2,
  },
];

const HomeCarousel = () => {
  const [banners, setBanners] = useState([]);

  var settings = {
    dots: false,
    infinite: true,
    arrows: false,
    className: "hero-banner-arrow",
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  const getBanners = async () => {
    const response = await axios.get(
      `${ApiURL}page-banner/get-all-page-banners?page_name=home`
    );

    if (response.data?.data?.bannerList) {
      setBanners(response.data?.data?.bannerList);
    }
  };

  useEffect(() => {
    getBanners();
  }, []);

  console.log(banners, "data");

  return (
    <div className="home-slider">
      <Slider {...settings}>
        {banners.map((item) => {
          let isImage = true;
          let fileType = "";

          if (typeof item?.banner_image === "string") {
            fileType = item?.banner_image.substring(item?.banner_image?.lastIndexOf(".") + 1);
            isImage = fileType.match(/(jpg|jpeg|png|gif)/i);
          }
          return (
            <div>
              <div className="box" style={{ width: "100%", display: "flex" }}>
                {isImage ? (
                  <img
                    style={{ width: "100%" }}
                    src={item?.banner_image}
                    alt="Client logo"
                  />
                ) : (
                  <video width={"100%"} autoPlay loop muted>
                    <source src={item?.banner_image} type="video/mp4" />
                  </video>
                )}
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default HomeCarousel;
