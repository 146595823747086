import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Avatar, Box } from "@mui/material";
import Image1 from "../../web/assets/image/Be_Member/Image1.png";
import Image2 from "../../web/assets/image/Be_Member/Image6.png";
import axios from "axios";
import { base_Url } from "../../web/utils/common";
import { snackBar } from "../../redux/common";
import { useParams } from "react-router-dom";
import NotFound from "../../web/pages/notFound/NotFound";

const BusinessUser = () => {
  const [allGetBusiness, setAllGetBusiness] = useState([]);
  const params = useParams();

  const getBusinessDetials = async () => {
    try {
      const res = await axios.get(
        `${base_Url}business/get-business-details/${params?.id}`
      );
      if (res?.data?.status === true) {
        console.log("res?.data?.status", res?.data?.status);
        setAllGetBusiness(res?.data?.data);
      } else {
        return <NotFound />;
      }
    } catch (err) {
      snackBar(err?.message || "API call failed");
    } finally {
    }
  };

  useEffect(() => {
    getBusinessDetials();
  }, []);

  return (
    <>
      <div
        style={{
          background:
            "linear-gradient(317deg, rgba(254,118,120,1) 9%, rgba(21,62,128,1) 100%)",
          height: "200vh",
          marginTop: "-20px",
          paddingTop: "25px",
        }}
      >
        <Box>
          <Card
            sx={{
              width: "400px",
              marginTop: "20px",
              overflow: "hidden",
              margin: "20px auto",
              zIndex: 0,
              background: "linear-gradient(45deg, #ffffff, #FFFFFF)",
              backgroundSize: "100% 100%",
              color: "black",
              padding: " 0px 0px 27px",
              position: "relative",
              borderRadius: "20px",
            }}
          >
            <CardMedia
              sx={{
                height: 140,
                position: "relative",
                backgroundImage: `url(${Image1})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "absolute",
                  top: "100%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <Avatar
                  alt="Avatar"
                  src={allGetBusiness?.profile_picture}
                  sx={{ width: 96, height: 96 }}
                />
              </Box>
            </CardMedia>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "55px",
              }}
            >
              <Typography
                variant="h6"
                fontSize="23px"
                fontWeight="bold"
                marginBottom="10px"
              >
                {allGetBusiness?.first_name} {allGetBusiness?.last_name}
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontWight: "600",
                  fontSize: "14px",
                }}
              >
                Your Designation
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontWight: "600",
                  fontSize: "14px",
                }}
              >
                {allGetBusiness?.business?.business_name}
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontWight: "600",
                  fontSize: "14px",
                }}
              >
                {allGetBusiness?.email}
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontWight: "600",
                  fontSize: "14px",
                }}
              >
                {allGetBusiness?.mobile_number}
              </Typography>
            </CardContent>

            <div
              style={{
                padding: "45px 0 28px",
                display: "block",
                textAlign: "center",
              }}
            >
              <a
                href="tel:+918111081114"
                target="_blank"
                style={{ padding: " 0 8px" }}
              >
                <img
                  src="https://digicarda.com/images/call.png"
                  alt="Call"
                  title="Call"
                  style={{ width: "45px", maxwidth: "100%" }}
                />
              </a>
              <a
                href="https://api.whatsapp.com/send?phone=918111081114&text=Hi, amit, Got your information from your digicarda.com ..."
                target="_blank"
                style={{ padding: " 0 8px" }}
              >
                <img
                  src="https://digicarda.com/images/whatsapp.png"
                  alt="Whats App"
                  title="Connect with whatsapp"
                  style={{ width: "45px", maxwidth: "100%" }}
                />
              </a>
              <a
                href="https://g.page/digicarda?share"
                target="_blank"
                style={{ padding: " 0 8px" }}
              >
                <img
                  src="https://digicarda.com/images/location.png"
                  alt="Location"
                  title="Our Location"
                  style={{ width: "45px", maxwidth: "100%" }}
                />
              </a>
              <a
                href="Mailto:support@digicarda.com"
                target="_blank"
                style={{ padding: " 0 8px" }}
              >
                <img
                  src="https://digicarda.com/images/email.png"
                  alt="Email"
                  title="Connect by Email"
                  style={{ width: "45px", maxwidth: "100%" }}
                />
              </a>
              {/* <a
                href="https://digicarda.com/"
                target="_blank"
                style={{ padding: " 0 8px" }}
              >
                <img
                  src="images/website.png"
                  alt="Website"
                  title="Connect by Website"
                  style={{ width: "45px", maxwidth: "100%" }}
                />
              </a> */}
            </div>
          </Card>
        </Box>

        <Box>
          <Card
            sx={{
              width: "400px",
              marginTop: "20px",
              overflow: "hidden",
              margin: "20px auto",
              zIndex: 0,
              background: "linear-gradient(45deg, #ffffff, #FFFFFF)",
              backgroundSize: "100% 100%",
              color: "black",
              padding: " 0px 0px 27px",
              position: "relative",
              borderRadius: "20px",
            }}
          >
            <Box sx={{ backgroundColor: "#ff5252" }}>
              <Typography
                sx={{
                  fontSize: "20px",
                  justifyContent: "center",
                  display: "flex",
                  fontWeight: "700",
                }}
              >
                Business Details
              </Typography>
            </Box>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "40px",
              }}
            >
              <Typography variant="h6" fontSize="18px" fontWeight="500">
                <strong>Business Name:</strong>{" "}
                {allGetBusiness?.business?.business_name}
              </Typography>
              <Typography variant="h6" fontSize="18px" fontWeight="500">
                <strong>Email Address:</strong>{" "}
                {allGetBusiness?.business?.business_email}
              </Typography>
              <Typography variant="h6" fontSize="18px" fontWeight="500">
                <strong>Category:</strong>{" "}
                {allGetBusiness?.business?.business_category}
              </Typography>
              <Typography variant="h6" fontSize="18px" fontWeight="500">
                <strong>Type:</strong> {allGetBusiness?.business?.business_type}
              </Typography>
              <Typography variant="h6" fontSize="18px" fontWeight="500">
                <strong>Address:</strong>
                {allGetBusiness?.address}
              </Typography>
            </CardContent>
          </Card>
        </Box>

        <Box>
          <Card
            sx={{
              width: "400px",
              marginTop: "20px",
              overflow: "hidden",
              margin: "20px auto",
              zIndex: 0,
              background: "linear-gradient(45deg, #ffffff, #FFFFFF)",
              backgroundSize: "100% 100%",
              color: "black",
              padding: " 0px 0px 27px",
              position: "relative",
              borderRadius: "20px",
            }}
          >
            <Box sx={{ backgroundColor: "#ff5252" }}>
              <Typography
                sx={{
                  fontSize: "20px",
                  justifyContent: "center",
                  display: "flex",
                  fontWeight: "700",
                }}
              >
                About Us
              </Typography>
            </Box>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "40px",
              }}
            >
              <Typography
                variant="h6"
                sx={{ fontSize: "20px", fontWeight: "700" }}
              >
                For DEMO:
              </Typography>
              <Typography variant="body1">
                Please Click on <strong>Whatsapp CHAT</strong> Button or{" "}
                <strong>Enquiry</strong> Button on the card for Industry
                specific demo.
              </Typography>

              <Typography
                variant="h6"
                sx={{ fontSize: "20px", fontWeight: "700", marginTop: "20px" }}
              >
                How It Works :
              </Typography>
              <Typography variant="body1">
                Please watch 2 minutes Youtube Video Below to see the DigiCarda
                features.
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </div>
    </>
  );
};

export default BusinessUser;
