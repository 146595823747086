import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Pagination,
  Typography,
  Drawer,
  Stack,
  Divider,
  Button,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import PageHeader from "../../components/pageHeader/PageHeader";
import EnhancedTable from "../../components/table/EnhancedTable ";
import {
  DeleteIcon,
  EditIcon,
  CloseIcon,
  Visibility,
} from "../../assets/icons/Icons";
import "../../components/table/table.css";
import { Form, useForm } from "../../common/useForms";
import FormDialog from "../../components/dialog/dialog";
import { makeStyles } from "@material-ui/core/styles";
import Input from "../../common/Input";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import DeleteDialog from "../dialog/deletedialog";
import { useNavigate } from "react-router-dom";
import SendIcon from "@mui/icons-material/Send";
import {
  DeleteNotification,
  GetAllNotifications,
} from "../../redux/action/NotificationActions";
import SendNotification from "./SendNotification";
import useUserRolePermissions from "../../common/UserPermissions";
import { permissions } from "../../common/constants";
import { getPermissions } from "../../common/permissions";
import Loader from "../loader/Loader";
import { DisplayHTMLString } from "../../common/HtmlToString";

const useStyles = makeStyles((theme) => ({
  filterMain: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "#fff",
  },
  mainFilterBox: {
    padding: "10px 20px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "200px",
    },

    [theme.breakpoints.up("md")]: {
      width: "300px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "240px !important",
    },
    [theme.breakpoints.up("xs")]: {
      width: "100%",
    },
  },

  stackFilter: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  typographyFilter: {
    fontWeight: "bold !important",
    color: "#000 !important",
    fontSize: "30px !important",
  },
  buttonFilter: {
    flexDirection: "row !important",
    marginTop: "8px",
    gap: "8px",
  },
}));

const columns = [
  {
    id: "title",
    numeric: true,
    disablePadding: false,
    label: "Title",
  },
  {
    id: "message",
    numeric: false,
    disablePadding: true,
    label: "Message",
  },

  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "Action",
  },
].filter((el) => el !== null);

const initialValues = {
  title: "",
  messsage: "",
};

const RecordPerPage = [
  { id: 5, title: "5" },
  { id: 10, title: "10" },
];
const initialFilterValues = {
  title: "",
};

const NotificationList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState(false);
  const [filterData, setFilterData] = useState(true);
  const [filterDatas, setFilterDatas] = useState(initialFilterValues);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedrole, setSelectedrole] = useState(null);
  const [openSend, setOpenSend] = useState(false);
  const role = useUserRolePermissions();

  const [DeleteId, SetDeleteId] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [popupName, setPopupName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { loading, notification, updateLoading, deleteLoading, createLoading } =
    useSelector((state) => state?.NotificationReducer);

  const handleClickSendOpen = (data) => {
    setOpenSend(true);
    setSelectedrole(data);
  };

  const handleSendClose = () => {
    setOpenSend(false);
  };

  const handleChangeRow = (event) => {
    const rowPerPage = event.target.value;
    setRowsPerPage(event.target.value);

    dispatch(GetAllNotifications(page, rowPerPage));
  };

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const tableTitle = "SubscriptionList";

  useEffect(() => {
    if (!open) {
      dispatch(GetAllNotifications(page, rowsPerPage));
    }
  }, [page, open, dispatch]);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleClickOpen = () => {
    navigate("/admin-Createnotification", {
      state: { formName: "Create Notification", userData: {} },
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    resetForm();
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const {
    values,
    setFieldValues,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  } = useForm(initialValues, true);

  const handleEditClick = (data) => {
    setSelectedUser(data);
    navigate("/admin-update-notification", {
      state: { formName: "Update Notification", userData: data },
    });
    // setPopupName("Update User")
    setValues({
      ...values,
      title: data.title || "",
      message: data.message || "",
    });
    setErrors({});
    setOpen(true);
  };

  const handleClickOpenDelete = (data) => {
    setOpenDelete(true);
    SetDeleteId(data._id);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleSingleDelete = async () => {
    await dispatch(DeleteNotification(DeleteId, handleCloseDelete)).then(
      (res) => {
        if (res && res.data.status === true) {
          dispatch(GetAllNotifications(page, rowsPerPage));
        }
      }
    );
  };

  // const fields = [
  //   {
  //     id: "banner_type_id",
  //     label: "Banner type",
  //     type: "select",
  //     options:
  //       bannerTypeData &&
  //       bannerTypeData?.map((el) => {
  //         return { value: el._id, label: el.banner_type };
  //       }),
  //     value: values.banner_type_id,
  //     name: "banner_type_id",
  //     errors: errors.banner_type_id,
  //   },

  //   {
  //     id: "banner_images",
  //     type: "file",
  //     labelAccess: "banner_images",
  //     value: values.banner_images,
  //     name: "banner_images",
  //     errors: errors.banner_images,
  //     multiple: true,
  //   },

  //   {
  //     id: "chapter_id",
  //     label: "Chapter Name",
  //     type: "select",
  //     options:
  //       chapterData &&
  //       chapterData?.map((el) => {
  //         return { value: el._id, label: el.chapter_name };
  //       }),
  //     value: values.chapter_id,
  //     name: "chapter_id",
  //     errors: errors.chapter_id,
  //   },
  // ];

  const rows =
    notification?.notificationData &&
    Array.isArray(notification?.notificationData)
      ? notification?.notificationData?.map((data) => {
          const selected = data === selectedUser || data === selectedrole;
          return {
            _id: data._id,
            title: data.title,
            message: DisplayHTMLString(data.message),

            action: (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {!!getPermissions(permissions.notification, "is_edit") && (
                  <IconButton
                    onClick={() => handleEditClick(data)}
                    sx={{ color: "black" }}
                  >
                    <EditIcon />
                  </IconButton>
                )}
                {!!getPermissions(permissions.notification, "is_delete") && (
                  <IconButton
                    onClick={() => handleClickOpenDelete(data)}
                    sx={{ color: "black" }}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}

                <IconButton
                  onClick={() => handleClickSendOpen(data)}
                  sx={{ color: "black" }}
                >
                  <SendIcon />
                </IconButton>
                {/* )} */}
              </div>
            ),
            selected: selected,
          };
        })
      : [];

  const openFilterDrawer = () => {
    setFilter(true);
  };

  const closeFilterDrawer = () => {
    setFilter(false);
    setFilterData(!filterData);
  };

  const closeFilterDrawer1 = () => {
    setFilter(false);
  };

  const resetFormFilter = () => {
    setFilterDatas(initialFilterValues);
    setPage(1);
    dispatch(GetAllNotifications(1, rowsPerPage, "", "", "", ""));
    closeFilterDrawer();
  };

  const handleSubmitFilter = (e) => {
    e.preventDefault();
    const body = {
      title: filterDatas.title,
    };
    setFilterDatas(body);
    const title = filterDatas.title ? filterDatas.title : "";
    setPage(1);
    dispatch(GetAllNotifications(page, rowsPerPage, title));
    closeFilterDrawer1();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFilterDatas((prevFilterData) => ({
      ...prevFilterData,
      [name]: value,
    }));
  };
  return (
    <Box>
      <PageHeader
        onFilterClick={openFilterDrawer}
        title="Notifications"
        onAddClick={handleClickOpen}
        isShowAdd={!!getPermissions(permissions.notification, "is_add")}
      />

      {loading ? (
        <Loader />
      ) : (
        // <Box sx={{ display: "flex", justifyContent: "center" }}>
        //   <CircularProgress />
        // </Box>
        <EnhancedTable
          key={rows._id}
          columns={columns}
          rows={rows}
          order={order}
          orderBy={orderBy}
          selected={selected}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          title={tableTitle}
          tableList={notification?.notificationData}
        />
      )}
      <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
        <form onSubmit={handleSubmitFilter} className={classes.filterMain}>
          <Box className={classes.mainFilterBox}>
            <Stack className={classes.stackFilter}>
              <Typography className={classes.typographyFilter}>
                Filter
              </Typography>

              <CloseIcon
                className="close-button"
                onClick={closeFilterDrawer1}
              />
            </Stack>
            <Box
              sx={{
                marginTop: "5px",
              }}
            >
              <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />
            </Box>

            <div>
              <Box>
                <Input
                  text="Add New"
                  variant="outlined"
                  color="primary"
                  label="Title"
                  name="title"
                  value={filterDatas.title}
                  onChange={handleChange}
                  maxLength={200}
                />
              </Box>

              <Stack className={classes.buttonFilter}>
                <Button
                  color="secondary"
                  size="large"
                  variant="contained"
                  className="cancel-filter"
                  onClick={resetFormFilter}
                >
                  Reset
                </Button>
                <Button
                  color="primary"
                  size="large"
                  variant="contained"
                  className="order-filter-button"
                  type="submit"
                  style={{
                    cursor: "pointer",
                  }}
                >
                  Filter
                </Button>
              </Stack>
            </div>
          </Box>
        </form>
      </Drawer>
      <DeleteDialog
        open={openDelete}
        loading={deleteLoading}
        dialogTitle="Are you sure to want to delete this record?"
        handleClose={handleCloseDelete}
        handleDelete={() => handleSingleDelete(DeleteId)}
      />

      <SendNotification
        openSend={openSend}
        handleClickSendOpen={handleClickSendOpen}
        setOpenSend={setOpenSend}
        selectedrole={selectedrole}
      />
      <Box
        sx={{
          display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
          justifyContent: "space-between",
          alignItems: "center",
          marginLeft: { xs: "10px", sm: "20px", md: "20px", lg: "20px" },
          marginRight: { xs: "10px", sm: "15px", md: "15px", lg: "15px" },
          mt: 2,
          mb: 2,
        }}
      >
        <Box
          sx={{
            fontSize: "14px",
            marginBottom: { xs: "10px", sm: "0", md: "0", lg: "0" },
            gap: 2,
            width: { sm: "100%", md: "50%" },
          }}
          display="flex"
          alignItems="center"
        >
          <Box>
            Showing {notification?.notificationData?.currentPage} of{" "}
            {notification?.notificationData?.totalPages} Results
          </Box>
          <Box display="flex" sx={{ gap: 1 }} alignItems="center">
            <Typography>Records per page</Typography>

            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                id="limites"
                name="limites"
                value={rowsPerPage}
                onChange={handleChangeRow}
                displayEmpty
                sx={{ maxWidth: "65px", maxHeight: "2.2rem" }}
                inputProps={{ "aria-label": "Without label" }}
              >
                {RecordPerPage?.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name || item.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Pagination
          count={notification?.totalPages}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
          sx={{
            "& .MuiButtonBase-root ": {
              color: "#000 !important",
            },
            "& .Mui-selected ": {
              background: "#4b4b4e70 !important",
            },
            "& .Mui-disabled ": {
              background: "#6c696966 !important",
              color: "#000000 !important",
              opacity: 1,
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default NotificationList;
