import { enqueueSnackbar } from "notistack";
import axios from "../ApiConfig";
import { ApiURL, snackBar } from "../common";

const axiosApiCall = () =>
  axios.create({
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  });

export const GetAllPages = (page, rowsPerPage) => async (dispatch) => {
  try {
    dispatch({
      type: "PAGES_GET_LOADING",
      payload: true,
    });

    const res = await axios.get(
      `${ApiURL}page/get-all-pages?limit=${rowsPerPage}&page=${page}`
    );

    if (res.data.status === true) {
      const responseData =
        res.data.data || res.data.data.length > 0 ? res.data.data : [];
      dispatch({
        type: "PAGES_GET_SUCCESS",
        payload: responseData,
      });
    } else {
      dispatch({
        type: "PAGES_GET_SUCCESS",
        payload: [],
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({
      type: "PAGES_GET_LOADING",
      payload: false,
    });
  }
};

export const CreatePage = (data) => async (dispatch) => {
  try {
    dispatch({
      type: "CREATE_LOADING_PAGE",
      payload: true,
    });

    const res = await axios.post(`${ApiURL}page/create-page`, data);

    if (res.data.status === true) {
      dispatch({
        type: "CREATE_PAGE_SUCCESS",
        payload: res.data.data,
      });
      snackBar(res?.data?.message, res?.data?.status);
      return res;
    }
  } catch (err) {
    snackBar(err?.response?.message, err?.response?.status);
  } finally {
    dispatch({
      type: "CREATE_LOADING_PAGE",
      payload: false,
    });
  }
};

export const UpdatePage = (id, data) => async (dispatch) => {
  try {
    dispatch({
      type: "UPDATE_LOADING_PAGE",
      payload: true,
    });

    const res = await axios.put(`${ApiURL}page/update-page/${id}`, data);

    if (res.data.status === true) {
      dispatch({
        type: "UPDATE_PAGE_SUCCESS",
      });
      snackBar(res?.data?.message, res?.data?.status);
      return res;
    }
    snackBar(res?.data?.message, res?.data?.status);
  } catch (err) {
    snackBar(err?.response?.data?.message, err?.response?.data?.status);
    console.error(err);
  } finally {
    dispatch({
      type: "UPDATE_LOADING_PAGE",
      payload: false,
    });
  }
};

export const DeletePage = (id, handleClose) => async (dispatch) => {
  try {
    dispatch({
      type: "DELETE_PAGE_LOADING",
      payload: true,
    });
    const res = await axios.delete(`${ApiURL}page/delete-page/${id}`);

    if (res.data.status === true) {
      dispatch({
        type: "DELETE_PAGE_LOADING",
        payload: false,
      });
      dispatch({
        type: "DELETE_PAGE_SUCCESS",
        payload: res.data.data,
      });
      snackBar(res?.data?.message, res?.data?.status);
      handleClose();
      return res;
    }
    snackBar(res?.data?.message, res?.data?.status);
  } catch (err) {
    snackBar(err?.response?.data?.message, err?.response?.data?.status);
    console.error(err);
    dispatch({
      type: "DELETE_PAGE_LOADING",
      payload: false,
    });
  }
};
