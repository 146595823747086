import React from "react";
import Slider from "react-slick";
import image from "../../assets/image/banner/home-banner-1.png";
import image2 from "../../assets/image/banner/home-banner-2.png";

const data = [
  {
    bgImage: image,
  },
  {
    bgImage: image2,
  },
];

const SliderImage = () => {
  var settings = {
    dots: false,
    infinite: true,
    arrows: false,
    className: "hero-banner-arrow",
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "20%",
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          arrows: false,
          centerMode: false,
          centerPadding: "0%",
        },
      },
    ],
  };
  return (
    <div className="home-slider">
      <Slider {...settings}>
        {data.map((item) => (
          <div>
            <div
              className="box"
              style={{ width: "100%", display: "inline-block" }}
            >
              <img
                style={{ width: "100%" }}
                src={item?.bgImage}
                alt="Client logo"
              />
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default SliderImage;
