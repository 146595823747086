import React, { useState } from "react";

export function useForm(initialValues, validateOnChange = false, validate) {
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  
  const handleInputChange = (e) => {
  const { name, value, files } = e.target;
  if(files && files.length > 0){
    if(files.length > 1){
      setValues({
        ...values,
        [name]: value,
        file: files, // Spread the file from e.target.files[0]
      });
    } else {
      setValues({
        ...values,
        [name]: value,
        file: files[0], // Spread the file from e.target.files[0]
      });
    }
  } else {
    setValues({
      ...values,
      [name]: value,
    }); 
  }
  if (validateOnChange) validate({ [name]: value });
  };


  const resetForm = () => {
    setValues(initialValues);
    setErrors({});
  };

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  };
}

export function Form(props) {
  const { children, ...other } = props;
  return (
    <form autoComplete="off" {...other}>
      {props.children}
    </form>
  );
}
