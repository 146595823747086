import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import Input from "../../common/Input";
import { useFormik } from "formik";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import * as Yup from "yup";
import SelectBox from "../../common/SelectBox";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getChapterAll } from "../../redux/action/ChapterAction";
import {
  CreateBlog,
  GetBlogs,
  UpdateBlog,
} from "../../redux/action/BlogAction";
import axios from "axios";
import { ApiURL } from "../../redux/common";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ImageIcon from "@mui/icons-material/Image";
import { PlayArrow } from "@mui/icons-material";

const CreateBlogDetails = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("user_data")).token;
  const { title, description, blog_category, blog_images, chapter_id, _id } =
    location?.state?.eventData;
  const [selectedImage, setSelectedImage] = useState(blog_images || []);
  const [selectedImageError, setSelectedImageError] = useState(false);

  const handleImageUpload = (e) => {
    const files = e.target.files;
    setSelectedImageError(false);
    setSelectedImage((prev) => [...prev, ...files]);
  };

  const { getAllChapter } = useSelector((state) => state.ChapterReducer);
  const { createLoading, updateLoading } = useSelector(
    (state) => state.EventReducer
  );

  const allChapterData =
    getAllChapter &&
    getAllChapter?.chapterData?.map((el) => {
      return { value: el._id, label: el.chapter_name };
    });

  useEffect(() => {
    dispatch(getChapterAll());
  }, []);

  const [blogCategory, setBlogCategory] = useState([]);

  useEffect(() => {
    const getBlogCategory = async () => {
      try {
        await axios
          .get(`${ApiURL}blog-category/get-all-category`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            const allBlogCategory =
              res &&
              res?.data?.data?.map((el) => {
                return { value: el._id, label: el.category_name };
              });
            setBlogCategory(allBlogCategory);
          });
      } catch (error) {}
    };
    getBlogCategory();
  }, []);

  const removeSelectedImage = (indexToRemove) => {
    let imagesArray = [];
    if (selectedImage instanceof FileList) {
      imagesArray = Array.from(selectedImage);
    } else {
      imagesArray = Array.from(selectedImage);
    }

    const remainingImg = imagesArray.filter((item, i) => {
      return i !== indexToRemove;
    });
    setSelectedImage(remainingImg);
  };

  const changeSelectedImage = (e, changeIndex) => {
    const files = e.target.files;

    if (files && files[0]) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const updatedImages = [...selectedImage];
        updatedImages[changeIndex] = e.target.result;

        setSelectedImage(updatedImages);
      };

      reader.readAsDataURL(files[0]);
    }
  };

  const showImages = (selectedImage) => {
    let imagesArray = [];
    if (selectedImage instanceof FileList) {
      imagesArray = Array.from(selectedImage);
    } else {
      imagesArray = Array.from(selectedImage);
    }
    return (
      <>
        {imagesArray.map((item, i) => {
          let isImage = true;
          let fileType = "";

          const item_url = item instanceof File ? item?.name : item;
          if (typeof item_url === "string") {
            fileType = item_url.substring(item_url.lastIndexOf(".") + 1);
            isImage = fileType.match(/(jpg|jpeg|png|gif)/i);
          }
          return (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  borderBottom: "1px solid lightgray",
                  padding: "5px",
                }}
              >
                <div
                  style={{ display: "flex", flex: "1", alignItems: "center" }}
                >
                  {" "}
                  {/* Added flex: 1 to the first div to fill remaining space */}
                  {isImage ? (
                    <a
                      target="_blank"
                      href={
                        typeof item === "string"
                          ? item
                          : URL.createObjectURL(item)
                      }
                    >
                      <ImageIcon sx={{ marginRight: "0.5rem" }} />
                      <img
                        key={i}
                        style={{ width: 80 }}
                        src={
                          typeof item === "string"
                            ? item
                            : URL.createObjectURL(item)
                        }
                        alt={`Selected Image ${i + 1}`}
                        loading="lazy"
                        onError={(e) => {
                          console.error(`Error loading image ${i + 1}:`, e);
                        }}
                      />
                    </a>
                  ) : (
                    <a
                      target="_blank"
                      href={
                        typeof item === "string"
                          ? item
                          : URL.createObjectURL(item)
                      }
                    >
                      <PlayArrow sx={{ marginRight: "0.5rem" }} />
                      <video style={{ width: 80 }} loop muted playsInline>
                        <source
                          src={
                            typeof item === "string"
                              ? item
                              : URL.createObjectURL(item)
                          }
                          type={`video/${fileType}`}
                        />
                        Your browser does not support the video tag.
                      </video>
                    </a>
                  )}
                </div>
                <div style={{ marginLeft: "auto" }}>
                  {" "}
                  {/* Using marginLeft: "auto" to push the button to the end */}
                  <Button
                    color="error"
                    variant="outlined"
                    onClick={() => removeSelectedImage(i)}
                  >
                    X
                  </Button>
                  <br />
                </div>
                {location.state.formName === "Update Blog" ? (
                  <div style={{ marginLeft: "auto", paddingLeft: "5px" }}>
                    {" "}
                    {/* Using marginLeft: "auto" to push the button to the end */}
                    <input
                      type="file"
                      accept="image/*, video/*"
                      id={`change_images_${i}`}
                      name={`change_images_${i}`}
                      style={{ display: "none" }}
                      onChange={(e) => changeSelectedImage(e, i)}
                    />
                    <label htmlFor={`change_images_${i}`}>
                      <Button
                        component="span"
                        color="success"
                        variant="outlined"
                      >
                        Change
                      </Button>
                    </label>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </>
          );
        })}
      </>
    );
  };

  const UserSchema = Yup.object().shape({
    title: Yup.string()
      .trim()
      .required("Please enter blog title")
      .matches(/^\s*\S.*/, "Please enter blog title"),
    description: Yup.string().required("Please enter blog description"),
    blog_category: Yup.string().required("Please select blog category"),
    chapter_id: Yup.string().required("Please select chapter"),
  });

  const handleClose = () => {
    navigate("/admin-blog");
  };
  const formik = useFormik({
    initialValues: {
      title: title || "",
      description: description || "",
      blog_category: blog_category || "",
      chapter_id: chapter_id || "",
    },
    validationSchema: UserSchema,
    onSubmit: async (values, { resetForm }) => {
      // const birthDate = new Date(values.birth_date);
      // const formattedDate = birthDate.toISOString().split("T")[0];
      if (!selectedImage?.length) {
        setSelectedImageError(true);
        return;
      }
      const body = {
        title: values.title,
        description: values.description,
        blog_category: values.blog_category,
        chapter_id: values.chapter_id,
        blog_images: selectedImage,
      };
      const formData = new FormData();

      if (location.state.formName === "Create Blog") {
        formData.append("title", body.title);
        // if (selectEvent_type === "Chapter")
        formData.append("chapter_id", body.chapter_id);
        formData.append("blog_category", body.blog_category);
        if (body.description) formData.append("description", body.description);
        let imagesArray = [];
        if (selectedImage instanceof FileList) {
          imagesArray = Array.from(selectedImage);
        } else {
          imagesArray = Array.from([selectedImage]);
        }
        imagesArray.forEach((image, index) => {
          formData.append("blog_images", image);
        });
        // const imagesArray = Array.from(selectedImage);
        // imagesArray.forEach((image, index) => {
        //     formData.append("blog_images", image);
        // });
      } else {
        formData.append("title", body.title);
        // if (selectEvent_type === "Chapter")
        formData.append("chapter_id", body.chapter_id);
        formData.append("description", body.description);
        formData.append("blog_category", body.blog_category);
        let imagesArray = [];
        if (selectedImage instanceof FileList) {
          imagesArray = Array.from(selectedImage);
        } else {
          imagesArray = Array.from(selectedImage);
        }
        imagesArray.forEach((image, index) => {
          formData.append("blog_images", image);
        });
        // const imagesArray = Array.from(selectedImage);
        // imagesArray.forEach((image, index) => {
        //     formData.append("blog_images", image);
        // });
      }

      const resetInitForm = () => {
        resetForm();
        setSelectedImage(null);
      };

      if (location.state.formName === "Create Blog") {
        dispatch(CreateBlog(formData));
        resetInitForm();
        handleClose();
      } else {
        await dispatch(UpdateBlog(_id, formData)).then((res) => {
          if (res && res.data.status === true) {
            navigate("/admin-blog");
            dispatch(GetBlogs(1, ""));
          }
        });
      }
    },
  });

  ClassicEditor.defaultConfig = {
    toolbar: {
      items: [
        "heading",
        "|",
        "bold",
        "italic",
        "blockQuote",
        "|",
        "bulletedList",
        "numberedList",
        "outdent",
        "indent",
        "|",
        "insertTable",
        "|",
        "undo",
        "redo",
      ],
    },
    image: {
      toolbar: [
        "imageStyle:full",
        "imageStyle:side",
        "|",
        "imageTextAlternative",
      ],
    },
    table: {
      contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
    },
    language: "en",
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          margin: "3rem",
          height: "100vh",
        }}
      >
        <ArrowCircleLeftOutlinedIcon
          fontSize="large"
          sx={{ cursor: "pointer" }}
          onClick={() => navigate("/admin-blog")}
        />
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid item md={8} xs={12} sm={12}>
            <Grid item md={12}>
              <Box component={Paper} padding={{ xs: 2, sm: 3 }} elevation={5}>
                <form onSubmit={formik.handleSubmit}>
                  <Typography
                    textAlign="center"
                    fontWeight={900}
                    fontSize={28}
                    marginBottom={2}
                    color="black"
                  >
                    {location.state.formName}
                  </Typography>

                  <Grid container spacing={2}>
                    <Grid item md={12} xs={12} sm={12}>
                      <Input
                        id="title"
                        name="title"
                        type="text"
                        maxLength={100}
                        label="Blog Title"
                        onChange={formik.handleChange}
                        value={formik.values.title}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.title && Boolean(formik.errors.title)
                        }
                        helperText={
                          formik.touched.title && formik.errors.title ? (
                            <div className="err-msg err-msg-font">
                              {formik.errors.title}
                            </div>
                          ) : null
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item md={12} xs={12} sm={12}>
                      <div style={{ marginTop: "3px" }}>
                        <CKEditor
                          editor={ClassicEditor}
                          data={formik.values.description}
                          config={{
                            placeholder: "Add Description",
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            formik.setFieldValue("description", data);
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    {/* {formik.values.event_type === "Chapter" && ( */}
                    <Grid item md={12} xs={12} sm={12}>
                      <SelectBox
                        id="blog_category"
                        name="blog_category"
                        label="Blog Category"
                        ClassName="range-select-filter"
                        value={formik.values.blog_category}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        options={blogCategory || []}
                        error={
                          formik.touched.blog_category &&
                          Boolean(formik.errors.blog_category)
                        }
                        touched={formik.touched.blog_category}
                      />
                      {formik.touched.blog_category &&
                        formik.errors.blog_category && (
                          <div className="err-msg-font">
                            {formik.errors.blog_category}
                          </div>
                        )}
                    </Grid>
                    {/* )} */}
                  </Grid>
                  <Grid container spacing={2}>
                    {/* {formik.values.event_type === "Chapter" && ( */}
                    <Grid item md={12} xs={12} sm={12}>
                      <SelectBox
                        id="chapter_id"
                        name="chapter_id"
                        label="Chapter"
                        ClassName="range-select-filter"
                        value={formik.values.chapter_id}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        options={allChapterData || []}
                        error={
                          formik.touched.chapter_id &&
                          Boolean(formik.errors.chapter_id)
                        }
                        touched={formik.touched.chapter_id}
                      />
                      {formik.touched.chapter_id &&
                        formik.errors.chapter_id && (
                          <div className="err-msg-font">
                            {formik.errors.chapter_id}
                          </div>
                        )}
                    </Grid>
                    {/* )} */}
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      md={12}
                      xs={12}
                      sm={12}
                      sx={{
                        width: "100%",
                        maxHeight: "200px",
                        overflowX: "scroll",
                        scrollBehavior: "smooth",
                        marginTop: "40px",
                      }}
                    >
                      {selectedImage && selectedImage.length > 0
                        ? showImages(selectedImage)
                        : ""}
                    </Grid>
                    <Grid item md={4} xs={12} sm={12}>
                      <input
                        type="file"
                        multiple
                        accept="image/*, video/*"
                        id="service_images"
                        name="service_images"
                        style={{ display: "none" }}
                        onChange={handleImageUpload}
                      />
                      <label htmlFor="service_images">
                        <Button
                          component="span"
                          variant="contained"
                          color="inherit"
                          sx={{ marginTop: "1rem" }}
                        >
                          <ImageIcon sx={{ marginRight: "0.5rem" }} />
                          Upload Images
                        </Button>
                      </label>
                      {selectedImageError ? (
                        <div className="err-msg-font">
                          Please upload media files.
                        </div>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                  {(
                    location.state.formName === "Create Event"
                      ? createLoading
                      : updateLoading
                  ) ? (
                    <Button sx={{ marginTop: 4 }} variant="contained">
                      <CircularProgress
                        style={{ width: "25px", height: "25px", color: "#fff" }}
                      />
                    </Button>
                  ) : (
                    <Button
                      sx={{ marginTop: 4 }}
                      type="submit"
                      variant="contained"
                    >
                      Submit
                    </Button>
                  )}
                </form>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default CreateBlogDetails;
