import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import Input from "../../common/Input";
import { useFormik } from "formik";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import * as Yup from "yup";
import SelectBox from "../../common/SelectBox";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  createService,
  getAllService,
  updateService,
} from "../../redux/action/ServiceAction";
import MuiPhoneNumber from "material-ui-phone-number";

const statusOptions = [
  { value: "true", label: "Active" },
  { value: "false", label: "Inactive" },
];

const CreateServices = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    _id,
    service_name,
    service_price,
    contact_number,
    description,
    is_active,
    service_images,
  } = location?.state?.serviceData;
  const navigate = useNavigate();

  const [selectedImage, setSelectedImage] = useState(service_images);

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleImageUpload = (e) => {
    const files = e.target.files;
    if (selectedImage && selectedImage.length) {
      setSelectedImage([...selectedImage, ...files]);
    } else {
      setSelectedImage(files);
    }
  };

  const { createLoading, updateLoading } = useSelector(
    (state) => state.ServiceReducer
  );
  const [addedDiscritption, setAddedDiscritption] = useState(
    description && description.length
      ? description
      : [{ title: "", points: [""] }]
  );

  const removeSelectedImage = (indexToRemove) => {
    let imagesArray = [];
    if (selectedImage instanceof FileList) {
      imagesArray = Array.from(selectedImage);
    } else {
      imagesArray = Array.from(selectedImage);
    }

    const remainingImg = imagesArray.filter((item, i) => {
      return i !== indexToRemove;
    });
    setSelectedImage(remainingImg);
  };

  const changeSelectedImage = (e, changeIndex) => {
    const files = e.target.files;

    if (files && files[0]) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const updatedImages = [...selectedImage];
        updatedImages[changeIndex] = e.target.result;

        setSelectedImage(updatedImages);
      };

      reader.readAsDataURL(files[0]);
    }
  };

  const showImages = (selectedImage) => {
    let imagesArray = [];
    if (selectedImage instanceof FileList) {
      imagesArray = Array.from(selectedImage);
    } else {
      imagesArray = Array.from(selectedImage);
    }
    return (
      <>
        {imagesArray.map((item, i) => {
          return (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  borderBottom: "1px solid lightgray",
                  padding: "5px",
                }}
              >
                <div style={{ flex: "1" }}>
                  {" "}
                  <a
                    target="_blank"
                    href={
                      typeof item === "string"
                        ? item
                        : URL.createObjectURL(item)
                    }
                  >
                    <img
                      key={i}
                      style={{ width: 80 }}
                      src={
                        typeof item === "string"
                          ? item
                          : URL.createObjectURL(item)
                      }
                      alt={`Selected Image ${i + 1}`}
                      loading="lazy"
                      onError={(e) => {
                        console.error(`Error loading image ${i + 1}:`, e);
                      }}
                    />
                  </a>
                </div>
                <div style={{ marginLeft: "auto" }}>
                  {" "}
                  {/* Using marginLeft: "auto" to push the button to the end */}
                  <Button
                    color="error"
                    variant="outlined"
                    onClick={() => removeSelectedImage(i)}
                  >
                    X
                  </Button>
                  <br />
                </div>
                {location.state.formName === "Update Service" ? (
                  <div style={{ marginLeft: "auto", paddingLeft: "5px" }}>
                    {" "}
                    {/* Using marginLeft: "auto" to push the button to the end */}
                    <input
                      type="file"
                      accept="image/*, video/*"
                      id={`change_images_${i}`}
                      name={`change_images_${i}`}
                      style={{ display: "none" }}
                      onChange={(e) => changeSelectedImage(e, i)}
                    />
                    <label htmlFor={`change_images_${i}`}>
                      <Button
                        component="span"
                        color="success"
                        variant="outlined"
                      >
                        Change
                      </Button>
                    </label>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </>
          );
        })}
      </>
    );
  };

  const UserSchema = Yup.object().shape({
    service_name: Yup.string()
      .trim()
      .required("Please enter service name")
      .matches(/^\s*\S.*/, "Please enter service name"),
    // description: Yup.string().required("Please enter description"),
    service_price: Yup.string().required("Please select service price"),
    contact_number: Yup.string().required("Please select contact number"),
  });

  const handleClose = () => {
    navigate("/admin-services");
  };
  const formik = useFormik({
    initialValues: {
      service_name: service_name || "",
      contact_number: /^\d{10}$/.test(contact_number)
        ? `+91 ${contact_number && String(contact_number).slice(0, 5)}-${
            contact_number && String(contact_number).slice(5)
          }`
        : contact_number || "",
      service_price: service_price || "",
      description: description?.[0] || "",
      is_active: is_active,
    },
    validationSchema: UserSchema,
    onSubmit: async (values, { resetForm }) => {
      const body = {
        service_name: values.service_name,
        service_price: values.service_price,
        contact_number: values.contact_number,
        description: addedDiscritption,
        service_images: selectedImage,
        is_active: values.is_active,
      };
      const formData = new FormData();

      if (location.state.formName === "Create Services") {
        formData.append("service_name", body.service_name);
        formData.append(
          "contact_number",
          body.contact_number.replace(/\+91|\s|-/g, "")
        );
        formData.append("service_price", body.service_price);
        let imagesArray = [];
        imagesArray = Array.from(selectedImage);
        imagesArray.forEach((image, index) => {
          formData.append("service_images", image);
        });
        if (body.description)
          formData.append("description", JSON.stringify(body.description));
      } else {
        formData.append("service_name", body.service_name);
        formData.append(
          "contact_number",
          body.contact_number.replace(/\+91|\s|-/g, "")
        );
        formData.append("service_price", body.service_price);
        let imagesArray = [];
        if (selectedImage instanceof FileList) {
          imagesArray = Array.from(selectedImage);
        } else {
          imagesArray = Array.from(selectedImage);
        }
        imagesArray.forEach((image, index) => {
          formData.append("service_images", image);
        });
        // if(!imagesArray?.length){
        //   formData.append("service_images", undefined);
        // }
        if (body.description)
          formData.append("description", JSON.stringify(body.description));
        if (body.is_active) formData.append("is_active", body.is_active);
      }

      const resetInitForm = () => {
        resetForm();
        setSelectedImage(null);
      };
      
      if (location.state.formName === "Create Services") {
        await dispatch(createService(formData)).then((res) => {
          if (res?.data?.status === true) {
            navigate("/admin-services");
            dispatch(getAllService(1, "", rowsPerPage));
          }
        });
        resetInitForm();
        handleClose();
      } else {
        await dispatch(updateService(_id, formData)).then((res) => {
          if (res?.data?.status === true) {
            navigate("/admin-services");
            dispatch(getAllService(1, "", rowsPerPage));
          }
        });
      }
    },
  });

  const handleRemoveAddedDesc = (ind, subDataInd) => {
    setAddedDiscritption((prev) => {
      const data = prev.map((dataobj, index) => {
        if (ind == index) {
          return {
            title: dataobj.title,
            points: dataobj["points"].filter(
              (data, indexx) => indexx !== subDataInd
            ),
          };
        }
        return dataobj;
      });
      return data;
    });
  };
  const handleDiscriptionAdd = () => {
    setAddedDiscritption([...addedDiscritption, { title: "", points: [] }]);
  };
  const addSubDesc = (dataInd) => {
    setAddedDiscritption((prev) => {
      const data = prev.map((dataobj, index) => {
        if (dataInd == index) {
          return {
            title: dataobj.title,
            points: [...dataobj["points"], ""],
          };
        }
        return dataobj;
      });
      return data;
    });
  };

  const handleRemoveAddedDiscription = (index) => {
    setAddedDiscritption((prev) => {
      const data = prev.filter((dataobj, previndex) => previndex !== index);
      return data;
    });
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          margin: "3rem",
          height: "100vh",
        }}
      >
        <ArrowCircleLeftOutlinedIcon
          fontSize="large"
          sx={{ cursor: "pointer" }}
          onClick={() => navigate("/admin-services")}
        />
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid item md={8} xs={12} sm={12}>
            <Grid item md={12}>
              <Box component={Paper} padding={{ xs: 2, sm: 3 }} elevation={5}>
                <form onSubmit={formik.handleSubmit}>
                  <Typography
                    textAlign="center"
                    fontWeight={900}
                    fontSize={28}
                    marginBottom={2}
                    color="black"
                  >
                    {location.state.formName}
                  </Typography>

                  <Grid container spacing={2}>
                    <Grid item md={12} xs={12} sm={12}>
                      <Input
                        id="service_name"
                        name="service_name"
                        type="text"
                        maxLength={100}
                        label="Service Name"
                        onChange={formik.handleChange}
                        value={formik.values.service_name}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.service_name &&
                          Boolean(formik.errors.service_name)
                        }
                        helperText={
                          formik.touched.service_name &&
                          formik.errors.service_name ? (
                            <div className="err-msg err-msg-font">
                              {formik.errors.service_name}
                            </div>
                          ) : null
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item md={12} xs={12} sm={12}>
                      <Input
                        id="service_price"
                        name="service_price"
                        type="number"
                        maxLength={100}
                        label="Service Price"
                        onChange={formik.handleChange}
                        value={formik.values.service_price}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.service_price &&
                          Boolean(formik.errors.service_price)
                        }
                        helperText={
                          formik.touched.service_price &&
                          formik.errors.service_price ? (
                            <div className="err-msg err-msg-font">
                              {formik.errors.service_price}
                            </div>
                          ) : null
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item md={12} xs={12} sm={12}>
                      <MuiPhoneNumber
                        sx={{ marginTop: "1em" }}
                        fullWidth
                        defaultCountry={"in"}
                        size="small"
                        variant="outlined"
                        id="contact_number"
                        name="contact_number"
                        label="Contact Number"
                        type="text"
                        onBlur={formik.handleBlur}
                        onChange={(value) => {
                          formik.setFieldValue("contact_number", value);
                        }}
                        value={formik.values.contact_number}
                        error={formik.errors.contact_number}
                        helperText={
                          formik.touched.contact_number &&
                          formik.errors.contact_number ? (
                            <div className="err-msg-mobile err-msg-font">
                              {formik.errors.contact_number}
                            </div>
                          ) : null
                        }
                        InputLabelProps={{ sx: { color: "black" } }}
                        // sx={{
                        //   "& .MuiOutlinedInput-root:hover": {
                        //     "& > fieldset": {
                        //       borderColor: "black",
                        //     },
                        //   },
                        //   "&.Mui-focused .MuiOutlinedInput-root": {
                        //     borderColor: "#4f46e5!important",
                        //   },
                        //   "& .MuiOutlinedInput-root": {
                        //     "&.Mui-focused fieldset": {
                        //       borderColor: "#4f46e5!important",
                        //     },
                        //     "& fieldset": {
                        //       borderColor: "black!important",
                        //     },
                        //     color: "black!important",
                        //     mb: 1,
                        //   },
                        //   mt: 1,
                        //   borderRadius: "12px",
                        // }}
                      />
                      {/* <Input
                        id="contact_number"
                        name="contact_number"
                        type="number"
                        maxLength={10}
                        label="Contact Number"
                        onChange={formik.handleChange}
                        value={formik.values.contact_number}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.contact_number &&
                          Boolean(formik.errors.contact_number)
                        }
                        helperText={
                          formik.touched.contact_number &&
                            formik.errors.contact_number ? (
                            <div className="err-msg err-msg-font">
                              {formik.errors.contact_number}
                            </div>
                          ) : null
                        }
                      /> */}
                    </Grid>
                  </Grid>
                  {/* description code started */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "10px",
                    }}
                  >
                    <Typography variant="h6" sx={{ marginTop: "0.5rem" }}>
                      Service Description:
                    </Typography>
                    <Button variant="contained" onClick={handleDiscriptionAdd}>
                      + Add Service
                    </Button>
                  </div>

                  {addedDiscritption.map((data, index) => {
                    return (
                      <>
                        <div
                          style={{
                            border: index !== 0 ? "2px solid lightgray" : "",
                            padding: index !== 0 ? "5px" : "",
                            borderRadius: index !== 0 ? "5px" : "",
                            marginTop: index !== 0 ? "10px" : "",
                            marginBottom: index !== 0 ? "10px" : "",
                          }}
                        >
                          <div
                            key={index}
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Grid container spacing={2}>
                              <Grid
                                sx={{ display: "flex", justifyContent: "end" }}
                                item
                                md={12}
                                xs={12}
                                sm={12}
                              >
                                {index !== 0 && (
                                  <Button
                                    color="error"
                                    variant="contained"
                                    style={{
                                      marginLeft: "10px",
                                      border: "2px solid",
                                    }}
                                    onClick={() =>
                                      handleRemoveAddedDiscription(index)
                                    }
                                  >
                                    X
                                  </Button>
                                )}
                              </Grid>
                              <Grid item md={12} xs={12} sm={12}>
                                <Input
                                  name={`description`}
                                  value={addedDiscritption[index].title}
                                  type="text"
                                  label="Title"
                                  maxLength={500}
                                  onChange={(e) => {
                                    setAddedDiscritption((prev) => {
                                      const data = prev.map(
                                        (dataobj, indofprev) => {
                                          if (indofprev == index) {
                                            return {
                                              title: e.target.value,
                                              points: dataobj["points"],
                                            };
                                          }
                                          return dataobj;
                                        }
                                      );
                                      return data;
                                    });
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </div>
                          {data["points"].map((field, subIndex) => (
                            <div
                              key={subIndex}
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Input
                                maxRows={2}
                                type="text"
                                label="Description"
                                value={
                                  addedDiscritption[index]["points"][subIndex]
                                }
                                onChange={(e) => {
                                  setAddedDiscritption((prev) => {
                                    const data = prev.map(
                                      (dataobj, indofprev) => {
                                        if (indofprev == index) {
                                          return {
                                            title: dataobj.title,
                                            points: dataobj["points"].map(
                                              (data, subpoiIndex) => {
                                                if (subpoiIndex == subIndex) {
                                                  return e.target.value;
                                                }
                                                return data;
                                              }
                                            ),
                                          };
                                        }
                                        return dataobj;
                                      }
                                    );
                                    return data;
                                  });
                                }}
                              />
                              <Button
                                color="error"
                                variant="outlined"
                                style={{
                                  marginLeft: "10px",
                                  border: "2px solid",
                                }}
                                onClick={() =>
                                  handleRemoveAddedDesc(index, subIndex)
                                }
                              >
                                X
                              </Button>
                            </div>
                          ))}
                          <div
                            style={{ display: "flex", justifyContent: "end" }}
                          >
                            <Button
                              color="success"
                              variant="contained"
                              style={{
                                marginLeft: "10px",
                                border: "2px solid",
                              }}
                              onClick={() => addSubDesc(index)}
                            >
                              + Add Sub Desc...
                            </Button>
                          </div>
                        </div>
                      </>
                    );
                  })}
                  {/* description code ended */}

                  {location.state.formName === "Update Service" ? (
                    <Grid container spacing={2}>
                      <Grid
                        item
                        md={12}
                        xs={12}
                        sm={12}
                        sx={{
                          width: "100%",
                          maxHeight: "200px",
                          overflowX: "scroll",
                          scrollBehavior: "smooth",
                          marginTop: "40px",
                        }}
                      >
                        <div style={{ marginTop: "5px" }}>
                          <SelectBox
                            type="text"
                            id="is_active"
                            name="is_active"
                            label="Status"
                            ClassName="range-select-filter"
                            onChange={formik.handleChange}
                            value={formik.values.is_active}
                            // defaultValue={statusModal?.status || "pending"}
                            options={statusOptions}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  ) : null}

                  <Grid container spacing={2}>
                    <Grid
                      item
                      md={12}
                      xs={12}
                      sm={12}
                      sx={{
                        width: "100%",
                        maxHeight: "200px",
                        overflowX: "scroll",
                        scrollBehavior: "smooth",
                        marginTop: "40px",
                      }}
                    >
                      {selectedImage && selectedImage.length > 0
                        ? showImages(selectedImage)
                        : ""}
                    </Grid>
                    <Grid item md={3} xs={12} sm={12}>
                      <input
                        type="file"
                        multiple
                        accept="image/*, video/*"
                        id="service_images"
                        name="service_images"
                        style={{ display: "none" }}
                        onChange={handleImageUpload}
                      />
                      <label htmlFor="service_images">
                        <Button
                          component="span"
                          variant="contained"
                          color="inherit"
                          sx={{ marginTop: "1rem" }}
                        >
                          Service Images
                        </Button>
                      </label>
                    </Grid>
                  </Grid>
                  {(
                    location.state.formName === "Create Services"
                      ? createLoading
                      : updateLoading
                  ) ? (
                    <Button sx={{ marginTop: 4 }} variant="contained">
                      <CircularProgress
                        style={{ width: "25px", height: "25px", color: "#fff" }}
                      />
                    </Button>
                  ) : (
                    <Button
                      sx={{ marginTop: 4 }}
                      type="submit"
                      variant="contained"
                    >
                      Submit
                    </Button>
                  )}
                </form>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default CreateServices;
