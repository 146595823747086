import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Pagination,
  Typography,
  Drawer,
  Stack,
  Divider,
  Button,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import PageHeader from "../pageHeader/PageHeader";
import EnhancedTable from "../table/EnhancedTable ";
import {
  DeleteIcon,
  EditIcon,
  CloseIcon,
  Visibility,
} from "../../assets/icons/Icons";
import "../../components/table/table.css";
import { useForm } from "../../common/useForms";
import FormDialog from "../dialog/dialog";
import { makeStyles } from "@material-ui/core/styles";
import Input from "../../common/Input";
import { useDispatch, useSelector } from "react-redux";
import DeleteDialog from "../dialog/deletedialog";
import { useNavigate } from "react-router-dom";
import useUserRolePermissions from "../../common/UserPermissions";
import axios from "axios";
import { ApiURL } from "../../redux/common";
import { deleteService, getAllService } from "../../redux/action/ServiceAction";
import ShowFirstImage from "../showFirstImage/ShowFirstImage";
import Loader from "../loader/Loader";
import { getPermissions } from "../../common/permissions";
import { permissions } from "../../common/constants";

const useStyles = makeStyles((theme) => ({
  filterMain: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "#fff",
  },
  mainFilterBox: {
    padding: "10px 20px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "200px",
    },

    [theme.breakpoints.up("md")]: {
      width: "300px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "240px !important",
    },
    [theme.breakpoints.up("xs")]: {
      width: "100%",
    },
  },

  stackFilter: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  typographyFilter: {
    fontWeight: "bold !important",
    color: "#000 !important",
    fontSize: "30px !important",
  },
  buttonFilter: {
    flexDirection: "row !important",
    marginTop: "8px",
    gap: "8px",
  },
}));

const initialValues = {
  country: "",
  state: "",
  city: "",
  postalcode: "",
  is_active: "",
};
const initialFilterValues = {
  service_name: "",
};

const RecordPerPage = [
  { id: 5, title: "5" },
  { id: 10, title: "10" },
  // { id: 15, title: "15" },
  // { id: 20, title: "20" },
  // { id: 30, title: "30" },
];

const ServicesListing = () => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState(false);
  const [filterData, setFilterData] = useState(true);
  const [filterDatas, setFilterDatas] = useState(initialFilterValues);
  const [selectPostal, setselectPostal] = useState(null);
  const [DeleteId, SetDeleteId] = useState(null);
  const [dialogTitle, setDialogTitle] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [downLoadData, setDownLoadData] = useState("");

  const { loading, service } = useSelector((state) => state?.ServiceReducer);

  const userRoleData = useUserRolePermissions("Membership");

  const isEditAllowed = userRoleData && userRoleData.is_edit === 1;
  const isDeleteAllowed = userRoleData && userRoleData.is_delete === 1;
  const isAddAllowed = getPermissions(permissions.Service, "is_add");

  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();

  const fetchServiceDetail = async () => {
    await dispatch(getAllService(page, "", rowsPerPage));
  };
  useEffect(() => {
    fetchServiceDetail();
  }, [page, rowsPerPage]);

  const columns = [
    {
      id: "service_name",
      numeric: false,
      disablePadding: true,
      label: "Service Name",
    },
    {
      id: "service_images",
      numeric: true,
      disablePadding: false,
      label: "Service Images ",
    },
    {
      id: "contact_number",
      numeric: false,
      disablePadding: true,
      label: "Contact Number ",
    },
    {
      id: "service_price",
      numeric: false,
      disablePadding: true,
      label: "Service Price",
    },
    {
      id: "view",
      numeric: true,
      disablePadding: false,
      label: "View",
    },
    {
      id: "is_active",
      numeric: true,
      disablePadding: false,
      label: "Is Active",
    },
    // (isEditAllowed || isDeleteAllowed) ?
    !!getPermissions(permissions.Service, "is_delete") ||
    !!getPermissions(permissions.Service, "is_edit")
      ? { id: "action", numeric: true, disablePadding: false, label: "Action" }
      : null,
  ].filter((el) => el !== null);

  const tableTitle = "ServicesList";

  const handleChangeRow = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleBtnClick = () => {
    navigate("/admin-services/create-services", {
      state: { formName: "Create Services", serviceData: {} },
    });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      // const newSelected = rows.map((n) => n.id);
      // setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true);

  const handleEditClick = (service) => {
    navigate(`/admin-services/update-services`, {
      state: { formName: "Update Service", serviceData: service },
    });
    setErrors({});
  };

  const handleClickOpenDelete = (service) => {
    setOpenDelete(true);
    SetDeleteId(service._id);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleSingleDelete = async () => {
    handleCloseDelete();
    await dispatch(deleteService(DeleteId));
    dispatch(getAllService(page, "", rowsPerPage));
  };

  //filter
  const resetFormFilter = () => {
    setFilterDatas(initialFilterValues);
    setPage(1);
    dispatch(getAllService(page, "", rowsPerPage));
    closeFilterDrawer();
  };

  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
    setFilterData(!filterData);
  };
  const closeFilterDrawer1 = () => {
    setFilter(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const numericRegex = /^\d+$/;

    if (name === "postalcode" && !numericRegex.test(value)) {
      return;
    }

    setFilterDatas({
      ...filterDatas,
      [name]: value,
    });
  };

  const handleSubmitFilter = (e) => {
    e.preventDefault();
    const service_name = filterDatas.service_name;
    setPage(1);
    dispatch(getAllService(page, service_name, rowsPerPage));
    closeFilterDrawer1();
  };

  const handleViewClick = (service) => {
    navigate("/admin-service-details", { state: { serviceData: service } });
  };

  const rows =
    service?.serviceData && Array.isArray(service?.serviceData)
      ? service?.serviceData.map((service) => {
          // const selected = service === selectPostal;
          return {
            _id: service?._id,
            service_name: service?.service_name,
            contact_number: service?.contact_number,
            service_price: service?.service_price,
            is_active: service?.is_active ? "Active" : "inActive",
            service_images: <ShowFirstImage images={service?.service_images} />,
            view: (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <IconButton
                  onClick={() => handleViewClick(service)}
                  sx={{ color: "black" }}
                >
                  <Visibility />
                </IconButton>
              </div>
            ),
            action: (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {!!getPermissions(permissions.Service, "is_edit") && (
                  <IconButton
                    onClick={() => handleEditClick(service)}
                    sx={{ color: "black" }}
                  >
                    <EditIcon />
                  </IconButton>
                )}
                {!!getPermissions(permissions.Service, "is_delete") && (
                  <IconButton
                    onClick={() => handleClickOpenDelete(service)}
                    sx={{ color: "black" }}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </div>
            ),
            // selected: selected,
          };
        })
      : [];

  const isAnyFieldFilled = Object.values(filterDatas).some((value) =>
    Boolean(value)
  );

  const DownLoadPdf = async () => {
    try {
      const res = await axios.get(`${ApiURL}membership/download-data`);
      setDownLoadData(res?.data?.data);
    } catch (error) {
      console.error("Error fetching role data:", error);
      return {};
    }
  };

  useEffect(() => {
    DownLoadPdf();
  }, []);

  return (
    <div>
      <Box>
        <PageHeader
          title="Services"
          isShowAdd={false}
          onBtnClick={handleBtnClick}
          btnText={isAddAllowed ? "Create Services" : ""}
          // onAddClick={handleClickOpen}
          onFilterClick={openFilterDrawer}
          href={downLoadData}
        />
        {/* {
        loading ? (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
            </Box>
        ) : ( */}
        {loading ? (
          <Loader />
        ) : (
          // <Box sx={{ display: "flex", justifyContent: "center" }}>
          //     <CircularProgress />
          // </Box>
          <EnhancedTable
            key={5}
            columns={columns}
            rows={rows}
            order={order}
            orderBy={orderBy}
            selected={selected}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            title={tableTitle}
            tableList={service && service?.serviceData}
          />
        )}
        {/* )} */}

        <DeleteDialog
          open={openDelete}
          // loading={deleteLoading}
          dialogTitle="Are you sure to want to delete this record?"
          handleClose={handleCloseDelete}
          handleDelete={() => handleSingleDelete(DeleteId)}
        />

        <Box
          sx={{
            display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
            justifyContent: "space-between",
            alignItems: "center",
            marginLeft: { xs: "10px", sm: "20px", md: "20px", lg: "20px" },
            marginRight: { xs: "10px", sm: "15px", md: "15px", lg: "15px" },
            mt: 2,
            mb: 2,
          }}
        >
          <Box
            sx={{
              fontSize: "14px",
              marginBottom: { xs: "10px", sm: "0", md: "0", lg: "0" },
              gap: 2,
              width: { sm: "100%", md: "50%" },
            }}
            display="flex"
            alignItems="center"
          >
            <Box>
              {/* {" "}
                Showing {membershipData?.currentPage} of{" "}
                {membershipData?.totalPages} Pages */}
            </Box>
            <Box display="flex" sx={{ gap: 1 }} alignItems="center">
              <Typography>Records per page</Typography>

              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <Select
                  id="limites"
                  name="limites"
                  value={rowsPerPage}
                  onChange={handleChangeRow}
                  displayEmpty
                  sx={{ maxWidth: "65px", maxHeight: "2.2rem" }}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {RecordPerPage?.map((item) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name || item.title}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Pagination
            count={service && service?.totalPages}
            page={page}
            onChange={handleChangePage}
            variant="outlined"
            shape="rounded"
            sx={{
              "& .MuiButtonBase-root ": {
                color: "#000 !important",
              },
              "& .Mui-selected ": {
                background: "#4b4b4e70 !important",
              },
              "& .Mui-disabled ": {
                background: "#6c696966 !important",
                color: "#000000 !important",
                opacity: 1,
              },
            }}
          />
        </Box>

        <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
          <form onSubmit={handleSubmitFilter} className={classes.filterMain}>
            <Box className={classes.mainFilterBox}>
              <Stack className={classes.stackFilter}>
                <Typography className={classes.typographyFilter}>
                  Filter
                </Typography>

                <CloseIcon
                  className="close-button"
                  onClick={closeFilterDrawer1}
                />
              </Stack>
              <Box
                sx={{
                  marginTop: "5px",
                }}
              >
                <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />
              </Box>

              <div>
                <Box>
                  <Input
                    variant="outlined"
                    color="primary"
                    label="Service Name"
                    name="service_name"
                    value={filterDatas.service_name}
                    onChange={handleChange}
                    maxLength={200}
                  />
                </Box>
                <Stack className={classes.buttonFilter}>
                  <Button
                    color="secondary"
                    size="large"
                    variant="contained"
                    className="cancel-filter"
                    onClick={resetFormFilter}
                  >
                    Reset
                  </Button>
                  <Button
                    color="primary"
                    size="large"
                    variant="contained"
                    className="order-filter-button"
                    type="submit"
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    Filter
                  </Button>
                </Stack>
              </div>
            </Box>
          </form>
        </Drawer>
      </Box>
    </div>
  );
};

export default ServicesListing;
