import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import Input from "../../common/Input";
import { useFormik } from "formik";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CreateNotifications,
  GetAllNotifications,
  UpdateNotification,
} from "../../redux/action/NotificationActions";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";

const CreateNotification = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { _id, title, message } = location?.state?.userData;
  const editorConfiguration = {
    // Specify the configuration options here
    // removePlugins: 'MediaEmbed,ImageUpload', // Remove MediaEmbed and ImageUpload plugins
    // Other configurations as needed
  };

  const UserSchema = Yup.object().shape({
    title: Yup.string()
      .required("Please enter first name")
      .matches(/^\s*\S.*/, "Please enter first name"),
    message: Yup.string()
      .required("Please enter last name")
      .matches(/^\s*\S.*/, "Please enter last name"),
  });

  const formik = useFormik({
    initialValues: {
      title: title || "",
      message: message || "",
    },
    // validationSchema: UserSchema,
    onSubmit: (values, { resetForm }) => {
      const body = {
        title: values.title,
        message: values.message,
      };

      const resetInitForm = () => {
        resetForm();
      };

      if (location.state.formName === "Create Notification") {
        dispatch(CreateNotifications(body));
        resetForm();
        navigate("/admin-notification");
        resetInitForm();
      } else {
        dispatch(UpdateNotification(_id, body));
        navigate("/admin-notification");
        dispatch(GetAllNotifications(1, ""));
      }
    },
  });

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          margin: "3rem",
          height: "100vh",
        }}
      >
        <ArrowCircleLeftOutlinedIcon
          fontSize="large"
          sx={{ cursor: "pointer" }}
          onClick={() => navigate("/admin-notification")}
        />
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid item md={8} xs={12} sm={12}>
            <Grid item md={12}>
              <Box component={Paper} padding={{ xs: 2, sm: 3 }} elevation={5}>
                <form onSubmit={formik.handleSubmit}>
                  <Typography
                    textAlign="center"
                    fontWeight={900}
                    fontSize={28}
                    marginBottom={2}
                    color="black"
                  >
                    {location.state.formName}
                  </Typography>

                  <Grid container spacing={2}>
                    <Grid item md={12} xs={12} sm={12}>
                      <Input
                        id="title"
                        name="title"
                        type="text"
                        maxLength={50}
                        label="Title"
                        onChange={formik.handleChange}
                        value={formik.values.title}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.title && Boolean(formik.errors.title)
                        }
                        helperText={
                          formik.touched.title && formik.errors.title ? (
                            <div className="err-msg err-msg-font">
                              {formik.errors.title}
                            </div>
                          ) : null
                        }
                      />
                    </Grid>

                    {/* <Grid item md={12} xs={12} sm={12}>
                      <Input
                        id="message"
                        name="message"
                        type="text"
                        label="Message"
                        maxLength={50}
                        onChange={formik.handleChange}
                        value={formik.values.message}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.message &&
                          Boolean(formik.errors.message)
                        }
                        helperText={
                          formik.touched.message && formik.errors.message ? (
                            <div className="err-msg err-msg-font">
                              {formik.errors.message}
                            </div>
                          ) : null
                        }
                      />
                    </Grid> */}
                    <Grid
                      container
                      spacing={2}
                      sx={{
                        padding: "16px",
                        // paddingLeft: "34px",
                        paddingRight: "3px",
                      }}
                    >
                      <Grid item md={12} xs={12} sm={12}>
                        <div style={{ marginTop: "25px" }}>
                          <CKEditor
                            editor={ClassicEditor}
                            config={{
                              editorConfiguration,
                              placeholder: "Add message",
                            }}
                            data={formik.values.message} // Set initial data from formik
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              formik.setFieldValue("message", data); // Update formik value
                            }}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Button
                    sx={{ marginTop: 4 }}
                    type="submit"
                    variant="contained"
                  >
                    Submit
                  </Button>
                </form>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default CreateNotification;
