import {
  Box,
  Button,
  CardMedia,
  CircularProgress,
  Dialog,
  Grid,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import PropTypes from "prop-types";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Logo from "../../assets/image/blog/Login.svg";
import { LoginApi } from "../../redux/authAction";
import { v4 as uuidv4 } from "uuid";
import Cookies from "js-cookie";
import { useSnackbar } from "notistack";
import { snackBar } from "../../utils/common";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    alignItems: "center",
    marginTop: "50px",
  },
  subMain: {
    margin: "0 auto",
    backgroundColor: "#e4e4e4",
    borderRadius: "20px",
  },

  mainCard: {
    boxShadow: "none !important",
    backgroundColor: "#fff !important",
    color: "#000 !important",
    display: "block",
    textAlign: "center",
  },
  boxCard: {
    width: "360px",
    padding: "40px",
  },
  typography: {
    fontSize: "25px !important",
    fontWeight: "600 !important",
    color: "rgb(255, 87, 34)",
  },
}));

const Login = ({ open, handleClose }) => {
  const uuid = uuidv4();

  const rememberMeCookieString = Cookies?.get("rememberMe");

  let rememberMeCookie = {};
  if (rememberMeCookieString) {
    try {
      rememberMeCookie = JSON.parse(rememberMeCookieString);
    } catch (error) {
      console.error("Error parsing JSON:", error);
    }
  }

  const initialValues = {
    email: rememberMeCookie?.email || "",
    mobile_number: rememberMeCookie?.mobile_number || "",
    password: rememberMeCookie?.password || "",
  };

  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [data, setData] = useState({});
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validationSchema = Yup.object({
    ...(!data?.mobile_number && {
      email: Yup.string()
        .email("Invalid email address")
        .required("Please enter email"),
    }),
    ...(!data?.email && {
      mobile_number: Yup.string()
        .required("Please enter mobile")
        .matches(/^[0-9]+$/, "Mobile number must contain only numbers"),
    }),
    password: Yup.string()
      .required("Please enter password")
      .min(6, "Password must be at least 6 characters"),
  });

  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    try {
      const loginData = values.email
        ? { password: values.password, email: values.email, device_id: uuid }
        : {
            password: values.password,
            mobile_number: values.mobile_number,
            device_id: uuid,
          };
      if (rememberMe) {
        Cookies.set("rememberMe", JSON.stringify(loginData), { expires: 7 });
      }
      const response = await LoginApi(loginData);
      if (response?.status === true) {
        handleClose();
        resetForm();
        snackBar(response?.message, response?.status);
        dispatch({
          type: "AUTH_SUCCESS",
          payload: response,
        });
        navigate("/admin/dashboard");
      } else {
        console.log("errordataresponse", response);
        snackBar("Please provide valid password!", response?.status);
      }
    } catch (error) {
      snackBar("Please provide valid password!");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div>
      <div className="food-login">
        <Box component="form" noValidate sx={{ width: "600px" }}>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <Grid
              container
              component="main"
              width="100%"
              sx={{
                padding: "55px",
                backgroundColor: "white",
                // height: "700px",
              }}
            >
              <Grid
                item
                xs={false}
                sm={4}
                md={5}
                px={2}
                justifyContent="center"
                display="flex"
                alignItems="center"
              >
                <CardMedia
                  component="img"
                  src={Logo}
                  alt="Live from space album cover"
                  sx={{
                    width: "100%",
                    display: { xs: "none", sm: "block" },
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={8}
                md={7}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="left"
              >
                <Box
                  sx={{
                    my: 1,
                    mx: 2,
                    display: "flex",
                    flexDirection: "column",
                    width: { xs: "212px", md: "300px" },
                  }}
                >
                  <Typography component="h1" variant="h5">
                    Welcome to GGC
                  </Typography>
                  <Typography variant="body2" mb={2}>
                    Login to your account
                  </Typography>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({
                      isSubmitting,
                      values,
                      handleChange,
                      setFieldError,
                      errors,
                      setErrors,
                      ...rest
                    }) => {
                      setData(values);
                      return (
                        <Form>
                          <div className="form-group mb-2">
                            <Field
                              type="text"
                              // id="email"
                              name="email"
                              placeholder="Email"
                              className="email-input"
                              style={{
                                width: "100%",
                                height: "40px",
                                borderRadius: "5px",
                                border: "1px solid #dee2e6",
                                paddingLeft: "10px",
                              }}
                              disabled={values.mobile_number}
                              maxLength={40}
                            />
                            <ErrorMessage
                              name="email"
                              component="div"
                              style={{
                                marginTop: "5px",
                                color: "red",
                                fontSize: "15px",
                              }}
                            />
                          </div>
                          <Typography
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              marginBottom: "10px",
                            }}
                          >
                            OR
                          </Typography>

                          <div className="form-group mb-2">
                            <Field
                              type="text"
                              id="mobile_number"
                              name="mobile_number"
                              placeholder="Mobile Number"
                              className="mobile_number-input"
                              style={{
                                width: "100%",
                                height: "40px",
                                borderRadius: "5px",
                                border: "1px solid #dee2e6",
                                paddingLeft: "10px",
                              }}
                              maxLength={10}
                              disabled={values.email}
                            />
                            <ErrorMessage
                              name="mobile_number"
                              component="div"
                              style={{
                                marginTop: "5px",
                                color: "red",
                                fontSize: "15px",
                              }}
                            />
                          </div>
                          <div
                            style={{ position: "relative" }}
                            className="form-group mb-2"
                          >
                            <Field
                              type={showPassword ? "text" : "password"}
                              id="password"
                              name="password"
                              placeholder="Password"
                              className="password-input"
                              style={{
                                width: "100%",
                                height: "40px",
                                borderRadius: "5px",
                                border: "1px solid #dee2e6",
                                paddingLeft: "10px",
                              }}
                              maxLength={30}
                            />
                            <IconButton
                              sx={{
                                position: "absolute",
                                top: "0px",
                                right: "0px",
                              }}
                              onClick={togglePasswordVisibility}
                              aria-label={
                                showPassword ? "Hide password" : "Show password"
                              }
                            >
                              {showPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>

                            <ErrorMessage
                              name="password"
                              component="div"
                              style={{
                                color: "red",
                                fontSize: "15px",
                              }}
                            />
                          </div>
                          <div className="form-group mb-6">
                            <div className="custom-control custom-checkbox">
                              <input
                                value={rememberMe}
                                type="checkbox"
                                className="custom-control-input"
                                id="check"
                                onClick={(e) => setRememberMe(e.target.checked)}
                              />
                              <label
                                className="custom-control-label text-dark"
                                htmlFor="check"
                              >
                                Remember
                              </label>
                            </div>
                          </div>
                          <button
                            type="submit"
                            disabled={isSubmitting}
                            className="btn btn-primary btn-block font-weight-bold text-uppercase font-size-lg rounded-sm"
                          >
                            {isSubmitting ? (
                              <CircularProgress color="inherit" size="26px" />
                            ) : (
                              "Log In"
                            )}
                          </button>

                          <Link
                            to="be-member-form"
                            variant="body2"
                            onClick={handleClose}
                            style={{ marginTop: "15px" }}
                          >
                            {"Don't have an account? Sign Up"}
                          </Link>
                          {/* </Grid> */}

                          {/* <Link className="mb-8" to="be-member-form">
                            Sign Up / Register
                          </Link> */}
                        </Form>
                      );
                    }}
                  </Formik>
                </Box>
              </Grid>
            </Grid>
          </Dialog>
        </Box>
      </div>
    </div>
  );
};

export default Login;
