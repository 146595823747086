import React, { useEffect } from "react";
import { Autocomplete, Box, Button, Grid, Paper, TextField, Typography } from "@mui/material";
import Input from "../../../common/Input";
import { useFormik } from "formik";
import * as Yup from "yup";
import { GetAllStates, cleareAllStates } from "../../../redux/action/RoleandPermissionAction";
import { useDispatch, useSelector } from "react-redux";
import { GetAllCities, cleareAllCities } from "../../../redux/action/CityAction";
import { GetAllPostalCodes, clearePostalCodes } from "../../../redux/action/PostalCodeAction";
import { GetAllCountry } from "../../../redux/action/CountryAction";


const validationSchemaForm = Yup.object().shape({
    address: Yup.string()
        .required("Please enter address")
        .matches(/^\s*\S.*/, "Please enter address"),
    country: Yup.object().required("Please select country"),
    state: Yup.object().required("Please select state"),
    city: Yup.object().required("Please select city"),
    pincode: Yup.object().required("Please select pincode"),
});


const Step3 = ({ handleBack, handleNext, activeStep, value }) => {
    const dispatch = useDispatch();

    const { allCountryData } = useSelector((state) => state.CountryReducer);
    const { allStateData } = useSelector((state) => state.StateReducer);
    const { allCitiesData } = useSelector((state) => state.CityReducer);
    const { allPostalData } = useSelector((state) => state.PostalCodeReducer);

    useEffect(() => {
        dispatch(GetAllCountry());
    }, []);

    const formik = useFormik({
        initialValues: {
            address: value.address || '',
            postalcode_id: value.postalcode_id || '',
            state: value.state || '',
            city: value.city || '',
            country: value.country || '',
            pincode: value.pincode || '',
        },
        validationSchema: validationSchemaForm,
        onSubmit: (values) => {
            // You can perform form submission here
            handleNext(values);
        },
    });
    return (
        <>
            <Box
                component={Paper}
                marginX={30}
                padding={5}
            // sx={{ backgroundColor: "green" }}
            >
                <Typography
                    textAlign="center"
                    fontWeight={900}
                    fontSize={28}
                    marginBottom={2}
                    color="black"
                >
                    Contact Information
                </Typography>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item md={12} xs={12} sm={12}>
                            <Input
                                id="address"
                                name="address"
                                type="text"
                                label="Address"
                                onChange={formik.handleChange}
                                value={formik.values.address}
                                onBlur={formik.handleBlur}
                                maxLength={500}
                                error={
                                    formik.touched.address &&
                                    Boolean(formik.errors.address)
                                }
                                helperText={
                                    formik.touched.address && formik.errors.address ? (
                                        <div className="err-msg err-msg-font">
                                            {formik.errors.address}
                                        </div>
                                    ) : null
                                }
                                maxRows={2}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item md={6} xs={12} sm={12}>
                            <div style={{ marginTop: "1rem" }}>
                                <Autocomplete
                                    id="country"
                                    name="country"
                                    value={formik.values.country || null}
                                    // classes={customStyles}
                                    onChange={(e, value) => {
                                        formik.setFieldValue("country", value);
                                        dispatch(GetAllStates(value?._id));
                                        dispatch(cleareAllStates());
                                        formik.setFieldValue("state", "");
                                        formik.setFieldValue("city", "");
                                        formik.setFieldValue("pincode", "");
                                    }}
                                    options={allCountryData?.countryData || []}
                                    autoHighlight
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.country &&
                                        Boolean(formik.errors.country)
                                    }
                                    helperText={
                                        formik.touched.country && formik.errors.country ? (
                                            <div className="err-msg err-msg-font">
                                                {formik.errors.country}
                                            </div>
                                        ) : null
                                    }
                                    getOptionLabel={(option) => option.country_name}
                                    renderInput={(params) => (
                                        <TextField
                                            sx={{ color: "#000" }}
                                            {...params}
                                            label="Country"
                                            inputProps={{
                                                ...params.inputProps,
                                                style: { color: "#000" },
                                            }}
                                        />
                                    )}
                                />
                                {formik.errors.country && (
                                    <Typography
                                        variant="caption"
                                        color="error"
                                        sx={{ fontSize: "12px" }}
                                    >
                                        {formik.errors.country}
                                    </Typography>
                                )}
                            </div>
                        </Grid>

                        <Grid item md={6} xs={12} sm={12}>
                            <div style={{ marginTop: "1rem" }}>
                                <Autocomplete
                                    id="state"
                                    name="state"
                                    value={formik.values.state || null}
                                    onChange={(e, value) => {
                                        formik.setFieldValue("state", value);
                                        dispatch(GetAllCities(value?._id));
                                        dispatch(cleareAllCities());
                                        formik.setFieldValue("city", "");
                                        formik.setFieldValue("pincode", "");
                                    }}
                                    options={allStateData || []}
                                    autoHighlight
                                    getOptionLabel={(option) => option.state_name}
                                    renderInput={(params) => (
                                        <TextField
                                            sx={{ color: "#000" }}
                                            {...params}
                                            label="State"
                                            inputProps={{
                                                ...params.inputProps,
                                                style: { color: "#000" },
                                            }}
                                        />
                                    )}
                                />
                                {formik.touched.state && formik.errors.state && (
                                    <Typography
                                        variant="caption"
                                        color="error"
                                        sx={{ fontSize: "12px" }}
                                    >
                                        {formik.errors.state}
                                    </Typography>
                                )}
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item md={6} xs={12} sm={12}>
                            <div style={{ marginTop: "1rem" }}>
                                <Autocomplete
                                    id="city"
                                    name="city"
                                    value={formik.values.city || null}
                                    onChange={(e, value) => {
                                        formik.setFieldValue("city", value);
                                        dispatch(GetAllPostalCodes(value?._id));
                                        dispatch(clearePostalCodes());
                                        formik.setFieldValue("pincode", "");
                                    }}
                                    options={allCitiesData || []}
                                    autoHighlight
                                    getOptionLabel={(option) => option.city_name}
                                    renderInput={(params) => (
                                        <TextField
                                            sx={{ color: "#000" }}
                                            {...params}
                                            label="City"
                                            inputProps={{
                                                ...params.inputProps,
                                                style: { color: "#000" },
                                            }}
                                        />
                                    )}
                                />
                                {formik.touched.city && formik.errors.city && (
                                    <Typography
                                        variant="caption"
                                        color="error"
                                        sx={{ fontSize: "12px" }}
                                    >
                                        {formik.errors.city}
                                    </Typography>
                                )}
                            </div>
                        </Grid>

                        <Grid item md={6} xs={12} sm={12}>
                            <div style={{ marginTop: "1rem" }}>
                                <Autocomplete
                                    id="pincode"
                                    name="Postal code"
                                    value={formik.values.pincode || null}
                                    onChange={(e, value) =>
                                        formik.setFieldValue("pincode", value)
                                    }
                                    options={allPostalData || []}
                                    autoHighlight
                                    getOptionLabel={(option) => option.postal_code}
                                    renderInput={(params) => (
                                        <TextField
                                            sx={{ color: "#000" }}
                                            {...params}
                                            label="Postal code"
                                            inputProps={{
                                                ...params.inputProps,
                                                style: { color: "#000" },
                                            }}
                                        />
                                    )}
                                />
                                {formik.touched.pincode && formik.errors.pincode && (
                                    <Typography
                                        variant="caption"
                                        color="error"
                                        sx={{ fontSize: "12px" }}
                                    >
                                        {formik.errors.pincode}
                                    </Typography>
                                )}
                            </div>
                        </Grid>
                    </Grid>


                    <Grid
                        container
                        sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}
                    >
                        <Grid item>
                            <Button
                                sx={{ margin: 1 }}
                                type="button"
                                variant="contained"
                                // disabled={activeStep === 0}
                                onClick={() => handleBack(formik.values)}
                            >
                                Back
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                sx={{ margin: 1 }}
                                type="submit"
                                // onClick={handleSubmit}
                                variant="contained"
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                    {/* </Grid> */}
                </form>
            </Box>
        </>
    )
}

export default Step3