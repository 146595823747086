import React from "react";

const ShowFirstImage = ({ images }) => {
  function isImage(url) {
    return /\.(jpeg|jpg|gif|png|bmp)$/i.test(url);
  }

  const imageUrls = images.filter((url) => isImage(url));

  if (imageUrls.length > 0) {
    return (
      <a target="_blank" href={imageUrls[0]}>
        <img
          style={{ width: "70px", height: "50px", objectFit: "cover" }}
          src={imageUrls[0]}
          alt="GGC"
        />
      </a>
    );
  } else {
    return <>-</>;
  }
};

export default ShowFirstImage;
