import axios from "axios";
import { ApiURL, snackBar } from "../common";




const axiosApiCall = () => axios.create({
    headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
    }
})


export const getAllService = (page, service_name, rowsPerPage) => async (dispatch) => {
    try {
        dispatch({
            type: "GET_SERVICE_LOADING",
            payload: true,
        })

        await axios.get(`${ApiURL}service/all-service?limit=${rowsPerPage}&page=${page}&service_name=${service_name}`).then(res => {
            if (res.data.status && res.data.data) {
                dispatch({
                    type: "SERVICE_SUCCESS",
                    payload: res.data.data
                })
                dispatch({
                    type: "GET_SERVICE_LOADING",
                    payload: false,
                })
            } else {
                dispatch({
                    type: "SERVICE_FAIL",
                    payload: []
                })
            }
        })

    } catch (error) {

    }

}

export const createService = (data) => async (dispatch) => {
    try {
        dispatch({
            type: "CREATE_SERVICE_LOADING",
            payload: true,
        })
        await axiosApiCall().post(`${ApiURL}service/create-service`, data).then((res => {
            if (res?.data?.status) {
                dispatch({
                    type: "CREATE_SERVICE_SUCCESS",
                    payload: res.data.data
                })
                dispatch({
                    type: "CREATE_SERVICE_LOADING",
                    payload: false
                })
                snackBar(res?.data?.message, res.data.status);
                return res
            } else {
                dispatch({
                    type: "CREATE_SERVICE_LOADING",
                    payload: false
                })
                dispatch({
                    type: "CREATE_SERVICE_FAIL",
                    payload: []
                })
            }
        }))

    } catch (error) {
        dispatch({
            type: "CREATE_SERVICE_LOADING",
            payload: false,
        });
        snackBar(error?.response?.data?.message, error?.response?.data?.status)
        console.error(error);
    } finally {
        dispatch({
            type: "CREATE_SERVICE_LOADING",
            payload: false,
        });
        return true;
    }
}

export const updateService = (id, data) => async (dispatch) => {
    try {
        dispatch({
            type: "UPDATE_SERVICE_LOADING",
            payload: true,
        })
        const res = await axiosApiCall().put(`${ApiURL}service/update/${id}`, data).then((res => {
            if (res.data.status) {
                dispatch({
                    type: "UPDATE_SERVICE_SUCCESS",
                    payload: res.data.data
                })
                dispatch({
                    type: "UPDATE_SERVICE_LOADING",
                    payload: false
                })
                snackBar(res?.data?.message, res.data.status);
                return res;
            } else {
                dispatch({
                    type: "UPDATE_SERVICE_LOADING",
                    payload: false
                })
                dispatch({
                    type: "UPDATE_SERVICE_FAIL",
                    payload: []
                })
            }
        }))
        return res;
    } catch (error) {
        dispatch({
            type: "UPDATE_SERVICE_LOADING",
            payload: false
        })
        snackBar(error?.response?.data?.message, error?.response?.data?.status)
        console.error(error);
    }
}

export const deleteService = (id, handleCloseDelete) => async (dispatch) => {
    try {
        dispatch({
            type: "DELETE_SERVICE_LOADING",
            payload: true
        })
        await axios.delete(`${ApiURL}service/delete/${id}`, {
            header: {
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
            }
        }).then(res => {
            if (res.data.status) {
                dispatch({
                    type: "DELETE_SERVICE_SUCCESS",
                    payload: res.data.data
                })
                dispatch({
                    type: "DELETE_SERVICE_LOADING",
                    payload: false
                })
                snackBar(res?.data?.message, res.data.status);
                return res;
            } else {
                dispatch({
                    type: "DELETE_SERVICE_LOADING",
                    payload: false
                })
                snackBar(res?.data?.message, res.data.status);
            }
        })
    } catch (error) {
        snackBar(error?.response?.data?.message, error?.response?.data?.status)
        console.error(error);
    }
}