import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Input from "../../components/Input";
import { useFormik } from "formik";
import MuiPhoneNumber from "material-ui-phone-number";
import * as Yup from "yup";
import "./bemember.css";
import { useLocation } from "../../context/LocationContext";
import axios from "axios";
import { base_Url, snackBar } from "../../utils/common";

import "./bemember.css";
import { useNavigate } from "react-router-dom";

let emailValidation =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const SignupSchema = Yup.object().shape({
  name: Yup.string().required("Please enter name"),
  email: Yup.string()
    .required("Please enter email")
    .matches(emailValidation, "Please enter valid email"),
  mobile_number: Yup.string().required("Please enter mobile number"),
  city: Yup.object().required("Please select city"),
  state: Yup.object().required("Please select state"),
  country: Yup.object().required("Please select country"),
  postal: Yup.object().required("Please select postal code"),
  role: Yup.object().required("Please select role"),
  referral_code: Yup.string()
    .notRequired()
    .length(8, "Referral code must be exactly 8 characters"),
});

const BeMember = () => {
  const navigate = useNavigate();
  const {
    country,
    getAllCountry,
    getState,
    getRole,
    state,
    role,
    getCity,
    city,
    getPostalCode,
    postal,
    setState,
    setCity,
    setPostal,
  } = useLocation();

  const CreatePartnerReq = async (data) => {
    try {
      const res = await axios.post(
        `${base_Url}partner-request/create-request`,
        data
      );

      if (res?.data?.status === true) {
        snackBar(res?.data?.message, res?.data?.status);
      } else {
        snackBar(res?.data?.message, res?.data?.status);
      }
    } catch (err) {
      snackBar(err?.message || "API call failed");
    } finally {
    }
  };

  useEffect(() => {
    getAllCountry();
    getRole();
  }, []);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      mobile_number: "",
      role: "",
      city: "",
      state: "",
      country: "",
      postal: "",
      message: "",
      referral_code: "",
    },
    validationSchema: SignupSchema,
    onSubmit: (values, { resetForm }) => {
      const number = values.mobile_number.split(" ");
      const body = {
        name: values.name,
        email: values.email,
        phone_code: number[0]?.replace("+", ""),
        mobile_number: number[1]?.replace("-", ""),
        role_id: values.role._id,
        message: values.message || "-",
        city_id: values.city._id,
        referral_code: values.referral_code || "",
        country_id: values.country._id,
        state_id: values.state._id,
        postalcode_id: values.postal._id,
      };
      if (!body?.referral_code) {
        delete body?.referral_code;
      }
      CreatePartnerReq(body);
      resetForm();
      navigate("/");
    },
  });

  // const number = formik.values.mobile_number.split(" ");

  return (
    <div>
      <Box
        sx={{
          margin: 5,
        }}
      >
        <Grid container spacing={{ xs: 2, md: 2, sm: 2, xl: 5, lg: 5 }}>
          <Grid item md={12} xs={12} sm={12}>
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Grid item md={6}>
                <Box component={Paper} padding={{ xs: 2, sm: 3 }} elevation={5}>
                  <Typography
                    textAlign="center"
                    fontWeight={500}
                    fontSize={28}
                    marginBottom={2}
                    color="black"
                    font-family="Nunito,Inter var,Roboto,Helvetica Neue,Arial,sans-serif"
                  >
                    Registration
                  </Typography>
                  <form onSubmit={formik.handleSubmit}>
                    <Input
                      id="name"
                      name="name"
                      type="text"
                      label="Name"
                      onChange={formik.handleChange}
                      value={formik.values.name}
                      onBlur={formik.handleBlur}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={
                        formik.touched.name && formik.errors.name ? (
                          <div className="err-msg err-msg-font">
                            {formik.errors.name}
                          </div>
                        ) : null
                      }
                      maxLength={50}
                    />
                    <Input
                      id="email"
                      name="email"
                      label="Email Address"
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      InputLabelProps={{ sx: { color: "black" } }}
                      helperText={
                        formik.touched.email && formik.errors.email ? (
                          <div className="err-msg err-msg-font">
                            {formik.errors.email}
                          </div>
                        ) : null
                      }
                      maxLength={150}
                    />

                    <MuiPhoneNumber
                      fullWidth
                      defaultCountry={"in"}
                      size="small"
                      variant="outlined"
                      id="mobile_number"
                      name="mobile_number"
                      label="Mobile Number"
                      type="text"
                      onBlur={formik.handleBlur}
                      onChange={(value) => {
                        formik.setFieldValue("mobile_number", value);
                      }}
                      value={formik.values.mobile_number}
                      error={
                        formik.touched.mobile_number &&
                        formik.errors.mobile_number
                      }
                      helperText={
                        formik.touched.mobile_number &&
                        formik.errors.mobile_number ? (
                          <div className="err-msg-mobile err-msg-font">
                            {formik.errors.mobile_number}
                          </div>
                        ) : null
                      }
                      InputLabelProps={{ sx: { color: "black" } }}
                      sx={{ marginTop: "10px" }}
                      // sx={{
                      //   "& .MuiOutlinedInput-root:hover": {
                      //     "& > fieldset": {
                      //       borderColor: "black",
                      //     },
                      //   },
                      //   "&.Mui-focused .MuiOutlinedInput-root": {
                      //     borderColor: "#4f46e5!important",
                      //   },
                      //   "& .MuiOutlinedInput-root": {
                      //     "&.Mui-focused fieldset": {
                      //       borderColor: "#4f46e5!important",
                      //     },
                      //     "& fieldset": {
                      //       borderColor: "black!important",
                      //     },
                      //     color: "black!important",
                      //     mb: 1,
                      //   },
                      //   mt: 1,
                      //   borderRadius: "12px",
                      // }}
                    />
                    <div style={{ marginTop: "1rem" }}>
                      <Autocomplete
                        id="role"
                        name="role"
                        value={formik.values.role || null}
                        // classes={customStyles}
                        onChange={(e, value) => {
                          formik.setFieldValue("role", value);
                        }}
                        options={role || []}
                        autoHighlight
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.role && Boolean(formik.errors.role)
                        }
                        helperText={
                          formik.touched.role && formik.errors.role ? (
                            <div className="err-msg-font">
                              {formik.errors.role}
                            </div>
                          ) : null
                        }
                        getOptionLabel={(option) => option.role}
                        renderInput={(params) => (
                          <TextField
                            sx={{ color: "#000" }}
                            {...params}
                            label="Role"
                            inputProps={{
                              ...params.inputProps,
                              style: { color: "#000" },
                            }}
                            error={
                              formik.touched.role && Boolean(formik.errors.role)
                            }
                          />
                        )}
                      />
                      {formik.touched.role && formik.errors.role && (
                        <Typography
                          variant="caption"
                          color="error"
                          sx={{ fontSize: "12px" }}
                        >
                          {formik.errors.role}
                        </Typography>
                      )}
                    </div>
                    <div style={{ marginTop: "1rem" }}>
                      <Input
                        id="referral_code"
                        name="referral_code"
                        type="text"
                        label="Referral Code"
                        onChange={formik.handleChange}
                        value={formik.values.referral_code}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.referral_code &&
                          Boolean(formik.errors.referral_code)
                        }
                        helperText={
                          formik.touched.referral_code &&
                          formik.errors.referral_code ? (
                            <div className="err-msg err-msg-font">
                              {formik.errors.referral_code}
                            </div>
                          ) : null
                        }
                        maxLength={8}
                      />
                    </div>
                    <div style={{ marginTop: "1rem" }}>
                      <Autocomplete
                        id="country"
                        name="country"
                        value={formik.values.country || null}
                        // classes={customStyles}
                        onChange={(e, value) => {
                          formik.setFieldValue("country", value);
                          if (value?._id) getState(value?._id);
                          setState([]);
                          setCity([]);
                          setPostal([]);
                          formik.setFieldValue("state", "");
                          formik.setFieldValue("city", "");
                          formik.setFieldValue("postal", "");
                        }}
                        options={country?.countryData || []}
                        autoHighlight
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.country &&
                          Boolean(formik.errors.country)
                        }
                        helperText={
                          formik.touched.country && formik.errors.country ? (
                            <div className="err-msg-font">
                              {formik.errors.country}
                            </div>
                          ) : null
                        }
                        getOptionLabel={(option) => option.country_name}
                        renderInput={(params) => (
                          <TextField
                            sx={{ color: "#000" }}
                            {...params}
                            label="Country"
                            inputProps={{
                              ...params.inputProps,
                              style: { color: "#000" },
                            }}
                            error={
                              formik.touched.country &&
                              Boolean(formik.errors.country)
                            }
                          />
                        )}
                      />
                      {formik.touched.country && formik.errors.country && (
                        <Typography
                          variant="caption"
                          color="error"
                          sx={{ fontSize: "12px" }}
                        >
                          {formik.errors.country}
                        </Typography>
                      )}
                    </div>

                    <div style={{ marginTop: "1rem" }}>
                      {console.log(formik, "formikvalues")}
                      <Autocomplete
                        id="state"
                        name="state"
                        value={formik.values.state || null}
                        onChange={(e, value) => {
                          formik.setFieldValue("state", value);
                          if (value?._id) getCity(value?._id);
                          setCity([]);
                          setPostal([]);
                          formik.setFieldValue("city", "");
                          formik.setFieldValue("postal", "");
                        }}
                        options={state || []}
                        autoHighlight
                        getOptionLabel={(option) => option.state_name}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.state && Boolean(formik.errors.state)
                        }
                        renderInput={(params) => (
                          <TextField
                            sx={{ color: "#000" }}
                            {...params}
                            label="State"
                            inputProps={{
                              ...params.inputProps,
                              style: { color: "#000" },
                            }}
                            error={
                              formik.touched.state &&
                              Boolean(formik.errors.state)
                            }
                          />
                        )}
                      />
                      {formik.touched.state && formik.errors.state && (
                        <Typography
                          variant="caption"
                          color="error"
                          sx={{ fontSize: "12px" }}
                        >
                          {formik.errors.state}
                        </Typography>
                      )}
                    </div>

                    <div style={{ marginTop: "1rem" }}>
                      <Autocomplete
                        id="city"
                        name="city"
                        value={formik.values.city || null}
                        onChange={(e, value) => {
                          formik.setFieldValue("city", value);
                          if (value?._id) getPostalCode(value?._id);
                          setPostal([]);
                          formik.setFieldValue("postal", "");
                        }}
                        options={city || []}
                        autoHighlight
                        getOptionLabel={(option) => option.city_name}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.city && Boolean(formik.errors.city)
                        }
                        renderInput={(params) => (
                          <TextField
                            sx={{ color: "#000" }}
                            {...params}
                            label="City"
                            inputProps={{
                              ...params.inputProps,
                              style: { color: "#000" },
                            }}
                            error={
                              formik.touched.city && Boolean(formik.errors.city)
                            }
                          />
                        )}
                      />
                      {formik.touched.city && formik.errors.city && (
                        <Typography
                          variant="caption"
                          color="error"
                          sx={{ fontSize: "12px" }}
                        >
                          {formik.errors.city}
                        </Typography>
                      )}
                    </div>

                    <div style={{ marginTop: "1rem" }}>
                      <Autocomplete
                        id="postal"
                        name="Postal code"
                        value={formik.values.postal || null}
                        onChange={(e, value) =>
                          formik.setFieldValue("postal", value)
                        }
                        options={postal || []}
                        autoHighlight
                        getOptionLabel={(option) =>
                          option.postal_code?.toString()
                        }
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.postal && Boolean(formik.errors.postal)
                        }
                        renderInput={(params) => (
                          <TextField
                            sx={{ color: "#000" }}
                            {...params}
                            label="Postal code"
                            inputProps={{
                              ...params.inputProps,
                              style: { color: "#000" },
                            }}
                            error={
                              formik.touched.postal &&
                              Boolean(formik.errors.postal)
                            }
                          />
                        )}
                      />
                      {formik.touched.postal && formik.errors.postal && (
                        <Typography
                          variant="caption"
                          color="error"
                          sx={{ fontSize: "12px" }}
                        >
                          {formik.errors.postal}
                        </Typography>
                      )}
                    </div>

                    <Input
                      fullWidth
                      maxRows={4}
                      name="message"
                      value={formik.values.message}
                      label="Message"
                      variant="outlined"
                      onChange={formik.handleChange}
                      // onBlur={formik.handleBlur}
                      // error={
                      //   formik.touched.message && Boolean(formik.errors.message)
                      // }
                      // helperText={
                      //   formik.touched.message && formik.errors.message ? (
                      //     <div>{formik.errors.message}</div>
                      //   ) : null
                      // }
                      maxLength={500}
                    />
                    <Button
                      sx={{ marginTop: 4 }}
                      type="submit"
                      variant="contained"
                    >
                      Submit
                    </Button>
                  </form>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default BeMember;
