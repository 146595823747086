import React, { useEffect, useState } from "react";
import { ApiURL, snackBar } from "../../redux/common";
import axios from "../../redux/ApiConfig";
import Loader from "../../components/loader/Loader";
import InternalServerError from "../InternalServerError.js";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Button, Grid } from "@mui/material";
import PageHeader from "../../components/pageHeader/PageHeader";
import { getPermissions } from "../../common/permissions.js";
import { permissions } from "../../common/constants.js";

ClassicEditor.defaultConfig = {
  toolbar: {
    items: [
      "heading",
      "|",
      "bold",
      "italic",
      "blockQuote",
      "|",
      "bulletedList",
      "numberedList",
      "outdent",
      "indent",
      "|",
      "insertTable",
      "|",
      "undo",
      "redo",
    ],
  },
  image: {
    toolbar: [
      "imageStyle:full",
      "imageStyle:side",
      "|",
      "imageTextAlternative",
    ],
  },
  table: {
    contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
  },
  language: "en",
};

const AdminPrivacyPolicy = () => {
  const [loading, setLoading] = useState(true);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [data, setData] = useState("");
  const [error, setError] = useState(false);

  const getPrivacyPolicyData = async () => {
    try {
      const res = await axios.get(`${ApiURL}privacy-policy`);
      if (res?.data?.status) {
        setData(res?.data?.data?.policy);
      } else {
        setData("");
      }
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdatePolicy = async () => {
    try {
      setUpdateLoading(true);
      const res = await axios.put(`${ApiURL}privacy-policy`, { policy: data });
      snackBar(res?.data?.message, res?.data?.status);
    } catch {
      snackBar("Error updating status", "error");
    } finally {
      setUpdateLoading(false);
    }
  };

  useEffect(() => {
    getPrivacyPolicyData();
  }, []);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <InternalServerError />;
  }

  return (
    <>
      <PageHeader title="Privacy Policy" isShowAdd={false} />
      <Grid container spacing={3} justifyContent="center" paddingTop={2}>
        <Grid item xs={8} md={9} sm={11}>
          <CKEditor
            disabled={!getPermissions(permissions.privacy_policy, "is_edit")}
            editor={ClassicEditor}
            data={data}
            config={{
              placeholder: "Add Description",
            }}
            onChange={(event, editor) => {
              const newData = editor.getData();
              setData(newData); // Update data state with new content
            }}
          />
        </Grid>
        {!!getPermissions(permissions.privacy_policy, "is_edit") && (
          <Grid item xs={8} md={9} sm={11} display="flex" justifyContent="end">
            <Button
              variant="contained"
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                border: "0",
                color: "#fff",
                ":hover": { bgcolor: "#3974d3", border: "0" },
                backgroundColor: "#153e80",
                marginLeft: {
                  xs: "0px",
                  sm: "15px",
                  md: "15px",
                  lg: "15px",
                },
              }}
              onClick={handleUpdatePolicy}
              disabled={updateLoading}
            >
              {updateLoading ? "Updating..." : "Update Policy"}
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default AdminPrivacyPolicy;
