import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import Input from "../../common/Input";
import * as Yup from "yup";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { UpdateUserManage } from "../../redux/action/UserManageAction";
import { CreateRole } from "../../redux/action/RoleandPermissionAction";
import axios from "../../redux/ApiConfig";
import { ApiURL, snackBar } from "../../redux/common";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const UpdatePermission = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const dispatch = useDispatch();

  const [data, setData] = useState({});
  const [moduleList, setModuleList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);

  const getAllModule = async () => {
    const res = await axios.get(`${ApiURL}module/all-modules`);
    setModuleList(res.data.data);
  };

  const getRoleDataById = async () => {
    const res = await axios.get(`${ApiURL}role/by-id/${state?._id}`);
    setData(res?.data?.data?.permissions || {});
  };

  const handleRowChange = (event) => {
    const [module, permission] = event.target.name.split(".");
    setData({
      ...data,
      [module]: { ...data[module], [permission]: Number(event.target.checked) },
    });
  };


  const rows = moduleList.map((module) =>
    createData(
      module.name,
      <Checkbox
        color="primary"
        checked={data[module.name]?.is_read || false}
        name={`${module.name}.is_read`}
        onChange={handleRowChange}
      />,
      <Checkbox
        color="primary"
        checked={data[module.name]?.is_add || false}
        name={`${module.name}.is_add`}
        onChange={handleRowChange}
      />,
      <Checkbox
        color="primary"
        checked={data[module.name]?.is_edit || false}
        name={`${module.name}.is_edit`}
        onChange={handleRowChange}
      />,
      <Checkbox
        color="primary"
        checked={data[module.name]?.is_delete || false}
        name={`${module.name}.is_delete`}
        onChange={handleRowChange}
      />
    )
  );

  ;

  const formik = useFormik({
    initialValues: {
      role: "",
    },
    // validationSchema: PartnerReqSchema,
    onSubmit: async (values) => {
      let body = {};
      //   if (isEdit) {
      body = {
        role: values.role,
        permissions: data,
      };
      //   }
        
      console.log("body_74125893",body)

      if (isEdit) {
        // dispatch(UpdateUserManage(state._id, body));
        const res = await axios.put(`${ApiURL}role/update-role/${state?._id}`, body);
        snackBar(res?.data?.message, res?.data?.status);
        // navigate("/admin-role-permission");

      }

      if (!isEdit) {
        dispatch(CreateRole(body));
        navigate("/admin-role-permission");
      }
    },
  });

  useEffect(() => {
    if (state && state.role) setIsEdit(true);
    {
      formik.setValues({
        ...formik.values,
        role: state?.role || "",
      });
    }
  }, [state, formik.setValues]);

  useEffect(() => {
    getAllModule();
    if (state?._id) {
      getRoleDataById(state?._id);
    }
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        margin: "3rem",
        height: "100vh",
      }}
    >
      <ArrowCircleLeftOutlinedIcon
        fontSize="large"
        onClick={() => navigate("/admin-role-permission")}
        sx={{ cursor: "pointer" }}
      />
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "60px",
        }}
      >
        <Grid item md={12} xs={12} sm={12}>
          <Grid item md={12}>
            <Box component={Paper} padding={{ xs: 2, sm: 3 }} elevation={5}>
              <form onSubmit={formik.handleSubmit}>
                <Grid item md={6}>
                  <Typography variant="h6" sx={{ marginTop: "0.5rem" }}>
                    Role:
                  </Typography>
                  <Input
                    id="role"
                    name="role"
                    type="text"
                    label="Role"
                    onChange={formik.handleChange}
                    value={formik.values.role}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.cheque_number &&
                      Boolean(formik.errors.cheque_number)
                    }
                    helperText={
                      formik.touched.cheque_number &&
                        formik.errors.cheque_number ? (
                        <div>{formik.errors.cheque_number}</div>
                      ) : null
                    }
                  />
                </Grid>

                <Grid>
                  <Typography
                    fontWeight={900}
                    fontSize={21}
                    marginBottom={2}
                    color="black"
                    sx={{ marginTop: "25px" }}
                  >
                    Roles Permission
                  </Typography>

                  <Box>
                    <TableContainer component={Paper}sx={{ maxHeight: 540 }}>
                      <Table sx={{ minWidth: 650 }} aria-label="sticky table" stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell>Role</TableCell>
                            <TableCell align="center">
                              {/* <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isViewAllChecked}
                                  onChange={handleViewAllCheckboxChange}
                                  name="viewAll"
                                  color="primary"
                                />
                              }
                              label="View"
                            /> */}
                              View
                            </TableCell>
                            <TableCell align="center">
                              {/* <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isAddAllChecked}
                                  onChange={handleAddAllCheckboxChange}
                                  name="addAll"
                                  color="primary"
                                />
                              }
                              label="Add"
                            /> */}
                              Add
                            </TableCell>
                            <TableCell align="center">
                              {/* <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isEditAllChecked}
                                  onChange={handleEditAllCheckboxChange}
                                  name="editAll"
                                  color="primary"
                                />
                              }
                              label="Edit"
                            /> */}
                              Edit
                            </TableCell>
                            <TableCell align="center">
                              {/* <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isDeleteAllChecked}
                                  onChange={handleDeleteAllCheckboxChange}
                                  name="deleteAll"
                                  color="primary"
                                />
                              }
                              label="Delete"
                            /> */}
                              Delete
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.map((row) => (
                            <TableRow
                              key={row.name}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {row.name}
                              </TableCell>
                              <TableCell align="center">
                                {row.calories}
                              </TableCell>
                              <TableCell align="center">{row.fat}</TableCell>
                              <TableCell align="center">{row.carbs}</TableCell>
                              <TableCell align="center">
                                {row.protein}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Grid>

                <Button sx={{ marginTop: 4 }} type="submit" variant="contained">
                  {isEdit ? "Update" : "Submit"}
                </Button>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UpdatePermission;
