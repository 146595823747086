import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Pagination,
  Typography,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import PageHeader from "../../components/pageHeader/PageHeader";
import EnhancedTable from "../../components/table/EnhancedTable ";
import { DeleteIcon, EditIcon } from "../../assets/icons/Icons";
import "../../components/table/table.css";
import { useForm } from "../../common/useForms";
import FormDialog from "../../components/dialog/dialog";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { getPermissions } from "../../common/permissions";
import { permissions } from "../../common/constants";
import DeleteDialog from "../../components/dialog/deletedialog";
import Loader from "../../components/loader/Loader";
import {
  CreatePageBanner,
  DeletePageBanner,
  GetAllPageBanners,
  UpdatePageBanner,
} from "../../redux/action/PageBannersAction";
import { GetAllPages } from "../../redux/action/PagesAction";

const useStyles = makeStyles((theme) => ({
  filterMain: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "#fff",
  },
  mainFilterBox: {
    padding: "10px 20px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "200px",
    },

    [theme.breakpoints.up("md")]: {
      width: "300px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "240px !important",
    },
    [theme.breakpoints.up("xs")]: {
      width: "100%",
    },
  },

  stackFilter: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  typographyFilter: {
    fontWeight: "bold !important",
    color: "#000 !important",
    fontSize: "30px !important",
  },
  buttonFilter: {
    flexDirection: "row !important",
    marginTop: "8px",
    gap: "8px",
  },
}));

const columns = [
  {
    id: "banner_image",
    numeric: true,
    disablePadding: false,
    label: "Banner Image",
  },

  {
    id: "banner_page",
    numeric: false,
    disablePadding: true,
    label: "Banner Page",
  },

  !!getPermissions(permissions.page_banners, "is_edit") ||
  !!getPermissions(permissions.page_banners, "is_delete")
    ? { id: "action", numeric: true, disablePadding: false, label: "Action" }
    : null,
].filter((el) => el !== null);

const initialValues = {
  banner_image: "",
  banner_page: "",
};

const RecordPerPage = [
  { id: 5, title: "5" },
  { id: 10, title: "10" },
];

const AdminPageBanners = () => {
  const dispatch = useDispatch();
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState(false);
  const [filterData, setFilterData] = useState(true);
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [DeleteId, SetDeleteId] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [popupName, setPopupName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [editImageValue, setEditImageValue] = useState();

  const bannerData = useSelector(
    (state) => state?.PageBannersReducer?.pageBanners?.bannerList
  );
  const bannerPagesData = useSelector(
    (state) => state?.PagesReducer?.pages?.pageList
  );

  const { loading, pageBanners, createLoading, updateLoading, deleteLoading } =
    useSelector((state) => state.PageBannersReducer);
  // console.log(pageBanners);

  useEffect(() => {
    dispatch(GetAllPageBanners(page, rowsPerPage));
    dispatch(GetAllPages());
  }, [page, dispatch]);

  const handleChangeRow = (event) => {
    const newRowPerPage = event.target.value;
    const newPageNumber = 1;
    setRowsPerPage(event.target.value);
    setPage(newPageNumber);

    dispatch(GetAllPageBanners(newPageNumber, newRowPerPage));
  };

  const tableTitle = "SubscriptionList";

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleClickOpen = () => {
    setPopupName("Create Page Banner");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    resetForm();
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if (popupName === "Edit Page Banner") {
      if ("banner_page" in fieldValues) {
        if (
          fieldValues.banner_page === "" ||
          fieldValues.banner_page === undefined ||
          fieldValues.banner_page === null ||
          fieldValues.banner_page?.trim() === ""
        ) {
          temp.banner_page = "Please select banner's page";
        } else {
          temp.banner_page = "";
        }
      }
    } else {
      if ("banner_image" in fieldValues) {
        if (
          fieldValues.banner_image === "" ||
          fieldValues.banner_image === undefined ||
          fieldValues.banner_image === null
        ) {
          temp.banner_image = "This field is required.";
        } else {
          temp.banner_image = "";
        }
      }
      if ("banner_page" in fieldValues) {
        if (
          fieldValues.banner_page === "" ||
          fieldValues.banner_page === undefined ||
          fieldValues.banner_page === null ||
          fieldValues.banner_page?.trim() === ""
        ) {
          temp.banner_page = "Please select banner's page";
        } else {
          temp.banner_page = "";
        }
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleEditClick = async (data) => {
    setSelectedBanner(data);
    setErrors({});
    setPopupName("Edit Page Banner");
    if (data?.banner_image) {
      setEditImageValue(data?.banner_image);
    }
    setValues({
      ...values,
      banner_page: data?.banner_page || "",
    });
    setErrors({});
    setOpen(true);
  };

  const handleClickOpenDelete = (data) => {
    setOpenDelete(true);
    SetDeleteId(data._id);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleSingleDelete = async () => {
    await dispatch(DeletePageBanner(DeleteId, handleCloseDelete)).then(
      (res) => {
        if (res && res.data.status === true) {
          dispatch(GetAllPageBanners(page, rowsPerPage));
        }
      }
    );
  };

  const fields = [
    {
      id: "banner_image",
      type: "file",
      labelAccess: "banner_image",
      value: values.banner_image,
      name: "banner_image",
      errors: errors.banner_image,
      multiple: false,
    },
    {
      id: "banner_page",
      label: "Page Name",
      type: "select",
      options:
        bannerPagesData &&
        bannerPagesData?.map((el) => {
          return { value: el.page_name, label: el.page_name };
        }),
      value: values.banner_page,
      name: "banner_page",
      errors: errors.banner_page,
    },
  ];
  const rows =
    bannerData && Array.isArray(bannerData)
      ? bannerData?.map((data) => {
          const selected = data === selectedBanner;
          return {
            _id: data._id,
            banner_image: (
              <img
                style={{ width: "70px", height: "50px", objectFit: "cover" }}
                src={data.banner_image}
                alt="GGC"
              />
            ),
            banner_page: data.banner_page || "-",

            // status: data.is_active ? "approved" : 'pending',
            action: (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {!!getPermissions(permissions.page_banners, "is_edit") && (
                  <IconButton
                    onClick={() => handleEditClick(data)}
                    sx={{ color: "black" }}
                  >
                    <EditIcon />
                  </IconButton>
                )}
                {!!getPermissions(permissions.page_banners, "is_delete") && (
                  <IconButton
                    onClick={() => handleClickOpenDelete(data)}
                    sx={{ color: "black" }}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </div>
            ),
            selected: selected,
          };
        })
      : [];

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validate()) {
      const formData = new FormData();
      let image = null;
      if (values.file) {
        image = values.file;
        formData.append(`page_banner_image`, image);
      }
      const [page] = bannerPagesData.filter(
        (item) => item.page_name === values.banner_page
      );
      formData.append("banner_page", page._id);

      if (popupName === "Edit Page Banner") {
        await dispatch(UpdatePageBanner(selectedBanner._id, formData));
      } else {
        await dispatch(CreatePageBanner(formData));
      }
      await dispatch(GetAllPageBanners(page, rowsPerPage));
      handleClose();
    }
  };

  const classes = useStyles();

  return (
    <Box>
      <PageHeader
        title="Page Banners"
        onAddClick={handleClickOpen}
        isShowAdd={!!getPermissions(permissions.page_banners, "is_add")}
      />

      {loading ? (
        <Loader />
      ) : (
        // <Box sx={{ display: "flex", justifyContent: "center" }}>
        //   <CircularProgress />
        // </Box>
        <EnhancedTable
          key={rows._id}
          columns={columns}
          rows={rows}
          order={order}
          orderBy={orderBy}
          selected={selected}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          title={tableTitle}
          tableList={bannerData}
        />
      )}
      <FormDialog
        open={open}
        dialogTitle={popupName}
        fields={
          popupName === "Edit Page Banner"
            ? fields.filter((el, index) =>
                ["banner_image", "banner_page"].includes(el.name)
              )
            : fields.filter((el) => !["status"].includes(el.name))
        }
        loading={
          popupName === "Edit Page Banner" ? updateLoading : createLoading
        }
        submitText="Submit"
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        onChange={handleInputChange}
        // isAnyFieldNotEmpty={isAnyFieldNotEmpty}
      />

      <DeleteDialog
        open={openDelete}
        loading={deleteLoading}
        dialogTitle="Are you sure to want to delete this record?"
        handleClose={handleCloseDelete}
        handleDelete={() => handleSingleDelete(DeleteId)}
      />

      <Box
        sx={{
          display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
          justifyContent: "space-between",
          alignItems: "center",
          marginLeft: { xs: "10px", sm: "20px", md: "20px", lg: "20px" },
          marginRight: { xs: "10px", sm: "15px", md: "15px", lg: "15px" },
          mt: 2,
          mb: 2,
        }}
      >
        <Box
          sx={{
            fontSize: "14px",
            marginBottom: { xs: "10px", sm: "0", md: "0", lg: "0" },
            gap: 2,
            width: { sm: "100%", md: "50%" },
          }}
          display="flex"
          alignItems="center"
        >
          <Box>
            {" "}
            Showing {pageBanners?.currentPage} of {pageBanners?.totalPages}{" "}
            Results
          </Box>
          <Box display="flex" sx={{ gap: 1 }} alignItems="center">
            <Typography>Records per page</Typography>

            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                id="limites"
                name="limites"
                value={rowsPerPage}
                onChange={handleChangeRow}
                displayEmpty
                sx={{ maxWidth: "65px", maxHeight: "2.2rem" }}
                inputProps={{ "aria-label": "Without label" }}
              >
                {RecordPerPage?.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name || item.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Pagination
          count={pageBanners?.totalPages}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
          sx={{
            "& .MuiButtonBase-root ": {
              color: "#000 !important",
            },
            "& .Mui-selected ": {
              background: "#4b4b4e70 !important",
            },
            "& .Mui-disabled ": {
              background: "#6c696966 !important",
              color: "#000000 !important",
              opacity: 1,
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default AdminPageBanners;
