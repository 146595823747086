import { enqueueSnackbar } from "notistack";
import axios from "../ApiConfig";
import { ApiURL, snackBar } from "../common";

const axiosApiCall = () =>
  axios.create({
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  });

export const GetAllFAQ = (page, rowsPerPage) => async (dispatch) => {
  try {
    dispatch({
      type: "FAQ_GET_LOADING",
      payload: true,
    });

    const res = await axios.get(
      `${ApiURL}faq-category/get-all-category?limit=${rowsPerPage}&page=${page}`
    );

    if (res.data.status === true) {
      const responseData =
        res.data.data || res.data.data.length > 0 ? res.data.data : [];
      dispatch({
        type: "FAQ_GET_SUCCESS",
        payload: responseData,
      });
    } else {
      dispatch({
        type: "FAQ_GET_SUCCESS",
        payload: [],
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({
      type: "FAQ_GET_LOADING",
      payload: false,
    });
  }
};

export const CreateFAQ = (data) => async (dispatch) => {
  try {
    dispatch({
      type: "CREATE_LOADING_FAQ",
      payload: true,
    });

    const res = await axios.post(
      `${ApiURL}faq-category/create-faq-category`,
      data
    );

    if (res.data.status === true) {
      dispatch({
        type: "CREATE_FAQ_SUCCESS",
        payload: res.data.data,
      });
      snackBar(res?.data?.message, res?.data?.status);
      return res;
    }
  } catch (err) {
    snackBar(err?.response?.message, err?.response?.status);
  } finally {
    dispatch({
      type: "CREATE_LOADING_FAQ",
      payload: false,
    });
  }
};

export const UpdateFAQ = (id, data) => async (dispatch) => {
  try {
    dispatch({
      type: "UPDATE_LOADING_FAQ",
      payload: true,
    });

    const res = await axios.put(
      `${ApiURL}faq-category/update-category/${id}`,
      data
    );

    if (res.data.status === true) {
      dispatch({
        type: "UPDATE_FAQ_SUCCESS",
      });
      snackBar(res?.data?.message, res?.data?.status);
      return res;
    }
    snackBar(res?.data?.message, res?.data?.status);
  } catch (err) {
    snackBar(err?.response?.data?.message, err?.response?.data?.status);
    console.error(err);
  } finally {
    dispatch({
      type: "UPDATE_LOADING_FAQ",
      payload: false,
    });
  }
};

export const DeleteFAQ = (id, handleClose) => async (dispatch) => {
  try {
    dispatch({
      type: "DELETE_FAQ_LOADING",
      payload: true,
    });
    const res = await axios.delete(
      `${ApiURL}faq-category/delete-category/${id}`
    );

    if (res.data.status === true) {
      dispatch({
        type: "DELETE_FAQ_LOADING",
        payload: false,
      });
      dispatch({
        type: "DELETE_FAQ_SUCCESS",
        payload: res.data.data,
      });
      snackBar(res?.data?.message, res?.data?.status);
      handleClose();
      return res;
    }
    snackBar(res?.data?.message, res?.data?.status);
  } catch (err) {
    snackBar(err?.response?.data?.message, err?.response?.data?.status);
    console.error(err);
    dispatch({
      type: "DELETE_FAQ_LOADING",
      payload: false,
    });
  }
};
