import React from "react";
import HomeOurDirectory from "../../components/slides/HomeOurDirectory/HomeOurDirectory";
import HomeArticles from "../../components/slides/HomeArticles";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import ClientReview from "../../components/clientReview/ClientReview";
import HomeCarousel from "../../components/homeTopSlider/HomeCarousel";
import SearchComponent from "../../components/search/SerachComponent";
import { Button, Grid } from "@mui/material";
import Home3 from "../../assets/image/banner/Home3.jpg";
import Home1 from "../../assets/image/banner/Home1.jpg";
import { useNavigate } from "react-router-dom";
import JoinWithUs from "../../components/joinWithUs/JoinWithUs";


const joinWithUs = [
  {
    img: Home3,
    title: "Be a Member",
    desc: "At our community business, we understand the importance of providing comprehensive support for entrepreneurs and small business owners like you. We offer a range of services to help you thrive in today's competitive market. By becoming a member of our community, you'll gain access to all essential resources in one convenient place, giving you a significant advantage in achieving your entrepreneurial goals.",
    btn_name: "Be a Member",
  },
  {
    img: Home1,
    title: "Be a Partner",
    desc: "Are you ready to elevate your business to new heights? Join the Global Garner Community, where local meets global, and success knows no bounds. As the proud initiative of Global Garner Sales Service Limited, a trailblazer that has achieved a remarkable 6650 crore valuation in just 7 years, we bring you a unique platform that transcends geographical boundaries and propels your business towards unprecedented growth.",
    btn_name: "Be a Partner",
  },

]



const Home = () => {
  const [ref, inView] = useInView({
    triggerOnce: true, // Ensures animation triggers once
    threshold: 0, // Adjust as needed
  });
  const nav = useNavigate()

  const cardVariants = {
    initial: { opacity: 0, y: -150 },
    animate: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.8, ease: "easeOut" },
    },
  };

  const containerVariants = {
    initial: { opacity: 0 },
    animate: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.5,
        ease: "easeInOut",
      },
    },
  };

  const handleBeaMemeber = () => {
    nav("/be-member");
  }
  const handleBeaPartner = () => {
    nav("/be-partner");
  }
  return (
    <div className="content-wrap">
      <section id="section-01">
        <HomeCarousel />
        <SearchComponent />
        <div className="home-main-how-it-work bg-white pt-5">
          <div className="container">
            <h2 className="mb-5">
              <span>See </span>
              <span className="font-weight-light">How It Works</span>
            </h2>
            <motion.div
              ref={ref}
              variants={containerVariants}
              initial="initial"
              animate={inView ? "animate" : "initial"}
              className="row no-gutters pb-5"
            >
              <div className="col-lg-4 mb-4 mb-lg-0 px-0 px-lg-1">
                <motion.div
                  variants={cardVariants}
                  className="media icon-box-style-02 align-items-center"
                >
                  <div className="d-flex flex-column mr-4">
                    <span className="number h1 font-weight-bold">1</span>
                  </div>
                  <div className="media-body lh-14">
                    <h5 className="mb-3 lh-1 d-flex align-items-end">
                      <svg className="icon icon-checkmark-circle" style={{fill:"#00CC00"}}>
                        <use xlinkHref="#icon-checkmark-circle" />
                      </svg>
                      &nbsp; Submission
                    </h5>
                    <p className="font-size-md text-gray mb-0 text-muted text-justify">
                      Verify Your membership eligibility by submitting
                      application
                    </p>
                  </div>
                </motion.div>
              </div>
              <div className="col-lg-4 mb-4 mb-lg-0 px-0 px-lg-1">
                <motion.div
                  variants={cardVariants}
                  className="media icon-box-style-02 align-items-center"
                >
                  <div className="d-flex flex-column mr-4 ">
                    <span
                      className="number h1 font-weight-900"
                      style={{ color: "#898989" }}
                    >
                      2
                    </span>
                  </div>
                  <div className="media-body lh-14">
                    <h5 className="mb-3 lh-1 d-flex align-items-end">
                      <svg className="icon icon-checkmark-circle" style={{fill:"#009900"}}>
                        <use xlinkHref="#icon-checkmark-circle" />
                      </svg>
                      &nbsp; Activation
                    </h5>
                    <p className="font-size-md text-gray mb-0 text-muted text-justify">
                      Activate Your membership and get Dedicated RM appointment
                    </p>
                  </div>
                </motion.div>
              </div>
              <div className="col-lg-4 mb-4 mb-lg-0 px-0 px-lg-1">
                <motion.div
                  variants={cardVariants}
                  className="media icon-box-style-02 align-items-center"
                >
                  <div className="d-flex flex-column mr-4">
                    <span
                      className="number h1 font-weight-1000"
                      style={{ color: "#353535" }}
                    >
                      3
                    </span>
                  </div>
                  <div className="media-body lh-14">
                    <h5 className="mb-3 lh-1 d-flex align-items-end">
                      <svg className="icon icon-checkmark-circle" style={{fill:"#006600"}}>
                        <use xlinkHref="#icon-checkmark-circle" />
                      </svg>
                      &nbsp; Acceleration
                    </h5>
                    <p className="font-size-md text-gray mb-0 text-muted text-justify">
                      Enjoy your membership offerings with community Networking
                    </p>
                  </div>
                </motion.div>
              </div>
            </motion.div>
            <div className="border-bottom" />
          </div>
        </div>
      </section>
      <div className="container pb-3">
        <Grid
          container
        >
          <JoinWithUs
            joinWithUs={joinWithUs}
            navigateToMember={handleBeaMemeber}
            navigateToPartner={handleBeaPartner} />
        </Grid>
        <div className="border-bottom" />
      </div>
      <HomeOurDirectory />
      <section id="section-04">
        <ClientReview
          cardsPerScreen={2}
          title={
            <h2 className="mb-3">
              <span className="font-weight-semibold">Clients </span>
              <span className="font-weight-light">Review</span>
            </h2>
          }
        />
      </section>
      {/* /#section-04 end */}
      {/* #section 05 start */}
      <HomeArticles />
    </div>
  );
};

const region = [
  {
    region_name: "Austin",
  },
  {
    region_name: "Boston",
  },
  {
    region_name: "Chicago",
  },
  {
    region_name: "Denver",
  },
  {
    region_name: "Los Angeles",
  },
  {
    region_name: "New York",
  },
  {
    region_name: "San Francisco",
  },
  {
    region_name: "Seattle",
  },
  {
    region_name: "Washington",
  },
];

const highlights = [
  {
    highlights_name: "Foods",
    svg_icon: "icon-pizza",
  },
  {
    highlights_name: "Hotels",
    svg_icon: "icon-bed",
  },
  {
    highlights_name: "Jobs",
    svg_icon: "icon-brush2",
  },
  {
    highlights_name: "Medicals",
    svg_icon: "icon-pharmaceutical",
  },
  {
    highlights_name: "Services",
    svg_icon: "icon-cog",
  },
  {
    highlights_name: "Shopping",
    svg_icon: "icon-bag",
  },
  {
    highlights_name: "Automotive",
    svg_icon: "icon-car",
  },
];
export default Home;
