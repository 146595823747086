import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Form } from "../../common/useForms";
import Input from "../../common/Input";
import { useNavigate } from "react-router-dom";
import ButtonCom from "../../common/Button";
import Logo from "../../assets/images/logo-home.png";
import { themeSettings } from "../../theme";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  main: {
    background:
      "linear-gradient(317deg, rgba(254,118,120,1) 9%, rgba(21,62,128,1) 100%)",
    height: "100%",

    [theme.breakpoints.up("sm")]: {
      height: "100vh",
    },

    [theme.breakpoints.up("md")]: {
      height: "100vh",
    },

    display: "flex",
    alignItems: "center",
  },
  subMain: {
    width: "450px",
    margin: "0 auto",
    backgroundColor: "#fff",
    borderRadius: "20px",
  },
  resendButton: {
    color: "blue !important",
    fontWeight: 600,
    "&.Mui-disabled": {
      fontWeight: 600,
      color: "gray !important", // Change text color to white when disabled
    },
  },

  logo: {
    marginBottom: "20px",
    width: "60% !important",
    display: "block",
    margin: "0 auto",
  },
  mainCard: {
    boxShadow: "none !important",
    color: "#000 !important",
    display: "block",
    textAlign: "center",
    minWidth: 275,
  },
  boxCard: {
    [theme.breakpoints.up("xs")]: {
      padding: "20px",
    },

    [theme.breakpoints.up("md")]: {
      padding: "40px",
    },
  },

  typography: {
    fontSize: "24px !important",
    fontWeight: "bold !important",
    color: "#000",
  },

  resendBtnMain: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "13px",
  },

  subResendBtnMain: {
    display: "block",
    margin: "auto 0",
  },

  send: {
    ":hover": { bgcolor: "#153E80", color: "#FFFFFF" },
    marginTop: "20px !important",
    textTransform: "none !important",
    fontSize: "16px !important",
    color: "#FFFFFF !important",
    width: "100%",
    backgroundColor: "#153E80 !important",
    border: "2px solid #153E80 !important",
    borderRadius: "15px !important",
    padding: "10px 40px !important",
    "&.Mui-disabled": {
      backgroundColor: "#153E80 !important",
      color: "#fff !important",
    },
    marginLeft: "3px !important",
  },
}));

const Otp = () => {
  const classes = useStyles();
  const otpTimer = 2;
  const theme = useTheme();
  const navigate = useNavigate();

  const typography = themeSettings(theme.palette.mode);

  const [OTP, setOTP] = useState();
  const [counter, setCounter] = useState(otpTimer);

  const handleChange = (event) => {
    setOTP((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const digit1 = useRef(null);
  const digit2 = useRef(null);
  const digit3 = useRef(null);
  const digit4 = useRef(null);
  const digit5 = useRef(null);
  const digit6 = useRef(null);

  const handleOTP = (e) => {
    if (e.key === "Backspace") {
      if (e.target.name === "digit2") {
        digit1.current.focus();
      }
      if (e.target.name === "digit3") {
        digit2.current.focus();
      }
      if (e.target.name === "digit4") {
        digit3.current.focus();
      }
      if (e.target.name === "digit5") {
        digit4.current.focus();
      }
      if (e.target.name === "digit6") {
        digit5.current.focus();
      }
    } else {
      if (e.target.name === "digit1" && e.target.value) {
        digit2.current.focus();
      }
      if (e.target.name === "digit2" && e.target.value) {
        digit3.current.focus();
      }
      if (e.target.name === "digit3" && e.target.value) {
        digit4.current.focus();
      }
      if (e.target.name === "digit4" && e.target.value) {
        digit5.current.focus();
      }
      if (e.target.name === "digit5" && e.target.value) {
        digit6.current.focus();
      }
    }
  };

  const handleOtpChange = (e) => {
    e.preventDefault();
    setCounter(otpTimer);
  };

  return (
    <Box className={classes.main}>
      <Box className={classes.subMain}>
        <Grid
          container
          item
          columns={{ xs: 4, sm: 12, md: 12 }}
          spacing={1}
          sx={{
            alignItems: "center",
          }}
        >
          <Grid item xs={12} md={12}>
            <Box className={classes.boxCard}>
              <CardMedia
                component="img"
                image={Logo}
                alt="Paella dish"
                className={classes.logo}
              />
              <Box>
                <Card className={classes.mainCard}>
                  <CardContent>
                    <Typography fontSize={typography.typography.h5.fontSize}>
                      Welcome
                    </Typography>
                    <Typography fontSize={typography.typography.h5.fontSize}>
                      to
                    </Typography>
                    <Typography fontSize={typography.typography.h5.fontSize}>
                      Global Garner Community
                    </Typography>
                    <br />
                    <Typography fontSize={typography.typography.h5.fontSize}>
                      Building a stronger tomorrow
                    </Typography>
                  </CardContent>
                </Card>
              </Box>

              <Typography className={classes.typography}>
                Enter OTP recieved on mobile.
              </Typography>

              <Form>
                <Grid>
                  <Box display="flex">
                    <Box
                      sx={{
                        marginLeft: "10px",
                      }}
                    >
                      <div>
                        <Input
                          margin="normal"
                          fullWidth
                          id="digit1"
                          name="digit1"
                          type="text"
                          inputRef={digit1}
                          onChange={handleChange}
                          onKeyUp={handleOTP}
                          sx={{ mr: 5 }}
                          maxLength={1}
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            );
                          }}
                        />
                      </div>
                    </Box>
                    <Box sx={{ marginLeft: "10px" }}>
                      <Input
                        margin="normal"
                        fullWidth
                        id="digit2"
                        name="digit2"
                        type="text"
                        inputRef={digit2}
                        onChange={handleChange}
                        onKeyUp={handleOTP}
                        sx={{ mr: 5, display: "flex", textAlign: "center" }}
                        maxLength={1}
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                        }}
                      />
                    </Box>{" "}
                    <Box sx={{ marginLeft: "10px" }}>
                      <Input
                        margin="normal"
                        fullWidth
                        id="digit3"
                        name="digit3"
                        type="text"
                        inputRef={digit3}
                        onChange={handleChange}
                        onKeyUp={handleOTP}
                        sx={{ mr: 5, display: "flex", textAlign: "center" }}
                        maxLength={1}
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                        }}
                      />
                    </Box>{" "}
                    <Box sx={{ marginLeft: "10px" }}>
                      <Input
                        margin="normal"
                        fullWidth
                        id="digit4"
                        name="digit4"
                        type="text"
                        inputRef={digit4}
                        onChange={handleChange}
                        onKeyUp={handleOTP}
                        sx={{ mr: 5, display: "flex", textAlign: "center" }}
                        maxLength={1}
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                        }}
                      />
                    </Box>{" "}
                    <Box sx={{ marginLeft: "10px" }}>
                      <Input
                        margin="normal"
                        fullWidth
                        id="digit5"
                        name="digit5"
                        type="text"
                        inputRef={digit5}
                        onChange={handleChange}
                        onKeyUp={handleOTP}
                        sx={{ mr: 5, display: "flex", textAlign: "center" }}
                        maxLength={1}
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                        }}
                      />
                    </Box>{" "}
                    <Box sx={{ marginLeft: "10px" }}>
                      <Input
                        margin="normal"
                        fullWidth
                        id="digit6"
                        name="digit6"
                        type="text"
                        inputRef={digit6}
                        onChange={handleChange}
                        onKeyUp={handleOTP}
                        sx={{ mr: 5, display: "flex", textAlign: "center" }}
                        maxLength={1}
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                        }}
                      />
                    </Box>
                  </Box>
                  <div className={classes.resendBtnMain}>
                    <div className={classes.subResendBtnMain}>
                      <span>
                        Time Remaining:{" "}
                        {counter % 60 < 10
                          ? `${Math.floor(counter / 60)} : 0${counter % 60}`
                          : `${Math.floor(counter / 60)} : ${counter % 60}`}
                      </span>
                    </div>
                    <div>
                      <Button
                        variant="text"
                        className={classes.resendButton}
                        disabled={!!counter}
                        onClick={handleOtpChange}
                      >
                        Resend OTP
                      </Button>
                    </div>
                  </div>
                </Grid>

                <ButtonCom
                  className={classes.send}
                  type="submit"
                  text="Send"
                  onClick={() => navigate("/admin/dashboard")}
                ></ButtonCom>
              </Form>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Otp;
