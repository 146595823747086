import React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import "./table.css";
import { Chip } from "@mui/material";
import { getVariantChip } from "../../common-control";

const EnhancedTableRow = ({
  row,
  columns,
  isSelected,
  handleClick,
  labelId,
}) => {
  return (
    <TableRow
      hover
      // onClick={(event) => handleClick(event, row.name)}
      role="checkbox"
      aria-checked={isSelected}
      tabIndex={-1}
      key={row.name}
      selected={isSelected}
      sx={{
        cursor: "pointer",
        textAlign: "left !important",
      }}
    >
      <TableCell padding="checkbox">
        {/* <Checkbox
          className="mui-table-checkbox"
          color="primary"
          checked={isSelected}
          inputProps={{
            "aria-labelledby": labelId,
          }}
        /> */}
      </TableCell>
      {columns.map((column) => (
        <TableCell
          key={column.id}
          align={column.numeric ? "right" : "left"}
          sx={{
            textAlign: "left !important",
            // paddingLeft: "35px",
            color: "black !important",
          }}
        >
          {["status", "is_active"].includes(column?.id) ? (
            <Chip
              label={row[column.id].toUpperCase()}
              color={getVariantChip(row[column.id])}
              onClick={handleClick ? (() => handleClick(row)) : (() => {})}
            />
          ) : (
            row[column.id]
          )}
        </TableCell>
      ))}
    </TableRow>
  );
};

export default EnhancedTableRow;
