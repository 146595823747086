import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import styled from "@emotion/styled";
import moment from "moment/moment";
import SelectBox from "../../common/SelectBox";
import axios from "../../redux/ApiConfig";
import { ApiURL, snackBar } from "../../redux/common";
import { getPermissions } from "../../common/permissions";
import { permissions } from "../../common/constants";
import BusinessDocCard from "../businessDocCard/BusinessDocCard";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));

const statusOptions = [
  { label: "Pending", value: "pending" },
  { label: "Approved", value: "approved" },
  { label: "Rejected", value: "rejected" },
];

const ManageUserDetails = () => {
  const navigate = useNavigate("");

  const state = useLocation();
  const userDetails = state?.state?.partnerData;

  const [status, setStatus] = useState(
    userDetails?.approval_status || "pending"
  );
  const [documents, setDocuments] = useState([]);

  const formattedDate = moment(userDetails?.birth_date).format("DD/MM/YYYY");

  const handleChange = (e) => {
    setStatus(e.target.value);
  };

  const handleSubmit = async () => {
    // update api call
    try {
      const formData = new FormData();
      formData.append("approval_status", status);
      const res = await axios.put(
        `${ApiURL}user/update/${userDetails._id}`,
        formData
      );
      if (res?.data?.status) {
        snackBar(res?.data?.message, res?.data?.status);
        navigate("/admin-user");
      }
    } catch (err) {}
  };

  const getUserDetails = async (id) => {
    try {
      const res = await axios.get(`${ApiURL}user/get-user-by-id/${id}`);
      if (res?.data?.status) {
        setDocuments(res?.data?.data[0]?.business_documents);
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (userDetails?.business?.document_uploaded === true) {
      getUserDetails(userDetails?._id);
    }
  }, []);

  return (
    <div>
      <Box
        sx={{
          px: 3,
          paddingBottom: "3px",
          // backgroundColor: "rgb(241, 245, 249)",
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="baseline"
        >
          <ArrowCircleLeftOutlinedIcon
            fontSize="large"
            onClick={() => navigate("/admin-user")}
            sx={{ cursor: "pointer", marginTop: "5px", marginBottom: "5px" }}
          />
        </Box>
      </Box>

      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            sx={{
              paddingBottom: "25px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Card
              sx={{
                width: "90%",
                marginTop: "0px",
                height: "100%",
                backgroundColor: "#d1d1d17a",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: "20px",
                  color: "black",
                  marginTop: "-11px",
                  padding: "22px 15px",
                }}
              >
                User Details
              </Typography>
              <Box
                sx={{
                  flexGrow: 1,
                  marginTop: "10px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        fontWeight: "100",
                        fontSize: "17px",
                        marginTop: "-11px",
                        padding: " 15px",
                        display: "flex",
                        color: "#605a5a",
                      }}
                    >
                      Name
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        marginTop: "-31px",
                        padding: " 15px",
                        display: "flex",
                        color: "black",
                      }}
                    >
                      {userDetails?.first_name || "-"}{" "}
                      {userDetails?.last_name || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      sx={{
                        fontWeight: "100",
                        fontSize: "17px",
                        marginTop: "-11px",
                        padding: " 15px",
                        display: "flex",
                        color: "#605a5a",
                      }}
                    >
                      Email
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        marginTop: "-31px",
                        padding: " 15px",
                        display: "flex",
                        color: "black",
                      }}
                    >
                      {userDetails?.email || "-"}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        fontWeight: "100",
                        fontSize: "17px",
                        marginTop: "-11px",
                        padding: " 15px",
                        display: "flex",
                        color: "#605a5a",
                      }}
                    >
                      Mobile Number
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        marginTop: "-31px",
                        padding: " 15px",
                        display: "flex",
                        color: "black",
                      }}
                    >
                      {userDetails?.mobile_number || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      sx={{
                        fontWeight: "100",
                        fontSize: "17px",
                        marginTop: "-11px",
                        padding: " 15px",
                        display: "flex",
                        color: "#605a5a",
                      }}
                    >
                      Gender
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        marginTop: "-31px",
                        padding: " 15px",
                        display: "flex",
                        color: "black",
                      }}
                    >
                      {userDetails?.gender || "-"}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        fontWeight: "100",
                        fontSize: "17px",
                        marginTop: "-11px",
                        padding: " 15px",
                        display: "flex",
                        color: "#605a5a",
                      }}
                    >
                      Birth date
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        marginTop: "-31px",
                        padding: " 15px",
                        display: "flex",
                        color: "black",
                      }}
                    >
                      {formattedDate || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      sx={{
                        fontWeight: "100",
                        fontSize: "17px",
                        marginTop: "-11px",
                        padding: " 15px",
                        display: "flex",
                        color: "#605a5a",
                      }}
                    >
                      Chapter
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        marginTop: "-31px",
                        padding: " 15px",
                        display: "flex",
                        color: "black",
                      }}
                    >
                      {userDetails?.chapter_name || "-"}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        fontWeight: "100",
                        fontSize: "17px",
                        marginTop: "-11px",
                        padding: " 15px",
                        display: "flex",
                        color: "#605a5a",
                      }}
                    >
                      Role
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        marginTop: "-31px",
                        padding: " 15px",
                        display: "flex",
                        color: "black",
                      }}
                    >
                      {userDetails?.role || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      sx={{
                        fontWeight: "100",
                        fontSize: "17px",
                        marginTop: "-11px",
                        padding: " 15px",
                        display: "flex",
                        color: "#605a5a",
                      }}
                    >
                      Status
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        marginTop: "-31px",
                        padding: " 15px",
                        display: "flex",
                        color: "black",
                      }}
                    >
                      Active
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        fontWeight: "100",
                        fontSize: "17px",
                        marginTop: "-11px",
                        padding: " 15px",
                        display: "flex",
                        color: "#605a5a",
                      }}
                    >
                      Address Proof
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        marginTop: "-31px",
                        padding: " 15px",
                        display: "flex",
                        color: "black",
                      }}
                    >
                      {userDetails?.address || "-"}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            sx={{
              paddingBottom: "25px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Card
              sx={{
                width: "90%",
                marginTop: "0px",
                height: "100%",
                backgroundColor: "#d1d1d17a",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: "20px",
                  color: "black",
                  marginTop: "-11px",
                  padding: "22px 15px",
                }}
              >
                Business Details
              </Typography>
              <Box
                sx={{
                  flexGrow: 1,
                  marginTop: "10px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        fontWeight: "100",
                        fontSize: "17px",
                        marginTop: "-11px",
                        padding: " 15px",
                        display: "flex",
                        color: "#605a5a",
                      }}
                    >
                      Business Name
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        marginTop: "-31px",
                        padding: " 15px",
                        display: "flex",
                        color: "black",
                      }}
                    >
                      {userDetails?.business?.business_name || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      sx={{
                        fontWeight: "100",
                        fontSize: "17px",
                        marginTop: "-11px",
                        padding: " 15px",
                        display: "flex",
                        color: "#605a5a",
                      }}
                    >
                      Business Owner
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        marginTop: "-31px",
                        padding: " 15px",
                        display: "flex",
                        color: "black",
                      }}
                    >
                      {userDetails?.business?.owner_firstname || "-"}{" "}
                      {userDetails?.business?.owner_lastname || "-"}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        fontWeight: "100",
                        fontSize: "17px",
                        marginTop: "-11px",
                        padding: " 15px",
                        display: "flex",
                        color: "#605a5a",
                      }}
                    >
                      Business Mobile Number
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        marginTop: "-31px",
                        padding: " 15px",
                        display: "flex",
                        color: "black",
                      }}
                    >
                      {userDetails?.business?.business_contact || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      sx={{
                        fontWeight: "100",
                        fontSize: "17px",
                        marginTop: "-11px",
                        padding: " 15px",
                        display: "flex",
                        color: "#605a5a",
                      }}
                    >
                      Business Email
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        marginTop: "-31px",
                        padding: " 15px",
                        display: "flex",
                        color: "black",
                      }}
                    >
                      {userDetails?.business?.business_email || "-"}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        fontWeight: "100",
                        fontSize: "17px",
                        marginTop: "-11px",
                        padding: " 15px",
                        display: "flex",
                        color: "#605a5a",
                      }}
                    >
                      Website
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        marginTop: "-31px",
                        padding: " 15px",
                        display: "flex",
                        color: "black",
                      }}
                    >
                      {userDetails?.business?.business_website || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      sx={{
                        fontWeight: "100",
                        fontSize: "17px",
                        marginTop: "-11px",
                        padding: " 15px",
                        display: "flex",
                        color: "#605a5a",
                      }}
                    >
                      Business Category
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        marginTop: "-31px",
                        padding: " 15px",
                        display: "flex",
                        color: "black",
                      }}
                    >
                      {userDetails?.business_category || "-"}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        fontWeight: "100",
                        fontSize: "17px",
                        marginTop: "-11px",
                        padding: " 15px",
                        display: "flex",
                        color: "#605a5a",
                      }}
                    >
                      Business Type
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        marginTop: "-31px",
                        padding: " 15px",
                        display: "flex",
                        color: "black",
                      }}
                    >
                      {userDetails?.business?.business_type || "Private Ltd"}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      sx={{
                        fontWeight: "100",
                        fontSize: "17px",
                        marginTop: "-11px",
                        padding: " 15px",
                        display: "flex",
                        color: "#605a5a",
                      }}
                    >
                      Annual Turnover
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        marginTop: "-31px",
                        padding: " 15px",
                        display: "flex",
                        color: "black",
                      }}
                    >
                      {userDetails?.business?.annual_turnover || "-"}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        fontWeight: "100",
                        fontSize: "17px",
                        marginTop: "-11px",
                        padding: " 15px",
                        display: "flex",
                        color: "#605a5a",
                      }}
                    >
                      Established Year
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        marginTop: "-31px",
                        padding: " 15px",
                        display: "flex",
                        color: "black",
                      }}
                    >
                      {userDetails?.business?.establish_year || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      sx={{
                        fontWeight: "100",
                        fontSize: "17px",
                        marginTop: "-11px",
                        padding: " 15px",
                        display: "flex",
                        color: "#605a5a",
                      }}
                    >
                      Document Uploaded
                    </Typography>

                    {userDetails?.business?.document_uploaded === false ? (
                      <Typography
                        sx={{
                          fontWeight: "600",
                          fontSize: "18px",
                          marginTop: "-31px",
                          padding: " 15px",
                          display: "flex",
                          color: "black",
                        }}
                      >
                        No
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          fontWeight: "600",
                          fontSize: "18px",
                          marginTop: "-31px",
                          padding: " 15px",
                          display: "flex",
                          color: "black",
                        }}
                      >
                        Yes
                      </Typography>
                    )}
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        fontWeight: "100",
                        fontSize: "17px",
                        marginTop: "-11px",
                        padding: " 15px",
                        display: "flex",
                        color: "#605a5a",
                      }}
                    >
                      Business Address
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        marginTop: "-31px",
                        padding: " 15px",
                        display: "flex",
                        color: "black",
                      }}
                    >
                      {userDetails?.business?.address || "-"}
                    </Typography>
                  </Grid>
                </Grid>

                <Typography
                  sx={{
                    fontWeight: "100",
                    fontSize: "17px",
                    marginTop: "-11px",
                    padding: " 15px",
                    display: "flex",
                    color: "#605a5a",
                  }}
                >
                  Representative
                </Typography>
                {userDetails?.business?.representatives?.map((rows) => {
                  return (
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography
                          sx={{
                            fontWeight: "100",
                            fontSize: "17px",
                            marginTop: "-11px",
                            padding: " 15px",
                            display: "flex",
                            color: "#605a5a",
                          }}
                        >
                          Name
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: "600",
                            fontSize: "18px",
                            marginTop: "-31px",
                            padding: " 15px",
                            display: "flex",
                            color: "black",
                          }}
                        >
                          {rows?.first_name || "-"}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          sx={{
                            fontWeight: "100",
                            fontSize: "17px",
                            marginTop: "-11px",
                            padding: " 15px",
                            display: "flex",
                            color: "#605a5a",
                          }}
                        >
                          Email
                        </Typography>

                        <Typography
                          sx={{
                            fontWeight: "600",
                            fontSize: "18px",
                            marginTop: "-31px",
                            padding: " 15px",
                            display: "flex",
                            color: "black",
                          }}
                        >
                          {rows?.email || "-"}
                        </Typography>
                      </Grid>

                      <Grid item xs={4}>
                        <Typography
                          sx={{
                            fontWeight: "100",
                            fontSize: "17px",
                            marginTop: "-11px",
                            padding: " 15px",
                            display: "flex",
                            color: "#605a5a",
                          }}
                        >
                          Mobile Number
                        </Typography>

                        <Typography
                          sx={{
                            fontWeight: "600",
                            fontSize: "18px",
                            marginTop: "-31px",
                            padding: " 15px",
                            display: "flex",
                            color: "black",
                          }}
                        >
                          {rows?.mobile_number || "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                })}
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>

      {userDetails?.business?.document_uploaded && (
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{
                paddingBottom: "25px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Card
                sx={{
                  width: "90%",
                  marginTop: "0px",
                  height: "100%",
                  backgroundColor: "#d1d1d17a",
                }}
              >
                <Box
                  sx={{
                    flexGrow: 1,
                    marginTop: "10px",
                    marginBottom: "20px",
                    marginLeft: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "700",
                      fontSize: "20px",
                      color: "black",
                      marginTop: "-11px",
                      padding: "22px 0px",
                    }}
                  >
                    Business Documents
                  </Typography>
                  <Grid container spacing={1}>
                    {documents[0]?.gst_business_document && (
                      <BusinessDocCard
                        data={{
                          business_document:
                            documents[0]?.gst_business_document,
                          document_type: "GST",
                          status: documents[0]?.gst_status,
                          statusKey: "gst_status",
                          _id: documents[0]?._id,
                        }}
                      />
                    )}
                    {documents[0]?.pan_business_document && (
                      <BusinessDocCard
                        data={{
                          business_document:
                            documents[0]?.pan_business_document,
                          document_type: "PAN",
                          status: documents[0]?.pan_status,
                          statusKey: "pan_status",
                          _id: documents[0]?._id,
                        }}
                      />
                    )}
                  </Grid>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Box>
      )}

      {userDetails?.membership === null ? (
        <></>
      ) : (
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{
                paddingBottom: "25px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Card
                sx={{
                  width: "90%",
                  marginTop: "0px",
                  height: "100%",
                  backgroundColor: "#d1d1d17a",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "700",
                    fontSize: "20px",
                    color: "black",
                    marginTop: "-11px",
                    padding: "22px 15px",
                  }}
                >
                  Membership Details
                </Typography>
                <Box
                  sx={{
                    flexGrow: 1,
                    marginTop: "10px",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography
                        sx={{
                          fontWeight: "100",
                          fontSize: "17px",
                          marginTop: "-11px",
                          padding: " 15px",
                          display: "flex",
                          color: "#605a5a",
                        }}
                      >
                        Membership Name
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "600",
                          fontSize: "18px",
                          marginTop: "-31px",
                          padding: " 15px",
                          display: "flex",
                          color: "black",
                        }}
                      >
                        {userDetails?.membership?.membership_name || "-"}
                      </Typography>
                    </Grid>

                    <Grid item xs={4}>
                      <Typography
                        sx={{
                          fontWeight: "100",
                          fontSize: "17px",
                          marginTop: "-11px",
                          padding: " 15px",
                          display: "flex",
                          color: "#605a5a",
                        }}
                      >
                        Monthly Price
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "600",
                          fontSize: "18px",
                          marginTop: "-31px",
                          padding: " 15px",
                          display: "flex",
                          color: "black",
                        }}
                      >
                        {userDetails?.membership?.monthly_price || "-"}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography
                        sx={{
                          fontWeight: "100",
                          fontSize: "17px",
                          marginTop: "-11px",
                          padding: " 15px",
                          display: "flex",
                          color: "#605a5a",
                        }}
                      >
                        Max Range Amount
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "600",
                          fontSize: "18px",
                          marginTop: "-31px",
                          padding: " 15px",
                          display: "flex",
                          color: "black",
                        }}
                      >
                        {userDetails?.membership?.max_range_amount || "-"}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        sx={{
                          fontWeight: "100",
                          fontSize: "17px",
                          marginTop: "-11px",
                          padding: " 15px",
                          display: "flex",
                          color: "#605a5a",
                        }}
                      >
                        Min Range Amount
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "600",
                          fontSize: "18px",
                          marginTop: "-31px",
                          padding: " 15px",
                          display: "flex",
                          color: "black",
                        }}
                      >
                        {userDetails?.membership?.min_range_amount || "-"}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography
                        sx={{
                          fontWeight: "100",
                          fontSize: "17px",
                          marginTop: "-11px",
                          padding: " 15px",
                          display: "flex",
                          color: "#605a5a",
                        }}
                      >
                        Range type
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "600",
                          fontSize: "18px",
                          marginTop: "-31px",
                          padding: " 15px",
                          display: "flex",
                          color: "black",
                        }}
                      >
                        {userDetails?.membership?.range_type || "-"}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        sx={{
                          fontWeight: "100",
                          fontSize: "17px",
                          marginTop: "-11px",
                          padding: " 15px",
                          display: "flex",
                          color: "#605a5a",
                        }}
                      >
                        Yearly Price
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "600",
                          fontSize: "18px",
                          marginTop: "-31px",
                          padding: " 15px",
                          display: "flex",
                          color: "black",
                        }}
                      >
                        {userDetails?.membership?.yearly_price || "-"}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography
                        sx={{
                          fontWeight: "100",
                          fontSize: "17px",
                          marginTop: "-11px",
                          padding: " 15px",
                          display: "flex",
                          color: "#605a5a",
                        }}
                      >
                        Discount
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "600",
                          fontSize: "18px",
                          marginTop: "-31px",
                          padding: " 15px",
                          display: "flex",
                          color: "black",
                        }}
                      >
                        {userDetails?.membership?.discount || "-"}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          fontWeight: "100",
                          fontSize: "17px",
                          marginTop: "-11px",
                          padding: " 15px",
                          display: "flex",
                          color: "#605a5a",
                        }}
                      >
                        Description
                      </Typography>
                      {userDetails?.membership?.description?.map(
                        (item, index) => (
                          <div key={index}>
                            <Typography
                              key={`${index}-title`}
                              sx={{
                                fontWeight: "600",
                                fontSize: "18px",
                                marginTop: "-31px",
                                padding: " 15px",
                                display: "flex",
                                color: "black",
                              }}
                            >
                              {item.title || "-"}
                            </Typography>

                            {item?.["sub-points"]?.map((row, subIndex) => (
                              <Typography
                                key={`${index}-${subIndex}`}
                                sx={{
                                  fontWeight: "200",
                                  fontSize: "18px",
                                  marginTop: "-31px",
                                  padding: " 15px",
                                  display: "flex",
                                  color: "black",
                                }}
                              >
                                -{row || "-"}
                              </Typography>
                            ))}
                          </div>
                        )
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Box>
      )}

      {!!getPermissions(permissions.user, "is_edit") && (
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{
                paddingBottom: "25px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Card
                sx={{
                  width: "90%",
                  marginTop: "0px",
                  height: "100%",
                  backgroundColor: "#d1d1d17a",
                }}
              >
                <Box
                  sx={{
                    flexGrow: 1,
                    marginTop: "10px",
                    marginBottom: "20px",
                    marginLeft: "10px",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <SelectBox
                        type="text"
                        id="approval_status"
                        name="approval_status"
                        label="Status"
                        ClassName="range-select-filter"
                        value={status}
                        onChange={handleChange}
                        options={statusOptions}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        onClick={handleSubmit}
                        // disabled={!isAnyFieldNotEmpty}
                        sx={{
                          marginTop: "15px",
                          fontSize: "14px",
                          fontWeight: "600",
                          border: "0",
                          // color: isAnyFieldNotEmpty ? "#fff" : "#fff !important",
                          ":hover": { bgcolor: "#1976d2", border: "0" },
                          backgroundColor: "#153e80",
                          marginLeft: {
                            xs: "0px",
                            sm: "15px",
                            md: "15px",
                            lg: "15px",
                          },
                          color: "#fff",
                          textTransform: "none",
                          minWidth: "100px",
                          // opacity: isAnyFieldNotEmpty ? 1 : 0.5,
                          // pointerEvents: isAnyFieldNotEmpty ? "auto" : "none",
                        }}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Box>
      )}
    </div>
  );
};

export default ManageUserDetails;
