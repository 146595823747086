import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GetUserProfile,
  UpdateUserBusinessManage,
  UpdateUserManage,
  UpdateUserProfile,
} from "../redux/action/UserManageAction";
import { CloseIcon, EditIcon } from "../assets/icons/Icons";
import FormDialog from "../components/dialog/dialog";
import { useForm } from "../common/useForms";
import DefaultProfile from "../assets/images/Profile.png";
import { FormControl, InputLabel, Select } from "@material-ui/core";
import { api } from "../web/config/api";
import { ApiURL, snackBar } from "../redux/common";
import { GetAllCountry } from "../redux/action/CountryAction";
import { GetBusinessCategoryAll } from "../web/redux/findserachAction";
import axios from "../redux/ApiConfig";
import BusinessDocCard from "../components/businessDocCard/BusinessDocCard";
import { Link, useNavigate } from "react-router-dom";
import MuiPhoneNumber from "material-ui-phone-number";
import CopyToClipboardButton from "./CopiedLink";

const useStyles = makeStyles((theme) => ({
  image: {
    marginTop: "92px",
    marginLeft: "6px",
  },

  mainCompDetails: {
    display: "contents",
    alignItems: "center",
    justifyContent: "center",
  },

  [theme.breakpoints.down("xs")]: {
    mainCompDetails: {
      display: "flex",
      flexGrow: 1,
      flexDirection: "column",
      alignItems: "center",
    },
  },
}));

const StyledIcon = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: "center",
  marginBottom: theme.spacing(3),
}));

const Profile = () => {
  const nav = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [popupName, setPopupName] = useState("");
  const [editImageValue, setEditImageValue] = useState();
  const [role, setRole] = useState("");
  const [EditModal, setEditModal] = useState(false);
  const [EditBusinessModal, setEditBusinessModal] = useState(false);
  const [EditBusinessDocModal, setEditBusinessDocModal] = useState(false);

  const [allBusinessCategory, setAllBusinessCategory] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [selectedImage1, setSelectedImage1] = useState();
  const [selectedImage2, setSelectedImage2] = useState();
  const [selectedImage3, setSelectedImage3] = useState();

  const [valuess, setValuess] = useState({
    bank_name: "",
    acc_number: "",
    ifsc_code: "",
    acc_holder_name: "",
  });

  const profileData = useSelector(
    (state) => state.UserManageReducer.userProfileData
  );

  const initialValueBusiness = {
    business_name: "",
    owner_firstname: "",
    owner_lastname: "",
    business_logo: "",
    business_email: "",
    business_contact: "",
    establish_year: "",
    business_category_id: "",
    business_category_name: "",
    annual_turnover: "",
    business_website: "",
    address: "",
    // city_id: "",
    // state_id: "",
    // postalcode_id: "",
    // country: {
    //   _id: profileData?.data?.business?.representatives || "",
    //   country_name: profileData?.data?.country_name || "",
    // },
    // representatives: "",
    business_card: "",
    is_active: "",
    business_gst: "",
    business_pan: "",
    business_type: "",
    gst_business_document: "",
    pan_business_document: "",
    business_logo: "",
  };
  const [valuesBusiness, setValuesBusiness] = useState(initialValueBusiness);

  const [statusUpdateLoading, setStatusUpdateLoading] = useState(false);
  const [defaultRoleUpdateLoading, setDefaultRoleUpdateLoading] =
    useState(false);

  const [representatives, setRepresentatives] = useState([
    {
      mobile_number: "",
      email: "",
      first_name: "",
    },
  ]);

  const addRepresentative = () => {
    setRepresentatives([
      ...representatives,
      { mobile_number: "", email: "", first_name: "" }, // <-- Problematic line
    ]);
  };

  const deleteRepresentative = (index) => {
    const updatedRepresentatives = [...representatives];
    updatedRepresentatives.splice(index, 1);
    setRepresentatives(updatedRepresentatives);
  };

  const handleInputChangeRep = (index, field, value) => {
    const updatedRepresentatives = [...representatives];
    updatedRepresentatives[index][field] = value;
    setRepresentatives(updatedRepresentatives);
  };

  const handleImageUpload1 = (e) => {
    const file = e.target.files[0];
    setSelectedImage1(file);
  };

  const handleImageUpload3 = (e) => {
    const file = e.target.files[0];
    setSelectedImage3(file);
  };

  const handleImageUpload2 = (e) => {
    const file = e.target.files[0];
    setSelectedImage2(file);
  };

  const handleBankModal = () => {
    setEditModal(true);
  };

  const handleBankCloseModal = () => {
    setEditModal(false);
  };

  const handleBusinessModal = () => {
    setEditBusinessModal(true);
  };

  const handleBusinessCloseModal = () => {
    setEditBusinessModal(false);
  };

  const handleBusinessDocModal = () => {
    setEditBusinessDocModal(true);
    if (profileData?.data?.business_documents[0]) {
      setSelectedImage1(
        profileData?.data?.business_documents[0]?.gst_business_document
      );
      setSelectedImage2(
        profileData?.data?.business_documents[0]?.pan_business_document
      );
    }
  };

  const handleBusinessDocCloseModal = () => {
    setEditBusinessDocModal(false);
  };

  useEffect(() => {
    if (profileData?.data?.bank_details) {
      setValuess({
        ...valuess,
        bank_name: profileData?.data?.bank_details?.bank_name,
        acc_number: profileData?.data?.bank_details?.acc_number,
        ifsc_code: profileData?.data?.bank_details?.ifsc_code,
        acc_holder_name: profileData?.data?.bank_details?.acc_holder_name,
        membership_validity: profileData?.data?.membership_validity,
      });
    }
  }, [profileData?.data?.bank_details]);

  useEffect(() => {
    if (profileData?.data?.business) {
      setValuesBusiness({
        ...valuesBusiness,

        business_name: profileData?.data?.business?.business_name || "",
        owner_firstname: profileData?.data?.business?.owner_firstname || "",
        owner_lastname: profileData?.data?.business?.owner_lastname || "",
        business_logo: profileData?.data?.business?.business_logo || "",
        business_email: profileData?.data?.business?.business_email || "",
        business_contact: profileData?.data?.business?.business_contact || "",
        establish_year: profileData?.data?.business?.establish_year || "",
        business_category_name: profileData?.data?.business_category || "",
        business_category_id:
          profileData?.data?.business?.business_category_id || "",
        annual_turnover: profileData?.data?.business?.annual_turnover || "",
        business_website: profileData?.data?.business?.business_website || "",
        address: profileData?.data?.business?.address || "",
        // city_id: profileData?.data?.business?.city_id,
        // state_id: profileData?.data?.business?.state_id,
        // postalcode_id: profileData?.data?.business?.postalcode_id,
        // country_id: profileData?.data?.business?.country_id,
        // representatives: profileData?.data?.business?.representatives,
        business_card: profileData?.data?.business?.business_card || "",
        is_active: profileData?.data?.business?.is_active,
        business_gst: profileData?.data?.business?.business_gst || "",
        business_pan: profileData?.data?.business?.business_pan || "",
        business_type: profileData?.data?.business?.business_type || "",
        gst_business_document:
          profileData?.data?.business?.gst_business_document,
        pan_business_document:
          profileData?.data?.business?.pan_business_document,
      });

      setRepresentatives(profileData?.data?.business?.representatives);
    }
  }, [profileData?.data?.business]);

  const userDataString = localStorage.getItem("user_data");
  const userData = JSON.parse(userDataString);

  const userRole =
    userData?.admin?.user_role?.role || userData?.user?.user_role?.role;

  const initialValues = { name: "", email: "", profile_picture: "" };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values,
    setFieldValues,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  } = useForm(initialValues, true, validate);

  const fields = [
    {
      id: "name",
      label: "Name",
      type: "text",
      value: values.name,
      name: "name",
    },

    {
      id: "email",
      label: "email",
      type: "text",
      value: values.email,
      name: "email",
    },

    {
      id: "profile_picture",
      type: "file",
      labelAccess: "profile_picture",
      value: values.profile_picture,
      name: "profile_picture",
    },
  ];

  const handleClose = () => {
    setOpen(false);
    // resetForm();
  };

  const handleEditClick = async (data) => {
    setErrors({});
    setPopupName("Edit Profile");
    if (data?.profile_image) {
      setEditImageValue(data?.profile_image);
    }
    setValues({
      ...values,
      name: data?.name || "",
      email: data?.email || "",
    });
    setErrors({});
    setOpen(true);
  };

  //profile update
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append(
      "profile_picture",
      values.file ? values.file : editImageValue
    );
    formData.append("name", values.name);
    formData.append("email", values.email);
    const res = await dispatch(UpdateUserProfile(formData, handleClose));
    dispatch(GetUserProfile());
  };

  useEffect(() => {
    dispatch(GetUserProfile());
  }, []);

  const user_roles_options = profileData?.data?.user_roles.map((data) => ({
    value: data?._id,
    label: data?.role,
  }));

  const { allCountryData } = useSelector((state) => state.CountryReducer);
  const { allStateData } = useSelector((state) => state.StateReducer);
  const { allCitiesData } = useSelector((state) => state.CityReducer);
  const { allPostalData } = useSelector((state) => state.PostalCodeReducer);

  useEffect(() => {
    dispatch(GetAllCountry());
  }, []);

  const defaultProps = {
    options: allBusinessCategory.map((item) => ({
      value: item._id,
      label: item?.category_name,
    })),

    getOptionLabel: (option) => option.label,
  };

  const getAllBusinessCategory = async () => {
    const res = await GetBusinessCategoryAll();
    if (res) {
      setAllBusinessCategory(res?.data?.categoryData);
    }
  };

  useEffect(() => {
    getAllBusinessCategory();
  }, []);

  const handleUpdateDefault = async () => {
    try {
      setDefaultRoleUpdateLoading(true);
      const res = await api.put(`${ApiURL}user/set-default-role`, {
        role_id: role,
      });
      if (res?.status) {
        snackBar(res?.message, res?.status);
        if (userData && userData.user) {
          userData.user.role_id = role;
          if (res && res.data && res.data[0]) {
            userData.user.role = res.data[0].role;
          }
          if (profileData && profileData.data && profileData.data.user_roles) {
            userData.user.user_role = profileData.data.user_roles.find(
              (data) => data?._id === role
            );
          }
        }
        localStorage.setItem("user_data", JSON.stringify(userData));
        localStorage.setItem("userData", JSON.stringify(userData));
        window.location.href = "/admin/dashboard";
      } else {
        snackBar(res?.message, res?.status);
      }
    } catch {
      snackBar("Something went wrong!", false);
    } finally {
      setDefaultRoleUpdateLoading(false);
    }
  };

  //bankupdate
  const handleBankUpdate = async (e) => {
    e.preventDefault();
    setStatusUpdateLoading(true);

    const payload = {
      bank_name: valuess.bank_name,
      acc_number: valuess.acc_number,
      ifsc_code: valuess.ifsc_code,
      acc_holder_name: valuess.acc_holder_name,
    };

    const formData = new FormData();
    formData.append("bank_details", JSON.stringify(payload));
    try {
      await dispatch(UpdateUserManage(profileData?.data?._id, formData));
      handleBankCloseModal();
      dispatch(GetUserProfile());
      handleClose();
    } catch (error) {
      snackBar("Error updating bank account holder:", error);
    } finally {
      setStatusUpdateLoading(false);
    }
  };

  //businessupdate
  const handlBusinessUpdate = async (e) => {
    e.preventDefault();
    setStatusUpdateLoading(true);

    const formData = new FormData();
    formData.append("business_name", valuesBusiness?.business_name);
    formData.append("owner_firstname", valuesBusiness?.owner_firstname);
    formData.append("owner_lastname", valuesBusiness?.owner_lastname);
    formData.append("business_email", valuesBusiness?.business_email);
    formData.append("business_contact", valuesBusiness?.business_contact);
    formData.append("establish_year", valuesBusiness?.establish_year);
    formData.append(
      "business_category_id",
      valuesBusiness?.business_category_id
    );
    formData.append("annual_turnover", valuesBusiness?.annual_turnover);
    formData.append("business_website", valuesBusiness?.business_website);
    formData.append("address", valuesBusiness?.address);
    // formData.append("city_id", valuesBusiness?.city_id?._id);
    // formData.append("state_id", valuesBusiness?.state_id?._id);
    // formData.append("postalcode_id", valuesBusiness?.postalcode_id?._id);
    formData.append("representatives", JSON.stringify(representatives));
    formData.append("business_card", valuesBusiness?.business_card);
    formData.append("is_active", valuesBusiness?.is_active);
    formData.append("business_gst", valuesBusiness?.business_gst);
    formData.append("business_pan", valuesBusiness?.business_pan);
    formData.append("business_type", valuesBusiness?.business_type);
    formData.append("business_logo", selectedImage3);

    try {
      await dispatch(
        UpdateUserBusinessManage(profileData?.data?.business?._id, formData)
      );
      handleBusinessCloseModal();
      dispatch(GetUserProfile());
      handleClose();
    } catch (error) {
      snackBar("Error updating bank account holder:", error);
    } finally {
      setStatusUpdateLoading(false);
    }
  };

  //businessDocumentUpload
  const handlBusinessDocUpdate = async (e) => {
    e.preventDefault();
    setStatusUpdateLoading(true);

    const formData = new FormData();
    formData.append("gst_business_document", selectedImage1);
    formData.append("pan_business_document", selectedImage2);

    try {
      const res = await dispatch(
        UpdateUserBusinessManage(profileData?.data?.business?._id, formData)
      );
      handleBusinessDocCloseModal();
      setSelectedImage1(
        res?.data?.data?.business_documents[0]?.gst_business_document
      );
      setSelectedImage2(
        res?.data?.data?.business_documents[0]?.pan_business_document
      );
      dispatch(GetUserProfile());
      window.location.reload();
      getUserDetails(profileData?.data?._id);
      handleClose();
    } catch (error) {
      snackBar("Error updating bank account holder:", error);
    } finally {
      setStatusUpdateLoading(false);
    }
  };

  const handleChangess = (e) => {
    const { name, value } = e.target;
    setValuess({ ...valuess, [name]: value });
  };

  const handleBusinessChanges = (e) => {
    const { name, value } = e.target;
    setValuesBusiness({ ...valuesBusiness, [name]: value });
  };

  const getUserDetails = async (id) => {
    try {
      const res = await axios.get(`${ApiURL}user/get-user-by-id/${id}`);
      if (res?.data?.status) {
        setDocuments(res?.data?.data[0]?.business_documents);
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (profileData?.data?.business?.document_uploaded === true) {
      getUserDetails(profileData?.data?._id);
    }
  }, [profileData?.data?._id]);

  const [expiryDate, setExpiryDate] = useState("");
  const [expiryNotification, setExpiryNotification] = useState(false);
  const [expiryNotificationMsg, setExpiryNotificationMsg] = useState("");

  useEffect(() => {
    if (profileData?.data?.membership_validity?.expire_at) {
      const membershipValidity =
        profileData?.data?.membership_validity?.expire_at;
      const expiryDate = new Date(membershipValidity);
      const formattedDate = expiryDate.toLocaleDateString("en-IN", {
        year: "numeric",
        day: "2-digit",
        month: "2-digit",
      });
      setExpiryDate(formattedDate);

      // Set the number of days before expiry to trigger the notification
      const daysBeforeExpiry = 30;

      if (expiryDate) {
        const currentDate = new Date();
        const daysRemaining = Math.floor(
          (expiryDate - currentDate) / (1000 * 60 * 60 * 24)
        );

        if (daysRemaining <= daysBeforeExpiry) {
          // Trigger notification or take some action
          setExpiryNotification(true);
          setExpiryNotificationMsg(
            `Your membership will expire in ${daysRemaining} days.`
          );
        }
      }
    }
  }, [profileData, expiryNotification]);

  console.log("window.location.hostname", window.location.origin);
  const linkToCopy = `${window.location.origin}/business-card/${userData?.user?._id}`;

  return (
    <>
      <Box
        sx={{
          marginTop: "30px",
          marginLeft: { xs: "20px", md: "25px", lg: "25px" },
          marginRight: { xs: "15px", md: "25px", lg: "25px" },
        }}
      >
        <div>
          <CopyToClipboardButton textToCopy={linkToCopy} />
        </div>
        {profileData?.data?.role !== "Community Super Admin" ? (
          <Box
            sx={{
              maxWidth: {
                md: "100%",
                lg: "100%",
              },
            }}
          >
            {/* {profileData?.data?.membership_id ? ( */}
            <>
              <Box
                sx={{
                  flexGrow: 1,
                  marginBottom: 2,
                  bgcolor: "#your-background-color",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Card
                      sx={{
                        height: "100%",
                        paddingTop: "24px",
                        textAlign: "center",
                        color: "black",
                        paddingBottom: "13px",
                        boxShadow: "none",
                        borderRadius: "35px",
                        background:
                          expiryNotification ||
                          !profileData?.data?.membership_id
                            ? "#f9b0b0"
                            : "#defce6",
                      }}
                    >
                      <Grid container spacing={2} sx={{ marginTop: "-29px" }}>
                        <Grid
                          item
                          xs={6}
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          <Typography
                            variant="h5"
                            fontWeight="600"
                            color="black"
                            sx={{
                              paddingLeft: "44px",
                              marginTop: "0px",
                            }}
                          >
                            {!profileData?.data?.membership_id
                              ? "Purchase Now"
                              : "Membership Plan Expire at :"}{" "}
                            {profileData?.data?.membership_id ? expiryDate : ""}
                          </Typography>
                        </Grid>
                        {expiryNotification ||
                        !profileData?.data?.membership_id ? (
                          <Grid
                            item
                            xs={6}
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                              paddingRight: "39px",
                            }}
                          >
                            <Link
                              to="/be-member#upgrade_now"
                              variant="contained"
                              style={{
                                backgroundColor: "#2e7d32",
                                paddingLeft: "10px",
                                borderRadius: "24px",
                                color: "white",
                                paddingTop: "5px",
                                paddingRight: "10px",
                                paddingBottom: "5px",
                                border: "1px solid black",
                                boxShadow: "0px 2px 1px 0px black",
                                textDecoration: "none",
                                textDecoration: "none",
                              }}
                            >
                              {profileData?.data?.membership_id
                                ? "Upgrade Plan"
                                : "Purchase Plan"}
                            </Link>
                          </Grid>
                        ) : null}
                        <Grid
                          item
                          xs={6}
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          <Typography
                            variant="h6"
                            fontWeight="600"
                            color="black"
                            sx={{
                              paddingLeft: "44px",
                              marginTop: "0px",
                            }}
                          >
                            {expiryNotificationMsg}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
              </Box>
              <Divider />
            </>
            {/* ) : null
          } */}
          </Box>
        ) : (
          <></>
        )}
        <Box
          sx={{
            maxWidth: {
              md: "100%",
              lg: "100%",
            },
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Card
                  sx={{
                    height: "100%",
                    paddingTop: "24px",
                    textAlign: "center",
                    color: "black",
                    paddingBottom: "13px",
                    boxShadow: "none",

                    background:
                      "linear-gradient(317deg, rgba(254,118,120,1) 9%, rgba(21,62,128,1) 100%)",
                  }}
                >
                  <Grid container spacing={2} sx={{ marginTop: "-29px" }}>
                    <Grid item xs={6} sx={{ display: "flex" }}>
                      <Typography
                        sx={{
                          fontWeight: "600",
                          fontSize: "20px",
                          color: "snow",
                          marginTop: "-11px",
                          padding: " 15px",
                        }}
                      >
                        Personal Details
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sx={{
                        display: "flex",
                        justifyContent: "end",
                        padding: "12px",
                        paddingRight: "39px",
                      }}
                    >
                      <Button
                        onClick={() => handleEditClick(profileData.data)}
                        sx={{
                          ":hover": { bgcolor: "#153E80", color: "#FFFFFF" },
                          textTransform: "none",
                          fontSize: "16px",
                          color: "#FFFFFF",

                          backgroundColor: "#153E80",
                          border: "2px solid #153E80",
                          borderRadius: "15px",
                          height: "37px",

                          "&.Mui-disabled": {
                            backgroundColor: "#153E80",
                            color: "#fff",
                          },
                        }}
                        type="submit"
                        startIcon={<EditIcon sx={{ marginLeft: "10px" }} />}
                      ></Button>
                    </Grid>
                  </Grid>

                  <Divider />

                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >
                    <Grid item xs={12} sm={12} md={12} lg={2}>
                      <StyledIcon
                        sx={{
                          color: "black",
                          backgroundImage: "white",
                        }}
                      >
                        <img
                          className={classes.image}
                          style={{
                            width: "166px",
                            height: "68px",
                            borderRadius: "40px",
                          }}
                          src={
                            profileData && profileData?.data
                              ? profileData?.data?.profile_picture
                              : DefaultProfile
                          }
                          alt="profilepic"
                        />
                      </StyledIcon>
                    </Grid>
                    <Grid item xs={9}>
                      <div className={classes.mainCompDetails}>
                        <Grid
                          container
                          spacing={{ xs: 2, md: 3 }}
                          columns={{ xs: 4, sm: 8, md: 12 }}
                        >
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={4}
                            sx={{ marginTop: "50px" }}
                          >
                            <Typography
                              sx={{
                                fontWeight: "100",
                                fontSize: "17px",
                                marginTop: "-11px",
                                padding: " 15px",
                                display: "flex",
                                color: "#d1d1d1",
                              }}
                            >
                              Owner Name
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: "600",
                                fontSize: "18px",
                                color: "snow",
                                marginTop: "-31px",
                                padding: " 15px",
                                display: "flex",
                              }}
                            >
                              {profileData?.data?.name
                                ? profileData?.data?.name
                                : profileData?.data?.first_name +
                                  " " +
                                  profileData?.data?.last_name}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={4}
                            sx={{ marginTop: "50px" }}
                          >
                            <Typography
                              sx={{
                                fontWeight: "100",
                                fontSize: "17px",
                                marginTop: "-11px",
                                padding: " 15px",
                                display: "flex",
                                color: "#d1d1d1",
                              }}
                            >
                              Email
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: "600",
                                fontSize: "18px",
                                color: "snow",
                                marginTop: "-31px",
                                padding: " 15px",
                                display: "flex",
                              }}
                            >
                              {profileData?.data?.email || "-"}
                            </Typography>
                          </Grid>
                          {/* <Grid
                            item
                            xs={2}
                            sm={4}
                            md={4}
                            sx={{ marginTop: "50px" }}
                          >
                            <Typography
                              sx={{
                                fontWeight: "100",
                                fontSize: "17px",
                                marginTop: "-11px",
                                padding: " 15px",
                                display: "flex",
                                color: "#d1d1d1",
                              }}
                            >
                              Company Name
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: "600",
                                fontSize: "18px",
                                color: "snow",
                                marginTop: "-31px",
                                padding: " 15px",
                                display: "flex",
                              }}
                            >
                              Shine
                            </Typography>
                          </Grid> */}
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          marginTop: "30px",
          marginLeft: { xs: "20px", md: "25px", lg: "25px" },
          marginRight: { xs: "15px", md: "25px", lg: "25px" },
        }}
      >
        <div className="company-details">
          <Box
            sx={{
              maxWidth: {
                md: "100%",
                lg: "100%",
              },
            }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{ paddingBottom: "25px" }}
                >
                  <Card
                    sx={{
                      marginTop: "0px",
                      height: "100%",
                      backgroundColor: "#d1d1d17a",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "700",
                        fontSize: "20px",
                        color: "black",
                        marginTop: "-11px",
                        padding: "22px",
                      }}
                    >
                      Personal Details
                    </Typography>
                    <Box
                      sx={{
                        flexGrow: 1,
                        marginTop: "10px",
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <Typography
                            sx={{
                              fontWeight: "100",
                              fontSize: "17px",
                              marginTop: "-11px",
                              padding: " 15px",
                              display: "flex",
                              color: "#605a5a",
                            }}
                          >
                            Mobile number
                          </Typography>
                          <Typography
                            sx={{
                              fontWeight: "600",
                              fontSize: "18px",
                              marginTop: "-31px",
                              padding: " 15px",
                              display: "flex",
                              color: "black",
                            }}
                          >
                            {profileData?.data?.mobile_number}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Typography
                            sx={{
                              fontWeight: "100",
                              fontSize: "17px",
                              marginTop: "-11px",
                              padding: " 15px",
                              display: "flex",
                              color: "#605a5a",
                            }}
                          >
                            Address Proof
                          </Typography>
                          <Typography
                            sx={{
                              fontWeight: "600",
                              fontSize: "18px",
                              marginTop: "-31px",
                              padding: " 15px",
                              display: "flex",
                              color: "black",
                            }}
                          >
                            {profileData?.data?.country_name},{" "}
                            {profileData?.data?.state_name},{" "}
                            {profileData?.data?.city_name},{" "}
                            {profileData?.data?.postal_code}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ marginTop: "12px" }}>
                        <Grid item xs={12} md={6}>
                          {user_roles_options?.length > 1 ? (
                            <>
                              <Typography
                                sx={{
                                  fontWeight: "600",
                                  fontSize: "18px",
                                  marginTop: "-31px",
                                  padding: " 15px",
                                  display: "flex",
                                  color: "black",
                                }}
                              >
                                <>
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                      Default Role
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={role || profileData?.data?.role_id}
                                      label="Default Role"
                                      onChange={(e, value) => {
                                        setRole(e.target.value);
                                      }}
                                    >
                                      {user_roles_options?.map((item) => (
                                        <MenuItem value={item?.value}>
                                          {item?.label}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </>
                              </Typography>
                            </>
                          ) : (
                            <>
                              <Typography
                                sx={{
                                  fontWeight: "100",
                                  fontSize: "17px",
                                  marginTop: "-11px",
                                  padding: " 15px",
                                  display: "flex",
                                  color: "#605a5a",
                                }}
                              >
                                Default Role
                              </Typography>
                              <Typography
                                sx={{
                                  fontWeight: "600",
                                  fontSize: "18px",
                                  marginTop: "-31px",
                                  padding: " 15px",
                                  display: "flex",
                                  color: "black",
                                }}
                              >
                                {profileData?.data?.role}
                              </Typography>
                            </>
                          )}
                        </Grid>
                        {user_roles_options?.length > 1 && (
                          <Grid item xs={12} md={6}>
                            <Typography
                              sx={{
                                fontWeight: "600",
                                fontSize: "18px",
                                marginTop: "-31px",
                                padding: " 15px",
                                display: "flex",
                                color: "black",
                              }}
                            >
                              <Button
                                variant="contained"
                                onClick={handleUpdateDefault}
                                disabled={
                                  defaultRoleUpdateLoading ||
                                  !role ||
                                  role === profileData?.data?.role_id
                                }
                              >
                                Update
                              </Button>
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </div>
      </Box>
      {userRole !== "Community Super Admin" && (
        <Box
          sx={{
            marginTop: "10px",
            marginLeft: { xs: "20px", md: "25px", lg: "25px" },
            marginRight: { xs: "15px", md: "25px", lg: "25px" },
          }}
        >
          <div className="company-details">
            <Box
              sx={{
                maxWidth: {
                  md: "100%",
                  lg: "100%",
                },
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    sx={{
                      paddingBottom: "25px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Card
                      sx={{
                        width: "100%",
                        marginTop: "0px",
                        height: "100%",
                        backgroundColor: "#d1d1d17a",
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={6} md={6}>
                          <Typography
                            sx={{
                              fontWeight: "700",
                              fontSize: "20px",
                              color: "black",
                              marginTop: "-11px",
                              padding: "22px",
                            }}
                          >
                            Business Details
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          sx={{
                            display: "flex",
                            justifyContent: "end",
                            padding: "12px",
                            paddingRight: "39px",
                          }}
                          xs={6}
                          md={6}
                        >
                          <Button
                            onClick={() =>
                              handleBusinessModal(profileData.data)
                            }
                            sx={{
                              ":hover": {
                                bgcolor: "#153E80",
                                color: "#FFFFFF",
                              },
                              textTransform: "none",
                              fontSize: "16px",
                              color: "#FFFFFF",

                              backgroundColor: "#153E80",
                              border: "2px solid #153E80",
                              borderRadius: "15px",
                              height: "37px",
                              marginTop: "10px",

                              "&.Mui-disabled": {
                                backgroundColor: "#153E80",
                                color: "#fff",
                              },
                            }}
                            type="submit"
                            startIcon={<EditIcon sx={{ marginLeft: "10px" }} />}
                          ></Button>
                        </Grid>
                      </Grid>
                      <Box
                        sx={{
                          flexGrow: 1,
                          marginTop: "10px",
                        }}
                      >
                        <Grid item md={6} xs={12}>
                          <Typography
                            sx={{
                              fontWeight: "100",
                              fontSize: "17px",
                              marginTop: "-11px",
                              padding: " 15px",
                              display: "flex",
                              color: "#605a5a",
                            }}
                          >
                            Business Logo
                          </Typography>
                          {/* <Typography
                            sx={{
                              fontWeight: "600",
                              fontSize: "18px",
                              marginTop: "-31px",
                              padding: " 15px",
                              display: "flex",
                              color: "black",
                            }}
                          >
                            {profileData?.data?.business?.business_logo || "-"}
                          </Typography> */}

                          <img
                            src={profileData?.data?.business?.business_logo}
                            alt="business logo"
                          />
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item md={6} xs={12}>
                            <Typography
                              sx={{
                                fontWeight: "100",
                                fontSize: "17px",
                                marginTop: "-11px",
                                padding: " 15px",
                                display: "flex",
                                color: "#605a5a",
                              }}
                            >
                              Business Name
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: "600",
                                fontSize: "18px",
                                marginTop: "-31px",
                                padding: " 15px",
                                display: "flex",
                                color: "black",
                              }}
                            >
                              {profileData?.data?.business?.business_name ||
                                "-"}
                            </Typography>
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <Typography
                              sx={{
                                fontWeight: "100",
                                fontSize: "17px",
                                marginTop: "-11px",
                                padding: " 15px",
                                display: "flex",
                                color: "#605a5a",
                              }}
                            >
                              Business Owner
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: "600",
                                fontSize: "18px",
                                marginTop: "-31px",
                                padding: " 15px",
                                display: "flex",
                                color: "black",
                              }}
                            >
                              {profileData?.data?.business?.owner_firstname ||
                                "-"}{" "}
                              {profileData?.data?.business?.owner_lastname ||
                                "-"}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                          <Grid item md={6} xs={12}>
                            <Typography
                              sx={{
                                fontWeight: "100",
                                fontSize: "17px",
                                marginTop: "-11px",
                                padding: " 15px",
                                display: "flex",
                                color: "#605a5a",
                              }}
                            >
                              Business Mobile Number
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: "600",
                                fontSize: "18px",
                                marginTop: "-31px",
                                padding: " 15px",
                                display: "flex",
                                color: "black",
                              }}
                            >
                              {profileData?.data?.business?.business_contact ||
                                "-"}
                            </Typography>
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <Typography
                              sx={{
                                fontWeight: "100",
                                fontSize: "17px",
                                marginTop: "-11px",
                                padding: " 15px",
                                display: "flex",
                                color: "#605a5a",
                              }}
                            >
                              Business Email
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: "600",
                                fontSize: "18px",
                                marginTop: "-31px",
                                padding: " 15px",
                                display: "flex",
                                color: "black",
                              }}
                            >
                              {profileData?.data?.business?.business_email ||
                                "-"}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                          <Grid item md={6} xs={12}>
                            <Typography
                              sx={{
                                fontWeight: "100",
                                fontSize: "17px",
                                marginTop: "-11px",
                                padding: " 15px",
                                display: "flex",
                                color: "#605a5a",
                              }}
                            >
                              Website
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: "600",
                                fontSize: "18px",
                                marginTop: "-31px",
                                padding: " 15px",
                                display: "flex",
                                color: "black",
                              }}
                            >
                              {profileData?.data?.business?.business_website ||
                                "-"}
                            </Typography>
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <Typography
                              sx={{
                                fontWeight: "100",
                                fontSize: "17px",
                                marginTop: "-11px",
                                padding: " 15px",
                                display: "flex",
                                color: "#605a5a",
                              }}
                            >
                              Business Category
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: "600",
                                fontSize: "18px",
                                marginTop: "-31px",
                                padding: " 15px",
                                display: "flex",
                                color: "black",
                              }}
                            >
                              {profileData?.data?.business_category || "-"}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                          <Grid item md={6} xs={12}>
                            <Typography
                              sx={{
                                fontWeight: "100",
                                fontSize: "17px",
                                marginTop: "-11px",
                                padding: " 15px",
                                display: "flex",
                                color: "#605a5a",
                              }}
                            >
                              Business Type
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: "600",
                                fontSize: "18px",
                                marginTop: "-31px",
                                padding: " 15px",
                                display: "flex",
                                color: "black",
                              }}
                            >
                              {profileData?.data?.business?.business_type ||
                                "Private Ltd"}
                            </Typography>
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <Typography
                              sx={{
                                fontWeight: "100",
                                fontSize: "17px",
                                marginTop: "-11px",
                                padding: " 15px",
                                display: "flex",
                                color: "#605a5a",
                              }}
                            >
                              Annual Turnover
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: "600",
                                fontSize: "18px",
                                marginTop: "-31px",
                                padding: " 15px",
                                display: "flex",
                                color: "black",
                              }}
                            >
                              {profileData?.data?.business?.annual_turnover ||
                                "-"}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                          <Grid item md={6} xs={12}>
                            <Typography
                              sx={{
                                fontWeight: "100",
                                fontSize: "17px",
                                marginTop: "-11px",
                                padding: " 15px",
                                display: "flex",
                                color: "#605a5a",
                              }}
                            >
                              Established Year
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: "600",
                                fontSize: "18px",
                                marginTop: "-31px",
                                padding: " 15px",
                                display: "flex",
                                color: "black",
                              }}
                            >
                              {profileData?.data?.business?.establish_year ||
                                "-"}
                            </Typography>
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <Typography
                              sx={{
                                fontWeight: "100",
                                fontSize: "17px",
                                marginTop: "-11px",
                                padding: " 15px",
                                display: "flex",
                                color: "#605a5a",
                              }}
                            >
                              Document Uploaded
                            </Typography>

                            {profileData?.data?.business?.document_uploaded ===
                            false ? (
                              <Typography
                                sx={{
                                  fontWeight: "600",
                                  fontSize: "18px",
                                  marginTop: "-31px",
                                  padding: " 15px",
                                  display: "flex",
                                  color: "black",
                                }}
                              >
                                No
                              </Typography>
                            ) : (
                              <Typography
                                sx={{
                                  fontWeight: "600",
                                  fontSize: "18px",
                                  marginTop: "-31px",
                                  padding: " 15px",
                                  display: "flex",
                                  color: "black",
                                }}
                              >
                                Yes
                              </Typography>
                            )}
                          </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                          <Grid item md={6} xs={12}>
                            <Typography
                              sx={{
                                fontWeight: "100",
                                fontSize: "17px",
                                marginTop: "-11px",
                                padding: " 15px",
                                display: "flex",
                                color: "#605a5a",
                              }}
                            >
                              Business Address
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: "600",
                                fontSize: "18px",
                                marginTop: "-31px",
                                padding: " 15px",
                                display: "flex",
                                color: "black",
                              }}
                            >
                              {profileData?.data?.business?.address || "-"}
                            </Typography>
                          </Grid>

                          <Grid item md={6} xs={12}>
                            <Typography
                              sx={{
                                fontWeight: "100",
                                fontSize: "17px",
                                marginTop: "-11px",
                                padding: " 15px",
                                display: "flex",
                                color: "#605a5a",
                              }}
                            >
                              Business Pan Number
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: "600",
                                fontSize: "18px",
                                marginTop: "-31px",
                                padding: " 15px",
                                display: "flex",
                                color: "black",
                              }}
                            >
                              {profileData?.data?.business?.business_pan || "-"}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                          <Grid item md={6} xs={12}>
                            <Typography
                              sx={{
                                fontWeight: "100",
                                fontSize: "17px",
                                marginTop: "-11px",
                                padding: " 15px",
                                display: "flex",
                                color: "#605a5a",
                              }}
                            >
                              Business GST Number
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: "600",
                                fontSize: "18px",
                                marginTop: "-31px",
                                padding: " 15px",
                                display: "flex",
                                color: "black",
                              }}
                            >
                              {profileData?.data?.business?.business_gst || "-"}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Typography
                          sx={{
                            fontWeight: "100",
                            fontSize: "17px",
                            marginTop: "-11px",
                            padding: " 15px",
                            display: "flex",
                            color: "#605a5a",
                          }}
                        >
                          Representative
                        </Typography>
                        {profileData?.data?.business?.representatives?.map(
                          (rows) => {
                            return (
                              <Grid container spacing={2}>
                                <Grid item md={6} xs={12}>
                                  <Typography
                                    sx={{
                                      fontWeight: "100",
                                      fontSize: "17px",
                                      marginTop: "-11px",
                                      padding: " 15px",
                                      display: "flex",
                                      color: "#605a5a",
                                    }}
                                  >
                                    Name
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontWeight: "600",
                                      fontSize: "18px",
                                      marginTop: "-31px",
                                      padding: " 15px",
                                      display: "flex",
                                      color: "black",
                                    }}
                                  >
                                    {rows?.first_name || "-"}
                                  </Typography>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                  <Typography
                                    sx={{
                                      fontWeight: "100",
                                      fontSize: "17px",
                                      marginTop: "-11px",
                                      padding: " 15px",
                                      display: "flex",
                                      color: "#605a5a",
                                    }}
                                  >
                                    Email
                                  </Typography>

                                  <Typography
                                    sx={{
                                      fontWeight: "600",
                                      fontSize: "18px",
                                      marginTop: "-31px",
                                      padding: " 15px",
                                      display: "flex",
                                      color: "black",
                                    }}
                                  >
                                    {rows?.email || "-"}
                                  </Typography>
                                </Grid>

                                <Grid item md={6} xs={12}>
                                  <Typography
                                    sx={{
                                      fontWeight: "100",
                                      fontSize: "17px",
                                      marginTop: "-11px",
                                      padding: " 15px",
                                      display: "flex",
                                      color: "#605a5a",
                                    }}
                                  >
                                    Mobile Number
                                  </Typography>

                                  <Typography
                                    sx={{
                                      fontWeight: "600",
                                      fontSize: "18px",
                                      marginTop: "-31px",
                                      padding: " 15px",
                                      display: "flex",
                                      color: "black",
                                    }}
                                  >
                                    {rows?.mobile_number || "-"}
                                  </Typography>
                                </Grid>
                              </Grid>
                            );
                          }
                        )}
                      </Box>
                    </Card>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </div>
        </Box>
      )}

      {userRole !== "Community Super Admin" &&
        profileData?.data?.business?.document_uploaded && (
          <Box
            sx={{
              marginTop: "10px",
              marginLeft: { xs: "20px", md: "25px", lg: "25px" },
              marginRight: { xs: "15px", md: "25px", lg: "25px" },
            }}
          >
            <div className="company-details">
              <Box
                sx={{
                  maxWidth: {
                    md: "100%",
                    lg: "100%",
                  },
                }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      sx={{
                        paddingBottom: "25px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Card
                        sx={{
                          width: "100%",
                          marginTop: "0px",
                          height: "100%",
                          backgroundColor: "#d1d1d17a",
                        }}
                      >
                        <Box
                          sx={{
                            flexGrow: 1,
                            marginTop: "10px",
                            marginBottom: "20px",
                            marginLeft: "10px",
                          }}
                        >
                          <Grid container spacing={2}>
                            <Grid item xs={6} md={6}>
                              <Typography
                                sx={{
                                  fontWeight: "700",
                                  fontSize: "20px",
                                  color: "black",
                                  marginTop: "-11px",
                                  padding: "22px",
                                }}
                              >
                                Business Document
                              </Typography>
                            </Grid>
                            {(documents[0]?.pan_status !== "approved" ||
                              documents[0]?.gst_status !== "approved") && (
                              <Grid item xs={6} md={6}>
                                <Button
                                  onClick={() =>
                                    handleBusinessDocModal(profileData.data)
                                  }
                                  sx={{
                                    ":hover": {
                                      bgcolor: "#153E80",
                                      color: "#FFFFFF",
                                    },
                                    textTransform: "none",
                                    fontSize: "16px",
                                    color: "#FFFFFF",

                                    backgroundColor: "#153E80",
                                    border: "2px solid #153E80",
                                    borderRadius: "15px",
                                    height: "37px",
                                    marginLeft: "420px",
                                    marginTop: "10px",

                                    "&.Mui-disabled": {
                                      backgroundColor: "#153E80",
                                      color: "#fff",
                                    },
                                  }}
                                  type="submit"
                                  startIcon={
                                    <EditIcon sx={{ marginLeft: "10px" }} />
                                  }
                                ></Button>
                              </Grid>
                            )}
                          </Grid>
                          <Grid container spacing={1}>
                            {documents[0]?.gst_business_document && (
                              <BusinessDocCard
                                data={{
                                  business_document:
                                    documents[0]?.gst_business_document,
                                  document_type: "GST",
                                  status: documents[0]?.gst_status,
                                  statusKey: "",
                                  _id: documents[0]?._id,
                                  disable_update: true,
                                }}
                              />
                            )}
                            {documents[0]?.pan_business_document && (
                              <BusinessDocCard
                                data={{
                                  business_document:
                                    documents[0]?.pan_business_document,
                                  document_type: "PAN",
                                  status: documents[0]?.pan_status,
                                  statusKey: "",
                                  _id: documents[0]?._id,
                                  disable_update: true,
                                }}
                              />
                            )}
                          </Grid>
                        </Box>
                      </Card>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </div>
          </Box>
        )}

      {userRole !== "Community Super Admin" && (
        <Box
          sx={{
            marginTop: "10px",
            marginLeft: { xs: "20px", md: "25px", lg: "25px" },
            marginRight: { xs: "15px", md: "25px", lg: "25px" },
          }}
        >
          <div className="company-details">
            <Box
              sx={{
                maxWidth: {
                  md: "100%",
                  lg: "100%",
                },
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    sx={{ paddingBottom: "25px" }}
                  >
                    <Card
                      sx={{
                        marginTop: "0px",
                        height: "100%",
                        backgroundColor: "#d1d1d17a",
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={6} md={6}>
                          <Typography
                            sx={{
                              fontWeight: "700",
                              fontSize: "20px",
                              color: "black",
                              marginTop: "-11px",
                              padding: "22px",
                            }}
                          >
                            Bank Details
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <Button
                            onClick={() => handleBankModal(profileData.data)}
                            sx={{
                              ":hover": {
                                bgcolor: "#153E80",
                                color: "#FFFFFF",
                              },
                              textTransform: "none",
                              fontSize: "16px",
                              color: "#FFFFFF",

                              backgroundColor: "#153E80",
                              border: "2px solid #153E80",
                              borderRadius: "15px",
                              height: "37px",
                              marginLeft: "420px",
                              marginTop: "10px",

                              "&.Mui-disabled": {
                                backgroundColor: "#153E80",
                                color: "#fff",
                              },
                            }}
                            type="submit"
                            startIcon={<EditIcon sx={{ marginLeft: "10px" }} />}
                          ></Button>
                        </Grid>
                      </Grid>

                      <Box
                        sx={{
                          flexGrow: 1,
                          marginTop: "10px",
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Typography
                              sx={{
                                fontWeight: "100",
                                fontSize: "17px",
                                marginTop: "-11px",
                                padding: " 15px",
                                display: "flex",
                                color: "#605a5a",
                              }}
                            >
                              Account Holder Name
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: "600",
                                fontSize: "18px",
                                marginTop: "-31px",
                                padding: " 15px",
                                display: "flex",
                                color: "black",
                              }}
                            >
                              {profileData?.data?.bank_details
                                ?.acc_holder_name || "-"}
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography
                              sx={{
                                fontWeight: "100",
                                fontSize: "17px",
                                marginTop: "-11px",
                                padding: " 15px",
                                display: "flex",
                                color: "#605a5a",
                              }}
                            >
                              Bank Name
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: "600",
                                fontSize: "18px",
                                marginTop: "-31px",
                                padding: " 15px",
                                display: "flex",
                                color: "black",
                              }}
                            >
                              {profileData?.data?.bank_details?.bank_name ||
                                "-"}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ marginTop: "12px" }}>
                          <Grid item xs={6}>
                            <Typography
                              sx={{
                                fontWeight: "100",
                                fontSize: "17px",
                                marginTop: "-11px",
                                padding: " 15px",
                                display: "flex",
                                color: "#605a5a",
                              }}
                            >
                              Account Number
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: "600",
                                fontSize: "18px",
                                marginTop: "-31px",
                                padding: " 15px",
                                display: "flex",
                                color: "black",
                              }}
                            >
                              {profileData?.data?.bank_details?.acc_number ||
                                "-"}
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography
                              sx={{
                                fontWeight: "100",
                                fontSize: "17px",
                                marginTop: "-11px",
                                padding: " 15px",
                                display: "flex",
                                color: "#605a5a",
                              }}
                            >
                              IFSC Code
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: "600",
                                fontSize: "18px",
                                marginTop: "-31px",
                                padding: " 15px",
                                display: "flex",
                                color: "black",
                              }}
                            >
                              {profileData?.data?.bank_details?.ifsc_code ||
                                "-"}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </Card>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </div>

          <Dialog
            open={EditModal}
            fullWidth={false}
            onClose={false}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
          >
            <div
              className="mui-dialog-box-popup"
              style={{ backgroundColor: "#fff", color: "#000" }}
            >
              <DialogTitle
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="h5" sx={{ color: "#000 !important" }}>
                  Business Details
                </Typography>

                <IconButton>
                  <CloseIcon onClick={handleBankCloseModal} />
                </IconButton>
              </DialogTitle>

              <DialogContent sx={{ minWidth: "400px" }}>
                <form onSubmit={handleBankUpdate}>
                  <div style={{ marginTop: "5px" }}>
                    <TextField
                      sx={{ input: { color: "#000" } }}
                      id="bank_name"
                      label="Bank Name"
                      type="text"
                      fullWidth
                      variant="outlined"
                      value={valuess.bank_name}
                      name="bank_name"
                      onChange={handleChangess}
                      inputProps={{ maxLength: 50 }} // Adjust the max length as needed
                    />
                  </div>
                  <div style={{ marginTop: "5px" }}>
                    <TextField
                      sx={{ input: { color: "#000" } }}
                      margin="dense"
                      id="acc_number"
                      label="Bank Account Number"
                      type="text"
                      fullWidth
                      variant="outlined"
                      value={valuess.acc_number}
                      name="acc_number"
                      onChange={handleChangess}
                      inputProps={{ maxLength: 50 }} // Adjust the max length as needed
                    />
                  </div>
                  <div style={{ marginTop: "5px" }}>
                    <TextField
                      sx={{ input: { color: "#000" } }}
                      margin="dense"
                      id="ifsc_code"
                      label="Bank IFSC Code"
                      type="text"
                      fullWidth
                      variant="outlined"
                      value={valuess.ifsc_code}
                      name="ifsc_code"
                      onChange={handleChangess}
                      inputProps={{ maxLength: 50 }} // Adjust the max length as needed
                    />
                  </div>
                  <div style={{ marginTop: "5px" }}>
                    <TextField
                      sx={{ input: { color: "#000" } }}
                      margin="dense"
                      id="acc_holder_name"
                      label="Bank Account Holder"
                      type="text"
                      fullWidth
                      variant="outlined"
                      value={valuess.acc_holder_name}
                      name="acc_holder_name"
                      onChange={handleChangess}
                      inputProps={{ maxLength: 50 }} // Adjust the max length as needed
                    />
                  </div>
                  <DialogActions
                    sx={{ justifyContent: "center", display: "flex" }}
                  >
                    <Button
                      type="submit"
                      // disabled={!isAnyFieldNotEmpty}
                      sx={{
                        fontSize: "14px",
                        fontWeight: "600",
                        border: "0",
                        ":hover": { bgcolor: "#1976d2", border: "0" },
                        backgroundColor: "#153e80",
                        marginLeft: {
                          xs: "0px",
                          sm: "15px",
                          md: "15px",
                          lg: "15px",
                        },
                        color: "#fff",
                        textTransform: "none",
                        minWidth: "100px",
                      }}
                    >
                      Update
                    </Button>
                  </DialogActions>
                </form>
              </DialogContent>
            </div>
          </Dialog>
        </Box>
      )}

      <FormDialog
        // page="profile"
        // selectedImg={editImageValue}
        open={open}
        dialogTitle={popupName}
        fields={
          popupName === "Edit Profile"
            ? fields.filter((el, index) =>
                ["name", "email", "profile_picture"].includes(el.name)
              )
            : fields.filter((el) => !["status"].includes(el.name))
        }
        // loading={popupName === "Edit Profile"}
        submitText="Submit"
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        onChange={handleInputChange}
      />

      <Dialog
        open={EditBusinessModal}
        fullWidth={false}
        onClose={false}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
      >
        <div
          className="mui-dialog-box-popup"
          style={{ backgroundColor: "#fff", color: "#000" }}
        >
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h5" sx={{ color: "#000 !important" }}>
              Business Details
            </Typography>

            <IconButton>
              <CloseIcon onClick={handleBusinessCloseModal} />
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <form onSubmit={handlBusinessUpdate}>
              <Grid container spacing={2}>
                <Grid item xs={6} md={6}>
                  <div style={{ marginTop: "5px" }}>
                    <TextField
                      sx={{ input: { color: "#000" } }}
                      id="business_name"
                      label="Business Name"
                      type="text"
                      fullWidth
                      variant="outlined"
                      value={valuesBusiness.business_name || ""}
                      name="business_name"
                      onChange={handleBusinessChanges}
                      inputProps={{ maxLength: 50 }} // Adjust the max length as needed
                    />
                  </div>
                </Grid>
                <Grid item xs={6} md={6}>
                  <div style={{ marginTop: "5px" }}>
                    <TextField
                      sx={{ input: { color: "#000" } }}
                      id="owner_firstname"
                      label="Owner Firstname"
                      type="text"
                      fullWidth
                      variant="outlined"
                      value={valuesBusiness.owner_firstname || ""}
                      name="owner_firstname"
                      onChange={handleBusinessChanges}
                      inputProps={{ maxLength: 50 }} // Adjust the max length as needed
                    />
                  </div>
                </Grid>

                <Grid item xs={6} md={6}>
                  <div style={{ marginTop: "5px" }}>
                    <TextField
                      sx={{ input: { color: "#000" } }}
                      id="owner_lastname"
                      label="Owner Lastname"
                      type="text"
                      fullWidth
                      variant="outlined"
                      value={valuesBusiness.owner_lastname || ""}
                      name="owner_lastname"
                      onChange={handleBusinessChanges}
                      inputProps={{ maxLength: 50 }} // Adjust the max length as needed
                    />
                  </div>
                </Grid>
                <Grid item xs={6} md={6}>
                  <div style={{ marginTop: "5px" }}>
                    <TextField
                      sx={{ input: { color: "#000" } }}
                      id="business_email"
                      label="Business Email"
                      type="text"
                      fullWidth
                      variant="outlined"
                      value={valuesBusiness.business_email || ""}
                      name="business_email"
                      onChange={handleBusinessChanges}
                      inputProps={{ maxLength: 50 }} // Adjust the max length as needed
                    />
                  </div>
                </Grid>

                <Grid item xs={6} md={6}>
                  <div style={{ marginTop: "5px" }}>
                    <MuiPhoneNumber
                      fullWidth
                      defaultCountry={"in"}
                      size="small"
                      variant="outlined"
                      id="business_contact"
                      name="business_contact"
                      label="Business Contact"
                      type="text"
                      onChange={(value) => {
                        const newContactValue = value
                          .replace("+91", "")
                          .replace("-", "");
                        setValuesBusiness({
                          ...valuesBusiness,
                          business_contact: newContactValue,
                        });
                      }}
                      InputLabelProps={{ sx: { color: "black" } }}
                    />
                  </div>
                </Grid>

                <Grid item xs={6} md={6}>
                  <div style={{ marginTop: "5px" }}>
                    <TextField
                      sx={{ input: { color: "#000" } }}
                      id="establish_year"
                      label="Establish Year"
                      type="text"
                      fullWidth
                      variant="outlined"
                      value={valuesBusiness.establish_year || ""}
                      name="establish_year"
                      onChange={handleBusinessChanges}
                      inputProps={{ maxLength: 50 }} // Adjust the max length as needed
                    />
                  </div>
                </Grid>

                <Grid item xs={6} md={6}>
                  <div style={{ marginTop: "5px" }}>
                    <Autocomplete
                      {...defaultProps}
                      id="business_category_id"
                      name="business_category_id"
                      value={{
                        value: valuesBusiness?.business_category_id || "",
                        label: valuesBusiness?.business_category_name || "",
                      }}
                      autoComplete
                      includeInputInList
                      renderInput={(params) => (
                        <TextField
                          sx={{ color: "#000" }}
                          {...params}
                          label="Business Category"
                          inputProps={{
                            ...params.inputProps,
                            style: { color: "#000" },
                          }}
                        />
                      )}
                      onChange={(event, newValue) => {
                        setValuesBusiness({
                          ...valuesBusiness,
                          business_category_id: newValue?.value || "",
                          business_category_name: newValue?.label || "",
                        });
                      }}
                      className="form-control form-control-mini border-0 px-0 bg-transparent"
                    />
                  </div>
                </Grid>

                <Grid item xs={6} md={6}>
                  <div style={{ marginTop: "5px" }}>
                    <TextField
                      sx={{ input: { color: "#000" } }}
                      id="annual_turnover"
                      label="Annual Turnover"
                      type="text"
                      fullWidth
                      variant="outlined"
                      value={valuesBusiness.annual_turnover || ""}
                      name="annual_turnover"
                      onChange={handleBusinessChanges}
                      inputProps={{ maxLength: 10 }} // Adjust the max length as needed
                    />
                  </div>
                </Grid>
                <Grid item xs={6} md={6}>
                  <div style={{ marginTop: "5px" }}>
                    <TextField
                      sx={{ input: { color: "#000" } }}
                      id="business_website"
                      label="Business WebSite"
                      type="text"
                      fullWidth
                      variant="outlined"
                      value={valuesBusiness.business_website || ""}
                      name="business_website"
                      onChange={handleBusinessChanges}
                      inputProps={{ maxLength: 30 }} // Adjust the max length as needed
                    />
                  </div>
                </Grid>
                <Grid item xs={6} md={6}>
                  <div style={{ marginTop: "5px" }}>
                    <TextField
                      sx={{ input: { color: "#000" } }}
                      id="address"
                      label="Address"
                      type="text"
                      fullWidth
                      variant="outlined"
                      value={valuesBusiness.address || ""}
                      name="address"
                      onChange={handleBusinessChanges}
                      inputProps={{ maxLength: 50 }} // Adjust the max length as needed
                    />
                  </div>
                </Grid>

                {/* <Grid item md={6} xs={12} sm={12}>
                  <div style={{ marginTop: "1rem" }}>
                    <Autocomplete
                      id="country"
                      name="country"
                      value={valuesBusiness?.country || null}
                      // classes={customStyles}
                      onChange={(e, value) => {
                        setValuesBusiness({
                          ...valuesBusiness,
                          country: value,
                        });
                        dispatch(GetAllStates(value?._id));
                        dispatch(cleareAllStates());
                        setValuesBusiness((prev) => ({ ...prev, state: "" }));
                        setValuesBusiness((prev) => ({ ...prev, city: "" }));
                        setValuesBusiness((prev) => ({ ...prev, pincode: "" }));
                      }}
                      options={allCountryData?.countryData || []}
                      autoHighlight
                      getOptionLabel={(option) => {
                        return option.country_name;
                      }}
                      renderInput={(params) => (
                        <TextField
                          sx={{ color: "#000" }}
                          {...params}
                          label="Country"
                          inputProps={{
                            ...params.inputProps,
                            style: { color: "#000" },
                          }}
                        />
                      )}
                    />
                  </div>
                </Grid> */}

                {/* <Grid item md={6} xs={12} sm={12}>
                  <div style={{ marginTop: "1rem" }}>
                    <Autocomplete
                      id="state_id"
                      name="state_id"
                      value={valuesBusiness?.state_id || null}
                      onChange={(e, value) => {
                        setValuesBusiness({
                          ...valuesBusiness,
                          state_id: value,
                        });
                        dispatch(GetAllCities(value?._id));
                        dispatch(cleareAllCities());
                        setValuesBusiness((prev) => ({ ...prev, city: "" }));
                        setValuesBusiness((prev) => ({ ...prev, pincode: "" }));
                      }}
                      options={allStateData || []}
                      autoHighlight
                      getOptionLabel={(option) => option.state_name}
                      renderInput={(params) => (
                        <TextField
                          sx={{ color: "#000" }}
                          {...params}
                          label="State"
                          inputProps={{
                            ...params.inputProps,
                            style: { color: "#000" },
                          }}
                        />
                      )}
                    />
                  </div>
                </Grid> */}
                {/* 
                <Grid item md={6} xs={12} sm={12}>
                  <div style={{ marginTop: "1rem" }}>
                    <Autocomplete
                      id="city_id"
                      name="city_id"
                      value={valuesBusiness?.city_id || null}
                      onChange={(e, value) => {
                        setValuesBusiness({
                          ...valuesBusiness,
                          city_id: value,
                        });
                        dispatch(GetAllPostalCodes(value?._id));
                        dispatch(clearePostalCodes());
                        setValuesBusiness((prev) => ({ ...prev, pincode: "" }));
                      }}
                      options={allCitiesData || []}
                      autoHighlight
                      getOptionLabel={(option) => option.city_name}
                      renderInput={(params) => (
                        <TextField
                          sx={{ color: "#000" }}
                          {...params}
                          label="City"
                          inputProps={{
                            ...params.inputProps,
                            style: { color: "#000" },
                          }}
                        />
                      )}
                    />
                  </div>
                </Grid> */}

                {/* <Grid item md={6} xs={12} sm={12}>
                  <div style={{ marginTop: "1rem" }}>
                    <Autocomplete
                      id="postalcode_id "
                      name="postalcode_id "
                      value={valuesBusiness?.postalcode_id || null}
                      onChange={(e, value) =>
                        setValuesBusiness({
                          ...valuesBusiness,
                          postalcode_id: value,
                        })
                      }
                      options={allPostalData || []}
                      autoHighlight
                      getOptionLabel={(option) => option.postal_code}
                      renderInput={(params) => (
                        <TextField
                          sx={{ color: "#000" }}
                          {...params}
                          label="Postal code"
                          inputProps={{
                            ...params.inputProps,
                            style: { color: "#000" },
                          }}
                        />
                      )}
                    />
                  </div>
                </Grid> */}
                {/* 
                <Grid item xs={6} md={6}>
                  <div style={{ marginTop: "5px" }}>
                    <TextField
                      sx={{ input: { color: "#000" } }}
                      id="representatives "
                      label="representatives"
                      type="text"
                      fullWidth
                      variant="outlined"
                      value={valuesBusiness.representatives}
                      name="representatives "
                      onChange={handleBusinessChanges}
                      inputProps={{ maxLength: 50 }} // Adjust the max length as needed
                    />
                  </div>
                </Grid> */}

                <Grid item xs={6} md={6}>
                  <div style={{ marginTop: "5px" }}>
                    <TextField
                      sx={{ input: { color: "#000" } }}
                      id="business_card"
                      label="Business Card"
                      type="text"
                      fullWidth
                      variant="outlined"
                      value={valuesBusiness.business_card || ""}
                      name="business_card"
                      onChange={handleBusinessChanges}
                      inputProps={{ maxLength: 50 }} // Adjust the max length as needed
                    />
                  </div>
                </Grid>

                <Grid item xs={6} md={6}>
                  <div style={{ marginTop: "5px" }}>
                    <TextField
                      sx={{ input: { color: "#000" } }}
                      id="business_gst"
                      label="Business GST"
                      type="text"
                      fullWidth
                      variant="outlined"
                      value={valuesBusiness.business_gst || ""}
                      name="business_gst"
                      onChange={handleBusinessChanges}
                      inputProps={{ maxLength: 50 }} // Adjust the max length as needed
                    />
                  </div>
                </Grid>

                <Grid item xs={6} md={6}>
                  <div style={{ marginTop: "5px" }}>
                    <TextField
                      sx={{ input: { color: "#000" } }}
                      id="business_pan"
                      label="Business Pan"
                      type="text"
                      fullWidth
                      variant="outlined"
                      value={valuesBusiness.business_pan || ""}
                      name="business_pan"
                      onChange={handleBusinessChanges}
                      inputProps={{ maxLength: 50 }} // Adjust the max length as needed
                    />
                  </div>
                </Grid>

                <Grid item xs={6} md={6}>
                  <div style={{ marginTop: "5px" }}>
                    <TextField
                      sx={{ input: { color: "#000" } }}
                      id="business_type"
                      label="Business Type"
                      type="text"
                      fullWidth
                      variant="outlined"
                      value={valuesBusiness.business_type || ""}
                      name="business_type"
                      onChange={handleBusinessChanges}
                      inputProps={{ maxLength: 50 }} // Adjust the max length as needed
                    />
                  </div>
                </Grid>

                <Grid item xs={6} md={6}>
                  <Grid item md={12} xs={12} sm={12}>
                    <label>Business Logo</label>
                    <input
                      type="file"
                      accept="image/*"
                      id="image-upload3"
                      style={{ display: "none" }}
                      onChange={handleImageUpload3}
                    />
                    <label htmlFor="image-upload3">
                      <Button
                        component="span"
                        variant="contained"
                        color="inherit"
                      >
                        Upload Logo
                      </Button>
                    </label>
                    {/* {selectedImage1 ? null : (
                <div className="err-msg-font">
                  Please select an image to upload.
                </div>
              )} */}
                  </Grid>
                  <Grid item md={12} xs={12} sm={12}>
                    {selectedImage3 && (
                      <Grid item md={12} xs={12} sm={12}>
                        <Typography
                          variant="subtitle1"
                          sx={{ marginTop: "1rem" }}
                        >
                          Selected Image:
                          <br />
                          <a target="_blank" href={selectedImage3.name}>
                            {selectedImage3.name}
                          </a>
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                <Grid item md={12} xs={12}>
                  <div>
                    {representatives?.map((rep, index) => (
                      <div key={index}>
                        <Grid container spacing={2}>
                          <Grid item xs={6} md={6}>
                            <MuiPhoneNumber
                              fullWidth
                              defaultCountry={"in"}
                              size="small"
                              id="mobile_number "
                              label="Mobile number"
                              type="text"
                              variant="outlined"
                              value={rep.mobile_number || ""}
                              name="mobile_number "
                              onChange={(e) =>
                                handleInputChangeRep(index, "mobile_number", e)
                              }
                              InputLabelProps={{ sx: { color: "black" } }}
                            />
                          </Grid>
                          <Grid item xs={6} md={6}>
                            <TextField
                              sx={{ input: { color: "#000" } }}
                              id="email "
                              label="Email"
                              type="text"
                              fullWidth
                              variant="outlined"
                              value={rep.email}
                              name="email "
                              onChange={(e) =>
                                handleInputChangeRep(
                                  index,
                                  "email",
                                  e.target.value
                                )
                              }
                              inputProps={{ maxLength: 50 }}
                            />
                          </Grid>

                          <Grid item xs={6} md={6}>
                            <TextField
                              sx={{ input: { color: "#000" } }}
                              id="first_name "
                              label="Name"
                              type="text"
                              fullWidth
                              variant="outlined"
                              value={rep.first_name}
                              name="first_name "
                              onChange={(e) =>
                                handleInputChangeRep(
                                  index,
                                  "first_name",
                                  e.target.value
                                )
                              }
                              inputProps={{ maxLength: 50 }}
                            />
                          </Grid>

                          <Button
                            type="button"
                            onClick={() => deleteRepresentative(index)}
                            sx={{
                              fontSize: "14px",
                              fontWeight: "600",
                              border: "0",
                              ":hover": { bgcolor: "#red", border: "0" },
                              backgroundColor: "#153e80",
                              marginLeft: {
                                xs: "0px",
                                sm: "15px",
                                md: "15px",
                                lg: "15px",
                              },
                              color: "#fff",
                              textTransform: "none",
                              minWidth: "81px",
                              height: "35px",
                              marginTop: "21px",
                            }}
                          >
                            Delete
                          </Button>
                        </Grid>
                      </div>
                    ))}
                    <Button
                      type="button"
                      onClick={addRepresentative}
                      sx={{
                        fontSize: "14px",
                        fontWeight: "600",
                        border: "0",
                        ":hover": { bgcolor: "#1976d2", border: "0" },
                        backgroundColor: "#153e80",
                        marginLeft: {
                          xs: "0px",
                          sm: "15px",
                          md: "15px",
                          lg: "15px",
                        },
                        color: "#fff",
                        textTransform: "none",
                        minWidth: "100px",
                        marginTop: "10px",
                      }}
                    >
                      Add Representative
                    </Button>
                  </div>
                </Grid>
              </Grid>

              <DialogActions sx={{ justifyContent: "center", display: "flex" }}>
                <Button
                  type="submit"
                  // disabled={!isAnyFieldNotEmpty}
                  sx={{
                    fontSize: "14px",
                    fontWeight: "600",
                    border: "0",
                    ":hover": { bgcolor: "#1976d2", border: "0" },
                    backgroundColor: "#153e80",
                    marginLeft: {
                      xs: "0px",
                      sm: "15px",
                      md: "15px",
                      lg: "15px",
                    },
                    color: "#fff",
                    textTransform: "none",
                    minWidth: "100px",
                  }}
                >
                  Update
                </Button>
              </DialogActions>
            </form>
          </DialogContent>
        </div>
      </Dialog>

      <Dialog
        open={EditBusinessDocModal}
        fullWidth={false}
        onClose={false}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
      >
        <div
          className="mui-dialog-box-popup"
          style={{ backgroundColor: "#fff", color: "#000" }}
        >
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h5" sx={{ color: "#000 !important" }}>
              Business Document
            </Typography>

            <IconButton>
              <CloseIcon onClick={handleBusinessDocCloseModal} />
            </IconButton>
          </DialogTitle>

          <DialogContent sx={{ minWidth: "400px" }}>
            <form onSubmit={handlBusinessDocUpdate}>
              <Grid>
                {documents[0]?.gst_status !== "approved" && (
                  <Grid item xs={6} md={6}>
                    <Grid item md={3} xs={12} sm={12}>
                      <label>GST Document</label>
                      <input
                        type="file"
                        accept="image/*,application/pdf"
                        id="image-upload1"
                        style={{ display: "none" }}
                        onChange={handleImageUpload1}
                      />
                      <label htmlFor="image-upload1">
                        <Button
                          component="span"
                          variant="contained"
                          color="inherit"
                        >
                          Upload GST
                        </Button>
                      </label>
                      {/* {selectedImage1 ? null : (
                <div className="err-msg-font">
                  Please select an image to upload.
                </div>
              )} */}
                    </Grid>
                    <Grid item md={9} xs={12} sm={12}>
                      {selectedImage1 && profileData?.data && (
                        <Grid item md={12} xs={12} sm={12}>
                          <Typography
                            variant="subtitle1"
                            sx={{ marginTop: "1rem" }}
                          >
                            Selected Image:
                            <br />
                            <a target="_blank" href={selectedImage1.name}>
                              {selectedImage1.name || selectedImage1}
                            </a>
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                )}

                {documents[0]?.pan_status !== "approved" && (
                  <Grid item xs={6} md={6}>
                    <Grid item md={3} xs={12} sm={12}>
                      <label>PAN Document</label>
                      <input
                        type="file"
                        accept="image/*,application/pdf"
                        id="image-upload2"
                        style={{ display: "none" }}
                        onChange={handleImageUpload2}
                      />
                      <label htmlFor="image-upload2">
                        <Button
                          component="span"
                          variant="contained"
                          color="inherit"
                          style={{ marginLeft: "16px" }}
                        >
                          Upload Pan
                        </Button>
                      </label>
                      {/* {selectedImage1 ? null : (
                <div className="err-msg-font">
                  Please select an image to upload.
                </div>
              )} */}
                    </Grid>
                    <Grid item md={9} xs={12} sm={12}>
                      {selectedImage2 && profileData?.data && (
                        <Grid item md={12} xs={12} sm={12}>
                          <Typography
                            variant="subtitle1"
                            sx={{ marginTop: "1rem" }}
                          >
                            Selected Image:
                            <br />
                            <a target="_blank" href={selectedImage2.name}>
                              {selectedImage2.name || selectedImage2}
                            </a>
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <DialogActions sx={{ justifyContent: "center", display: "flex" }}>
                <Button
                  type="submit"
                  // disabled={!isAnyFieldNotEmpty}
                  sx={{
                    fontSize: "14px",
                    fontWeight: "600",
                    border: "0",
                    ":hover": { bgcolor: "#1976d2", border: "0" },
                    backgroundColor: "#153e80",
                    marginLeft: {
                      xs: "0px",
                      sm: "15px",
                      md: "15px",
                      lg: "15px",
                    },
                    color: "#fff",
                    textTransform: "none",
                    minWidth: "100px",
                  }}
                >
                  Update
                </Button>
              </DialogActions>
            </form>
          </DialogContent>
        </div>
      </Dialog>
    </>
  );
};

export default Profile;
