const initialState = {
    loading: false,
    createLoading: false,
    updateLoading: false,
    deleteLoading: false,
    service: [],
}

export default function ServiceReducer(state = initialState, action) {
    switch (action.type) {
        case "GET_SERVICE_LOADING":
            return {
                ...state,
                loading: action.payload
            }
        case "SERVICE_SUCCESS":
            return {
                ...state,
                service: action.payload,
                loading: false
            }
        case "CREATE_SERVICE_LOADING":
            return {
                ...state,
                createLoading: action.payload
            }
        case "CREATE_SERVICE_SUCCESS":
            return {
                ...state,
                service: action.payload,
                loading: false
            }
        case "UPDATE_SERVICE_LOADING":
            return {
                ...state,
                updateLoading: action.payload
            }
        case "UPDATE_SERVICE_SUCCESS":
            return {
                ...state,
                service: action.payload,
                loading: false
            }
        case "DELETE_SERVICE_LOADING":
            return {
                ...state,
                deleteLoading: false
            }
        case "DELETE_SERVICE_SUCCESS":
            return {
                ...state,
                service: action.payload,
                loading: false
            }
        case "SERVICE_FAIL":
            return {
                ...state,
                service: action.payload,
                loading: false
            }
        case "CREATE_SERVICE_FAIL":
            return {
                ...state,
                service: action.payload,
                loading: false
            }
        case "UPDATE_SERVICE_FAIL":
            return {
                ...state,
                service: action.payload,
                loading: false
            }
        case "DELETE_SERVICE_FAIL":
            return {
                ...state,
                service: action.payload,
                loading: false
            }
        default:
            return state;
    }
}

