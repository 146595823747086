import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Pagination,
  Typography,
  Drawer,
  Stack,
  Divider,
  Button,
  CircularProgress,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import PageHeader from "../pageHeader/PageHeader";
import EnhancedTable from "../table/EnhancedTable ";
import {
  DeleteIcon,
  EditIcon,
  CloseIcon,
  Visibility,
} from "../../assets/icons/Icons";
import "../../components/table/table.css";
import { useForm } from "../../common/useForms";
import FormDialog from "../dialog/dialog";
import { makeStyles } from "@material-ui/core/styles";
import Input from "../../common/Input";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import DeleteDialog from "../dialog/deletedialog";
import {
  DeletePartnerReq,
  GetPartnerRequest,
  UpdatePartnerNote,
  UpdatePartnerReq,
} from "../../redux/action/PartnerRequestAction";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";
import SelectBox from "../../common/SelectBox";
import { useNavigate } from "react-router-dom";
import NoteDialog from "../dialog/NoteDialog";
import { getPermissions } from "../../common/permissions";
import { permissions } from "../../common/constants";
import { ApiURL } from "../../redux/common";
import axios from "axios";
import Loader from "../loader/Loader";
import useUserRolePermissions from "../../common/UserPermissions";

const useStyles = makeStyles((theme) => ({
  filterMain: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "#fff",
  },
  mainFilterBox: {
    padding: "10px 20px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "200px",
    },

    [theme.breakpoints.up("md")]: {
      width: "300px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "240px !important",
    },
    [theme.breakpoints.up("xs")]: {
      width: "100%",
    },
  },

  stackFilter: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  typographyFilter: {
    fontWeight: "bold !important",
    color: "#000 !important",
    fontSize: "30px !important",
  },
  buttonFilter: {
    flexDirection: "row !important",
    marginTop: "8px",
    gap: "8px",
  },
}));

const columns = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "pin_code",
    numeric: true,
    disablePadding: false,
    label: "Pin Code",
  },
  {
    id: "role",
    numeric: true,
    disablePadding: false,
    label: "Role",
  },
  {
    id: "city",
    numeric: true,
    disablePadding: false,
    label: "City",
  },
  {
    id: "state",
    numeric: true,
    disablePadding: false,
    label: "State",
  },
  {
    id: "country",
    numeric: true,
    disablePadding: false,
    label: "Country",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "view",
    numeric: true,
    disablePadding: false,
    label: "View",
  },
  { id: "action", numeric: true, disablePadding: false, label: "Action" },
];

const initialValues = { status: "" };
const initialFilterValues = {
  name: "",
  // pincode: "",
  status: "",
};

const RecordPerPage = [
  { id: 5, title: "5" },
  { id: 10, title: "10" },
];

const PartnerRequestList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [noteOpen, setNoteOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState(false);
  const [filterData, setFilterData] = useState(true);
  const [filterDatas, setFilterDatas] = useState(initialFilterValues);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [DeleteId, SetDeleteId] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [popupName, setPopupName] = useState("");
  const [tempPass, setTempPass] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const statusOptions = [
    { value: "approved", label: "Approved" },
    { value: "pending", label: "Pending" },
    { value: "rejected", label: "Rejected" },
  ];
  const role = useUserRolePermissions();

  const {
    loading,
    partnerRequests,
    updateLoading,
    deleteLoading,
    noteUpdateLoading,
  } = useSelector((state) => state.PartnerRequestReducer);

  const classes = useStyles();

  const [downLoadData, setDownLoadData] = useState("");



  const DownLoadPdf = async () => {
    try {
        const res = await axios.get(`${ApiURL}partner-request/download-data`);
        setDownLoadData(res?.data?.data)

    } catch (error) {
        console.error('Error fetching role data:', error);
        return {};
    }
};

useEffect(() => {
    DownLoadPdf()
}, [])


  // partner-request

  const tableTitle = "SubscriptionList";

  const handleChangeRow = (event) => {
    const rowPerPage = event.target.value;
    setRowsPerPage(event.target.value);
    setPage(1);
    const name = filterDatas.name ? filterDatas.name : "";
    const status = filterDatas.status || "";
    dispatch(GetPartnerRequest(1, name, status, rowPerPage));
  };

  useEffect(() => {
    if (!open) {
      const name = filterDatas.name ? filterDatas.name : "";
      const status = filterDatas.status || "";
      dispatch(GetPartnerRequest(page, name, status, rowsPerPage));
    }
  }, [page, open, dispatch]);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleClose = () => {
    setOpen(false);
    resetForm();
  };

  const handleNoteClose = () => {
    setNoteOpen(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("email" in fieldValues) {
      if (fieldValues.email !== "" && fieldValues.email !== undefined) {
        const emailRegex =
          /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.(com|org|gov|edu|net|mil|in|co)$/;
        const isEmailValid = emailRegex.test(fieldValues.email);
        if (!isEmailValid) {
          temp.email = "Invalid email address. Please enter a valid email.";
        } else {
          temp.email = "";
        }
      } else {
        temp.email = "This field is required.";
      }
    }
    if ("role" in fieldValues)
      temp.role = fieldValues.role ? "" : "This field is required.";
    if ("mobile_number" in fieldValues) {
      if (fieldValues.mobile_number !== "") {
        if (/^[0-9\s]*$/.test(fieldValues.mobile_number)) {
          temp.mobile_number = "";
        } else {
          temp.mobile_number = "Only Number allow";
        }
      } else {
        temp.mobile_number = "This field is required.";
      }
    }
    if (popupName !== "Update User") {
      if ("password" in fieldValues) {
        if (fieldValues.password !== "" && fieldValues.password !== undefined) {
          setTempPass(fieldValues.password);
          if (fieldValues.password.length < 8) {
            temp.password = "Password  must be 8 charecter";
          } else {
            if (!/[0-9]/.test(fieldValues.password)) {
              temp.password = "Password  must be 1 Number";
            } else {
              if (!/[a-z]/.test(fieldValues.password)) {
                temp.password = "Password  must be 1 Lowercase letter";
              } else {
                if (!/[A-Z]/.test(fieldValues.password)) {
                  temp.password = "Password  must be 1 Uppercase letter";
                } else {
                  // setPass(fieldValues.password);
                  temp.password = "";
                }
              }
            }
          }
        } else {
          temp.password = "This field is required.";
        }
      }
      if ("confirm_password" in fieldValues) {
        if (
          fieldValues.confirm_password !== "" &&
          fieldValues.confirm_password !== undefined
        ) {
          if (fieldValues.confirm_password !== tempPass) {
            temp.confirm_password = "Passwords do not match";
          } else {
            temp.confirm_password = "";
          }
        } else {
          temp.confirm_password = "This field is required.";
        }
      }
    }
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleEditClick = async (data) => {
    setSelectedRequest(data);
    setPopupName("Update Request");
    setValues({
      ...values,
      status: data?.status || "",
    });
    setErrors({});
    setOpen(true);
  };

  const handleNoteClick = async (data) => {
    setSelectedRequest(data);
    setNoteOpen(true);
  };

  const handleClickOpenDelete = (data) => {
    setOpenDelete(true);
    SetDeleteId(data._id);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleSingleDelete = async () => {
    await dispatch(DeletePartnerReq(DeleteId, handleCloseDelete)).then(
      (res) => {
        if (res && res.data.status === true) {
          dispatch(GetPartnerRequest(page, "", "", rowsPerPage));
        }
      }
    );
  };

  const handleViewClick = (data) => {
    navigate("/admin-partner-request-details", { state: { partnerData: data } });
  };

  //filter

  const resetFormFilter = () => {
    setFilterDatas(initialFilterValues);
    setPage(1);
    dispatch(GetPartnerRequest(1, "", rowsPerPage));
    closeFilterDrawer();
  };

  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
    setFilterData(!filterData);
  };
  const closeFilterDrawer1 = () => {
    setFilter(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFilterDatas((prevFilterData) => ({
      ...prevFilterData,
      [name]: value,
    }));
  };

  const handleSubmitFilter = (e) => {
    e.preventDefault();
    const body = {
      name: filterDatas.name,
      // pincode: filterDatas.pincode,
      status: filterDatas.status,
    };
    setFilterDatas(body);
    const name = filterDatas.name ? filterDatas.name : "";
    const status = filterDatas.status || "";
    setPage(1);
    dispatch(GetPartnerRequest(page, name, status, rowsPerPage));
    closeFilterDrawer1();
  };

  const rows =
    partnerRequests && Array.isArray(partnerRequests?.requestPartnerData)
      ? partnerRequests?.requestPartnerData.map((data) => {
          const selected = data === selectedRequest;
          return {
            _id: data._id,
            name: data.name,
            pin_code: data.postalcode,
            role: data.role,
            city: data.city_name,
            state: data.state_name,
            country: data.country_name,
            status: data.status,
            view: (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <IconButton
                  onClick={() => handleViewClick(data)}
                  sx={{ color: "black" }}
                >
                  <Visibility />
                </IconButton>
              </div>
            ),
            action: (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <IconButton
                  onClick={() => handleNoteClick(data)}
                  sx={{ color: "black" }}
                >
                  <SummarizeOutlinedIcon />
                </IconButton>
                {!!getPermissions(permissions.partnerRequest, "is_edit") && (
                  <IconButton
                    onClick={() => handleEditClick(data)}
                    sx={{ color: "black" }}
                  >
                    <EditIcon />
                  </IconButton>
                )}
                {!!getPermissions(permissions.partnerRequest, "is_delete") && (
                  <IconButton
                    onClick={() => handleClickOpenDelete(data)}
                    sx={{ color: "black" }}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </div>
            ),
            selected: selected,
          };
        })
      : [];

  const fields = [
    {
      id: "status",
      label: "Status",
      type: "select",
      options: statusOptions,
      value: values.status,
      name: "status",
      errors: errors.status,
    },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    let body = {
      status: values.status,
    };

    if (popupName === "Update Request") {
      dispatch(UpdatePartnerReq(selectedRequest._id, body, handleClose));
    }
  };

  const handleNoteSubmit = async (noteData) => {
    await dispatch(
      UpdatePartnerNote(selectedRequest._id, noteData, handleNoteClose)
    ).then((res) => {
      if (res && res.data.status === true) {
        dispatch(GetPartnerRequest(page, "", "", rowsPerPage));
      }
    });
  };

  // const isAnyFieldFilled = Object.values(filterDatas).some((value) => Boolean(value));

  return (
    <Box>
      <PageHeader
        title="Partner Request"
        isShowAdd={false}
        onFilterClick={openFilterDrawer}
        href={downLoadData}
      />

      {loading ? (
        <Loader/>
        // <Box sx={{ display: "flex", justifyContent: "center" }}>
        //   <CircularProgress />
        // </Box>
      ) : (
        <EnhancedTable
          key={rows._id}
          columns={columns}
          rows={rows}
          order={order}
          orderBy={orderBy}
          selected={selected}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          title={tableTitle}
          tableList={partnerRequests?.requestPartnerData}
        />
      )}
      <FormDialog
        open={open}
        dialogTitle={popupName}
        fields={fields}
        loading={updateLoading}
        submitText="Submit"
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        onChange={handleInputChange}
        // isAnyFieldNotEmpty={isAnyFieldNotEmpty}
      />

      {noteOpen && (
        <NoteDialog
          open={noteOpen}
          dialogTitle={"Add Note"}
          loading={noteUpdateLoading}
          submitText="Submit"
          noteData={selectedRequest?.note}
          handleClose={handleNoteClose}
          handleSubmit={handleNoteSubmit}
          onChange={handleInputChange}
          // isAnyFieldNotEmpty={isAnyFieldNotEmpty}
        />
      )}

      <DeleteDialog
        open={openDelete}
        loading={deleteLoading}
        dialogTitle="Are you sure to want to delete this record?"
        handleClose={handleCloseDelete}
        handleDelete={() => handleSingleDelete(DeleteId)}
      />

      <Box
        sx={{
          display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
          justifyContent: "space-between",
          alignItems: "center",
          marginLeft: { xs: "10px", sm: "20px", md: "20px", lg: "20px" },
          marginRight: { xs: "10px", sm: "15px", md: "15px", lg: "15px" },
          mt: 2,
          mb: 2,
        }}
      >
        <Box
          sx={{
            fontSize: "14px",
            marginBottom: { xs: "10px", sm: "0", md: "0", lg: "0" },
            gap: 2,
            width: { sm: "100%", md: "50%" },
          }}
          display="flex"
          alignItems="center"
        >
          <Box>
            {" "}
            Showing {partnerRequests?.currentPage} of{" "}
            {partnerRequests?.totalPages} Results
          </Box>
          <Box display="flex" sx={{ gap: 1 }} alignItems="center">
            <Typography>Records per page</Typography>

            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                id="limites"
                name="limites"
                value={rowsPerPage}
                onChange={handleChangeRow}
                displayEmpty
                sx={{ maxWidth: "65px", maxHeight: "2.2rem" }}
                inputProps={{ "aria-label": "Without label" }}
              >
                {RecordPerPage?.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name || item.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Pagination
          count={partnerRequests?.totalPages}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
          sx={{
            "& .MuiButtonBase-root ": {
              color: "#000 !important",
            },
            "& .Mui-selected ": {
              background: "#4b4b4e70 !important",
            },
            "& .Mui-disabled ": {
              background: "#6c696966 !important",
              color: "#000000 !important",
              opacity: 1,
            },
          }}
        />
      </Box>

      <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
        <form onSubmit={handleSubmitFilter} className={classes.filterMain}>
          <Box className={classes.mainFilterBox}>
            <Stack className={classes.stackFilter}>
              <Typography className={classes.typographyFilter}>
                Filter
              </Typography>

              <CloseIcon
                className="close-button"
                onClick={closeFilterDrawer1}
              />
            </Stack>
            <Box
              sx={{
                marginTop: "5px",
              }}
            >
              <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />
            </Box>

            <div>
              <Box>
                <Input
                  // disabled={isAnyFieldFilled && !filterDatas.name}
                  text="Add New"
                  variant="outlined"
                  color="primary"
                  label="Name"
                  name="name"
                  value={filterDatas.name}
                  onChange={handleChange}
                  maxLength={200}
                />
              </Box>
              {/* <Box>
                <Input
                  disabled={isAnyFieldFilled && !filterDatas.pincode}
                  text="Add New"
                  variant="outlined"
                  color="primary"
                  label="Pin Code"
                  name="pincode"
                  value={filterDatas.pincode}
                  onChange={handleChange}
                />
              </Box> */}
              <Box>
                <SelectBox
                  name="status"
                  label="Status"
                  // disabled={isAnyFieldFilled && !filterDatas.status}
                  ClassName="status-select-filter"
                  value={filterDatas.status}
                  onChange={handleChange}
                  options={statusOptions}
                />
              </Box>
              <Stack className={classes.buttonFilter}>
                <Button
                  color="secondary"
                  size="large"
                  variant="contained"
                  className="cancel-filter"
                  onClick={resetFormFilter}
                >
                  Reset
                </Button>
                <Button
                  color="primary"
                  size="large"
                  variant="contained"
                  className="order-filter-button"
                  type="submit"
                  style={{
                    cursor: "pointer",
                  }}
                >
                  Filter
                </Button>
              </Stack>
            </div>
          </Box>
        </form>
      </Drawer>
    </Box>
  );
};

export default PartnerRequestList;
