import React from "react";
import bannerBg from "../../assets/image/banner/banner_2.png";
import how1 from "../../assets/image/other/how-it-work-1.png";
import how2 from "../../assets/image/other/how-it-work-2.png";
import how3 from "../../assets/image/other/how-it-work-3.png";
import { styled } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import Pricing from "../pricing/Pricing";
import { beMemberForm } from "../../constants/beMemberContent";
import BeCard from "../../components/beCard/BeCard";
import BePartnerPageNavigation from "../../components/BePartnerPageNavigation/BePartnerPageNavigation";

const StyledContainer = styled("div")({
  background: "linear-gradient(45deg, #1F4182, #223366)", // Gradient background
  width: "100vw",
  marginTop: "40px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
});

const ButtonContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "2rem",
  cursor: "pointer",
});

const FindChapterBtn = styled("div")({
  width: "200px",
  backgroundColor: "#F07579",
  color: "#fff",
  padding: "1rem 2rem",
  borderRadius: "5px",
  textDecoration: "none",
  transition: "background-color 0.3s ease",
  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Add a subtle shadow
  "&:hover": {
    backgroundColor: "#a3141f",
  },
});

const BeMemberNew = () => {
  const navigate = useNavigate("");
  const handleBeMember = () => {
    navigate("/be-member-form");
  };

  return (
    <>
      <div>
        <section
          id="section-01"
          className="be-partner-main-intro"
          style={{ display: "flex", alignItem: "center", justifyContent: "center"}}
        >
          <img src={bannerBg} />
        
        </section>

        <div
          id="wrapper-content"
          class="wrapper-content pt-7 pb-5"
          style={{ background: "#f5f5f5 !important" }}
        >
          <div class="container">
            <div class="page-description text-center text-dark font-size-h5 lh-1625"></div>
            <div class="how-it-work">
              <div className="container">
                <div className="row d-flex">
                  <p className="lead mb-7 font-size-lg font-weight-normal lh-18 fadeInDown animated text-center">
                    Are you ready to elevate your business to new heights? Join
                    the Global Garner Community, where local meets global, and
                    success knows no bounds. As the proud initiative of Global
                    Garner Sales Service Limited, a trailblazer that has achieved
                    a remarkable 6650 crore valuation in just 7 years, we bring
                    you a unique platform that transcends geographical boundaries
                    and propels your business towards unprecedented growth.
                  </p>
                  {beMemberForm.map((item) => (
                    <BeCard {...item} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div></div>
      <BePartnerPageNavigation
        text="Be a Member"
        link="/be-member-form"
        buttonTitle="Be a Member"
      />
      {/* <StyledContainer>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={2}
        >
          <Grid
            item
            xs={6}
            sm={6}
            md={6}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <CardContent sx={{paddingBottom:"16px !important"}}>
              <Grid item xs={10} sm={12} md={12} lg={12} xl={12}>
                <div
                  style={{
                    background: "#fff",
                    boxShadow: "0 0 3px 2px rgba(0,0,0,.15)",
                    borderRadius: "5.5px",
                    padding: "1em 5em",
                    textAlign: "center",
                  }}
                >
                  <ButtonContainer>
                    <FindChapterBtn
                      to="/be-member-form"
                      tabIndex="0"
                      onClick={handleBeMember}
                    >
                      Be a Member
                    </FindChapterBtn>
                  </ButtonContainer>
                  <br />
                </div>
              </Grid>
            </CardContent>
          </Grid>
        </Grid>
      </StyledContainer> */}

      <div id="upgrade_now"></div>
      <Pricing />
    </>
  );
};

export default BeMemberNew;
