import React from "react";
import moment from "moment/moment";
import { Link } from "react-router-dom";

const EventCard = ({ item }) => {
  return (
    <div key={item._id} className="col-md-6 col-lg-4 mb-6">
      <div className="card border-0">
        <Link to={`/event/${item._id}`} className="hover-scale">
          <img
            src={item?.event_images?.length && item.event_images[0]}
            alt="product 1"
            style={{ height: "300px", width: "100%", objectFit: "cover" }}
            className="card-img-top image"
          />
        </Link>
        <div className="card-body px-0">
          <div className="mb-2">
            <a className="link-hover-dark-primary">
              {item?.event_type}, {item?.chapter_name}
            </a>
          </div>
          <h5 className="card-title lh-13 letter-spacing-25">
            <Link
              to={`/event/${item._id}`}
              className="link-hover-dark-primary text-capitalize"
            >
              {item.event_name}
            </Link>
          </h5>
          <ul className="list-inline">
            <li className="list-inline-item mr-0">
              <span className="text-gray">
                {moment(item.start_time).format("MMMM Do YYYY")}
              </span>
            </li>
            <li className="list-inline-item">
              {/* <a href="#" className="link-hover-dark-primary"> */}
              {/* {item.user} */}
              {/* </a> */}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default EventCard;
