import React from "react";
import Provider from "../../components/map/Provider";
import MapLeaflet from "../../components/map/MapLeaflet";
import { ContactUsApi } from "../../redux/contactAction";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Slider from "react-slick";
import contact1 from "../../assets/image/contact/contact-1.jpg";
import contact2 from "../../assets/image/contact/contact-2.jpg";
import contact3 from "../../assets/image/contact/contact-3.jpg";
import { snackBar } from "../../utils/common";

const Contactus = () => {
  const initialValues = {
    name: "",
    email: "",
    subject: "",
    message: "",
  };

  var settings = {
    dots: false,
    infinite: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    className: "hero-banner-arrow",
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          arrows: false,
          centerMode: false,
          centerPadding: "0%",
        },
      },
    ],
    centerMode: true,
    centerPadding: "20%",
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Please enter email"),
    name: Yup.string().required("Please enter name"),

    subject: Yup.string().required("Please enter subject"),
    message: Yup.string().required("Please enter message"),
  });

  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    try {
      const response = await ContactUsApi(values);
      if (response?.status === true) {
        resetForm();
        snackBar(response?.message, response?.status);
      } else {
        snackBar(response?.message, response?.status);
      }
    } catch (error) {
      snackBar(error?.message || "API call failed");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <div id="wrapper-content" className="wrapper-content pt-0 pb-4">
        <div>
          <Slider {...settings}>
            <div>
              <div
                className="box center"
                style={{ width: "100%", display: "inline-block" }}
              >
                <img
                  style={{ height: "400px", width: "100%" }}
                  src={contact1}
                  alt="Gallery 02"
                />
              </div>
            </div>
            <div>
              <div
                className="box"
                style={{ width: "100%", display: "inline-block" }}
              >
                <img
                  style={{ height: "400px", width: "100%" }}
                  src={contact2}
                  alt="Gallery 03"
                />
              </div>
            </div>
            <div>
              <div
                className="box"
                style={{ width: "100%", display: "inline-block" }}
              >
                <img
                  style={{ height: "400px", width: "100%" }}
                  src={contact3}
                  alt="Gallery 01"
                />
              </div>
            </div>
          </Slider>
        </div>

        <div className="container">
          <div className="pt-4">
            <div className="heading text-center mb-5">
              <h3 className="mb-0 lh-12">
                Contact us for any further questions, possible projects and
                business partnerships
              </h3>
            </div>
            <div
              className="row mb-5"
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div className="col-md-4 mb-5 mb-md-0">
                <div className="text-center">
                  <h5 className="font-weight-normal text-uppercase mb-5">
                    Contact Directly
                  </h5>
                  <div className="d-flex flex-column">
                    <span>reachus@globalgarner.community</span>
                    <span>+91 6354917501</span>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-5 mb-md-0">
                <div className="text-center">
                  <h5 className="font-weight-normal text-uppercase mb-5">
                    Visit our office
                  </h5>
                  <div className="d-flex flex-column">
                    <span>Global Garner Sales Services Limited</span>
                    <span>5th floor,Grand Emporio, Motera Stadium Road,</span>
                    <span>Ahmedabad, Gujarat 380005</span>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-4">
                <div className="contact-info-item text-center">
                  <h5 className="font-weight-normal text-uppercase mb-5">
                    work with us
                  </h5>
                  <div className="d-flex flex-column">
                    <span>Send your CV to our email:</span>
                    <span>career@thedir.com</span>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div className="form-contact" style={{ backgroundColor: "#f2f5f6" }}>
            <div className="form-wrapper px-3 px-sm-12 pt-7 pb-7">
              <h3 className="mb-4 text-center">Get In Touch</h3>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <div className="row">
                      <div className="form-group col-md-4">
                        <Field
                          type="text"
                          id="name"
                          name="name"
                          placeholder="Name"
                          className="name-input"
                          style={{
                            width: "100%",
                            height: "40px",
                            border: "1px solid black",
                            borderTop: "none",
                            borderLeft: "none",
                            borderRight: "none",
                            '::placeholder': {
                              color: 'black',
                            },
                          }}
                          maxLength={30}
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          style={{
                            marginTop: "5px",
                            color: "red",
                            fontSize: "15px",
                          }}
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <Field
                          type="text"
                          id="email"
                          name="email"
                          placeholder="Email"
                          className="name-input"
                          style={{
                            width: "100%",
                            height: "40px",
                            border: "1px solid black",
                            borderTop: "none",
                            borderLeft: "none",
                            borderRight: "none",
                          }}
                          maxLength={40}
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          style={{
                            marginTop: "5px",
                            color: "red",
                            fontSize: "15px",
                          }}
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <Field
                          type="text"
                          id="subject"
                          name="subject"
                          placeholder="Subject"
                          className="name-input"
                          style={{
                            width: "100%",
                            height: "40px",
                            border: "1px solid black",
                            borderTop: "none",
                            borderLeft: "none",
                            borderRight: "none",
                          }}
                          maxLength={40}
                        />
                        <ErrorMessage
                          name="subject"
                          component="div"
                          style={{
                            marginTop: "5px",
                            color: "red",
                            fontSize: "15px",
                          }}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <Field
                        as="textarea"
                        type="text"
                        id="message"
                        name="message"
                        placeholder="Message"
                        className="name-input"
                        style={{
                          width: "100%",
                          minHeight: "150px",
                          height: "40px",
                          border: "1px solid black",
                          borderTop: "none",
                          borderLeft: "none",
                          borderRight: "none",
                        }}
                        maxLength={40}
                      />
                      <ErrorMessage
                        name="message"
                        component="div"
                        style={{
                          marginTop: "5px",
                          color: "red",
                          fontSize: "15px",
                        }}
                      />
                    </div>
                    <div className="text-center pt-4">
                      <button
                        className="btn btn-link text-decoration-underline text-dark text-uppercase font-size-md font-weight-semibold text-decoration-underline"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        post comment
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <div
        id="map"
        className="map"
        style={{ width: "100vw", height: "80vh" }}
      >
        <Provider>
          <MapLeaflet />
        </Provider>
      </div>
    </>
  );
};

export default Contactus;
