import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import Input from "../../common/Input";
import { useFormik } from "formik";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import * as Yup from "yup";
import SelectBox from "../../common/SelectBox";
import {
  CreateMembership,
  UpdateMembership,
} from "../../redux/action/MembershipAction";
import { useDispatch, useSelector } from "react-redux";
import { InputLabel } from "@material-ui/core";
import { useLocation, useNavigate } from "react-router-dom";
import MuiPhoneNumber from "material-ui-phone-number";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { GetAllCountry } from "../../redux/action/CountryAction";
import {
  GetAllStates,
  cleareAllStates,
} from "../../redux/action/RoleandPermissionAction";
import { GetAllCities, cleareAllCities } from "../../redux/action/CityAction";
import {
  GetAllPostalCodes,
  clearePostalCodes,
} from "../../redux/action/PostalCodeAction";
import {
  CreateUserManage,
  GetUserRole,
} from "../../redux/action/UserManageAction";
import { getChapterAll } from "../../redux/action/ChapterAction";

var emailValidation =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const UserSchema = Yup.object().shape({
  first_name: Yup.string()
    .required("Please enter first name")
    .matches(/^\s*\S.*/, "Please enter first name"),
  last_name: Yup.string()
    .required("Please enter last name")
    .matches(/^\s*\S.*/, "Please enter last name"),
  email: Yup.string()
    .required("Please enter email")
    .matches(emailValidation, "Please enter valid email"),
  mobile_number: Yup.string().required("Please enter mobile number"),
  // description: Yup.string().required("Please enter Description"),
  gender: Yup.string().required("Please select gender"),
  birth_date: Yup.object().required("Please select birth date"),
  address: Yup.string()
    .required("Please enter address")
    .matches(/^\s*\S.*/, "Please enter address"),
  country: Yup.object().required("Please select country"),
  state: Yup.object().required("Please select state"),
  city: Yup.object().required("Please select city"),
  pincode: Yup.object().required("Please select pincode"),
  // role: Yup.string().required("Please select refferel type"),
  chapter: Yup.string().required("Please select refferel type"),
  password: Yup.string()
    .required("Please enter password")
    .min(8, "Password must be at least 8 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/,
      "Password must contain at least one uppercase letter, one lowercase letter, and one number"
    ),
  confirm_password: Yup.string()
    .required("Please enter confirm password")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
  company_name: Yup.string().required("Please enter company name"),
  pan_number: Yup.string()
    .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "Invalid pan Number")
    .required("Please enter pan number"),
  gst_number: Yup.string()
    .required("Please enter gst number")
    .min(15, "GST number must be exactly 15 characters")
    .max(15, "GST number must be exactly 15 characters"),
});

const genderOptions = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
  { label: "Other", value: "other" },
];

const BeMemberFormComponents = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [value, setValue] = React.useState(dayjs(""));

  const { allCountryData } = useSelector((state) => state.CountryReducer);
  const { allStateData } = useSelector((state) => state.StateReducer);
  const { allCitiesData } = useSelector((state) => state.CityReducer);
  const { allPostalData } = useSelector((state) => state.PostalCodeReducer);
  const { roleData } = useSelector((state) => state.UserManageReducer);
  const { getAllChapter } = useSelector((state) => state.ChapterReducer);

  const allChapterData =
    getAllChapter &&
    getAllChapter?.chapterData?.map((el) => {
      return { value: el?._id, label: el.chapter_name };
    });

  const allRolesData =
    roleData &&
    roleData?.map((el) => {
      return { value: el._id, label: el.role };
    });

  useEffect(() => {
    dispatch(GetAllCountry());
    dispatch(GetUserRole());
    dispatch(getChapterAll());
  }, []);

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      mobile_number: "",
      gender: "",
      birth_date: "",
      address: "",
      country: "",
      state: "",
      city: "",
      pincode: "",
      chapter: "",
      password: "",
      confirm_password: "",
      company_name: "",
      pan_number: "",
      gst_number: "",
    },
    validationSchema: UserSchema,
    onSubmit: (values, { resetForm }) => {
      const number = values.mobile_number.split(" ");
      const body = {
        first_name: values.first_name,
        last_name: values.last_name,
        birth_date: values.birth_date.format("YYYY-MM-DD"),
        address: values.address,
        city_id: values.city._id,
        state_id: values.state._id,
        postalcode_id: values.pincode._id,
        country_id: values.country._id,
        gender: values.gender,
        phone_code: number[0]?.replace("+", ""),
        mobile_number: number[1]?.replace("-", ""),
        email: values.email,
        password: values.password,
        confirm_password: values.confirm_password,
        chapter_id: values.chapter,
        company_name: values.company_name,
        pan_number: values.pan_number,
        gst_number: values.gst_number,
      };
      const formData = new FormData();
      formData.append("first_name", body.first_name);
      formData.append("last_name", body.last_name);
      formData.append("birth_date", body.birth_date);
      formData.append("address", body.address);
      formData.append("city_id", body.city_id);
      formData.append("state_id", body.state_id);
      formData.append("postalcode_id", body.postalcode_id);
      formData.append("country_id", body.country_id);
      formData.append("gender", body.gender);
      formData.append("phone_code", body.phone_code);
      formData.append("mobile_number", body.mobile_number);
      formData.append("email", body.email);
      formData.append("role_id", body.role_id);
      formData.append("password", body.password);
      formData.append("confirm_password", body.confirm_password);
      formData.append("chapter_id", body.chapter_id);
      formData.append("company_name", body.company_name);
      formData.append("pan_number", body.pan_number);
      formData.append("gst_number", body.gst_number);

      const resetInitForm = () => {
        resetForm();
      };
      //   dispatch(CreateUserManage(formData));
      //   resetForm();
      //   resetInitForm();
    },
  });

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          height: "100vh",
          width: "100%",
        }}
      >
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid item md={12} xs={12} sm={12}>
            <Grid item md={12}>
              <Box component={Paper} padding={{ xs: 2, sm: 3 }} elevation={5}>
                <Typography
                  textAlign="center"
                  fontWeight={900}
                  fontSize={28}
                  marginBottom={2}
                  color="black"
                >
                  Be a Member
                </Typography>
                <form onSubmit={formik.handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12} sm={12}>
                      <Input
                        id="first_name"
                        name="first_name"
                        type="text"
                        maxLength={30}
                        label="First Name"
                        onChange={formik.handleChange}
                        value={formik.values.first_name}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.first_name &&
                          Boolean(formik.errors.first_name)
                        }
                        helperText={
                          formik.touched.first_name &&
                          formik.errors.first_name ? (
                            <div className="err-msg err-msg-font">
                              {formik.errors.first_name}
                            </div>
                          ) : null
                        }
                      />
                    </Grid>

                    <Grid item md={6} xs={12} sm={12}>
                      <Input
                        id="last_name"
                        name="last_name"
                        type="text"
                        label="Last Name"
                        maxLength={30}
                        onChange={formik.handleChange}
                        value={formik.values.last_name}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.last_name &&
                          Boolean(formik.errors.last_name)
                        }
                        helperText={
                          formik.touched.last_name &&
                          formik.errors.last_name ? (
                            <div className="err-msg err-msg-font">
                              {formik.errors.last_name}
                            </div>
                          ) : null
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12} sm={12}>
                      <Input
                        id="email"
                        name="email"
                        type="email"
                        label="Email"
                        maxLength={150}
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.email && Boolean(formik.errors.email)
                        }
                        helperText={
                          formik.touched.email && formik.errors.email ? (
                            <div className="err-msg err-msg-font">
                              {formik.errors.email}
                            </div>
                          ) : null
                        }
                      />
                    </Grid>
                    <Grid item md={6} xs={12} sm={12}>
                      <div style={{ marginTop: "9px" }}>
                        <MuiPhoneNumber
                          fullWidth
                          defaultCountry={"in"}
                          size="small"
                          variant="outlined"
                          id="mobile_number"
                          name="mobile_number"
                          label="Mobile Number"
                          type="text"
                          onBlur={formik.handleBlur}
                          onChange={(value) => {
                            formik.setFieldValue("mobile_number", value);
                          }}
                          value={formik.values.mobile_number}
                          error={formik.errors.mobile_number}
                          helperText={
                            formik.touched.mobile_number &&
                            formik.errors.mobile_number ? (
                              <div className="err-msg-mobile err-msg-font">
                                {formik.errors.mobile_number}
                              </div>
                            ) : null
                          }
                          InputLabelProps={{ sx: { color: "black" } }}
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12} sm={12}>
                      <SelectBox
                        type="text"
                        id="gender"
                        name="gender"
                        label="Gender"
                        ClassName="range-select-filter"
                        value={formik.values.gender}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        options={genderOptions}
                        error={
                          formik.touched.gender && Boolean(formik.errors.gender)
                        }
                        touched={formik.touched.gender}
                      />
                      {formik.touched.gender && formik.errors.gender && (
                        <div className="err-msg-font">
                          {formik.errors.gender}
                        </div>
                      )}
                    </Grid>

                    <Grid item md={6} xs={12} sm={12}>
                      <div style={{ marginTop: "7px" }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer
                            components={["DatePicker", "DatePicker"]}
                          >
                            <DatePicker
                              id="birth_date"
                              sx={{ width: "100%" }}
                              name="birth_date"
                              fullWidth
                              label="Birth Date"
                              value={formik.values.birth_date || null}
                              format="DD/MM/YYYY"
                              onChange={(newValue) =>
                                formik.setFieldValue("birth_date", newValue)
                              }
                              slotProps={{
                                textField: {
                                  variant: "outlined",
                                  error: Boolean(
                                    formik.touched.birth_date &&
                                      formik.errors.birth_date
                                  ), //error handling
                                  helperText: formik.touched.birth_date &&
                                    formik.errors.birth_date && (
                                      <div style={{marginLeft:"-12px"}} className="err-msg-font">
                                        {formik.errors.birth_date}
                                      </div>
                                    ),
                                },
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item md={12} xs={12} sm={12}>
                      <Input
                        id="address"
                        name="address"
                        type="text"
                        label="Address"
                        onChange={formik.handleChange}
                        value={formik.values.address}
                        onBlur={formik.handleBlur}
                        maxLength={500}
                        error={
                          formik.touched.address &&
                          Boolean(formik.errors.address)
                        }
                        helperText={
                          formik.touched.address && formik.errors.address ? (
                            <div className="err-msg err-msg-font">
                              {formik.errors.address}
                            </div>
                          ) : null
                        }
                        maxRows={2}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12} sm={12}>
                      <div style={{ marginTop: "1rem" }}>
                        <Autocomplete
                          id="country"
                          name="country"
                          value={formik.values.country || null}
                          // classes={customStyles}
                          onChange={(e, value) => {
                            formik.setFieldValue("country", value);
                            dispatch(GetAllStates(value?._id));
                            dispatch(cleareAllStates());
                            formik.setFieldValue("state", "");
                            formik.setFieldValue("city", "");
                            formik.setFieldValue("pincode", "");
                          }}
                          options={allCountryData?.countryData || []}
                          autoHighlight
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.country &&
                            Boolean(formik.errors.country)
                          }
                          helperText={
                            formik.touched.country && formik.errors.country ? (
                              <div className="err-msg err-msg-font">
                                {formik.errors.country}
                              </div>
                            ) : null
                          }
                          getOptionLabel={(option) => option.country_name}
                          renderInput={(params) => (
                            <TextField
                              sx={{ color: "#000" }}
                              {...params}
                              label="Country"
                              inputProps={{
                                ...params.inputProps,
                                style: { color: "#000" },
                              }}
                            />
                          )}
                        />
                        {formik.errors.country && (
                          <Typography
                            variant="caption"
                            color="error"
                            sx={{ fontSize: "12px" }}
                          >
                            {formik.errors.country}
                          </Typography>
                        )}
                      </div>
                    </Grid>

                    <Grid item md={6} xs={12} sm={12}>
                      <div style={{ marginTop: "1rem" }}>
                        <Autocomplete
                          id="state"
                          name="state"
                          value={formik.values.state || null}
                          onChange={(e, value) => {
                            formik.setFieldValue("state", value);
                            dispatch(GetAllCities(value?._id));
                            dispatch(cleareAllCities());
                            formik.setFieldValue("city", "");
                            formik.setFieldValue("pincode", "");
                          }}
                          options={allStateData || []}
                          autoHighlight
                          getOptionLabel={(option) => option.state_name}
                          renderInput={(params) => (
                            <TextField
                              sx={{ color: "#000" }}
                              {...params}
                              label="State"
                              inputProps={{
                                ...params.inputProps,
                                style: { color: "#000" },
                              }}
                            />
                          )}
                        />
                        {formik.touched.state && formik.errors.state && (
                          <Typography
                            variant="caption"
                            color="error"
                            sx={{ fontSize: "12px" }}
                          >
                            {formik.errors.state}
                          </Typography>
                        )}
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12} sm={12}>
                      <div style={{ marginTop: "1rem" }}>
                        <Autocomplete
                          id="city"
                          name="city"
                          value={formik.values.city || null}
                          onChange={(e, value) => {
                            formik.setFieldValue("city", value);
                            dispatch(GetAllPostalCodes(value?._id));
                            dispatch(clearePostalCodes());
                            formik.setFieldValue("pincode", "");
                          }}
                          options={allCitiesData || []}
                          autoHighlight
                          getOptionLabel={(option) => option.city_name}
                          renderInput={(params) => (
                            <TextField
                              sx={{ color: "#000" }}
                              {...params}
                              label="City"
                              inputProps={{
                                ...params.inputProps,
                                style: { color: "#000" },
                              }}
                            />
                          )}
                        />
                        {formik.touched.city && formik.errors.city && (
                          <Typography
                            variant="caption"
                            color="error"
                            sx={{ fontSize: "12px" }}
                          >
                            {formik.errors.city}
                          </Typography>
                        )}
                      </div>
                    </Grid>

                    <Grid item md={6} xs={12} sm={12}>
                      <div style={{ marginTop: "1rem" }}>
                        <Autocomplete
                          id="pincode"
                          name="Postal code"
                          value={formik.values.pincode || null}
                          onChange={(e, value) =>
                            formik.setFieldValue("pincode", value)
                          }
                          options={allPostalData || []}
                          autoHighlight
                          getOptionLabel={(option) => option.postal_code}
                          renderInput={(params) => (
                            <TextField
                              sx={{ color: "#000" }}
                              {...params}
                              label="Postal code"
                              inputProps={{
                                ...params.inputProps,
                                style: { color: "#000" },
                              }}
                            />
                          )}
                        />
                        {formik.touched.pincode && formik.errors.pincode && (
                          <Typography
                            variant="caption"
                            color="error"
                            sx={{ fontSize: "12px" }}
                          >
                            {formik.errors.pincode}
                          </Typography>
                        )}
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    {/* <Grid item md={6} xs={12} sm={12}>
                      <SelectBox
                        id="role"
                        name="role"
                        label="Refferel Type"
                        ClassName="range-select-filter"
                        value={formik.values.role}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        options={allRolesData || []}
                        error={
                          formik.touched.role && Boolean(formik.errors.role)
                        }
                        touched={formik.touched.role}
                      />
                      {formik.touched.role && formik.errors.role && (
                        <div className="err-msg-font">{formik.errors.role}</div>
                      )}
                    </Grid> */}
                    <Grid item md={12} xs={12} sm={12}>
                      <SelectBox
                        id="chapter"
                        name="chapter"
                        label="Refferel Type"
                        ClassName="range-select-filter"
                        value={formik.values.chapter}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        options={allChapterData || []}
                        error={
                          formik.touched.chapter &&
                          Boolean(formik.errors.chapter)
                        }
                        touched={formik.touched.chapter}
                      />
                      {formik.touched.chapter && formik.errors.chapter && (
                        <div className="err-msg-font">
                          {formik.errors.chapter}
                        </div>
                      )}
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12} sm={12}>
                      <div style={{ marginTop: "3px" }}>
                        <Input
                          id="password"
                          name="password"
                          type="password"
                          label="Password"
                          onChange={formik.handleChange}
                          value={formik.values.password}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.password &&
                            Boolean(formik.errors.password)
                          }
                          helperText={
                            formik.touched.password &&
                            formik.errors.password ? (
                              <div className="err-msg err-msg-font">
                                {formik.errors.password}
                              </div>
                            ) : null
                          }
                        />
                      </div>
                    </Grid>

                    <Grid item md={6} xs={12} sm={12}>
                      <div style={{ marginTop: "3px" }}>
                        <Input
                          id="confirm_password"
                          name="confirm_password"
                          type="password"
                          label="Confirm Password"
                          onChange={formik.handleChange}
                          value={formik.values.confirm_password}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.confirm_password &&
                            Boolean(formik.errors.confirm_password)
                          }
                          helperText={
                            formik.touched.confirm_password &&
                            formik.errors.confirm_password ? (
                              <div className="err-msg err-msg-font">
                                {formik.errors.confirm_password}
                              </div>
                            ) : null
                          }
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item md={12} xs={12} sm={12}>
                      <Input
                        id="company_name"
                        name="company_name"
                        type="text"
                        label="Company Name"
                        maxLength={150}
                        onChange={formik.handleChange}
                        value={formik.values.company_name}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.company_name &&
                          Boolean(formik.errors.company_name)
                        }
                        helperText={
                          formik.touched.company_name &&
                          formik.errors.company_name ? (
                            <div className="err-msg err-msg-font">
                              {formik.errors.company_name}
                            </div>
                          ) : null
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12} sm={12}>
                      <Input
                        id="pan_number"
                        name="pan_number"
                        type="text"
                        maxLength={10}
                        label="Pan Number"
                        onChange={formik.handleChange}
                        value={formik.values.pan_number}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.pan_number &&
                          Boolean(formik.errors.pan_number)
                        }
                        helperText={
                          formik.touched.pan_number &&
                          formik.errors.pan_number ? (
                            <div className="err-msg err-msg-font">
                              {formik.errors.pan_number}
                            </div>
                          ) : null
                        }
                      />
                    </Grid>

                    <Grid item md={6} xs={12} sm={12}>
                      <Input
                        id="gst_number"
                        name="gst_number"
                        type="text"
                        label="GST Number"
                        maxLength={15}
                        onChange={formik.handleChange}
                        value={formik.values.gst_number}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.gst_number &&
                          Boolean(formik.errors.gst_number)
                        }
                        helperText={
                          formik.touched.gst_number &&
                          formik.errors.gst_number ? (
                            <div className="err-msg err-msg-font">
                              {formik.errors.gst_number}
                            </div>
                          ) : null
                        }
                      />
                    </Grid>
                  </Grid>

                  <Button
                    sx={{ marginTop: 4 }}
                    type="submit"
                    variant="contained"
                  >
                    Submit
                  </Button>
                </form>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default BeMemberFormComponents;
