import axios from "axios"
import { ApiURL, snackBar } from "../common"

export const getAllReferral = (page, rowsPerPage, location) => async (dispatch) => {
    try {
        dispatch({
            type: "GET_ALL_REFERRAL_LOADING",
            payload: true
        })

        await axios.get(`${ApiURL}referral/all-referral?limit=${rowsPerPage}&page=${page}&location=${location}`).then((res) => {
            console.log("getAllReferral_123456", res)
            if (res.data.status) {
                dispatch({
                    type: "GET_ALL_REFERRAL_LOADING",
                    payload: false
                })
                dispatch({
                    type: "GET_ALL_REFERRAL_SUCCESS",
                    payload: res.data.data
                })
            } else {
                dispatch({
                    type: "GET_ALL_REFERRAL_LOADING",
                    payload: false
                })
                dispatch({
                    type: "GET_ALL_REFERRAL_FAIL",
                    payload: res.data.data
                })
            }
        })
    } catch (error) {
        dispatch({
            type: "GET_ALL_REFERRAL_LOADING",
            payload: false
        })
        dispatch({
            type: "GET_ALL_REFERRAL_FAIL",
            payload: []
        })
        snackBar(error, false)
    }
}
export const createReferral = (data) => async (dispatch) => {
    try {
        dispatch({
            type: "CREATE_REFERRAL_LOADING",
            payload: true
        })

        await axios.post(`${ApiURL}referral/create-referral`, data).then((res) => {
            console.log("getAllReferral_123456", res)
            if (res.data.status) {
                dispatch({
                    type: "CREATE_REFERRAL_LOADING",
                    payload: false
                })
                dispatch({
                    type: "CREATE_REFERRAL_SUCCESS",
                    payload: res.data.data
                })
                snackBar(res.data.message, res.data.status)
            } else if (res.data.status) {
                dispatch({
                    type: "CREATE_REFERRAL_LOADING",
                    payload: false
                })
                dispatch({
                    type: "CREATE_REFERRAL_FAIL",
                    payload: res.data.data
                })
                snackBar(res.data.message, res.data.status)
            }
        })
    } catch (error) {
        dispatch({
            type: "CREATE_REFERRAL_LOADING",
            payload: false
        })
        dispatch({
            type: "CREATE_REFERRAL_FAIL",
            payload: []
        })
        snackBar(error, false)
    }
}
export const updateReferral = (id, data) => async (dispatch) => {
    try {
        dispatch({
            type: "UPDATE_REFERRAL_LOADING",
            payload: true
        })

        await axios.put(`${ApiURL}referral/update/${id}`, data).then((res) => {
            console.log("getAllReferral_123456", res)
            if (res.data.status) {
                dispatch({
                    type: "UPDATE_REFERRAL_LOADING",
                    payload: false
                })
                dispatch({
                    type: "UPDATE_REFERRAL_SUCCESS",
                    payload: res.data.data
                })
                snackBar(res.data.message, res.data.status)
            } else if (res.data.status) {
                dispatch({
                    type: "UPDATE_REFERRAL_LOADING",
                    payload: false
                })
                dispatch({
                    type: "UPDATE_REFERRAL_FAIL",
                    payload: res.data.data
                })
                snackBar(res.data.message, res.data.status)
            }
        })
    } catch (error) {
        dispatch({
            type: "UPDATE_REFERRAL_LOADING",
            payload: false
        })
        dispatch({
            type: "UPDATE_REFERRAL_FAIL",
            payload: []
        })
        snackBar(error, false)
    }
}
export const deleteReferral = (id) => async (dispatch) => {
    try {
        dispatch({
            type: "DELETE_REFERRAL_LOADING",
            payload: true
        })

        await axios.delete(`${ApiURL}referral/delete/${id}`).then((res) => {
            console.log("getAllReferral_123456", res)
            if (res.data.status) {
                dispatch({
                    type: "DELETE_REFERRAL_LOADING",
                    payload: false
                })
                dispatch({
                    type: "DELETE_REFERRAL_SUCCESS",
                    payload: res.data.data
                })
                snackBar(res.data.message, res.data.status)
            } else if (res.data.status) {
                dispatch({
                    type: "DELETE_REFERRAL_LOADING",
                    payload: false
                })
                dispatch({
                    type: "DELETE_REFERRAL_FAIL",
                    payload: res.data.data
                })
                snackBar(res.data.message, res.data.status)
            }
        })
    } catch (error) {
        dispatch({
            type: "DELETE_REFERRAL_LOADING",
            payload: false
        })
        dispatch({
            type: "DELETE_REFERRAL_FAIL",
            payload: []
        })
        snackBar(error, false)
    }
}
export const getMyReferralRequest = (page, rowsPerPage, location) => async (dispatch) => {
    try {
        dispatch({
            type: "GET_MY_REFERRAL_LOADING",
            payload: true
        })

        await axios.get(`${ApiURL}referral/my-referral-request?limit=${rowsPerPage}&page=${page}&location=${location}`).then((res) => {
            console.log("getAllReferral_123456", res)
            if (res.data.status) {
                dispatch({
                    type: "GET_MY_REFERRAL_LOADING",
                    payload: false
                })
                dispatch({
                    type: "GET_MY_REFERRAL_SUCCESS",
                    payload: res.data.data
                })
            } else {
                dispatch({
                    type: "GET_MY_REFERRAL_LOADING",
                    payload: false
                })
                dispatch({
                    type: "GET_MY_REFERRAL_FAIL",
                    payload: res.data.data
                })
            }
        })
    } catch (error) {
        dispatch({
            type: "GET_MY_REFERRAL_LOADING",
            payload: false
        })
        dispatch({
            type: "GET_MY_REFERRAL_FAIL",
            payload: []
        })
        snackBar(error, false)
    }
}