import React, { useEffect, useState } from "react";
import main_blog_1 from "../../assets/image/blog/main-blog-1.jpg";
import main_blog_2 from "../../assets/image/blog/main-blog-2.jpg";
import main_blog_3 from "../../assets/image/blog/main-blog-3.jpg";
import { GetAllBlog } from "../../redux/blogAction";
import moment from "moment/moment";

import BlogCard from "../../pages/blog/BlogCard";
import { Link } from "react-router-dom";
import { removeHtmlTags } from "../htmlToString/HtmlToString";
import SliderImage from "../slider/SliderImage";
import NoBlogContent from "../NoBlogContent/NoBlogContent";

const HomeArticles = () => {
  const [allGetBlog, setAllGetBlog] = useState([]);

  const getBlog = async () => {
    const res = await GetAllBlog();
    if (res) {
      setAllGetBlog(res.data);
    }
  };

  useEffect(() => {
    getBlog();
  }, []);

  const firstThreeBlogs = allGetBlog?.blogData?.slice(0, 3);

  return (
    <>
      {firstThreeBlogs?.length > 0 ? (
        <section id="section-05" className="pt-4">
          <div className="container">
            <div className="d-flex align-items-center mb-2 flex-wrap flex-sm-nowrap">
              <h2>
                <span className="font-weight-semibold">Some </span>
                <span className="font-weight-light">Tips &amp; Articles</span>
              </h2>
              <Link
                to="/blog"
                className="link-hover-dark-primary ml-0 ml-sm-auto w-100 w-sm-auto"
              >
                <span className="font-size-md d-inline-block mr-1">
                  All articles
                </span>
                <i className="fal fa-chevron-right" />
              </Link>
            </div>
            <div className="row">
              {firstThreeBlogs?.map((item) => {
                return (
                  <>
                    <div className="col-md-4">
                      <div className="card border-0">
                        <Link
                          to={`/blog/` + item?._id}
                          className="link-hover-dark-primary text-capitalize"
                        >
                          <img
                            style={{ height: "300px", objectFit: "cover" }}
                            src={item?.blog_images[0]}
                            alt="product 1"
                            className="card-img-top image"
                          />
                        </Link>
                        <div className="card-body px-0">
                          <h5 className="card-title lh-13 letter-spacing-25 mb-2">
                            <Link
                              to={`/blog/` + item?._id}
                              className="link-hover-dark-primary text-capitalize"
                            >
                              {item?.title}
                            </Link>
                          </h5>
                          <p className="card-title lh-13 letter-spacing-25 card-text-hide">
                            {removeHtmlTags(item?.description)}
                          </p>
                          <ul className="list-inline">
                            <li className="list-inline-item mr-0">
                              <span className="text-gray">
                                {moment(item?.createdAt).format("MMMM Do YYYY")}{" "}
                                by
                              </span>
                            </li>{" "}
                            <li className="list-inline-item">
                              {item?.user_name}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}

              {/* {ArticlesDetails && ArticlesDetails.map((item) => {
                            return (
                                <BlogCard item={item} />
                            )
                        })} */}
            </div>
          </div>
        </section>
      ) : (
        <section id="section-05" className="pt-4">
          <div className="container">
            <div className="d-flex align-items-center mb-2 flex-wrap flex-sm-nowrap">
              <h2>
                <span className="font-weight-semibold">Some </span>
                <span className="font-weight-light">Tips &amp; Articles</span>
              </h2>
            </div>
            <NoBlogContent />
          </div>
        </section>
      )}
    </>
  );
};
const ArticlesDetails = [
  {
    img: main_blog_1,
    card_body: "Tips, Travel",
    card_title: "10 best homestay in florencia that you don't miss out",
    card_date: "Aug 28th, 2017 by",
    user: "Admin",
  },
  {
    img: main_blog_2,
    card_body: "Culture",
    card_title: "Coffee On Street & Look Super Car",
    card_date: "Aug 25th, 2017 by",
    user: "David",
  },
  {
    img: main_blog_3,
    card_body: "Location",
    card_title: "MadCap Coffee At Brooklyn Town For Who Love Black Coffee",
    card_date: "Aug 19th, 2017 by",
    user: "LoganCee",
  },
];
export default HomeArticles;
