import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Pagination,
  Typography,
  Drawer,
  Stack,
  Divider,
  Button,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import PageHeader from "../../components/pageHeader/PageHeader";
import EnhancedTable from "../../components/table/EnhancedTable ";
import {
  DeleteIcon,
  EditIcon,
  CloseIcon,
  Visibility,
} from "../../assets/icons/Icons";
import "../../components/table/table.css";
import { Form, useForm } from "../../common/useForms";
import FormDialog from "../../components/dialog/dialog";
import { makeStyles } from "@material-ui/core/styles";
import Input from "../../common/Input";
import { useDispatch, useSelector } from "react-redux";

import {
  CreateChapter,
  DeleteChapter,
  GetAllChapter,
  UpdateChapter,
  getChapterAll,
} from "../../redux/action/ChapterAction";
import { useSnackbar } from "notistack";
import DeleteDialog from "../dialog/deletedialog";
import { useNavigate } from "react-router-dom";
import { getPermissions } from "../../common/permissions";
import { permissions } from "../../common/constants";
import {
  CreateBanner,
  DeleteBanner,
  GetAllBannerType,
  UpdateBanner,
} from "../../redux/action/BannerAction";
import ShowFirstImage from "../showFirstImage/ShowFirstImage";
import { GetAllTransactions } from "../../redux/action/TransactionActions";
import SelectBox from "../../common/SelectBox";
import useUserRolePermissions from "../../common/UserPermissions";
import Loader from "../loader/Loader";

const statusOptions = [
  { label: "All", value: "all" },
  { label: "Successful", value: "successful" },
  { label: "Failed", value: "failed" },
  { label: "Refuded", value: "refuded" },
];

const useStyles = makeStyles((theme) => ({
  filterMain: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "#fff",
  },
  mainFilterBox: {
    padding: "10px 20px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "200px",
    },

    [theme.breakpoints.up("md")]: {
      width: "300px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "240px !important",
    },
    [theme.breakpoints.up("xs")]: {
      width: "100%",
    },
  },

  stackFilter: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  typographyFilter: {
    fontWeight: "bold !important",
    color: "#000 !important",
    fontSize: "30px !important",
  },
  buttonFilter: {
    flexDirection: "row !important",
    marginTop: "8px",
    gap: "8px",
  },
}));

const columns = [
  {
    id: "email",
    numeric: true,
    disablePadding: false,
    label: "Email",
  },
  {
    id: "contact",
    numeric: false,
    disablePadding: true,
    label: "Mobile",
  },
  {
    id: "method",
    numeric: false,
    disablePadding: true,
    label: "Payment Method",
  },
  {
    id: "amount",
    numeric: false,
    disablePadding: true,
    label: "Amount",
  },
  {
    id: "view",
    numeric: true,
    disablePadding: false,
    label: "View",
  },
  // {
  //   id: "amount",
  //   numeric: false,
  //   disablePadding: true,
  //   label: "Amount",
  // },
].filter((el) => el !== null);

const initialValues = {
  banner_images: "",
  banner_type_id: "",
  chapter_id: "",
};

const RecordPerPage = [
  { id: 5, title: "5" },
  { id: 10, title: "10" },
];
const initialFilterValues = {
  email: "",
  phone_number: "",
  success_status: "all",
};

const TransactionList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState(false);
  const [filterData, setFilterData] = useState(true);
  const [filterDatas, setFilterDatas] = useState(initialFilterValues);
  const [selectedBanner, setSelectedBanner] = useState(null);

  const [DeleteId, SetDeleteId] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [popupName, setPopupName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [editImageValue, setEditImageValue] = useState();

  const role = useUserRolePermissions();

  const { loading, transaction } = useSelector(
    (state) => state?.TransactionReducer
  );

  useEffect(() => {
    dispatch(GetAllBannerType());
    dispatch(getChapterAll());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getChapterAll());
  }, [dispatch]);

  const handleChangeRow = (event) => {
    const rowPerPage = event.target.value;
    setRowsPerPage(event.target.value);

    dispatch(GetAllTransactions(page, rowPerPage));
  };

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const tableTitle = "SubscriptionList";

  useEffect(() => {
    if (!open) {
      dispatch(GetAllTransactions(page, rowsPerPage));
    }
  }, [page, open, dispatch]);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleClickOpen = () => {
    setPopupName("Create Banner");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    resetForm();
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if (popupName === "Edit Banner") {
      if ("banner_type_id" in fieldValues) {
        if (
          fieldValues.banner_type_id === "" ||
          fieldValues.banner_type_id === undefined ||
          fieldValues.banner_type_id === null ||
          fieldValues.banner_type_id?.trim() === ""
        ) {
          temp.banner_type_id = "Please enter banner type";
        } else {
          temp.banner_type_id = "";
        }
      }
    } else {
      if ("banner_images" in fieldValues) {
        if (
          fieldValues.banner_images === "" ||
          fieldValues.banner_images === undefined ||
          fieldValues.banner_images === null
        ) {
          temp.banner_images = "This field is required.";
        } else {
          temp.banner_images = "";
        }
      }

      if ("banner_type_id" in fieldValues) {
        if (
          fieldValues.banner_type_id === "" ||
          fieldValues.banner_type_id === undefined ||
          fieldValues.banner_type_id === null
        ) {
          temp.banner_type_id = "Please select banner type";
        } else {
          temp.banner_type_id = "";
        }
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values,
    setFieldValues,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  } = useForm(initialValues, true, validate);

  const handleEditClick = async (data) => {
    setSelectedBanner(data);
    setErrors({});
    setPopupName("Edit Banner");
    if (data?.banner_images) {
      setEditImageValue(data?.banner_images);
    }
    setValues({
      ...values,
      banner_type_id: data?.banner_type || "",
      chapter_id: data?.chapter_id || "",
    });
    setErrors({});
    setOpen(true);
  };

  const handleClickOpenDelete = (data) => {
    setOpenDelete(true);
    SetDeleteId(data._id);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleSingleDelete = async () => {
    await dispatch(DeleteBanner(DeleteId, handleCloseDelete)).then((res) => {
      if (res && res.data.status === true) {
        dispatch(GetAllTransactions(page, rowsPerPage));
      }
    });
  };

  // const fields = [
  //   {
  //     id: "banner_type_id",
  //     label: "Banner type",
  //     type: "select",
  //     options:
  //       bannerTypeData &&
  //       bannerTypeData?.map((el) => {
  //         return { value: el._id, label: el.banner_type };
  //       }),
  //     value: values.banner_type_id,
  //     name: "banner_type_id",
  //     errors: errors.banner_type_id,
  //   },

  //   {
  //     id: "banner_images",
  //     type: "file",
  //     labelAccess: "banner_images",
  //     value: values.banner_images,
  //     name: "banner_images",
  //     errors: errors.banner_images,
  //     multiple: true,
  //   },

  //   {
  //     id: "chapter_id",
  //     label: "Chapter Name",
  //     type: "select",
  //     options:
  //       chapterData &&
  //       chapterData?.map((el) => {
  //         return { value: el._id, label: el.chapter_name };
  //       }),
  //     value: values.chapter_id,
  //     name: "chapter_id",
  //     errors: errors.chapter_id,
  //   },
  // ];

  const rows =
    transaction?.TransactionData && Array.isArray(transaction?.TransactionData)
      ? transaction?.TransactionData?.map((data) => {
          return {
            _id: data._id,
            email: data.email,
            contact: data.contact,
            method: data.method,
            amount: data.amount || "-",
            view: (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <IconButton
                  onClick={() =>
                    navigate("/admin-transactions/view", {
                      state: { transactiondata: data },
                    })
                  }
                  sx={{ color: "black" }}
                >
                  <Visibility />
                </IconButton>
              </div>
            ),
          };
        })
      : [];
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    let imagesArray = [];
    if (values.file instanceof FileList) {
      imagesArray = Array.from(values.file);
    } else {
      imagesArray = Array.from([values.file]);
    }
    imagesArray.forEach((image, index) => {
      formData.append(`banner_images`, image);
    });
    formData.append("banner_type", values.banner_type_id);
    formData.append("chapter_id", values.chapter_id);

    if (validate()) {
      if (popupName === "Edit Banner") {
        dispatch(UpdateBanner(selectedBanner._id, formData, handleClose));
      } else {
        dispatch(CreateBanner(formData, handleClose));
      }
    }
  };

  const openFilterDrawer = () => {
    setFilter(true);
  };

  const closeFilterDrawer = () => {
    setFilter(false);
    setFilterData(!filterData);
  };

  const closeFilterDrawer1 = () => {
    setFilter(false);
  };

  const resetFormFilter = () => {
    setFilterDatas(initialFilterValues);
    setPage(1);
    dispatch(GetAllTransactions(1, rowsPerPage, "", "", "", ""));
    closeFilterDrawer();
  };

  const handleSubmitFilter = (e) => {
    e.preventDefault();
    const body = {
      email: filterDatas.email,
      mobile_number: filterDatas.phone_number,
      success_status: filterDatas.success_status,
    };
    setFilterDatas(body);

    const email = filterDatas.email ? filterDatas.email : "";

    const mobile_number = filterDatas.phone_number
      ? filterDatas.phone_number
      : "";

    const success_status = filterDatas.success_status
      ? filterDatas.success_status !== "all"
        ? filterDatas.success_status
        : ""
      : "";

    setPage(1);
    dispatch(
      GetAllTransactions(
        page,
        rowsPerPage,
        email,
        mobile_number,
        success_status
      )
    );
    closeFilterDrawer1();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFilterDatas((prevFilterData) => ({
      ...prevFilterData,
      [name]: value,
    }));
  };
  return (
    <Box>
      <PageHeader
        onFilterClick={openFilterDrawer}
        title="Transactions"
        onAddClick={handleClickOpen}
        isShowAdd={!!getPermissions(permissions.transaction, "is_add") && false}
      />

      {loading ? (
        <Loader />
      ) : (
        // <Box sx={{ display: "flex", justifyContent: "center" }}>
        //   <CircularProgress />
        // </Box>
        <EnhancedTable
          key={rows._id}
          columns={columns}
          rows={rows}
          order={order}
          orderBy={orderBy}
          selected={selected}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          title={tableTitle}
          tableList={transaction?.TransactionData}
        />
      )}
      {/* <FormDialog
        open={open}
        dialogTitle={popupName}
        fields={
          popupName === "Edit Banner"
            ? fields.filter((el, index) =>
                ["chapter_id", "banner_type_id", "banner_images"].includes(
                  el.name
                )
              )
            : fields.filter((el) => !["status"].includes(el.name))
        }
        loading={popupName === "Edit Banner" ? updateLoading : createLoading}
        submitText="Submit"
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        onChange={handleInputChange}
        // isAnyFieldNotEmpty={isAnyFieldNotEmpty}
      /> */}
      <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
        <form onSubmit={handleSubmitFilter} className={classes.filterMain}>
          <Box className={classes.mainFilterBox}>
            <Stack className={classes.stackFilter}>
              <Typography className={classes.typographyFilter}>
                Filter
              </Typography>

              <CloseIcon
                className="close-button"
                onClick={closeFilterDrawer1}
              />
            </Stack>
            <Box
              sx={{
                marginTop: "5px",
              }}
            >
              <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />
            </Box>

            <div>
              <Box>
                <Input
                  text="Add New"
                  variant="outlined"
                  color="primary"
                  label="Email"
                  name="email"
                  value={filterDatas.email}
                  onChange={handleChange}
                  maxLength={200}
                />
              </Box>
              <Box>
                <Input
                  text="Add New"
                  variant="outlined"
                  color="primary"
                  label="Mobile"
                  name="phone_number"
                  value={filterDatas.phone_number}
                  onChange={handleChange}
                  maxLength={10}
                />
              </Box>

              <Box>
                <SelectBox
                  name="success_status"
                  label="Status"
                  ClassName="status-select-filter"
                  value={filterDatas.success_status}
                  onChange={handleChange}
                  options={statusOptions}
                />
              </Box>

              <Stack className={classes.buttonFilter}>
                <Button
                  color="secondary"
                  size="large"
                  variant="contained"
                  className="cancel-filter"
                  onClick={resetFormFilter}
                >
                  Reset
                </Button>
                <Button
                  color="primary"
                  size="large"
                  variant="contained"
                  className="order-filter-button"
                  type="submit"
                  style={{
                    cursor: "pointer",
                  }}
                >
                  Filter
                </Button>
              </Stack>
            </div>
          </Box>
        </form>
      </Drawer>
      {/* <DeleteDialog
        open={openDelete}
        loading={deleteLoading}
        dialogTitle="Are you sure to want to delete this record?"
        handleClose={handleCloseDelete}
        handleDelete={() => handleSingleDelete(DeleteId)}
      /> */}

      <Box
        sx={{
          display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
          justifyContent: "space-between",
          alignItems: "center",
          marginLeft: { xs: "10px", sm: "20px", md: "20px", lg: "20px" },
          marginRight: { xs: "10px", sm: "15px", md: "15px", lg: "15px" },
          mt: 2,
          mb: 2,
        }}
      >
        <Box
          sx={{
            fontSize: "14px",
            marginBottom: { xs: "10px", sm: "0", md: "0", lg: "0" },
            gap: 2,
            width: { sm: "100%", md: "50%" },
          }}
          display="flex"
          alignItems="center"
        >
          <Box>
            Showing {transaction?.TransactionData?.currentPage} of{" "}
            {transaction?.TransactionData?.totalPages} Results
          </Box>
          <Box display="flex" sx={{ gap: 1 }} alignItems="center">
            <Typography>Records per page</Typography>

            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                id="limites"
                name="limites"
                value={rowsPerPage}
                onChange={handleChangeRow}
                displayEmpty
                sx={{ maxWidth: "65px", maxHeight: "2.2rem" }}
                inputProps={{ "aria-label": "Without label" }}
              >
                {RecordPerPage?.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name || item.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Pagination
          count={transaction?.TransactionData?.totalPages}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
          sx={{
            "& .MuiButtonBase-root ": {
              color: "#000 !important",
            },
            "& .Mui-selected ": {
              background: "#4b4b4e70 !important",
            },
            "& .Mui-disabled ": {
              background: "#6c696966 !important",
              color: "#000000 !important",
              opacity: 1,
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default TransactionList;
