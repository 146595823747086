import {
  Box,
  CardMedia,
  Grid,
  Typography,
  useTheme,
  InputLabel,
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { Form, useForm } from "../../common/useForms";
import Input from "../../common/Input";
import { Link, useNavigate } from "react-router-dom";
import ButtonCom from "../../common/Button";
import Logo from "../../assets/images/logo-home.svg";
import { makeStyles } from "@material-ui/core/styles";
import { Visibility, VisibilityOff } from "../../assets/icons/Icons";
import Checkbox from "../../common/CheckBox";
import { loginUser } from "../../redux/action/AuthAction";
import { useSnackbar } from "notistack";
import Cookies from "js-cookie";
import { v4 as uuidv4 } from "uuid";

const useStyles = makeStyles((theme) => ({
  main: {
    background:
      "linear-gradient(317deg, rgba(254,118,120,1) 9%, rgba(21,62,128,1) 100%)",
    height: "100%",

    [theme.breakpoints.down("sm")]: {
      height: "100vh",
      padding: "10px",
    },

    [theme.breakpoints.up("md")]: {
      height: "100vh",
    },

    display: "flex",
    alignItems: "center",
  },
  subMain: {
    width: "450px",
    margin: "0 auto",
    backgroundColor: "#fff",
    borderRadius: "20px",
  },

  logo: {
    marginBottom: "20px",
    width: "60% !important",
    display: "block",
    margin: "0 auto",
  },
  mainCard: {
    boxShadow: "none !important",
    color: "#000 !important",
    display: "block",
    textAlign: "center",
    minWidth: 275,
  },
  boxCard: {
    [theme.breakpoints.up("xs")]: {
      padding: "20px",
    },

    [theme.breakpoints.up("md")]: {
      padding: "40px",
    },
  },

  typography: {
    fontSize: "24px !important",
    fontWeight: "bold !important",
    color: "#000",
  },

  send: {
    ":hover": { bgcolor: "#153E80", color: "#FFFFFF" },
    marginTop: "20px !important",
    textTransform: "none !important",
    fontSize: "16px !important",
    color: "#FFFFFF !important",
    width: "100%",
    backgroundColor: "#153E80 !important",
    border: "2px solid #153E80 !important",
    borderRadius: "15px !important",
    padding: "10px 40px !important",
    "&.Mui-disabled": {
      backgroundColor: "#153E80 !important",
      color: "#fff !important",
    },
    marginLeft: "3px !important",
  },

  mainPassword: {
    marginTop: "10px",
    width: "100%",
  },

  outlinePassword: {
    "& .MuiInputBase-input": {
      padding: "12px 14px !important",
    },

    "& .MuiOutlinedInput-notchedOutline": {
      paddingTop: "10px!important",
      borderColor: "rgba(107, 114, 128, .5)",

      borderRadius: "7px!important",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "blue",
    },
    paddingTop: "0px",
    color: "#000 !important",
  },
}));

const initialValues = { mobile: "" };

const Auth = () => {
  const validate = (fieldValues = values) => {
    let emailError = "";
    let mobileError = "";
    let passwordError = "";

    if (!fieldValues.mobile && !fieldValues.email) {
      emailError = "Please enter email";
      mobileError = "Please enter mobile";
    }
    if (!fieldValues.mobile && "email" in fieldValues) {
      const emailRegex =
        /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.(com|org|gov|edu|net|mil|in)$/;
      const isEmailValid =
        emailRegex.test(fieldValues.email) && fieldValues.email.endsWith("");
      if (!isEmailValid) {
        emailError = "Invalid Email";
      }
    }

    if (!fieldValues.email && "mobile" in fieldValues) {
      const regex = /^\d{10}$/;
      if (!regex.test(fieldValues.mobile)) {
        mobileError = "Invalid phone number";
      }
    }

    if (!password) {
      passwordError = "Please enter password";
    } else {
      passwordError = "";
    }

    setErrors({
      email: emailError,
      mobile: mobileError,
      password: passwordError,
    });

    return !(emailError || mobileError || passwordError);
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);
  const uuid = uuidv4();
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [password, setPassword] = useState("");

  const { loading } = useSelector((state) => state.auth);

  const handleRememberMeChange = (event) => {
    const isChecked = event.target.checked;
    setRememberMe(isChecked);

    // Cookies.set("rememberMe", isChecked.toString(), { expires: 7 });
  };

  useEffect(() => {
    try {
      const rememberMeCookie = JSON.parse(Cookies.get("rememberMe")) ?? {};
      setRememberMe(rememberMeCookie);
      setValues({
        email: rememberMeCookie?.email,
        mobile: rememberMeCookie?.mobile_number,
        password: rememberMeCookie?.password,
      });
      setPassword(rememberMeCookie?.password);
    } catch (err) {}
    // setShow(true);
    // const rememberMeValue = rememberMeCookie === "true";
  }, []);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validate()) {
      const loginData = values.email
        ? { password: password, email: values.email, device_id: uuid }
        : { password: password, mobile_number: values.mobile, device_id: uuid };
      if (rememberMe) {
        Cookies.set("rememberMe", JSON.stringify(loginData), { expires: 7 });
      }
      dispatch(loginUser(loginData, navigate));
    } else {
      return;
    }
  };

  const isAnyFieldFilled = Object.values(values).some((value) =>
    Boolean(value)
  );

  return (
    <Box className={classes.main}>
      <Box className={classes.subMain}>
        <Grid
          container
          item
          columns={{ xs: 4, sm: 12, md: 12 }}
          spacing={1}
          sx={{
            alignItems: "center",
          }}
        >
          <Grid item xs={12} md={12}>
            <Box className={classes.boxCard}>
              <Link to="/">
                <CardMedia
                  className={classes.logo}
                  component="img"
                  image={Logo}
                  alt="Paella dish"
                />
              </Link>
              <Form onSubmit={handleSubmit}>
                <Box sx={{ marginBottom: "10px" }}>
                  <Input
                    disable={Boolean(isAnyFieldFilled && values.mobile)}
                    text="Add New"
                    variant="outlined"
                    color="primary"
                    label="Email"
                    name="email"
                    value={values.email}
                    onChange={handleInputChange}
                    maxLength={150}
                  />

                  {Boolean(errors.email) ? (
                    <p
                      style={{
                        color: "rgb(255 0 0)",
                        marginTop: "4px",
                        marginBottom: "4px",
                        fontSize: "14px",
                      }}
                    >
                      {errors.email}
                    </p>
                  ) : (
                    ""
                  )}
                </Box>
                <Typography
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: 600,
                  }}
                >
                  OR
                </Typography>
                <Box sx={{ marginBottom: "10px" }}>
                  <Input
                    text="Add New"
                    disable={isAnyFieldFilled && values.email}
                    variant="outlined"
                    color="primary"
                    label="Mobile"
                    name="mobile"
                    value={values.mobile}
                    onChange={handleInputChange}
                    maxLength={10}
                  />

                  {Boolean(errors.mobile) ? (
                    <p
                      style={{
                        color: "rgb(255 0 0)",
                        marginTop: "4px",
                        marginBottom: "4px",
                        fontSize: "14px",
                      }}
                    >
                      {errors.mobile}
                    </p>
                  ) : (
                    ""
                  )}
                </Box>
                <FormControl
                  sx={{ marginTop: "10px", width: "100%" }}
                  variant="outlined"
                >
                  <InputLabel
                    htmlFor="outlined-adornment-password"
                    sx={{ color: "#000", bgcolor: "#fff", lineHeight: "1" }}
                  >
                    Password
                  </InputLabel>
                  <OutlinedInput
                    sx={{
                      "& .MuiInputBase-input": {
                        padding: "12px 14px !important",
                      },

                      "& .MuiOutlinedInput-notchedOutline": {
                        paddingTop: "10px!important",
                        borderColor: "rgba(107, 114, 128, .5)",

                        borderRadius: "7px!important",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "black",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "blue",
                      },
                      paddingTop: "0px",
                      color: "#000",
                    }}
                    id="outlined-adornment-password"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          sx={{ color: "#000" }}
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {Boolean(errors.password) ? (
                    <p
                      style={{
                        color: "rgb(255 0 0)",
                        marginTop: "4px",
                        marginBottom: "4px",
                        fontSize: "14px",
                      }}
                    >
                      {errors.password}
                    </p>
                  ) : (
                    ""
                  )}
                </FormControl>

                <Grid
                  container
                  item
                  xs={12}
                  spacing={1}
                  style={{ marginTop: "0rem", alignItems: "center" }}
                >
                  <Grid item xs={6}>
                    <Checkbox
                      sx={{ fontSize: "20px" }}
                      text="Add New"
                      variant="outlined"
                      color="primary"
                      label="Remember Me"
                      checked={rememberMe}
                      onChange={handleRememberMeChange}
                    />
                  </Grid>
                </Grid>

                <ButtonCom
                  className={classes.send}
                  type="submit"
                  text={
                    loading ? (
                      <CircularProgress
                        sx={{
                          color: "#fff",
                          width: "28px !important",
                          height: "28px !important",
                        }}
                      />
                    ) : (
                      "Login"
                    )
                  }
                ></ButtonCom>
              </Form>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Auth;
